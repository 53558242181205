import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { Divider } from '@mui/material';

import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';

const Members = ({ data }) => {
    return (
        <Scrollbars style={{ height: '200px' }}>
            {data?.length
                ? data?.map((item) => {
                    return (
                        <React.Fragment key={item.id}>
                            <div className="info__item">
                                <span>Категория участника:</span>
                                <span>{item.category || messages.INFO_IS_NOT_FOUND}</span>
                            </div>
                            <div className="info__item">
                                <span>ТС:</span>
                                <span>{item.ts || messages.INFO_IS_NOT_FOUND}</span>
                            </div>
                            <div className="info__item">
                                <span>Сведения об оставлении места ДТП:</span>
                                <span>{item.leaving_scene || messages.INFO_IS_NOT_FOUND}</span>
                            </div>
                            <div className="info__item">
                                <span>ФИО участника:</span>
                                <span>{item.fio || messages.INFO_IS_NOT_FOUND}</span>
                            </div>
                            <div className="info__item">
                                <span>Год рождения:</span>
                                <span>{item.birth_year || messages.INFO_IS_NOT_FOUND}</span>
                            </div>
                            <div className="info__item">
                                <span>Пол:</span>
                                <span>{item.gender_text || messages.INFO_IS_NOT_FOUND}</span>
                            </div>
                            <div className="info__item">
                                <span>{titles.SEVERITY_AFTER_DTP}:</span>
                                <span>{item.severity_text || messages.INFO_IS_NOT_FOUND}</span>
                            </div>
                            <div className="info__item">
                                <span>Непосредственные нарушения ПДД:</span>
                                <span>{item.direct_violations_text || 'Нет нарушений'}</span>
                            </div>
                            <div className="info__item">
                                <span>Сопутствующие нарушения ПДД:</span>
                                <span>{item.associated_violations_text || 'Нет нарушений'}</span>
                            </div>
                            <div className="info__item">
                                <span>Использовался ли ремень:</span>
                                <span>{item.fastened_text || messages.INFO_IS_NOT_FOUND}</span>
                            </div>
                            <div className="info__item">
                                <span>Тип детского удерживающего устройства:</span>
                                <span>{item.type_of_child_restraint || messages.INFO_IS_NOT_FOUND}</span>
                            </div>
                            <div className="info__item">
                                <span>Степень опьянения:</span>
                                <span>{item.intoxication_p ?? messages.INFO_IS_NOT_FOUND}</span>
                            </div>
                            <div className="info__item">
                                <span>Водительский стаж:</span>
                                <span>{item.experience || messages.INFO_IS_NOT_FOUND}</span>
                            </div>
                            <Divider/>
                        </React.Fragment>
                    );
                })
                : <div className="info__item">
                    {messages.INFO_IS_NOT_FOUND}
                </div>
            }
        </Scrollbars>
    );
};

export default Members;
