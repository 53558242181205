import moduleName from './module';

const prefix = `${moduleName}`;

export const GET_TYPES = `${prefix}/GET_TYPES`;
export const TYPES_LOADED = `${prefix}/TYPES_LOADED`;

export const GET_POLYGON = `${prefix}/GET_POLYGON`;
export const POLYGON_LOADED = `${prefix}/POLYGON_LOADED`;

export const GET_LIST = `${prefix}/GET_LIST`;
export const LIST_LOADED = `${prefix}/LIST_LOADED`;
export const LIST_LOADING = `${prefix}/LIST_LOADING`;

export const GET_DEVICE_COMPANY_LINK = `${prefix}/GET_DEVICE_COMPANY_LINK`;
export const CREATE_DEVICE_COMPANY_LINK = `${prefix}/CREATE_DEVICE_COMPANY_LINK`;
export const DELETE_DEVICE_COMPANY_LINK = `${prefix}/DELETE_DEVICE_COMPANY_LINK`;
export const DEVICE_COMPANY_LINK_LOADED = `${prefix}/DEVICE_COMPANY_LINK_LOADED`;
export const CLEAR_COMPANY_LINK = `${prefix}/CLEAR_COMPANY_LINK`;

export const LOADING = `${prefix}/LOADING`;

export const SET_FILTER = `${prefix}/SET_FILTER`;

export const SET_ACTIVE = `${prefix}/SET_ACTIVE`;

export const GET_STATUSES = `${prefix}/GET_STATUSES`;
export const STATUSES_LOADED = `${prefix}/STATUSES_LOADED`;

export const GET_PROJECTS = `${prefix}/GET_PROJECTS`;
export const PROJECTS_LOADED = `${prefix}/PROJECTS_LOADED`;

export const GET_DEVICE_DATA = `${prefix}/GET_DEVICE_DATA`;
export const DEVICE_DATA_LOADED = `${prefix}/DEVICE_DATA_LOADED`;
export const CLEAR_DEVICE_DATA = `${prefix}/CLEAR_DEVICE_DATA`;

export const GET_EVENTS = `${prefix}/GET_EVENTS`;
export const EVENTS_LOADED = `${prefix}/EVENTS_LOADED`;
export const CLEAR_EVENTS = `${prefix}/CLEAR_EVENTS`;

export const SET_WS_DATA = `${prefix}/SET_WS_DATA`;

export const LOAD_METEO_SETTINGS = `${prefix}LOAD/METEO/SETTINGS`;
export const LOADED_METEO_SETTINGS = `${prefix}/LOADED/METEO/SETTINGS`;
export const LOADING_METEO_SETTINGS = `${prefix}/LOADING/METEO/SETTINGS`;
export const EDIT_METEO_SETTING = `${prefix}/EDIT/METEO/SETTING`;
export const EDIT_SETTINGS_LIST = `${prefix}/EDIT_SETTINGS_LIST`;

export const LOAD_METEO_ALERTS = `${prefix}/LOAD_METEO_ALERTS`;
export const LOADED_METEO_ALERTS = `${prefix}/LOADED_METEO_ALERTS`;

export const LOAD_METEO_OUTPUT_PARAMS = `${prefix}/LOAD_METEO_OUTPUT_PARAMS`;
export const LOADED_METEO_OUTPUT_PARAMS = `${prefix}/LOADED_METEO_OUTPUT_PARAMS`;

export const LOAD_METEO_OUTPUT_SETTINGS = `${prefix}/LOAD_METEO_OUTPUT_SETTINGS`;
export const LOADED_METEO_OUTPUT_SETTINGS = `${prefix}/LOADED_METEO_OUTPUT_SETTINGS`;
export const LOADING_METEO_OUTPUT_SETTINGS = `${prefix}/LOADING_METEO_OUTPUT_SETTINGS`;
export const EDIT_METEO_OUTPUT_SETTINGS = `${prefix}/EDIT_METEO_OUTPUT_SETTINGS`;
export const DELETE_METEO_OUTPUT_SETTINGS = `${prefix}/DELETE_METEO_OUTPUT_SETTINGS`;

export const LOAD_DEVICES_GROUP = `${prefix}/LOAD_DEVICES_GROUP`;
export const LOADED_DEVICES_GROUP = `${prefix}/LOADED_DEVICES_GROUP`;
export const LOADING_DEVICES_GROUP = `${prefix}/LOADING_DEVICES_GROUP`;
export const CREATE_DEVICES_GROUP = `${prefix}/CREATE_DEVICES_GROUP`;
export const EDIT_DEVICES_GROUP = `${prefix}/EDIT_DEVICES_GROUP`;
export const DELETE_DEVICES_GROUP = `${prefix}/DELETE_DEVICES_GROUP`;

export const LOADING_BUTTON = `${prefix}/LOADING_BUTTON`;

export const LOAD_SHOWCASE = `${prefix}/LOAD_SHOWCASE`;
export const LOADING_SHOWCASE = `${prefix}/LOADING_SHOWCASE`;
export const LOADED_SHOWCASE = `${prefix}/LOADED_SHOWCASE`;

export const LOAD_METEO_BY_RADIUS = `${prefix}/LOAD_METEO_BY_RADIUS`;
export const LOADED_METEO_BY_RADIUS = `${prefix}/LOADED_METEO_BY_RADIUS`;

export const LOAD_SETTINGS_GROUP = `${prefix}/LOAD_SETTINGS_GROUP`;
export const LOADING_SETTINGS_GROUP = `${prefix}/LOADING_SETTINGS_GROUP`;
export const LOADED_SETTINGS_GROUP = `${prefix}/LOADED_SETTINGS_GROUP`;
export const EDIT_SETTINGS_GROUP = `${prefix}/EDIT_SETTINGS_GROUP`;

export const LOAD_DEVICE_STATUSES = `${prefix}/LOAD_DEVICE_STATUSES`;
export const LOADED_DEVICE_STATUSES = `${prefix}/LOADED_DEVICE_STATUSES`;

export const LOAD_METEO_FORECAST = `${prefix}/LOAD_METEO_FORECAST`;
export const LOADING_METEO_FORECAST = `${prefix}/LOADING_METEO_FORECAST`;
export const LOADED_METEO_FORECAST = `${prefix}/LOADED_METEO_FORECAST`;