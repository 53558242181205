import { useState } from 'react';
import { FormControl, IconButton, TextField, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import ConfirmDelete from 'components/common/ConfirmModal';
import messages from 'helpers/constants/messages';

// import RenderKoapSelect from '../RenderKoapSelect';
import RenderDuploCodesSelect from '../RenderDuploCodesSelect';


const ZoneItem = ({ zone, index, fields = [], onChange, onDelete, validation }) => {
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);

    const onChangeItem = (e) => {
        const { name, value } = e.target;
        onChange({ ...zone, [name]: value });
    };

    return (
        <div className="row align-items-center" style={{ backgroundColor: '#ebebeb', padding: '5px' }}>
            <div className="row__item">
                <div className="row align-items-center">
                    {fields.map(el => (
                        (el.field === 'duplo_codes' || el.field === 'koap')
                            ?  null
                            : <FormControl key={el.field} className="row__item" size="small" variant="outlined">
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    label={el.name}
                                    required={el.require}
                                    value={zone[el.field]}
                                    name={el.field}
                                    onChange={onChangeItem}
                                    type={el.type}
                                    error={(!zone[el.field] && el.require) || validation.isKey(`zones.${index}.${el.field}`)}
                                    helperText={validation.get(`zones.${index}.${el.field}`)}
                                />
                            </FormControl>
                    ))}
                </div>
                <RenderDuploCodesSelect
                    item={zone.duplo_codes}
                    onChange={(value) => onChange({ ...zone, duplo_codes: value })}
                    field={fields.find(el => el.field === 'duplo_codes') || {}}
                    error={validation.isKey(`zones.${index}.duplo_codes`)}
                    helperText={validation.get(`zones.${index}.duplo_codes`)}
                />
            </div>
            <Tooltip title={'Удалить зону'}>
                <IconButton onClick={() => setShowConfirmDelete(true)} size="small">
                    <DeleteIcon fontSize="inherit" />
                </IconButton>
            </Tooltip>


            {showConfirmDelete
        && <ConfirmDelete
            open={showConfirmDelete}
            onClose={()=> setShowConfirmDelete(false)}
            onSuccess={()=> {
                setShowConfirmDelete(false);
                onDelete();
            }}
            message={messages.CONFIRM_DELETE}
        />
            }
        </div>
    );
};

export default ZoneItem;
