import moduleName from './module';

const prefix = `${moduleName}`;

export const GET_LIST = `${prefix}/GET_LIST`;
export const LIST_LOADED = `${prefix}/LIST_LOADED`;
export const LIST_LOADING = `${prefix}/LIST_LOADING`;

export const LOAD_STATUSES = `${prefix}/LOAD_STATUSES`;
export const LOADED_STATUSES = `${prefix}/LOADED_STATUSES`;
export const LOADING_STATUSES = `${prefix}/LOADING_STATUSES`;

export const LOAD_SIDEBAR = `${prefix}/LOAD_SIDEBAR`;
export const LOADED_SIDEBAR = `${prefix}/LOADED_SIDEBAR`;
export const LOADING_SIDEBAR = `${prefix}/LOADING_SIDEBAR`;
export const CLEAR_SIDEBAR = `${prefix}/CLEAR_SIDEBAR`;

export const LOAD_POLYGON = `${prefix}/LOAD_POLYGON`;
export const LOADED_POLYGON = `${prefix}/LOADED_POLYGON`;
export const LOADING_POLYGON = `${prefix}/LOADING_POLYGON`;

export const SET_FILTERS = `${prefix}/SET_FILTERS`;
export const SET_ACTIVE = `${prefix}/SET_ACTIVE`;