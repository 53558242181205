import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import titles from 'helpers/constants/titles';
import renderAddress from 'helpers/renderAddress';
import { metroSelectors } from 'modules/Metro';
import metroTitles from 'modules/Metro/utils/helpers/constants';
import Information from 'components/common/Information';

import icon from '../icons/icon';

import ReportsList from './ReportsList';

const PopUp = ({ id = 0 }) => {
    const vestibulePolygon = useSelector(metroSelectors.vestibulePolygon);

    const data = useMemo(
        () => vestibulePolygon?.find((item) => item.id === id) || {},
        [id, vestibulePolygon]
    );

    const fields = {
        address_text: {
            title: titles.ADDRESS,
            value: ({ address, address_text }) => address_text || renderAddress(address),
        },
        branch: {
            title: metroTitles.BRANCH,
            value: ({ station }) => station?.branch?.name || null,
        },
        number_on_branch: {
            title: metroTitles.NUMBER_ON_BRANCH,
            value: ({ station }) => station?.number_on_branch || null,
        },
    };

    return (
        <div className="map-popup">
            <div className="map-popup_header">
                <div>
                    <div
                        className="img"
                        dangerouslySetInnerHTML={{ __html: icon({ fill: '#fff' }) }}
                    />
                    <span style={{ color: data?.station?.branch?.color }}>
                        {data?.station?.name || ''}
                    </span>
                    {data?.name && <span>({data?.name})</span>}
                </div>
            </div>
            <div className="map-popup_body">
                {data?.status === 2 && (
                    <div className="flex-center error">
                        <h1>Станция закрыта!</h1>
                    </div>
                )}

                <Information data={data} title={fields} returnNull />

                <ReportsList id={id} />
            </div>
        </div>
    );
};

export default PopUp;
