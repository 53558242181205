import React, { useEffect, useState } from 'react';

import GetReports from 'components/common/GetReports';
import SimpleSelect from 'components/common/SimpleSelect';

const GetEventReport = ({ handleDownload, typeList, access, test_id_prefix = '' }) => {

    const [ selectedType,  setSelectedType ] = useState('');
    const [ selectedFormats,  setSelectedFormats ] = useState([]);
    const onDownload = (formats) => {
        handleDownload(formats, selectedType);
        setSelectedType('');
        setSelectedFormats([]);
    };

    useEffect(() => {
        if (!selectedType) {
            setSelectedFormats([]);
        }
    }, [selectedType]);

    const availableTypeList = access 
        ? typeList.reduce((res, el) => {
            // если report id есть в access и он true
            if (access[el.key]) {
                res.push(el);
            }
            return res;
        }, [])
        : typeList;


    return (
        <div className="row noMargin">
            <SimpleSelect
                selected={selectedType}
                endpoint={availableTypeList}
                setSelected={setSelectedType}
                optionLabel="name"
                optionKey="key"
                optionValue="key"
                title="Тип отчета"
                test_id_prefix={`${test_id_prefix}/report`}
            />
            <GetReports
                onDownload={onDownload}
                disabled={selectedType.length === 0}
                onChange={setSelectedFormats}
                selected={selectedFormats}
                reportId={selectedType}
                test_id_prefix={test_id_prefix}
            />
        </div>
    );
};

export default GetEventReport;