import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadCategoriesVehicle, loadVehicleList } from 'redux/TransportRoad/actions';
import { transportRoadSelectors } from 'redux/TransportRoad';
import messages from 'helpers/constants/messages';
import { useStoreProp } from 'helpers/hooks';
import titles from 'helpers/constants/titles';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import { LSContainer } from 'components/common/List';
import PageLayout from 'components/layout/PageLayout';

import Item from './Item';
import ModalForm from './ModalForm';
import Filter from './Filter';

const RWVehicles = () => {
    const dispatch = useDispatch();

    const categoryTS = useStoreProp(
        loadCategoriesVehicle,
        'transportRoad',
        'categoriesVehicle'
    );

    const listData = useSelector(transportRoadSelectors.vehiclesData);
    const listMeta = useSelector(transportRoadSelectors.vehiclesMeta);
    const loading = useSelector(transportRoadSelectors.vehicleLoading);

    const [openModal, setOpenModal] = useState(false);
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, data: {} });

    const reloadList = (isDelete) => {
        isDelete && listData?.length === 1 && listMeta?.last_page > 1
            ? setParams({ ...params, page: params.page - 1 })
            : dispatch(loadVehicleList(params.page, limit, params.data));
    };

    useEffect(() => {
        dispatch(loadVehicleList(params.page, limit, params.data));
    }, [dispatch, limit, params]);

    const renderContent = () => {
        return (listData?.length > 0
            ? (<LSContainer
                headers={[
                    { title: titles.GRZ, width: '23%' },
                    { title: titles.BRAND, width: '23%' },
                    { title: titles.MODEL, width: '23%' },
                    { title: titles.TYPE_OF_VEHICLE, width: '23%' },
                    { title: titles.ACTIONS, align: 'right', isActions: true }
                ]}
            >
                {listData?.map((item) => (
                    <Item
                        key={item.id}
                        item={item}
                        reloadList={reloadList}
                        categoryTS={categoryTS}
                    />
                ))}
            </LSContainer>)
            : (!loading && <div>{messages.DATA_IS_NOT_FOUND}</div>)
        );
    };

    return (
        <>
            <PageLayout
                header="Дорожная техника"
                filters={<Filter setParams={setParams}/>}
                loading={loading}
                informPanelProps={{
                    buttons: (
                        <>
                            <FormButtons
                                buttons={[
                                    {
                                        ...buttonsTypes.add,
                                        onClick: () => setOpenModal(true),
                                    }
                                ]}
                                positionLeft
                                noPadding
                            />
                        </>),

                    total: listMeta?.total
                }}
                content={renderContent}
                paginationProps={{
                    loadList: (page) => setParams({ ...params, page }),
                    list: listMeta,
                    limit,
                    setLimit
                }}
            />

            {openModal && (
                <ModalForm
                    isOpen={openModal}
                    onClose={() => setOpenModal(false)}
                    reloadList={() => setParams({ page: 1, data: {} })}
                    isNew
                />
            )}
        </>
    );
};

export default RWVehicles;
