import { useMemo } from 'react';
import classNames from 'classnames';
import { FormHelperText } from '@mui/material';

import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import InformPanel from 'components/layout/PageLayout/InformPanel';

import Item from './Item';

const Formula = ({
    required,
    lat,
    lon,
    selected,
    setSelected,
    onSave,
    error,
    helperText,
}) => {

    const isDisabled = useMemo(() => {
        return selected
            .reduce((r, item, index) => {
                const {
                    operator,
                    puid,
                } = item;

                return r
                    || index === 0
                    ? !puid
                    : !puid || !operator;
            }, false);
    }, [selected]);

    // обратное конвертирование
    const reverseConvert = (list) => list
        ?.reduce((res, { operator, puid }) => operator
            ? [...res, operator, puid.id]
            : [...res, puid.id], []);

    const handleSave = (data) => onSave(reverseConvert(data));

    // изменение
    const handleChange = (index) => (item) => {
        const values = [
            ...selected.slice(0, index),
            {
                ...selected[index],
                ...item,
            },
            ...selected.slice(index + 1),
        ];
        setSelected(values);
        handleSave(values);
    };

    // удаление
    const handleDelete = (index) => () => {
        const values = [
            ...selected.slice(0, index),
            ...selected.slice(index + 1),
        ];
        setSelected(values);
        handleSave(values);
    };

    const handleAdd = () => {
        setSelected([
            ...selected,
            {
                operator: '',
                puid: '',
            }
        ]);
    };

    return (
        <div>
            <h2
                className={classNames({
                    'error': error || (!!required && selected.length === 0)
                })}
            >
                Формула расчета {!!required ? '*' : ''}
            </h2>
            {helperText && (
                <FormHelperText error>
                    {helperText}
                </FormHelperText>
            )}

            <InformPanel
                buttons={
                    <FormButtons
                        noPadding
                        noMarginLeft
                        buttons={[
                            {
                                ...buttonsTypes.add,
                                onClick: handleAdd,
                                disabled: isDisabled,
                            }
                        ]}
                    />
                }
            />

            {selected.map((item, index) => (
                <Item
                    lat={lat}
                    lon={lon}
                    key={index}
                    index={index}
                    value={item}
                    onChange={handleChange(index)}
                    onDelete={handleDelete(index)}
                    // подсветить все выбранные
                    selected={selected?.map(({ puid }) => puid)}
                />
            ))}
        </div>
    );
};

export default Formula;
