import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { trafficLightsSelectors } from 'redux/TrafficLights';
import { loadedTrafficLightsList, loadTrafficLightsList } from 'redux/TrafficLights/actions';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import InformPanel from 'components/layout/PageLayout/InformPanel';

import SelectLightsModal from './SelectLightsModal';
import RenderList from './RenderList';

const TrafficLightList = ({ light_ids, onChange, latlng }) => {
    const dispatch = useDispatch();
    const lightListData = useSelector(trafficLightsSelectors.lightListData);
    const [list, setList] = useState([]);
    const [openSelectLights, setOpenSelectLights] = useState(false);

    useEffect(() => {
        if (light_ids.length) {
            dispatch(loadTrafficLightsList(1, light_ids.length, { ids: light_ids, with_deleted: 1 }));
        } else {
            dispatch(loadTrafficLightsList(1, light_ids.length, { ids: [0], with_deleted: 1 }));
        }
        return () => dispatch(loadedTrafficLightsList({ data: [] }));
    }, [dispatch, light_ids]);

    useEffect(() => {
        setList(lightListData);
    }, [lightListData]);

    const onSave = (data) => {
        setList(data);
        onChange(data.map(({ id }) => id));
        setOpenSelectLights(false);
    };

    return (
        <>
            <h2 style={{ marginTop: '0.5rem' }}>Список светофоров:</h2>

            <InformPanel
                buttons={
                    <FormButtons
                        noMarginLeft
                        noPadding
                        buttons={[
                            {
                                ...(light_ids.length > 0 ? buttonsTypes.change : buttonsTypes.add),
                                color: 'infoButton',
                                onClick: () => setOpenSelectLights(true),
                                disabled: !latlng[0] || !latlng[1],
                            },
                        ]}
                    />
                }
            />

            {list.length > 0 && <RenderList items={list} />}

            {openSelectLights && (
                <SelectLightsModal
                    isOpen={openSelectLights}
                    onSave={onSave}
                    onClose={() => setOpenSelectLights(false)}
                    latlng={latlng}
                    trafficLights={list}
                />
            )}
        </>
    );
};

export default TrafficLightList;
