import React from 'react';
import valid from 'img/icons/complex/valid.png';
import cn from 'classnames';
import { Tooltip } from '@mui/material';

import type { Complex } from 'components/pages/Dictionaries/DorisControl/VFComplex/types';

import styles from '../vfcomplex.module.scss';

interface CertificateIconProps {
    complex: Complex;
}

const CertificateIcon = ({
    complex: { is_not_valid_verification, verification_valid_till },
}: CertificateIconProps) => {

    const generateTooltip = (title: string, icon: JSX.Element) => (<Tooltip title={title}>{icon}</Tooltip>);

    if (is_not_valid_verification === null && verification_valid_till === null) {
        return generateTooltip(
            'Сертификат не указан', 
            <i className={cn('far fa-file-certificate', styles.fa_icon)}/>
        );
    }

    if (is_not_valid_verification) {
        return generateTooltip(
            'Сертификат просрочен', 
            <i className={cn('fad fa-file-certificate', styles.fa_icon, styles.fa_icon_color)}/>
        );
    }

    return generateTooltip(
        'Действующий сертификат', 
        <img style={{ marginRight: 0, width: 20, height: 20 }} src={valid} alt="cert"/>
    );
};

export default CertificateIcon;
