import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Collapse } from '@mui/material';
import MapIcon from '@mui/icons-material/Map';

import {
    loadVehicleCategories,
    loadVehicleHistoryModal,
    loadWorkorderLogs
} from 'redux/TransportRoad/actions';
import { setHistoryOption } from 'redux/TransportRoad/actions';
import { transportRoadSelectors } from 'redux/TransportRoad';
import messages from 'helpers/constants/messages';
import useStoreProp from 'helpers/hooks/useStoreProp';
import {
    fullDateTimeWithTimeZone,
    getDateObjectFromFormat,
    getEndOf,
    getHumanDate,
    getStartOf,
} from 'helpers/date.config';
import titles from 'helpers/constants/titles';
import types from 'components/common/Transport/RoadTransportHistory/types';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import DateRange from 'components/common/Dates/DateRange';
import { ActionMore, ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';
import CircleStatus from 'components/common/CircleStatus';
import colorStatus from 'helpers/workorder.config';

import WorkorderEvents from './Events';

import type { ItemPropTypes } from './types';

const Item = ({ item, selectedId, setSelect }: ItemPropTypes) => {
    const dispatch = useDispatch();

    const [openModal, setOpenModal] = useState(false);

    const [startDate, setStartDate] = useState<Date | null>(getDateObjectFromFormat(`${item.date_at} ${item.start_at || '00:00'}`, 'yyyy-MM-dd HH:mm'));
    const [endDate, setEndDate] = useState<Date | null>(getDateObjectFromFormat(`${item.date_at} ${item.end_at || '00:00'}`, 'yyyy-MM-dd HH:mm'));

    const historyModal = useSelector(transportRoadSelectors.historyModal);
    const categories = useStoreProp(loadVehicleCategories, 'transportRoad', 'vehicleCategories');
    const category = categories.find((el: { id: number; }) => el.id === item?.vehicle?.category_id);

    const acceptHistory = () => {
        dispatch(loadVehicleHistoryModal(item?.vehicle?.serial_egts, {
            date_start: fullDateTimeWithTimeZone(startDate),
            date_end: fullDateTimeWithTimeZone(endDate),
        }));
    };

    const accept = (e: { stopPropagation: () => void; }) => {
        e.stopPropagation();
        acceptHistory();

        const option = [
            {
                type: types.historyColor,
                uuid: item?.vehicle?.serial_egts,
                data: historyModal,
                date_start: fullDateTimeWithTimeZone(startDate),
                date_end: fullDateTimeWithTimeZone(endDate)
            },
            {
                type: types.route,
                data: item?.route?.road,
            },
            {
                type: types.stations,
                id: item?.route?.id,
            },
            {
                type: types.history,
            }
        ];

        dispatch(setHistoryOption(
            'История ТС по наряду',
            option,
            true,
        ));
    };

    const onClose = () => {
        setStartDate(getStartOf('day', getDateObjectFromFormat(item.date_at, 'yyyy-MM-dd')));
        setEndDate(getEndOf('day',  getDateObjectFromFormat(item.date_at, 'yyyy-MM-dd')));

        setOpenModal(false);
    };

    const errorDates = () =>
        (!!startDate && startDate < getStartOf('day', getDateObjectFromFormat(item.date_at, 'yyyy-MM-dd')) )
        || (!!endDate && endDate > getEndOf('day',  getDateObjectFromFormat(item.date_at, 'yyyy-MM-dd')));

    useEffect(() => {
        if (item.id) {
            dispatch(loadWorkorderLogs(item.id));
        }
    }, [dispatch, item.id]);

    const refreshScreen = (e: any) => {
        e.stopPropagation();
        dispatch(loadWorkorderLogs(item.id));
    };

    return (
        <>
            <LSContentItem onClick={() => setSelect(item)}>
                <LSContentColumn>
                    <CircleStatus title={item.status_text} color={colorStatus(item.status)} />
                </LSContentColumn>
                <LSContentColumn>
                    {item.id || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {item.name || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {item.route?.num || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {category?.name || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {item.vehicle?.number || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {item.organization?.title || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {item.date_at ? getHumanDate(getDateObjectFromFormat(item.date_at, 'yyyy-MM-dd')) : messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {item.start_at || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {item.end_at || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn isActions>
                    <FormButtons
                        justButton
                        buttons={[
                            {
                                ...buttonsTypes.defaultPrimary,
                                name: 'Обновить',
                                icon: <i className="fas fa-redo-alt" />,
                                onClick: refreshScreen
                            }
                        ]}
                    />

                    <ActionsButtons
                        buttons={[
                            {
                                icon: <MapIcon fontSize="inherit"/>,
                                name: 'Показать на карте',
                                onClick: ()=> setOpenModal(true),
                            }
                        ]}
                    />
                    <ActionMore
                        isOpen={selectedId === item?.id}
                    />
                </LSContentColumn>
            </LSContentItem>
            {selectedId === item?.id
                && <Collapse in={selectedId === item?.id} timeout="auto" unmountOnExit>
                    <WorkorderEvents
                        workOrderId={item?.id}
                    />
                </Collapse>
            }
            {openModal && (
                <Modal
                    title={titles.SELECT_PERIOD}
                    maxWidthProp={'md'}
                    isOpen={openModal}
                    onClose={() => setOpenModal(false)}
                    buttons={
                        <FormButtons
                            buttons={[
                                {
                                    ...buttonsTypes.show,
                                    onClick: accept,
                                    disabled: errorDates(),
                                },
                                {
                                    ...buttonsTypes.close,
                                    onClick: onClose,
                                },
                            ]}
                        />
                    }
                >
                    <div style={{ display: 'flex' }}>
                        <DateRange
                            valueStartDate={startDate}
                            valueEndDate={endDate}
                            handleDate={(e) => setStartDate(e)}
                            handleEndDate={(e) => setEndDate(e)}
                            startLabel={titles.START_PERIOD}
                            endLabel={titles.END_PERIOD}
                            renderError={errorDates}
                            errorMessage={() => errorDates() ? 'Выбранный период должен быть в пределах наряда, не более 1 дня' : ''}
                        />

                    </div>
                </Modal>
            )}
        </>
    );
};

export default Item;
