import { List } from '@mui/material';

import messages from 'helpers/constants/messages';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import InformPanel from 'components/layout/PageLayout/InformPanel';
import Loading from 'components/common/Loading';

import LinkItem from './Item';

// компонент для отрисовки списка связанных ДИТ
const BoardsList = (
    {
        onChangeList,
        disabled = true,
        dit_list = [],
        loading = false,
        onClick,
        inModal = false,
        needButton = true,
    }) => {

    const deleteBoard = (i) => {
        const newBoards = dit_list.filter((el, index) => index !== Number(i));
        onChangeList(newBoards);
    };

    return (
        <>
            {loading && <Loading linear />}

            {(!inModal && needButton) && (
                <InformPanel
                    buttons={
                        <FormButtonsComponent
                            noPadding
                            noMarginLeft
                            buttons={[
                                {
                                    ...buttonsTypes.change,
                                    color: 'infoButton',
                                    onClick,
                                    disabled: loading
                                },
                            ]}
                        />
                    }
                />
            )}

            {dit_list?.length > 0
                ? <List className="list">
                    {dit_list?.map((dit, index) => {
                        return <LinkItem
                            key={dit?.id}
                            item={dit}
                            index={index}
                            {...(!disabled && { onDelete: () => deleteBoard(index) })}
                        />;
                    })}
                </List>
                : (!loading && <p style={{ padding: '1rem' }}>{messages.NO_DATA}</p>)
            }
        </>
    );
};

export default BoardsList;
