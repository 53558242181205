import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List } from '@mui/material';

import FormButtons, { buttonsTypes } from '../../../../../common/FormButtons';
import CustomPagination from '../../../../../common/CustomPagination';
import { loadThreatLevels } from '../../../../../../redux/SituationalPlans/actions';
import messages from '../../../../../../helpers/constants/messages';
import Search from '../../../../../common/Search';
import Loading from '../../../../../common/Loading';
import Context from '../../../../../../helpers/context';
import { SituationalPlansSelectors } from '../../../../../../redux/SituationalPlans';

import Item from './Item';
import ModalForm from './ModalForm';

const Dangers = () => {
    const dispatch = useDispatch();
    const { permissions } = useContext(Context);

    const data = useSelector(SituationalPlansSelectors.threatLevelsData);
    const meta = useSelector(SituationalPlansSelectors.threatLevelsMeta);
    const loadingThreatLevels = useSelector(SituationalPlansSelectors.loadingThreatLevels);

    const [params, setParams] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const storageLimit = !!localStorage.getItem('limit')
        ? Number(localStorage.getItem('limit'))
        : 25;
    const [limit, setLimit] = useState(storageLimit);

    useEffect(() => {
        dispatch(loadThreatLevels(1, limit));
    }, []);

    return (
        <div>
            <h1>Уровни угрозы</h1>
            <Search
                action={loadThreatLevels}
                loadParams={(params) => setParams(params)}
            />
            <div className="filter__wrap__btn filter__btn__column">
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.add,
                            onClick: () => setOpenModal(true),
                            disabled: !permissions?.is_create,
                        }
                    ]}
                />
            </div>
            {loadingThreatLevels && <Loading circular={true}/>}
            {openModal
            && <ModalForm
                isOpen={openModal}
                onClose={() => setOpenModal(false)}
                isNew={true}
            />
            }
            {data?.length > 0
                ? (<>
                    <List className="list">
                        {data
                            ?.map((item) => <Item
                                key={item.id + item.name}
                                item={item}
                            />)
                        }
                    </List>
                    <CustomPagination
                        loadList={(page, limit) => dispatch(loadThreatLevels(page, limit, { name: params }))}
                        list={meta}
                        limit={limit}
                        setLimit={setLimit}
                    />
                </>)
                : (!loadingThreatLevels && <div>{messages.DATA_IS_NOT_FOUND}</div>)
            }
        </div>
    );
};

export default Dangers;
