import { fullDateTimeWithoutSeconds } from 'helpers/date.config';
import messages from 'helpers/constants/messages';
import prepareCoords from 'helpers/prepareCoords';
import titles from 'helpers/constants/titles';
import getAddressStringFromObject from 'components/common/Location/LoadAddressByCoords/getAddressStringFromObject';

const Item = (props) => {
    const {
        id,
        name,
        type,
        status,
        lat,
        lon,
        address_text,
        created_at,
        confirmed_at,
        address = {},
    } = props;

    return (
        <>
            <div>
                <strong>Id: </strong>{id || messages.NO_DATA}
            </div>
            <div>
                <strong>{titles.NAME}: </strong>{name || messages.NO_DATA}
            </div>
            <div>
                <strong>Вид: </strong>{type || messages.NO_DATA}
            </div>
            <div>
                <strong>{titles.STATUS}: </strong>{status || messages.NO_DATA}
            </div>
            <div>
                <strong>{titles.COORDINATES}: </strong>{lat || lon ? `${prepareCoords(lat)} / ${prepareCoords(lon)}` : messages.NO_DATA}
            </div>
            <div className="description">
                <strong>{titles.ADDRESS}: </strong>{address_text || getAddressStringFromObject(address || {}) || messages.NO_DATA}
            </div>
            <div>
                <strong>{titles.DATE_TIME_CREATED}: </strong>{fullDateTimeWithoutSeconds(created_at)}
            </div>
            {confirmed_at && (
                <div>
                    <strong>{titles.DATE_TIME_CONFIRMED}: </strong>{fullDateTimeWithoutSeconds(confirmed_at)}
                </div>
            )}
        </>
    );
};

export default Item;
