import React, { useState } from 'react';

import titles from 'helpers/constants/titles';

import HeaderColumn from './HeaderColumn';
import Sorting from './Sorting';

import type { Sort } from '../../../../types/common';
import type { Header } from '../types';

interface HeaderColumnListProps {
    headers: Header[];
    hideActions: boolean;
    childProps: { width?: React.CSSProperties['width'] };
    onSort?: (value: Sort[]) => void;
}

function HeaderColumnList({ headers, hideActions, childProps, onSort }: HeaderColumnListProps) {
    const [sort, setSort] = useState<Sort[]>([]);

    const handleChangeSort = (key: string, direction: 'asc' | 'desc') => {
        const foundIndex = sort.findIndex(el => el.field === key);
        const newState = foundIndex >= 0
            ? sort.map((el, i) => foundIndex === i ? { ...el, direction } : el)
            : [...sort, { field: key, direction }];

        setSort(newState);
        onSort && onSort(newState);
    };

    return (
        <>
            {headers.map(({ title, ...props }, index) => {
                const isActions = props.isActions;
                // если это Действия и title не прокинут, ставим по-умолчанию
                title = isActions && !title ? titles.ACTIONS : title;

                if (isActions && hideActions) return null;

                return (
                    <HeaderColumn key={index} {...props} {...childProps}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                ...(props.align === 'center' && { justifyContent: 'center' }),
                            }}
                        >
                            <b>{title}</b>

                            {props.options?.sortable && (
                                <Sorting onChange={handleChangeSort} options={props.options} />
                            )}
                        </div>
                    </HeaderColumn>
                );
            })}
        </>
    );
}

export default HeaderColumnList;
