import { useEffect, useState } from 'react';
import { useDispatch, useSelector, } from 'react-redux';
import { List, ListItem, ListItemText, TextField } from '@mui/material';

import {
    loadCurrentCompany,
} from 'redux/Companies/actions';
import { transportPassengerSelectors } from 'redux/TransportPassenger';
import {
    loadBrigadesNoWorkorder,
    loadCurrentWorkorder,
    loadVehicleCard,
} from 'redux/TransportPassenger/actions';
import {
    dateWithDashYYYYMMDD,
    fullDateTimeWithTimeZone,
    getConfigForMinMaxDate,
    getEndOf,
    getHumanDate,
    getStartOf,
    isDateBefore,
} from 'helpers/date.config';
import { useValidation } from 'helpers/hooks';
import dateFormat from 'helpers/constants/dateFormat';
import titles from 'helpers/constants/titles';
import { checkAndReturnValue, isValue } from 'helpers/checkValue';
import SelectTrafficSchedule from 'components/common/Autocomplete/PassengerTransport/TrafficSchedule';
import SelectBrigades from 'components/common/Autocomplete/Transport/Brigades';
import SelectVehicles from 'components/common/Autocomplete/Transport/Vehicles';
import DateRange from 'components/common/Dates/DateRange';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import Loading from 'components/common/Loading';
import Modal from 'components/common/Modal';
import SingleKeyboardDateTimePicker from 'components/common/Dates/SingleKeyboardDateTimePicker';
import FormInfoWrapper from 'components/common/FormInfoWrapper';

import styles from './workorders.module.scss';

const AddEdit = ({ data = {}, isNew = false, onClose = () => {}, isOpen, onSave, isCalendar = false, disablePeriod, test_id_prefix }) => {
    const validation = useValidation();
    const dispatch = useDispatch();

    const [formValues, setFormValues] = useState({
        start_date: getStartOf('day', data?.date_at),
        end_date: getEndOf('day', data?.date_at),
        date_at: data?.date_at,
        traffic_schedule: data?.traffic_schedule || {},
        vehicle: data?.vehicle || {},
        brigades: data?.brigades || [],
        organization: data?.organization || {},
        organization_id: checkAndReturnValue(data?.organization_id, null),
    });

    const periodDateError = () => {
        return validation.isKey('start_date') || validation.isKey('end_date')
        || (isDateBefore(formValues?.start_date, formValues?.traffic_schedule?.start_at)
            || (formValues?.traffic_schedule?.end_at
                ? isDateBefore(formValues?.traffic_schedule?.end_at, formValues.end_date)
                : false ))
            ? 'Даты начала и окончания периода должны быть в пределах интервала - срока действия графика движения'
            : '';
    };

    const loading = useSelector(transportPassengerSelectors.currentWorkordersLoading);

    useEffect(() => {
        if (data.id && !data.traffic_schedule) {
            dispatch(loadCurrentWorkorder(1, 25, { id: data.id }, (value) => {
                setFormValues(value);
            }));
        }
    }, [data, dispatch]);

    useEffect(() => {
        if (isCalendar && data?.vehicle_id) {
            dispatch(loadVehicleCard(
                data?.vehicle_id,
                {},
                (vehicle) => setFormValues((prev) => ({
                    ...prev,
                    vehicle,
                }))
            ));
        }
    }, [isCalendar, data?.vehicle_id, dispatch]);

    useEffect(() => {
        if ((!formValues.organization || Object.keys(formValues.organization).length === 0)
            && isValue(formValues?.organization_id)) {
            dispatch(loadCurrentCompany(
                formValues?.organization_id,
                (organization = {}) => setFormValues((prev) => ({
                    ...prev,
                    organization,
                }))
            ));
        }
    }, [formValues.organization, formValues.organization_id, dispatch]);

    const handleChangeAutoCom = (value, name) => {
        if (name === 'brigades') {
            const brigades = value.map(item => {
                if (item?.shift) {
                    return item;
                }
                return { ...item, shift: 1 };
            });
            return setFormValues({
                ...formValues,
                [name]: brigades.filter(item => item.id !== 0)
            });
        }

        if (name === 'traffic_schedule') {
            return setFormValues({
                ...formValues,
                [name]: value,
                ...(!isCalendar
                    ? {
                        organization: value?.organization,
                        organization_id: value?.organization_id
                    }
                    : {}
                ),
                ...(!isCalendar && { vehicle: {} }),
                brigades: []
            });
        }
    };

    const handleChangeShift = (id, value) => {
        const changedData = formValues?.brigades.map(item => {
            if (item?.id === id) {
                return { ...item, shift: +value };
            }
            return item;
        });
        setFormValues({ ...formValues, brigades: changedData });
    };

    const handleChangeDate = (value) => {
        setFormValues({
            ...formValues,
            ...value
        });
    };

    const hasEachBrigadeShift = formValues.brigades?.map(item => !!item?.shift).includes(false);

    const areAllFieldsFilled = !!formValues.traffic_schedule?.id
        && formValues.organization?.id !== null && formValues.organization?.id !== undefined
        && !!formValues.vehicle?.id
        && !hasEachBrigadeShift;

    const submit = () => {
        const result = {
            start_date: dateWithDashYYYYMMDD(formValues.start_date),
            end_date: dateWithDashYYYYMMDD(formValues.end_date),
            organization_id: formValues.organization_id || 0,
            traffic_schedule_id: formValues.traffic_schedule?.id,
            vehicle_id: formValues.vehicle?.id,
            brigades: formValues.brigades?.map(({ id, shift }) => ({ id, shift })),
            date_at: formValues?.date_at,
        };
        onSave(result);
    };

    return (
        <Modal
            title={isNew ? titles.ADD_WORKORDER : titles.EDIT_WORKORDER}
            isOpen={isOpen}
            onClose={onClose}
            noPadding
            buttons={<FormButtonsComponent
                test_id_prefix={test_id_prefix}
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: onClose,
                        testName: 'cancel'
                    },
                    {
                        ...buttonsTypes.save,
                        disabled: !areAllFieldsFilled || !!periodDateError(),
                        onClick: submit,
                        testName: 'save'
                    },
                ]}
            />}
        >
            {loading && <Loading linear />}
            <form className="modal__form">
                {isNew
                    ? <div className="row">
                        <DateRange
                            dateOnly={true}
                            pickersFormat={dateFormat.DATE_FOR_PICKER}
                            valueStartDate={formValues?.start_date}
                            valueEndDate={formValues?.end_date}
                            handleDate={(value) => handleChangeDate({ start_date: value })}
                            handleEndDate={(value) => handleChangeDate({ end_date: value })}
                            className="row"
                            helperText={periodDateError()}
                            minDate={getConfigForMinMaxDate()}
                            isDisabled={isCalendar}
                            disableStartField={disablePeriod}
                            disableEndField={disablePeriod}
                            equalDates
                            test_id_prefix={test_id_prefix}
                        />
                    </div>
                    : <div className="block">
                        <SingleKeyboardDateTimePicker
                            dateOnly={true}
                            label={'Дата наряда'}
                            value={formValues?.date_at}
                            pickerFormat={dateFormat.DATE_FOR_PICKER}
                            isDisabled={true}
                            test_id_prefix={test_id_prefix}
                        />
                    </div>
                }

                <div className="block">
                    <FormInfoWrapper error={!formValues.traffic_schedule?.id || validation.isKey('traffic_schedule_id')} helperText={validation.get('traffic_schedule_id')}>
                        <SelectTrafficSchedule
                            selected={formValues.traffic_schedule}
                            onChange={(value) => handleChangeAutoCom(value, 'traffic_schedule')}
                            filter={{
                                date_start: dateWithDashYYYYMMDD(formValues.start_date),
                                date_end: dateWithDashYYYYMMDD(formValues.end_date),
                                date_inverted: 1,
                                status_ids: [1],
                                ...(isCalendar
                                    && isValue(data?.organization_id)
                                    ? { organization_id_list: [data?.organization_id] }
                                    : {}
                                ),
                                ...(formValues?.vehicle?.id
                                    ? { vehicle_category_ids: [formValues.vehicle?.category_id] }
                                    : {}
                                ),
                            }}
                            required
                            is_simple={0}
                            test_id_prefix={test_id_prefix}
                        />
                    </FormInfoWrapper>
                </div>
                <div className="block">
                    <TextField
                        className="row__item"
                        disabled
                        value={formValues.organization?.title || ''}
                        variant="outlined"
                        size="small"
                        label={titles.ORGANIZATION}
                    />
                </div>
                <div className="row">
                    <TextField
                        className="row__item"
                        disabled
                        value={formValues?.traffic_schedule?.start_at
                            ? getHumanDate(formValues?.traffic_schedule?.start_at)
                            : ''
                        }
                        variant="outlined"
                        size="small"
                        label={titles.START}
                    />
                    <TextField
                        className="row__item"
                        disabled
                        value={formValues?.traffic_schedule?.end_at
                            ? getHumanDate(formValues?.traffic_schedule?.end_at)
                            : ''
                        }
                        variant="outlined"
                        size="small"
                        label={titles.END}
                    />
                </div>
                <div className="row">
                    <TextField
                        className="row__item"
                        disabled
                        value={formValues.traffic_schedule?.route_direct?.num || ''}
                        variant="outlined"
                        size="small"
                        label={titles.ROUTE}
                    />
                    <TextField
                        className="row__item"
                        disabled
                        value={formValues.traffic_schedule?.route_direct?.name || ''}
                        variant="outlined"
                        size="small"
                        label={titles.NAME}
                    />
                </div>
                <div className="block" align="left">
                    <FormInfoWrapper error={!formValues.vehicle?.id || validation.isKey('vehicle_id')} helperText={validation.get('vehicle_id')}>
                        <SelectVehicles
                            selected={formValues.vehicle}
                            onChange={(value) => {
                                setFormValues({
                                    ...formValues,
                                    vehicle: value
                                });
                            }}
                            required
                            disabled={(formValues?.organization?.id === null
                                || formValues?.organization?.id === undefined)
                                || (isCalendar && !!data?.vehicle_id)}
                            error={!formValues.vehicle?.id || validation.isKey('vehicle_id')}
                            filter={{
                                start_at: fullDateTimeWithTimeZone(formValues.date_at),
                                end_at: fullDateTimeWithTimeZone(formValues.date_at),
                                organization_id: formValues?.organization_id,
                                category_id: formValues?.traffic_schedule?.route_direct?.category_id,
                            }}
                            searchBy="number"
                            test_id_prefix={test_id_prefix}
                        />
                    </FormInfoWrapper>
                </div>
                <div className="row">
                    <TextField
                        className="row__item"
                        disabled
                        value={formValues.traffic_schedule?.first_station?.name || ''}
                        variant="outlined"
                        size="small"
                        label="Пункт подачи ТС"
                    />
                </div>
                <div className="block" align="left">
                    <FormInfoWrapper helperText={!areAllFieldsFilled ? 'Выберите сначала Организацию и ТС' : ''}>
                        <SelectBrigades
                            multiple
                            selected={formValues.brigades}
                            loadListAction={loadBrigadesNoWorkorder}
                            storeName="transportPassenger"
                            storeNameProps="brigadesNoWorkorder"
                            disabled={!isValue(formValues.organization_id)}
                            onChange={(value) => handleChangeAutoCom(value, 'brigades')}
                            filter = {{
                                start_at: fullDateTimeWithTimeZone(formValues.date_at),
                                end_at: fullDateTimeWithTimeZone(formValues.date_at),
                                organization_id: formValues?.organization_id
                            }}
                            test_id_prefix={test_id_prefix}
                        />
                    </FormInfoWrapper>
                </div>
                {formValues?.brigades?.length > 0
                    && <List className="list">
                        {formValues?.brigades?.map((item, index) => {
                            item.shift =item?.shift || index + 1;
                            return <ListItem key={item?.id} className="list_item">
                                <ListItemText>{item?.name}</ListItemText>
                                <TextField
                                    value={item?.shift}
                                    onChange={({ target: { value } }) => handleChangeShift(item?.id, value)}
                                    className={styles.textFiledWithoutArrows}
                                    error={!item?.shift}
                                    label="Смена"
                                    type="number"
                                    size="small"
                                    variant="outlined"
                                />
                            </ListItem>;
                        })}
                    </List>
                }
            </form>
        </Modal>
    );
};

export default AddEdit;
