import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import cn from 'classnames';

const RED = '#d32f2f';
const BLUE = '#3f51b5';

const useStyles = makeStyles({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        '& > *': {
            maxWidth: '100%', // чтобы не растягивался от контента
        },
        '& > *:not(.tooltip)': {
            // если элементов до тултипа больше чем 1
            marginLeft: '10px',
            flex: 1,
        },
        '& > :first-child': {
            flex: 1,
            marginLeft: 0,
        },
    },

    btn: {
        minWidth: '30px',
        height: '30px',
        alignSelf: 'self-center',
    },
});

interface FormInfoWrapperProps {
    children: React.ReactNode;
    error?: boolean;
    helperText?: string;
    className?: string;
    style?: React.CSSProperties;
    btnStyle?: React.CSSProperties;
    test_id_prefix?: string;
}

const FormInfoWrapper = ({
    children,
    error,
    helperText,
    className,
    style,
    btnStyle,
    test_id_prefix, // для автотестов
}: FormInfoWrapperProps) => {
    const styles = useStyles();

    return (
        <div className={cn(styles.wrapper, className)} style={style}>
            {children}

            {helperText && (
                <Tooltip title={helperText} className={'tooltip'}>
                    <IconButton
                        size="small"
                        className={styles.btn}
                        style={btnStyle}
                        data-testid={`${test_id_prefix}/${error ? 'error' : 'helper'}`}
                    >
                        <i
                            style={{ color: error ? RED : BLUE }}
                            className="far fa-exclamation-circle"
                        />
                    </IconButton>
                </Tooltip>
            )}
        </div>
    );
};

export default FormInfoWrapper;
