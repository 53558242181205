import iconCluster from './icons/iconCluster';

const config = {
    name: 'Перекрытия',
    slug: 'roadwork-overlap',
    // маркеров нет - только область и круг
    clusterIcon: iconCluster,
    layerIcon: 'fas fa-do-not-enter',
    lineWidth: {
        default: 4,
        thin: 4,
        normal: 7,
        thick: 11,
    },
};
// добавление на карте
export default config;
