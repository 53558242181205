import { config } from 'config';

const apiDataBusUrl = () => config.get('urls').apiDataBusUrl;

const apiUrls = {
    getCollectionServices: () => `${apiDataBusUrl()}/collection-service/list`,
    
    getCollectionServicesTypes: () => `${apiDataBusUrl()}/collection-service/types`,

    getCollectionServicesTemplates: () => `${apiDataBusUrl()}/collection-service/templates`,

    createCollectionService: () => `${apiDataBusUrl()}/collection-service`,

    editCollectionService: (id) => `${apiDataBusUrl()}/collection-service/${id}`,

    deleteCollectionService: (id) => `${apiDataBusUrl()}/collection-service/${id}`,
};

export default apiUrls;