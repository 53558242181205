import React from 'react';
import Scrollbars from 'react-custom-scrollbars';

import Information from 'components/common/Information';
import { fullDateTimeWithoutSeconds } from 'helpers/date.config';
import messages from 'helpers/constants/messages';

const PropertiesTab = ({ marker }) => {
    return (
        <Scrollbars style={{ height: '300px' }}>
            <Information
                data={marker.vehicle}
                title={{
                    mmsi: {
                        title: 'MMSI',
                    },
                    imo: {
                        title: 'ИМО номер судна',
                    },
                    distance_to_stern: {
                        title: 'Размер',
                    },
                    distance_to_bow: {
                        title: 'Размер до кормы',
                    },
                    distance_to_port: {
                        title: 'Расстояние до порта',
                    },
                    distance_to_starboard: {
                        title: 'Размер по правому борту',
                    },
                    position_fixation_type_text: {
                        title: 'Тип фиксации положения',
                    },
                    arrival_time: {
                        title: 'Время прибытия',
                        value: ({ arrival_time }) => fullDateTimeWithoutSeconds(arrival_time),
                    },
                    draught: {
                        title: 'Осадка (дм)',
                    },
                    destination: {
                        title: 'Пункт назначения',
                    },
                }}
                defaultValue={messages.NO_VALUE}
                returnNull
            />
        </Scrollbars>
    );
};

export default PropertiesTab;
