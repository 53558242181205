import * as types from './types';

// данные
export const getDeviceDataEcology = (device_external_id) => ({
    type: types.GET_DEVICE_DATA,
    payload: device_external_id,
});

export const saveDeviceDataEcology = (data) => ({
    type: types.DEVICE_DATA_LOADED,
    payload: data,
});

export const clearDeviceDataEcology = () => ({
    type: types.CLEAR_DEVICE_DATA,
});

// события
export const getEventsEcology = (external_id, page = 1, limit = 10, replaceStore = true) => ({
    type: types.GET_EVENTS,
    payload: {
        external_id,
        page,
        limit
    },
    replaceStore
});

export const saveEventsEcology = (data, replaceStore = true) => ({
    type: types.EVENTS_LOADED,
    payload: data,
    replaceStore
});

export const clearEventsEcology = () => ({
    type: types.CLEAR_EVENTS
});

// проекты
export const getProjectsEcology = () => ({
    type: types.GET_PROJECTS,
});

export const saveProjectsEcology = (data) => ({
    type: types.PROJECTS_LOADED,
    payload: data,
});

// типы
export const getDeviceTypesEcology = () => ({
    type: types.GET_TYPES,
});

export const saveDeviceTypesEcology = (typesRw) => ({
    type: types.TYPES_LOADED,
    payload: typesRw,
});

// статусы
export const getStatusesEcology = () => ({
    type: types.GET_STATUSES,
});

export const saveStatusesEcology = (data) => ({
    type: types.STATUSES_LOADED,
    payload: data,
});

// список
export const getDeviceListEcology = (params = {}, isReplace = true) => ({
    type: types.GET_LIST,
    payload: params,
    isReplace
});

export const saveDeviceListEcology = (response, replaceStore) => ({
    type: types.LIST_LOADED,
    payload: response,
    replaceStore
});

export const deviceListLoading = (bool = false) => ({
    type: types.LIST_LOADING,
    payload: bool,
});

export const getDeviceCompanyLink = (id) => ({
    type: types.GET_DEVICE_COMPANY_LINK,
    payload: id
});

export const createDeviceCompanyLink = (id, sendData, callback) => ({
    type: types.CREATE_DEVICE_COMPANY_LINK,
    payload: {
        id,
        sendData,
        callback
    }
});

export const deleteDeviceCompanyLink = (id, callback) => ({
    type: types.DELETE_DEVICE_COMPANY_LINK,
    payload: {
        id,
        callback
    }
});

export const saveDeviceCompanyLink = (data) => ({
    type: types.DEVICE_COMPANY_LINK_LOADED,
    payload: data
});

export const clearCompanyLink = () =>({
    type: types.CLEAR_COMPANY_LINK,
    payload: {}
});

// загрузка
export const loading = (bool = false) => ({
    type: types.LOADING,
    payload: bool,
});

// полигон
export const getForPolygonEcology = (polygon) => ({
    type: types.GET_POLYGON,
    payload: polygon,
});

export const saveForPolygonEcology = (data) => ({
    type: types.POLYGON_LOADED,
    payload: data,
});

export const clearForPolygonEcology = () => ({
    type: types.POLYGON_LOADED,
    payload: [],
});

// установить активный
export const setActiveEcology = (item = {}) => ({
    type: types.SET_ACTIVE,
    payload: item,
});

export const resetActiveEcology = () => ({
    type: types.SET_ACTIVE,
    payload: {},
});

// установить фильтр
export const setFilterEcology = (filter) => ({
    type: types.SET_FILTER,
    payload: filter,
});

export const clearFiltersEcology = () => ({
    type: types.SET_FILTER,
    payload: {},
});

export const wsDataEcologe = (events) => ({
    type: types.SET_WS_DATA,
    payload: events,
});

// настройки
export const loadEcologySettings = (page = 1, limit = 25, query = {}) => ({
    type: types.LOAD_ECOLOGY_SETTINGS,
    payload: { page, limit, query }
});

export const loadedEcologySettings = (data) => ({
    type: types.LOADED_ECOLOGY_SETTINGS,
    payload: data
});

export const loadingEcologySettings = (bool = false) => ({
    type: types.LOADING_ECOLOGY_SETTINGS,
    payload: bool
});

export const editEcologySettings = (id, data) => ({
    type: types.EDIT_ECOLOGY_SETTINGS,
    payload: { id, data }
});

export const editSettingsList = (data, callback = () => {}) => ({
    type: types.EDIT_SETTINGS_LIST,
    payload: data,
    callback
});

// оповещении
export const loadEcologyAlerts = () => ({
    type: types.LOAD_ECOLOGY_ALERTS,
});

export const loadedEcologyAlerts = (data) => ({
    type: types.LOADED_ECOLOGY_ALERTS,
    payload: data,
});

// настройки вывода параметров
export const loadEcologyOutputParams = (page = 1, limit = 15, query = {}) => ({
    type: types.LOAD_ECOLOGY_OUTPUT_PARAMS,
    payload: {
        page,
        limit,
        query,
    }
});

export const loadedEcologyOutputParams = (data) => ({
    type: types.LOADED_ECOLOGY_OUTPUT_PARAMS,
    payload: data
});

export const loadEcologyOutputSettings = () => ({
    type: types.LOAD_ECOLOGY_OUTPUT_SETTINGS
});

export const loadedEcologyOutputSettings = (data) => ({
    type: types.LOADED_ECOLOGY_OUTPUT_SETTINGS,
    payload: data
});

export const loadingEcologyOutputSettings = (bool = false) => ({
    type: types.LOADING_ECOLOGY_OUTPUT_SETTINGS,
    payload: bool
});

export const editEcologyOutputSettings = (id, data, callback) => ({
    type: types.EDIT_ECOLOGY_OUTPUT_SETTINGS,
    payload: { id, data },
    callback
});

export const deleteEcologyOutputSettings = (id, data, callback) => ({
    type: types.DELETE_ECOLOGY_OUTPUT_SETTINGS,
    payload: { id, data },
    callback
});

export const loadDevicesGroup = (params) => ({
    type: types.LOAD_DEVICES_GROUP,
    payload: params
});

export const loadingDevicesGroup = (loading = false) => ({
    type: types.LOADING_DEVICES_GROUP,
    payload: loading
});

export const loadedDevicesGroup = (response) => ({
    type: types.LOADED_DEVICES_GROUP,
    payload: response
});

export const createDevicesGroup = (params, callback = () => {}) => ({
    type: types.CREATE_DEVICES_GROUP,
    payload: params,
    callback
});

export const editDevicesGroup = (params, callback = () => {}) => ({
    type: types.EDIT_DEVICES_GROUP,
    payload: params,
    callback
});

export const deleteDevicesGroup = (id, callback = () => {}) => ({
    type: types.DELETE_DEVICES_GROUP,
    payload: id,
    callback
});

export const loadingButton = (loading = false) => ({
    type: types.LOADING_BUTTON,
    payload: loading
});

export const loadShowcase = () => ({
    type: types.LOAD_SHOWCASE,
});
export const loadingShowcase = (bool = false) => ({
    type: types.LOADING_SHOWCASE,
    payload: bool
});
export const loadedShowcase = (data) => ({
    type: types.LOADED_SHOWCASE,
    payload: data
});
export const clearShowcase = () => ({
    type: types.LOADED_SHOWCASE,
    payload: {}
});

export const loadEcoByRadius = (data, callback = () => {}) => ({
    type: types.LOAD_ECO_BY_RADIUS,
    payload: data,
    callback
});

export const loadedEcoByRadius = (data) => ({
    type: types.LOADED_ECO_BY_RADIUS,
    payload: data
});

// Settings Group
export const loadSettingsGroup = () => ({
    type: types.LOAD_SETTINGS_GROUP,
});

export const loadingSettingsGroup = (bool) => ({
    type: types.LOADING_SETTINGS_GROUP,
    payload: bool
});

export const loadedSettingsGroup = (data) => ({
    type: types.LOADED_SETTINGS_GROUP,
    payload: data
});

export const editSettingsGroup = (id, data, callback = () => {}) => ({
    type: types.EDIT_SETTINGS_GROUP,
    payload: { id, data },
    callback
});

export const loadDeviceStatuses = () => ({
    type: types.LOAD_DEVICE_STATUSES,
});
export const loadedDeviceStatuses = (data) => ({
    type: types.LOADED_DEVICE_STATUSES,
    payload: data
});
