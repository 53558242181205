import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { editDTPStatuses, loadDtpTypesAll, saveDtp } from 'redux/Incidents/actions';
import { incidentsSelectors } from 'redux/Incidents';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import { useStoreFromSelectorListToObject } from 'helpers/hooks';

import { getColor } from '../helper';
import icon from '../icons/icon';

import Information from './Information';
import tabsList from './tabsList';
import InfoTrafficBlocking from './InfoTrafficBlocking';
import ModalForm from './ModalForm';
import Members from './members';
import Vehicles from './vehicles';

const PopUp = (props) => {
    const { readOnly, isTrafficBlocking } = props;
    // забираем данные из полигона
    const typesAll = useStoreFromSelectorListToObject(loadDtpTypesAll, incidentsSelectors.typesAll);
    const polygonVisible = useSelector(incidentsSelectors.polygonVisible);

    const dispatch = useDispatch();

    const data = props?.dtpData || polygonVisible.find(({ id }) => props.uuid === id) || {};
    const [tabIndex, setTabIndex] = useState(0);
    const [open, setOpen] = useState(false);

    const toggleOpenModal = () => {
        setOpen(!open);
    };

    const callback = () => {
        dispatch(saveDtp(true));
        setOpen(false);
    };

    return (
        <div className="map-popup">
            <div className="map-popup_header" style={{ backgroundColor: getColor(data, typesAll.get) }}>
                <div>
                    <div
                        className="img"
                        dangerouslySetInnerHTML={{ __html: icon({ fill: '#fff' }) }}
                    />
                    <span>ДТП</span>
                </div>
                {readOnly === false
                    && (
                        <FormButtons
                            noPadding
                            className="action"
                            buttons={[
                                {
                                    ...buttonsTypes.addStation,
                                    name: 'Изменить статус',
                                    onClick: toggleOpenModal,
                                }
                            ]}
                        />
                    )
                }
            </div>
            <div className="map-popup_body">
                {isTrafficBlocking
                    // Перекрытие движения
                    ? (
                        <InfoTrafficBlocking data={data} />
                    )
                    // стандартные данные
                    : (
                        <Tabs
                            selectedIndex={tabIndex}
                            onSelect={(tabIndex) => {
                                setTabIndex(tabIndex);
                            }}
                        >
                            <TabList>
                                {tabsList.map(({ title, key }) => (
                                    <Tab key={`tabtitle_${key}`}>
                                        {title}
                                    </Tab>
                                ))}
                            </TabList>

                            {tabsList.map(({ key, data: titlesData }, index) => (
                                <TabPanel key={key}>
                                    {tabIndex === index && (
                                        <div className="custom-popup-body">
                                            {key === 'members'
                                                ? <Members
                                                    data={data?.members || []}
                                                />
                                                : key === 'vehicles'
                                                    ? <Vehicles
                                                        data={data?.vehicles || []}
                                                    />
                                                    : <Information
                                                        data={data}
                                                        list={titlesData}
                                                    />
                                            }
                                        </div>
                                    )}
                                </TabPanel>
                            ))}
                        </Tabs>
                    )
                }

            </div>
            {open && (
                <ModalForm
                    open={open}
                    onClose={() => setOpen(false)}
                    onSave={(status) => dispatch(editDTPStatuses({ ids: [data?.id], new_status: Number(status) }, callback))}
                    initialStatus={data?.status?.toString()}
                />
            )}
        </div>
    );
};

export default PopUp;
