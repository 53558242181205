const dtpTitles = {
    DTP: 'ДТП',
    EDIT_DTP_TYPE: 'Редактировать тип ДТП',
    EDIT_COLOR_DTP_ICON: 'Изменить цвет иконки',
    DTP_TYPE: 'Тип ДТП',
    DTP_TYPES: 'Типы ДТП',
    COUNT_DTP_MEMBERS:  'Количество участников',
    COUNT_DTP_WOUNDED: 'Количество раненых',
    COUNT_DTP_DEAD: 'Количество погибших',
    COUNT_DTP_TS:  'Количество ТС',
    DTP_KM: 'Километр (если авария на дороге)',
    DTP_M: 'Метр (если авария на дороге)',
    DTP_DESCRIPTION: 'Описание ДТП',
    SCHEMA_DTP: 'Схема ДТП',
    DTP_DATA_SOURCE: 'Источник загрузки данных',
    ROAD: 'Дорога',
    ROAD_VALUE: 'Значение дороги',
    ROAD_CATEGORY: 'Категория дороги',
    ROAD_CONDITION: 'Состояние проезжей части',
    STREET_CATEGORY: 'Категория улицы',
    WEATHER: 'Погода',
    LIGHTING: 'Освещение',
    CHANGES_DRIVING: 'Изменения в режиме движения',
    INTERSECTION_STREET : 'Улица пересечения',
    DATE_TIME_DTP: 'Дата, время ДТП',
    SEVERITY_AFTER_DTP: 'Состояние после ДТП',
    RELATIVE_EVENT: 'Связанное событие',
    COUNT_DTP_WOUNDED_KIDS: 'Количество раненых детей',
    COUNT_DTP_DEAD_KIDS: 'Количество погибших детей',
    DTP_COUNT: 'Всего ДТП',
    DTP_NUMBER: 'Номер ДТП'
};

export default dtpTitles;
