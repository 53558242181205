/**
 * Sagas
 * **/
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { setValidation } from 'redux/Validation/actions';
import { messageHelper } from 'helpers/messageHelper';

import { messageTypes, showMessage } from '../Message/actions';

import api from './api.methods';
import * as types from './types';
import * as actions from './actions';

function* loadTrackingListSaga({ payload }) {
    const { page, limit, data } = payload;
    yield put(actions.loadingTrackingList(true));
    const response = yield call(api.getTrackingList, page, limit, data);
    if (response?.success) {
        yield put(actions.loadedTrackingList(response));
    }
    yield put(actions.loadingTrackingList(false));
}

function* loadTrackingMoreInfoSaga({ payload }) {
    const { page, limit, id } = payload;
    yield put(actions.loadingTrackingMoreInfo(true));
    const response = yield call(api.getTrackingMoreInfo, page, limit, id);
    if (response?.success) {
        yield put(actions.loadedTrackingMoreInfo(response));
    }
    yield put(actions.loadingTrackingMoreInfo(false));
}

function* loadTrackingDeployedInfoSaga({ payload }) {
    const { id, grz } = payload;
    yield put(actions.loadingTrackingDeployedInfo(true));
    const response = yield call(api.getTrackingDeployedInfo, id, grz);
    if (response?.success) {
        yield put(actions.loadedTrackingDeployedInfo(response));
    }
    yield put(actions.loadingTrackingDeployedInfo(false));
}

function* loadTrackingStatusesSaga() {
    const response = yield call(api.getTrackingStatuses);
    if (response?.success) {
        yield put(actions.loadedTrackingStatuses(response));
    }
}

function* loadWantedListSaga({ payload }) {
    yield put(actions.setLoading(true));
    const response = yield call(api.loadWantedList, payload);

    if (response?.success) {
        yield put(actions.loadedWantedList(response));
    }
    yield put(actions.setLoading(false));
}

function* createWantedSaga({ payload, callback }) {
    yield put(actions.setLoading(true));
    const response = yield call(api.createWanted, payload);
    yield put(actions.setStatusWanted(response?.success));
    if (response?.success) {
        yield put(showMessage(messageTypes.success, 'Транспорт добавлен в розыск'));
        callback && callback();
    }
    yield put(actions.setLoading(false));
}

function* editWantedSaga({ payload, callback }) {
    const { id, data } = payload;
    yield put(actions.setLoading(true));
    const response = yield call(api.editWanted, id, data);
    yield put(actions.setStatusWanted(response?.success));
    if (response?.success) {
        yield put(showMessage(messageTypes.success, 'Данные по транспорту в розыске обновлены'));
        callback && callback();
    }
    yield put(actions.setLoading(false));
}

function* deleteWantedSaga({ payload, callback }) {
    const id = payload;
    yield put(actions.setLoading(true));
    const response = yield call(api.deleteWanted, id);
    yield put(actions.setStatusWanted(response?.success));
    if (response?.success) {
        yield put(showMessage(messageTypes.success, 'Розыск удален'));
        callback && callback();
    }
    yield put(actions.setLoading(false));
}

function* loadTypesBaseSaga() {
    const response = yield call(api.loadTypesBase);
    if (response?.success) {
        yield put(actions.loadedTypesBase(response.data));
    }
}

function* createTrackingSaga({ payload }) {
    const response = yield call(api.createTracking, payload);
    if (response?.success) {
        yield put(actions.loadTrackingList(1, 25));
        yield put(setValidation({ success: response.success }));
        yield put(showMessage(messageTypes.success, 'Слежка создана'));
    }
}

function* editTrackingSaga({ payload }) {
    const { id, data } = payload;
    const response = yield call(api.editTracking, id, data);
    if (response?.success) {
        yield put(actions.loadTrackingList(1, 25));
        yield put(setValidation({ success: response.success }));
        yield put(showMessage(messageTypes.success, 'Слежка изменена'));
    }
}

function* deleteTrackingSaga({ payload }) {
    const response = yield call(api.deleteTracking, payload);
    if (response?.success) {
        yield put(actions.loadTrackingList(1, 25));
        yield put(showMessage(messageTypes.success, 'Слежка удалена'));
    }
}

function* stopWantedSaga({ payload, callback }) {
    const { id, comment } = payload;
    const response = yield call(api.stopWanted, id, comment);
    if (response?.success) {
        yield put(showMessage(messageTypes.success, 'Розыск остановлен'));
        callback && callback();
    }
    // yield put(actions.stoppedWanted(response?.success || false));
}

function* loadWantedEventsSaga({ payload }) {
    const { page, limit, data } = payload;
    yield put(actions.loadingWantedEvents(true));
    const response = yield call(api.loadWantedEvents, { page, limit, ...data });
    if (response?.success) {
        yield put(actions.loadedWantedEvents(response));
    }
    yield put(actions.loadingWantedEvents(false));
}

function* loadVehicleInfoSaga({ payload }) {
    const response = yield call(api.getVehicleInfo);
    if (response?.success) {
        yield put(actions.loadedVehicleInfo(response?.data));
    }
}

function* loadEventWantedTrackSaga({ payload }) {
    yield put(actions.loadingEventWantedTrack(true));
    const report = payload?.report || false;
    const response = yield call(api.eventWantedTrack, payload);
    if (response?.success) {
        report
            ? yield messageHelper(response)
            : yield put(actions.loadedEventWantedTrack(response?.data));
    }
    yield put(actions.loadingEventWantedTrack(false));
}

function* loadVideoEventSourcesSaga() {
    yield put(actions.loadingVideoEventSources(true));
    const response = yield call(api.videoEventSources);
    if (response?.success) {
        yield put(actions.loadedVideoEventSources(response?.data));
    }
    yield put(actions.loadingVideoEventSources(false));
}

export default function* saga() {
    yield all([
        takeLatest(types.LOAD_TRACKING_LIST, loadTrackingListSaga),
        takeLatest(types.LOAD_TRACKING_MORE_INFO, loadTrackingMoreInfoSaga),
        takeLatest(types.LOAD_TRACKING_DEPLOYED_INFO, loadTrackingDeployedInfoSaga),
        takeLatest(types.LOAD_TRACKING_STATUSES, loadTrackingStatusesSaga),
        takeLatest(types.LOAD_WANTED_LIST, loadWantedListSaga),
        takeLatest(types.CREATE_WANTED, createWantedSaga),
        takeLatest(types.EDIT_WANTED, editWantedSaga),
        takeLatest(types.DELETE_WANTED, deleteWantedSaga),
        takeLatest(types.LOAD_TYPES_BASE, loadTypesBaseSaga),
        takeLatest(types.CREATE_TRACKING, createTrackingSaga),
        takeLatest(types.EDIT_TRACKING, editTrackingSaga),
        takeLatest(types.DELETE_TRACKING, deleteTrackingSaga),
        takeLatest(types.STOP_WANTED, stopWantedSaga),
        takeLatest(types.LOAD_WANTED_EVENTS, loadWantedEventsSaga),
        takeLatest(types.LOAD_VEHICLE_INFO, loadVehicleInfoSaga),
        takeLatest(types.LOAD_EVENT_WANTED_TRACK, loadEventWantedTrackSaga),
        takeLatest(types.LOAD_VIDEO_EVENT_SOURCES, loadVideoEventSourcesSaga),
    ]);
}
