import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Autocomplete, TextField } from '@mui/material';
import { isEqual } from 'lodash';
import queryString from 'query-string';

import {
    loadConcentrationAreaStatuses,
    loadConcentrationAreaTypes,
} from 'redux/Incidents/actions';
import titles from 'helpers/constants/titles';
import { getEndOf, getStartOf } from 'helpers/date.config';
import { useStoreProp } from 'helpers/hooks';
import messages from 'helpers/constants/messages';
import { TypeLoad, Types } from 'components/common/Autocomplete/DTP';
import DateRange from 'components/common/Dates/DateRange';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import StatusesSelect from 'components/common/Autocomplete/Statuses';

const Filter = ({ setParams }) => {
    const location = useLocation();
    const { id } = queryString.parse(location.search);

    const initState = {
        start_date: getStartOf('year'),
        end_date: getEndOf('year'),
        type: null,
        status: null,
        dtp_type: [],
        dtp_type_load: [],
        with_dtp_list: 1,
        id_list: []
    };

    const types = useStoreProp(loadConcentrationAreaTypes, 'incidents', 'concentrationAreaTypes');
    const statuses = useStoreProp(loadConcentrationAreaStatuses, 'incidents', 'concentrationAreaStatuses');

    const [data, setData] = useState({
        ...initState,
        ...(id
            ? {
                id_list: id ? [id] : [],
                start_date: '',
                end_date: '',
            }
            : {}
        )
    });

    const handleSearch = (filters = data) => {
        setParams({ page: 1, query: filters });
    };

    const handleResetFilter = (needRefresh) => {
        setData(initState);

        if(needRefresh){
            setParams(({ page: 1, query: initState }));
        }
    };

    const isDisabled = isEqual(initState, data);

    const updateFilter = (filters) => {
        setData({
            ...initState,
            ...filters
        });
        if (Object.keys(filters).length) {
            handleSearch({
                ...initState,
                ...filters
            });
        }
    };

    return (
        <LayoutFilter
            onResetFilter={handleResetFilter}
            onSearch={handleSearch}
            filter={data}
            setUserFilter={updateFilter}  // если у пользователя сохранен фильтр для этой страницы, мы его из общего компонента сетаем сюда
            disabled={isDisabled}
            filterException={['start_date', 'end_date',]}
        >
            <LayoutFilter.Visible>
                <DateRange
                    // dateOnly
                    valueStartDate={data.start_date}
                    valueEndDate={data.end_date}
                    handleDate={(date) => setData({ ...data, start_date: date })}
                    handleEndDate={(date) => setData({ ...data, end_date: date })}
                />

                <Autocomplete
                    multiple
                    value={data?.id_list}
                    freeSolo
                    autoSelect
                    options={[]}
                    onChange={(_, value) => setData({ ...data, id_list: value })}
                    size="small"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label="ID"
                        />
                    )}
                />
            </LayoutFilter.Visible>
            <LayoutFilter.Invisible>
                <Autocomplete
                    value={data?.type}
                    options={types}
                    disableClearable={true}
                    filterSelectedOptions={true}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => option.name || ''}
                    noOptionsText={messages.NO_DATA}
                    size="small"
                    onChange={(e, newValue) => {
                        setData({ ...data, type: newValue });
                    }}
                    clearText="Очистить"
                    openText="Открыть"
                    closeText="Закрыть"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label={'Вид'}
                        />
                    )}
                />
                <StatusesSelect
                    selected={data?.status}
                    options={statuses}
                    onChange={(newValue) => {
                        setData({ ...data, status: newValue });
                    }}
                />

                <Types
                    multiple
                    selected={data?.dtp_type || []}
                    onChange={(dtp_type) => setData({ ...data, dtp_type })}
                />

                <TypeLoad
                    selected={data?.dtp_type_load || []}
                    onChange={(dtp_type_load) => setData({ ...data, dtp_type_load })}
                    openOnFocus
                />
            </LayoutFilter.Invisible>
        </LayoutFilter>
    );
};

export default Filter;
