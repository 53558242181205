import instance from '../../helpers/axios.config';

import apiUrls from './api.urls';

const ApiEcologyMethods = {
    getList: async (params) => {
        const response = await instance.get(apiUrls.getList(), { params });
        return response?.data;
    },

    getStatuses: async () => {
        const response = await instance.get(apiUrls.getStatuses());
        return response?.data;
    },

    getSidebar: async (params) => {
        const response = await instance.get(apiUrls.getSidebar(), { params });
        return response?.data;
    },

    getPolygon: async (polygon, filter) => {
        const response = await instance.post(apiUrls.getPolygon(), {
            polygon,
            ...filter,
        });
        return response.data;
    },
};

export default ApiEcologyMethods;
