import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from './api.methods';
import * as types from './types';
import * as actions from './actions';

function* getListSaga({ payload }) {
    yield put(actions.loadingList(true));
    const response = yield call(api.getList, payload);
    if (response) {
        yield put(actions.loadedList(response));
    }
    yield put(actions.loadingList(false));
}

function* loadStatusesSaga() {
    yield put(actions.loadingStatuses(true));
    const response = yield call(api.getStatuses);
    if (response?.success) {
        yield put(actions.loadedStatuses(response.data));
    }
    yield put(actions.loadingStatuses(false));
}

function* loadSideBarSaga({ payload }) {
    const { page } = payload;
    yield put(actions.loadingSidebar(true));
    const response = yield call(api.getSidebar, payload);
    if (response?.success) {
        yield put(actions.loadedSidebar(response, page === 1));
    }
    yield put(actions.loadingSidebar(false));
}

function* loadPolygonSaga({ payload }) {
    const { polygon, filter } = payload;
    yield put(actions.loadingPolygon(true));
    const response = yield call(api.getPolygon, polygon, filter);
    if (response?.success) {
        yield put(actions.loadedPolygon(response?.data || []));
    }
    yield put(actions.loadingPolygon(false));
}

export default function* saga() {
    yield all([
        takeLatest(types.GET_LIST, getListSaga),
        takeLatest(types.LOAD_STATUSES, loadStatusesSaga),
        takeLatest(types.LOAD_SIDEBAR, loadSideBarSaga),
        takeLatest(types.LOAD_POLYGON, loadPolygonSaga),
    ]);
}
