import { useCallback, useMemo, useState } from 'react';
import { Link, TextField } from '@mui/material';

import titles from 'helpers/constants/titles';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Attachments from 'components/common/Upload/Attachments';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import InformPanel from 'components/layout/PageLayout/InformPanel';
import InfoBlock from 'components/common/InfoBlock';

import ModalTC from './ModalTC';
import FormSelect from './FormSelect';
import VehicleClassesList from './VehicleClassesList';

import type { ChangePartTypes } from './types';

const ChangePart = ({
    data,
    setData,
    typeTransportation,
    vehicleClasses,
    routeSeasonality,
    regularTransportations
}: ChangePartTypes) => {
    const [openModal, setOpenModal] = useState(false);

    const handleChange = useCallback(({ target: { name, value } }) => {
        setData({
            ...data,
            [name]: value
        });
    }, [data, setData]);

    const handleAddClass = useCallback(value => {
        setData({
            ...data,
            vehicle_classes: [
                ...data.vehicle_classes,
                value
            ],
        });
        onClose();
    }, [data, setData]);

    const handleDeleteClass = useCallback(id => {
        setData({
            ...data,
            vehicle_classes: data.vehicle_classes.filter(item => item.class_id !== id)
        });
    }, [data, setData]);

    const handleChangePaymentInCash = useCallback(({ target: { name, value } }) => {
        setData({
            ...data,
            payment_in_cash: {
                ...data.payment_in_cash,
                [name]: value
            }
        });
    }, [data, setData]);

    const handleChangeCashlessPayment = useCallback(({ target: { name, value } }) => {
        setData({
            ...data,
            payment_cashless: {
                ...data.payment_cashless,
                [name]: value
            }
        });
    }, [data, setData]);

    const handleFile = useCallback(value => {
        setData({
            ...data,
            payment_file: value ? {
                filename: value.name,
                link: value.public_link
            } : null
        });
    }, [data, setData]);

    const onClose = useCallback(() => setOpenModal(false), []);

    const routeSeasonalityList = useMemo(() => ({
        name: 'Сезонность маршрута',
        field: 'seasonality',
        list: routeSeasonality
    }), [routeSeasonality]);

    const regularTransportationsList = useMemo(() => ({
        name: 'Вид регулярных перевозок',
        field: 'type_of_regular_transportation',
        list: regularTransportations
    }), [regularTransportations]);

    const transportClasses = useMemo(() => ({
        name: 'Kласс ТС',
        field: 'class_id',
        list: vehicleClasses
    }), [vehicleClasses]);

    const typeTransportationList = useMemo(() => ({
        name: 'Классификация для реестра тарифов на проезд',
        field: 'type_transportation',
        list: typeTransportation
    }), [typeTransportation]);

    const helperText = useMemo(() => 'Поле должно быть заполнено', []);

    return (
        <>
            <h2>Параметры маршрута</h2>
            <FormSelect
                data={data}
                param={regularTransportationsList}
                callback={handleChange}
                required
            />
            <FormSelect
                data={data}
                param={routeSeasonalityList}
                callback={handleChange}
                required
            />

            <InfoBlock label="Используемые классы ТС" className="block">
                <InformPanel
                    buttons={
                        <FormButtons
                            noPadding
                            noMarginLeft
                            buttons={[
                                {
                                    ...buttonsTypes.add,
                                    onClick: () => setOpenModal(true),
                                },
                            ]}
                        />
                    }
                />

                <VehicleClassesList
                    data={data.vehicle_classes}
                    onDelete={handleDeleteClass}
                    selectList={vehicleClasses}
                    change={true}
                />
            </InfoBlock>

            <h2> Сведения о тарифах</h2>
            <div className="block">
                <FormSelect
                    data={data}
                    param={typeTransportationList}
                    callback={handleChange}
                />
            </div>
            <h3>Оплата наличными</h3>
            <div className="filter__wrap filter__wrap__more__field">
                <FormInfoWrapper
                    helperText={!data?.payment_in_cash?.tariff_passenger ? helperText : ''}
                    error={!data?.payment_in_cash?.tariff_passenger}
                >
                    <TextField
                        label="Стоимость проезда пассажира"
                        size="small"
                        value={data?.payment_in_cash?.tariff_passenger}
                        variant="outlined"
                        name="tariff_passenger"
                        type="number"
                        onChange={handleChangePaymentInCash}
                        inputProps={{ min: 0 }}
                        error={!data?.payment_in_cash?.tariff_passenger}
                    />
                </FormInfoWrapper>
                <FormInfoWrapper
                    helperText={!data?.payment_in_cash?.tariff_baggage ? helperText : ''}
                    error={!data?.payment_in_cash?.tariff_baggage}
                >
                    <TextField
                        label="Стоимость провоза багажа"
                        size="small"
                        value={data?.payment_in_cash?.tariff_baggage}
                        variant="outlined"
                        name="tariff_baggage"
                        onChange={handleChangePaymentInCash}
                        inputProps={{ min: 0 }}
                        type="number"
                        error={!data?.payment_in_cash?.tariff_baggage}
                    />
                </FormInfoWrapper>
            </div>
            <h3>Безналичная оплата</h3>
            <div className="filter__wrap filter__wrap__more__field">
                <FormInfoWrapper
                    helperText={!data?.payment_cashless?.tariff_passenger ? helperText : ''}
                    error={!data?.payment_cashless?.tariff_passenger}
                >
                    <TextField
                        label="Стоимость проезда пассажира"
                        size="small"
                        value={data?.payment_cashless?.tariff_passenger}
                        variant="outlined"
                        name="tariff_passenger"
                        inputProps={{ min: 0 }}
                        type="number"
                        onChange={handleChangeCashlessPayment}
                        error={!data?.payment_cashless?.tariff_passenger}
                    />
                </FormInfoWrapper>
                <FormInfoWrapper
                    helperText={!data?.payment_cashless?.tariff_baggage ? helperText : ''}
                    error={!data?.payment_cashless?.tariff_baggage}
                >
                    <TextField
                        label="Стоимость провоза багажа"
                        size="small"
                        value={data?.payment_cashless?.tariff_baggage}
                        variant="outlined"
                        name="tariff_baggage"
                        inputProps={{ min: 0 }}
                        type="number"
                        onChange={handleChangeCashlessPayment}
                        error={!data?.payment_cashless?.tariff_baggage}
                    />
                </FormInfoWrapper>
            </div>
            <Attachments
                service="transport-passenger"
                // @ts-ignore
                onChange={(url: any) => handleFile(url)}
                files={(!data?.payment_file || Array.isArray(data?.payment_file))
                    ? []
                    : [data?.payment_file]
                }
                isRequired={false}
                renderContent={(item: {
                    public_link: string | undefined;
                    filename: string;
                }) => <Link href={item.public_link} target="_blank">{item.filename}</Link>}
                singleFile
            />
            <div className="block">
                <TextField
                    className="block"
                    label={titles.COMMENT}
                    multiline
                    rows={2}
                    variant="outlined"
                    value={data.comment}
                    name="comment"
                    onChange={handleChange}
                />
            </div>

            {openModal
                && <ModalTC
                    open={openModal}
                    onClose={onClose}
                    onSave={handleAddClass}
                    selectTC={transportClasses}
                    data={data}
                />
            }
        </>
    );
};

export default ChangePart;
