import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Tooltip } from '@mui/material';

import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';
import PageLayout from 'components/layout/PageLayout';
import NoData from 'components/common/Information/NoData';
import {
    LSContainer,
    LSContentColumn,
    LSContentItem
} from 'components/common/List';
import { externalTransportSelectors } from 'modules/ExternalTransport';
import { loadStationList, loadTransportTypes } from 'modules/ExternalTransport/redux/actions';
import { iconByType } from 'modules/ExternalTransport/utils/helper';
import { useStoreProp } from 'helpers/hooks';
import removeEmptyFields from 'helpers/removeEmptyFields';

import FilterForm from './Filter';

import type { FilterFields, Item, Params } from './types';

const ExternalTransportStations = () => {
    const dispatch = useDispatch();

    const types = useStoreProp(loadTransportTypes, 'externalTransport', 'transport_types');
    const data = useSelector(externalTransportSelectors.stationListData);
    const meta = useSelector(externalTransportSelectors.stationListMeta);
    const loading = useSelector(externalTransportSelectors.loading);
    const storageLimit = !!localStorage.getItem('limit')
        ? Number(localStorage.getItem('limit'))
        : 25;
    const [params, setParams] = useState<Params>({
        page: 1,
        limit: storageLimit,
        data: {}
    });

    useEffect(() => {
        const { page, limit, data } = params;
        const types = data.transport_types
            ? { transport_types: data.transport_types.map(el => el.id || Number(el)) }
            : {};
        const newFilter = removeEmptyFields({ ...data, ...types }, false);
        dispatch(loadStationList(page, limit, newFilter));
    }, [dispatch, params]);

    const createTooltipTitle = (el: Item) => (
        <>
            <span>{types[el.transport_type]}</span> <br />
            {<span>{el.status_text}</span>}
        </>
    );

    const onChangeFilter = (data: FilterFields) => {
        setParams((prev) => ({
            ...prev,
            page: 1,
            data
        }));
    };

    const renderContent = () => (
        data?.length > 0
            ? <LSContainer
                headers={[
                    { title: '', width: '50px' },
                    { title: titles.NAME, width: '40%' },
                    { title: titles.ADDRESS, width: '40%' },
                ]}
            >
                {data.map((item: Item) => (
                    <LSContentItem key={item?.id}>
                        <LSContentColumn>
                            <Tooltip title={createTooltipTitle(item)}>
                                <i className={iconByType[item.transport_type]} style={{ color: item?.color, fontSize: '1.5rem' }} />
                            </Tooltip>
                        </LSContentColumn>
                        <LSContentColumn>
                            {item?.name || messages.NO_VALUE}
                        </LSContentColumn>
                        <LSContentColumn>
                            {item?.address_text || messages.NO_VALUE}
                        </LSContentColumn>
                    </LSContentItem>
                ))}
            </LSContainer>
            : (!loading && <NoData/>)
    );

    return (
        <PageLayout
            header="Список станций"
            filters={<FilterForm data={params.data} onChange={onChangeFilter} />}
            informPanelProps={{
                total: meta?.total
            }}
            loading={loading}
            content={renderContent}
            paginationProps={{
                loadList: (page, limit = params.limit) => setParams({ ...params, page, limit }),
                list: meta,
                limit: params.limit,
                setLimit: (limit) => setParams({ ...params, limit })
            }}
        />
    );
};

export default ExternalTransportStations;
