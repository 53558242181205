import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadPddViolations } from 'redux/DorisControl/actions';
import { dorisControlSelectors } from 'redux/DorisControl';
import PageLayout from 'components/layout/PageLayout';
import { LSContainer } from 'components/common/List';
import NoData from 'components/common/Information/NoData';

import Item from './Item';
import Filter from './Filter';

const PDDSettings = () => {
    const dispatch = useDispatch();

    const data = useSelector(dorisControlSelectors.pddViolationsData);
    const meta = useSelector(dorisControlSelectors.pddViolationsMeta);
    const loading = useSelector(dorisControlSelectors.loading_pdd_violations);

    const storageLimit = Number(localStorage.getItem('limit') || 25);
    const [limit, setLimit] = useState(storageLimit);
    const [params, setParams] = useState({ page: 1, query: {} });

    const loadList = useCallback(() => {
        const { page, query } = params;

        dispatch(loadPddViolations(page, limit, query));
    }, [dispatch, limit, params]);

    useEffect(() => {
        loadList();
    }, [loadList]);

    const renderContent = () => data.length > 0
        ? <LSContainer 
            headers={[
                { width: '5%' },
                { width: '8%' },
                { width: '80%' },
            ]}
        >
            {
                data?.map((item: { id: number, is_active: boolean}) => (
                    <Item
                        key={item.id}
                        item={item}
                        loadList={loadList}
                    />
                ))
            }
        </LSContainer>
        : (!loading && <NoData/>);
    
    return (
        <PageLayout
            header="Справочник КОАП статей"
            filters={<Filter setParams={setParams}/>}
            informPanelProps={{
                total: meta?.total
            }}
            paginationProps={{
                loadList: (page) => setParams({ ...params, page }),
                list: meta,
                limit,
                setLimit,
            }}
            content={renderContent}
            loading={loading}
        />
    );
};

export default PDDSettings;
