// иконка для списка
const iconCluster = (props) => {
    return `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.000000 103.000000" fill="${props?.fill || '#e7e7e7'}">
            <g transform="translate(0.000000,103.000000) scale(0.100000,-0.100000)" stroke="none">
                <circle cx="470" cy="550" r="450" />
            </g>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512"><!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.-->
                <path fill="${props?.fillInner || 'rgba(0, 0, 0, 0.54)'}" d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm176 296H72c-8.8 0-16-7.2-16-16v-64c0-8.8 7.2-16 16-16h352c8.8 0 16 7.2 16 16v64c0 8.8-7.2 16-16 16z"/>
            </svg>
        </svg>
    `;
    /*
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.000000 103.000000" fill="${props?.fill || '#e7e7e7'}">
            <g transform="translate(0.000000,103.000000) scale(0.100000,-0.100000)" stroke="none">
                <circle cx="470" cy="550" r="450" />
            </g>
            <svg x="11" y="-9" width="70" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="snowplow" className="svg-inline--fa fa-snowplow fa-w-20" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                <path fill="${props?.fillInner || "rgba(0, 0, 0, 0.54)"}" d="M120 376c-13.3 0-24 10.7-24 24s10.7 24 24 24 24-10.7 24-24-10.7-24-24-24zm80 0c-13.3 0-24 10.7-24 24s10.7 24 24 24 24-10.7 24-24-10.7-24-24-24zm80 0c-13.3 0-24 10.7-24 24s10.7 24 24 24 24-10.7 24-24-10.7-24-24-24zm80 0c-13.3 0-24 10.7-24 24s10.7 24 24 24 24-10.7 24-24-10.7-24-24-24zm238.6 49.4c-14.5-14.5-22.6-34.1-22.6-54.6V269.2c0-20.5 8.1-40.1 22.6-54.6l36.7-36.7c6.2-6.2 6.2-16.4 0-22.6l-22.6-22.6c-6.2-6.2-16.4-6.2-22.6 0l-36.7 36.7c-26.5 26.5-41.4 62.4-41.4 99.9V288h-64v-50.9c0-8.7-1.8-17.2-5.2-25.2L364.5 29.1C356.9 11.4 339.6 0 320.3 0H176c-26.5 0-48 21.5-48 48v112h-16c-26.5 0-48 21.5-48 48v91.2C26.3 317.2 0 355.4 0 400c0 61.9 50.1 112 112 112h256c61.9 0 112-50.1 112-112 0-17.3-4.2-33.4-11.2-48H512v18.7c0 37.5 14.9 73.4 41.4 99.9l36.7 36.7c6.2 6.2 16.4 6.2 22.6 0l22.6-22.6c6.2-6.2 6.2-16.4 0-22.6l-36.7-36.7zM192 64h117.8l68.6 160H256l-64-64V64zm176 384H112c-26.5 0-48-21.5-48-48s21.5-48 48-48h256c26.5 0 48 21.5 48 48s-21.5 48-48 48z"></path>
            </svg>
        </svg>
     */
};

export default iconCluster;
