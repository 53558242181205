import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';

import colorExtend from 'helpers/mapHelper/colorExtend';
import { useStoreFromSelector } from 'helpers/hooks';
import * as actions from 'redux/Incidents/actions';
import { incidentsSelectors } from 'redux/Incidents';


const Legend = () => {

    const typesAll = useStoreFromSelector(actions.loadDtpTypesAll, incidentsSelectors.typesAll);

    return (
        <div className="sidebar-tp-legend column">
            {/* типы */}
            <div className="item">
                <div className="title">
                    Типы ДТП
                </div>

                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                    }}
                >
                    {typesAll?.map?.(type => (
                        <div
                            key={type.id}
                            className="flex"
                            style={{
                                width: '200px',
                                alignItems: 'start',
                                marginBottom: '.5rem'
                            }}
                        >
                            <div
                                className="image"
                                style={{
                                    height: 'auto',
                                    width: 'auto',
                                    alignItems: 'top',
                                    // marginRight: '.5rem',
                                }}
                            >
                                <DirectionsCarIcon style={{ color: colorExtend(type.color || '') }} />
                            </div>

                            <div
                                className="small"
                                style={{
                                    'margin-top': '4px'
                                }}
                            >
                                - {type.name}
                            </div>
                        </div>
                    ))}
                </div>
            </div>

        </div>
    );
};

export default Legend;
