import { Typography } from '@mui/material';

import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';

const Item = ({ item, onClick }) => {
    
    return (
        <div className="description" onClick={onClick}>
            <Typography variant="subtitle2">
                <strong>{titles.NAME}: </strong>
                <span>{item?.name || messages.INFO_IS_NOT_FOUND}</span>
            </Typography>
            <Typography variant="body2">
                <strong>{titles.ADDRESS}: </strong>
                <span>{item?.address_text || messages.INFO_IS_NOT_FOUND}</span>
            </Typography>
        </div>
    );
};

export default Item;
