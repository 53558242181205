import { useEffect, useRef, useState } from 'react';
import { Autocomplete, Chip, TextField } from '@mui/material';
import { styled } from '@mui/system';

import { loadActions } from 'redux/Admin/actions';
import removeEmptyFields from 'helpers/removeEmptyFields';
import titles from 'helpers/constants/titles';
import { fullDateTimeWithTimeZone, getDateWithDuration, getEndOf, getStartOf } from 'helpers/date.config';
import { useStoreProp } from 'helpers/hooks';
import SelectUsers from 'components/common/Autocomplete/Users';
import DateRange from 'components/common/Dates/DateRange';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import SelectMicroservices from 'components/common/Autocomplete/Microservices';
import FormInfoWrapper from 'components/common/FormInfoWrapper';

import type { Action, FilterProps, FormData } from './types';

const GroupHeader = styled('div')`
    position: sticky;
    top: -8px;
    padding: 4px 10px;
    color: #000;
    background-color: #e6e6e6;
    font-weight: 600;
`;

const GroupItems = styled('ul')`
    padding: 0;
`;

const Filter = ({ setParams }: FilterProps) => {
    const [tagWidth, setTagWidth] = useState(0);
    const tagCountRef = useRef<any>(null);

    useEffect(() => {
        if (tagCountRef.current) {
            setTagWidth(tagCountRef.current.offsetWidth);
        }
    }, []);

    const actions = useStoreProp(loadActions, 'admin', 'actions');

    const initState: FormData = {
        user_id_list: [],
        start_date: getStartOf('day', getDateWithDuration({ weeks: -1 })),
        end_date: getEndOf(),
        action_list: [],
        microservice_list: [],
    };

    const [formData, setFormData] = useState<FormData>(initState);

    const transformActionsData: Action[] = Object.entries(actions || {}).reduce((result, [key, value]) => {
        const newValue = (value as string[]).map(el => ({
            microservice: key,
            value: el,
        })).filter(element2 =>
            formData.microservice_list.some(element1 =>
                element1.value === element2.microservice
            )
        );

        return result.concat(newValue);
    }, [] as Action[]);

    const handleChange = (name: keyof FormData, value: any) => {
        setFormData({ ...formData, [name]: value });
    };

    const search = () => {
        const prepareData = removeEmptyFields({
            ...formData,
            user_id_list: formData.user_id_list.map((item) => item.id),
            start_date: fullDateTimeWithTimeZone(formData.start_date || ''),
            end_date: fullDateTimeWithTimeZone(formData.end_date || ''),
            action_list: formData.action_list.map(item => item.value),
            microservice_list: formData.microservice_list.map(item => item.key)
        }, false);

        setParams({ page: 1, data: prepareData });
    };

    const resetFilter = (needRefresh: boolean) => {
        setFormData(initState);

        if(needRefresh) {
            setParams({
                page: 1,
                data: {
                    ...initState,
                    start_date: fullDateTimeWithTimeZone(initState.start_date),
                    end_date: fullDateTimeWithTimeZone(initState.end_date),
                }
            });
        }
    };

    const updateFilter = (filters: Partial<FormData>) => {
        setFormData({ ...initState, ...filters });

        if (Object.keys(filters).length) {
            search();
        }
    };

    return (
        <LayoutFilter
            onResetFilter={resetFilter}
            onSearch={search}
            filter={formData}
            setUserFilter={updateFilter}
            filterException={['start_date', 'end_date']}
        >
            <LayoutFilter.Visible>
                <SelectUsers
                    multiple={true}
                    selected={formData.user_id_list}
                    label={titles.USERS}
                    onChange={(value) => handleChange('user_id_list', value)}
                />
                <DateRange
                    valueStartDate={formData.start_date}
                    valueEndDate={formData.end_date}
                    handleDate={(value) => handleChange('start_date', value)}
                    handleEndDate={(value) => handleChange('end_date', value)}
                />
                <SelectMicroservices
                    multiple={true}
                    selected={formData.microservice_list}
                    onChange={(value) => handleChange('microservice_list', value)}
                />
                <FormInfoWrapper
                    helperText={transformActionsData.length ? '' : 'Сначала выберите микросервис'}
                    error={false}
                >
                    <Autocomplete
                        multiple
                        limitTags={1}
                        value={formData.action_list}
                        options={transformActionsData}
                        groupBy={(option) => option.microservice}
                        getOptionLabel={(option) => option.value}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        onChange={(_, value) => handleChange('action_list', value)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size="small"
                                label="Возможные действия"
                            />
                        )}
                        renderGroup={(params) => (
                            <li>
                                <GroupHeader>{params.group}</GroupHeader>
                                <GroupItems>{params.children}</GroupItems>
                            </li>
                        )}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip
                                    {...getTagProps({ index })}
                                    label={option.value}
                                    style={{ maxWidth: `calc(80% - ${tagWidth}px)` }}
                                    size="small"
                                    key={index}
                                />
                            ))
                        }
                    />
                </FormInfoWrapper>
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
