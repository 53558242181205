import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';

import { deleteComplexDocument, loadDocumentsHistory } from 'redux/DorisControl/actions';
import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';
import { getHumanDate } from 'helpers/date.config';
import CommentAction from 'components/common/CommentAction';
import ConfirmDelete from 'components/common/ConfirmModal';
import HistoryList from 'components/common/HistoryList';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';

import styles from '../../vfcomplex.module.scss';

import { DocumentsForm } from './DocumentsForm';

export const DocumentItem = ({ item, document_types, complex_id, readOnly = false, onDeleted, onEdited, test_id_prefix_passport = '' }) => {
    const dispatch = useDispatch();

    const [delModalOpen, setDelModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [commentBeforeDelete, setCommentBeforeDelete] = useState(false);
    const [openHistoryModal, setOpenHistoryModal] = useState(false);

    function openDocument() {
        if (item?.document) {
            window.open(item?.document);
        }
        return false;
    }

    const getValueFromObject = (value) =>
        (typeof value === 'object' && value !== null) ? value.link_fgis : value;

    const HistoryInfo = ({ item }) => {
        const { event, data } = item;

        if (!Array.isArray(data) || !data.length) return messages.NO_DATA;

        return (
            <>
                {data.map(({ name, new: newItem, old }) => (
                    <div key={name}>
                        <h3>{name}:</h3>
                        <div style={{ wordWrap: 'break-word' }}>
                            <i>Новое значение</i> - {getValueFromObject(newItem) || messages.NO_DATA}
                            {event === 'updated' && <><br />
                                <i>Старое значение</i> - {getValueFromObject(old) || messages.NO_DATA}
                            </>}
                        </div>
                    </div>
                ))}
            </>
        );
    };

    return (
        <>
            <ListItem style={ item?.document ? { cursor: 'pointer' } : { cursor: 'auto' } } className="list_item">
                { item?.document && <ListItemIcon><i className="far fa-file-alt" /></ListItemIcon> }
                <ListItemText onClick={openDocument}>
                    <Typography variant="body2">
                        <span className={styles.title__item}>
                            {document_types[item.type_id]}
                        </span>
                        { item?.number
                        && <span className={styles.title__item}>
                            <span className={styles.title__item_bold}>
                                {titles.DOCUMENT_NUMBER}:
                            </span>
                            {item?.number}
                        </span> }
                        { item?.author
                        && <span className={styles.title__item}>
                            <span className={styles.title__item_bold}>
                                {titles.AUTHOR}:
                            </span>{item?.author}
                        </span> }
                        { item?.date
                        && <span className={styles.title__item}>
                            <span className={styles.title__item_bold}>
                                {titles.DATE_OF_CREATION}:
                            </span>{getHumanDate(item?.date)}
                        </span> }
                    </Typography>
                </ListItemText>
                {!readOnly
                    && <FormButtons
                        buttons={[
                            {
                                ...buttonsTypes.historyIcon,
                                name: titles.HISTORY_OF_CHANGES,
                                onClick: (e) => {
                                    e.stopPropagation();
                                    setOpenHistoryModal(true);
                                },
                                testName: 'history'
                            },
                            {
                                ...buttonsTypes.editIcon,
                                onClick: () => setEditModalOpen(true),
                                testName: 'edit'
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: () => setDelModalOpen(true),
                                testName: 'delete'
                            },
                        ]}
                        noMarginLeft
                        justButton
                        test_id_prefix={test_id_prefix_passport}
                    />
                }
            </ListItem>

            { editModalOpen && (
                <DocumentsForm
                    document_types={document_types}
                    open={editModalOpen}
                    setOpenModal={setEditModalOpen}
                    complex_id={complex_id}
                    item={item}
                    onEdited={onEdited}
                />
            )}

            {commentBeforeDelete && (
                <CommentAction
                    title={titles.COMMENT_DELETE}
                    inputLabel={'Укажите причину удаления'}
                    open={commentBeforeDelete}
                    onClose={() => {
                        setCommentBeforeDelete(false);
                        setDelModalOpen(false);
                    }}
                    onAccept={(_comment) => {
                        dispatch(deleteComplexDocument(item.id, { _comment }, complex_id, () => { onDeleted && onDeleted(); }));
                    }}
                />
            )}

            { delModalOpen
            && <ConfirmDelete
                open={delModalOpen}
                onClose={()=> setDelModalOpen(false)}
                onSuccess={() => setCommentBeforeDelete(true)}
                message={messages.CONFIRM_DELETE}
            />
            }

            {openHistoryModal && (
                <Modal
                    isOpen={openHistoryModal}
                    title={titles.HISTORY_OF_CHANGES}
                    onClose={() => setOpenHistoryModal(false)}
                    noPadding={true}
                    buttons={<FormButtons
                        buttons={[
                            {
                                ...buttonsTypes.close,
                                onClick: () => setOpenHistoryModal(false)
                            }
                        ]}
                    />}
                >
                    <div className="modal__form">
                        <HistoryList
                            storeName="dorisControl"
                            storeProp="documentsHistory"
                            loadingProp="loadingDocumentsHistory"
                            loadAction={loadDocumentsHistory}
                            id={item.id}
                            ItemInfo={HistoryInfo}
                        />
                    </div>
                </Modal>
            )}
        </>
    );
};
