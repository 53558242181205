import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, } from 'react-router-dom';
import { __RouterContext } from 'react-router';
import { Checkbox, FormControlLabel, ListItem, } from '@mui/material';

import {
    clearComplexEvents,
    deleteFromServiceEvents,
    loadComplexEvents,
    moveServiceEventsToMain,
    setActive,
} from 'redux/DorisControl/actions';
import { dorisControlSelectors } from 'redux/DorisControl';
import { fullDateTimeWithTimeZone, getEndOf, getStartOf } from 'helpers/date.config';
import { usePermissions } from 'helpers/hooks/Permissons';
import removeEmptyFields from 'helpers/removeEmptyFields';
import FiltersCPVF from 'components/common/DorisControl/FiltersCPVF';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import MediaModalList from 'components/common/DorisControl/MediaModalList';
import PageLayout from 'components/layout/PageLayout';
import ReturnToButton from 'components/common/Buttons/ReturnToButton';
import { PageReportBlock, PageReportModal } from 'components/common/PageReport';

import DeleteForm from './DeleteForm';
import RenderContent from './RenderContent';
import { complexEventsURI } from './permissionConfig';

const ComplexEvents = ({
    complex_id,
    currentComplex,
    title = 'Отчет по событиям КФВФ',
    tab = null,
    test_id_prefix = '/reports/doris-control/complex-events'
}) => {
    const dispatch = useDispatch();
    const router = useContext(__RouterContext);
    const location = router?.location;
    const history = useHistory();
    const access = usePermissions(complexEventsURI);

    const loadingComplexEvents = useSelector(dorisControlSelectors.loadingComplexEvents);
    const data = useSelector(dorisControlSelectors.complexEventsData);
    const meta = useSelector(dorisControlSelectors.complexEventsMeta);

    const [isServiceBase, setIsServiceBase] = useState(false);
    const [openDeleteForm, setOpenDeleteForm] = useState(false);
    const complexRef = useRef(currentComplex);

    const storageLimit = !!localStorage.getItem('limit')
        ? Number(localStorage.getItem('limit'))
        : 25;

    const initialStateFilter = useMemo(() => ({
        page: 1,
        data: {
            start_date: getStartOf('day'),
            end_date: getEndOf('day'),
            complex_id_list: [],
            without_grz: false,
            grz_list: [],
            address: '',
            type_id_list: [], //'',
            model_id_list: [], //'', // Модель комплекса
            speed: 0,
            speed_condition: '>=',
            camera_code_list: [],
            contract_id_list: [],
            violation_type_list: [],
            exclude_violation_type_list: [],
            selectedComplexes: [],
            from_service_table: false,
            grz_color_list: [],
            grz_country_list: [],
            exclude_grz_country_list: [],
        },
        limit: storageLimit
    }), [storageLimit]);

    const [params, setParams] = useState(initialStateFilter);

    const paramsWithoutEmpty = useCallback((data) => {
        const complexesArrayCheck = () => {
            if (!data.selectedComplexes) return null;
            return Array.isArray(data.selectedComplexes)
                ? data.selectedComplexes.map(item => item.id)
                : data.selectedComplexes?.id;
        };

        const result = {
            ...data,
            start_date: fullDateTimeWithTimeZone(data.start_date),
            end_date: fullDateTimeWithTimeZone(data.end_date),
            violation_type_list: data.violation_type_list?.map(item => item.id),
            exclude_violation_type_list: data.exclude_violation_type_list?.map(item => item.id),
            complex_id_list: complex_id ? [complex_id] : complexesArrayCheck(),
            contract_id_list: data.contract_id_list?.map(({ id }) => id),
            speed: parseInt(data.speed),
            type_id_list: data.type_id_list?.map(({ id }) => id),
            model_id_list: data.model_id_list?.map(({ id }) => id),
            grz_color_list: data.grz_color_list?.map(({ id }) => id),
            grz_country_list: data.grz_country_list.map(({ id }) => id),
            exclude_grz_country_list: data.exclude_grz_country_list?.map(({ id }) => id),
        };

        delete result.selectedComplexes;
        return removeEmptyFields(result, false, true); // true для полей у которых boolean значение
    }, [complex_id]);


    const onSearch = useCallback((filter = params?.data) => {
        const filters = paramsWithoutEmpty(filter);
        // dispatch(loadAction(1, params.limit, filters));

        dispatch(loadComplexEvents(
            1,
            params.limit,
            filters,
            () => setIsServiceBase(filters.from_service_table)
        ));
    }, [params?.data, params.limit, /*loadAction,*/ dispatch, paramsWithoutEmpty,]);

    useEffect(() => {
        // первая загрузка либо если поменялся комплекс
        // параметры фильтра Осадчий просит сохранять при переходе к другому комплексу
        // поэтому меняем в фильтре только complex_id
        if (currentComplex && ((Number(currentComplex.id) !== Number(complexRef?.current?.id)))) {
            complexRef.current = currentComplex;
            let newData;
            setParams((prev) => {
                newData = {
                    ...prev.data,
                    complex_id: currentComplex.id
                };
                return {
                    ...prev,
                    data: newData
                };
            });
            onSearch(newData);
        }
    }, [complex_id, currentComplex, onSearch]);

    const [selectedEvents, setSelectedEvents] = useState([]);
    const [checkedAll, setCheckedAll] = useState(false);

    useEffect(() => {
        return () => dispatch(clearComplexEvents());
    }, []);

    const loadEventList = () => {
        const filters = paramsWithoutEmpty(params.data);
        // dispatch(loadAction(params.page, params.limit, filters));
        dispatch(loadComplexEvents(params.page, params.limit, filters, () => setIsServiceBase(filters.from_service_table)));
        setSelectedEvents([]);
        setCheckedAll(false);
    };

    const handleCheckAll = (e) => {
        if (checkedAll) {
            setSelectedEvents([]);
        } else {
            const selectedIds = data?.map(item => item?.uid);
            setSelectedEvents(selectedIds);
        }
        setCheckedAll(!checkedAll);
    };

    const onChangeMeta = (val) => {
        const newParams = {
            ...params,
            ...val
        };
        setParams(newParams);
        const filters = paramsWithoutEmpty(newParams.data);
        dispatch(loadComplexEvents(newParams.page, newParams.limit, filters, () => setIsServiceBase(filters.from_service_table)));
    };

    const onChangeFilter = (newData) => {
        if (!tab
            && (newData.selectedComplexes?.length > 1
                || newData.selectedComplexes[0]?.id !== params.data.complex_id?.[0])) {
            // если в фильтре выбрали другой комплекс, надо убрать complex_id из урла
            history.replace(location.pathname);
        }
        setParams((prev) => ({ ...prev, data: { ...prev.data, ...newData } }));
    };

    const resetFilter =(needRefresh) => {
        // убрали переход с комплеса на урл
        // if (complex_id && !tab) {
        //     // удаляем из урла и из initialState complex_id
        //     history.replace(location.pathname);
        //     complexRef.current = null;
        // }
        const newData = {
            ...initialStateFilter.data,
            complex_id: (complex_id && tab) ? [complex_id] : [],
        };
        setParams({
            ...initialStateFilter,
            data: newData
        });
        if (needRefresh) {
            onSearch(newData);
        }
    };

    const updateFilter = (filters) => {
        const newData = {
            ...params.data,
            ...filters,
        };
        if (Object.keys(filters).length > 0) {
            setParams((prev) => ({
                ...prev,
                data: newData
            }));

        }
        // нам в любом случае надо вначале запросить список
        onSearch(newData);
    };

    const handleCheckboxCheck = (event, id) => {
        const index = selectedEvents.findIndex(el => el === id);
        if (index < 0) {
            setSelectedEvents([
                ...selectedEvents,
                id
            ]);
        } else {
            setSelectedEvents([
                ...selectedEvents.slice(0, index),
                ...selectedEvents.slice(index + 1)
            ]);
        }
    };

    return (
        <>
            <PageLayout
                additionalButtons={
                    <ReturnToButton
                        onClick={complexRef?.current ? () => dispatch(setActive(complexRef?.current)) : () => {}}
                        test_id_prefix={test_id_prefix}
                    />
                }
                loading={loadingComplexEvents}
                header={tab ? '' : title}
                // subHeader={renderFreeSpace()}
                filters={
                    <FiltersCPVF
                        complexId={complex_id}
                        filter={params.data}
                        onFilterChanged={onChangeFilter}
                        onReset={resetFilter}
                        onUpdate={updateFilter}
                        onSearch={onSearch}
                        tab={tab}
                        test_id_prefix={test_id_prefix}
                    />
                }
                paginationProps={{
                    loadList: (page, limit) => onChangeMeta({ page, limit }),
                    list: meta,
                    limit: params.limit,
                    test_id_prefix: test_id_prefix
                }}
                content={() => (
                    <RenderContent
                        data={data}
                        loading={loadingComplexEvents}
                        complex_id={complex_id}
                        onCheckboxCheck={handleCheckboxCheck}
                        selectedEvents={selectedEvents}
                        isServiceBase={isServiceBase}
                        renderMediaModal={(props) => <MediaModalList
                            initialList={data}
                            limit={params.limit}
                            meta={meta}
                            filter={paramsWithoutEmpty(params.data)}
                            {...props}
                        />}
                        access={access}
                    />
                )}
                informPanelProps={{
                    buttons: (
                        <>
                            {(access.actions?.delete || access.actions?.is_delete)
                                && <FormButtonsComponent
                                    buttons={[{
                                        ...buttonsTypes.delete,
                                        onClick: () => setOpenDeleteForm(true),
                                        testName: 'delete'
                                    }]}
                                    positionLeft
                                    noPadding
                                    uri={complexEventsURI}
                                    test_id_prefix={test_id_prefix}
                                />
                            }

                            {!isServiceBase
                                && (
                                    <PageReportModal>
                                        <PageReportBlock
                                            filter={{
                                                ...paramsWithoutEmpty(params.data),
                                            }}
                                            urlTemplate={complexEventsURI}
                                            test_id_prefix={test_id_prefix}
                                        />
                                    </PageReportModal>
                                )
                            }
                        </>
                    ),
                    total: meta?.total
                }}
                actionPanel={
                    isServiceBase && data?.length > 0
                    && ((access.actions?.delete || access.actions?.is_delete) || (access.actions?.update || access.actions?.is_update))
                    && <ListItem dense>
                        <FormControlLabel
                            control={<Checkbox
                                checked={checkedAll}
                                onChange={handleCheckAll}
                                data-testid={`${test_id_prefix}:select_all/checkbox`}
                            />}
                            data-testid={`${test_id_prefix}:select_all/label`}
                            label={selectedEvents.length === params.limit ? 'Снять отметки' : 'Выбрать все'}
                        />
                        <FormButtonsComponent
                            uri={complexEventsURI}
                            test_id_prefix={test_id_prefix}
                            justButtons
                            buttons={[
                                {
                                    ...buttonsTypes.defaultSecondary,
                                    onClick: () => dispatch(moveServiceEventsToMain({ uuid: selectedEvents }, loadEventList)),
                                    disabled: selectedEvents.length === 0,
                                    name: 'перенести из сервисной базы в основную',
                                    size: 'small',
                                    testName: 'transfer_to_main'
                                },
                                {
                                    ...buttonsTypes.defaultPrimary,
                                    onClick: () => dispatch(deleteFromServiceEvents({ uuid: selectedEvents }, loadEventList)),
                                    disabled: selectedEvents.length === 0,
                                    name: 'удалить выбранные записи',
                                    size: 'small',
                                    testName: 'delete_selected'
                                }
                            ]}
                        />
                    </ListItem>
                }
            />
            {openDeleteForm
                && <DeleteForm
                    isOpen={openDeleteForm}
                    onDelete={() => {
                        setOpenDeleteForm(false);
                        loadEventList();
                    }}
                    onClose={() => {
                        setOpenDeleteForm(false);
                    }}
                    dateStart={params?.data?.start_date}
                    dateEnd={params?.data?.end_date}
                    test_id_prefix={test_id_prefix}
                />
            }


        </>
    );
};

export default ComplexEvents;
