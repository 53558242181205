import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, FormControlLabel } from '@mui/material';

import { parkingSelectors } from 'redux/Parking';
import { 
    loadCategories, 
    loadParkingList, 
    loadTariffList 
} from 'redux/Parking/actions';
import { useStoreProp } from 'helpers/hooks';
import titles from 'helpers/constants/titles';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import PageLayout from 'components/layout/PageLayout';
import { LSContainer } from 'components/common/List';
import NoData from 'components/common/Information/NoData';

import Item from './Item';
import ModalForm from './ModalForm';

import type { ItemTypes } from './types';

const Parking = () => {
    const dispatch = useDispatch();

    const categories = useStoreProp(
        loadCategories,
        'parking',
        'categories'
    );

    const tariffs = useStoreProp(loadTariffList, 'parking', 'tariffList');

    const [free_spaces, setFreeSpaces] = useState(false);
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [page, setPage] = useState<Number>(1);
    const list = useSelector(parkingSelectors.parkingListData);
    const meta = useSelector(parkingSelectors.parkingListMeta);
    const loading = useSelector(parkingSelectors.parkingListLoading);

    const [openModal, setOpenModal] = useState(false);

    const RenderList = () => (
        list?.length > 0 
            ? <LSContainer headers={[
                { title: '', width: '50px' },
                { title: titles.NAME, width: '18%' },
                { title: titles.CATEGORY, width: '18%' },
                { title: titles.ADDRESS, width: '30%' },
                { title: titles.TOTAL_PLACES, width: '10%' },
                { title: titles.PLACE_IS_FREE, width: '12%' },
                { title: titles.ACTIONS, isActions: true }
            ]}>
                {list.map((item: ItemTypes) => (
                    <Item
                        key={item.id}
                        item={item}
                        tariffs={tariffs}
                        categories={categories}
                        reloadList={() => onSearch(free_spaces)}
                    />
                ))}
            </LSContainer>
            : (!loading && <NoData/>)
    );

    useEffect(() => {
        dispatch(loadParkingList(page, limit));
    }, [dispatch, limit, page]);

    const onSearch = (freeSpaces: boolean) => {
        const params = !!freeSpaces ? { free_spaces: +freeSpaces } : {};
        dispatch(loadParkingList(page, limit, params));
    };

    const handleCheck = (checked: boolean) => {
        setFreeSpaces(checked);
        onSearch(checked);
    };

    return <>
        <PageLayout
            header="Парковки"
            actionPanel={<FormControlLabel
                control={
                    <Checkbox
                        //@ts-ignore
                        onClick={({ target }) => handleCheck(target.checked)}
                        checked={free_spaces}
                        size="small"
                    />
                }
                label="Свободные места"
            />}
            informPanelProps={{
                buttons: (
                    <FormButtonsComponent
                        noPadding
                        noMarginLeft
                        buttons={[
                            {
                                ...buttonsTypes.add,
                                onClick: () => setOpenModal(true),
                            }
                        ]}
                    />
                ),
                total: meta?.total
            }}
            loading={!!loading}
            content={RenderList}
            paginationProps={{
                loadList: (page) => setPage(page),
                list: meta,
                limit: limit,
                setLimit: setLimit,
            }}
        />
        {openModal 
            && <ModalForm
                isOpen={openModal}
                onClose={() => setOpenModal(false)}
                reloadList={() => onSearch(free_spaces)}
                tariffs={tariffs}
                categories={categories}
                isNew
            />
        }
    </>;
};

export default Parking;