import { useEffect, useState } from 'react';
import {
    FormControl,
    TextField,
} from '@mui/material';

import titles from 'helpers/constants/titles';
import useStoreProp from 'helpers/hooks/useStoreProp';
import { loadTransportTypes } from 'modules/ExternalTransport/redux/actions';
import TransportTypes from 'components/common/Autocomplete/Transport/Types';

import type { FilterFields, TransportType } from './types';

interface Filter {
    data: FilterFields;
    onChange: (a: { [key: string]: string | TransportType[] }) => void;
    className?: string;
    test_id_prefix?: string;
}

const Form = ({ data, onChange, className, test_id_prefix }: Filter) => {
    const transportTypes = useStoreProp(loadTransportTypes, 'externalTransport', 'transport_types');
    const resultTransportTypes = Object.entries(transportTypes).map<TransportType>(([id, name]) => ({ id, name: name as string }));


    const [selectedTypes, setSelectedTypes] = useState<TransportType[]>([]);

    useEffect(() => {
        setSelectedTypes(data.transport_types || []);
    }, [data, transportTypes]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        onChange({
            [name]: value,
        });
    };

    function isTransportType(ttype: string | null | TransportType): ttype is TransportType {
        const tt = (ttype as TransportType);
        return tt.id !== undefined && tt.name !== undefined;
    }

    return (
        <>
            <TextField
                label={titles.NAME}
                variant="outlined"
                size="small"
                value={data?.name || ''}
                name="name"
                onChange={handleChange}
                className={className}
                data-testid={`${test_id_prefix}/name`}
            />
            <TransportTypes
                multiple
                label={titles.TYPE_OF_VEHICLE}
                selected={selectedTypes}
                onChange={(el) => {
                    if (Array.isArray(el) ) {
                        const args = el.reduce<TransportType[]>((result, t) => {
                            if (isTransportType(t)) {
                                result.push(t);
                            }
                            return result;
                        }, []);
                        setSelectedTypes(args);
                        onChange({ transport_types: args });
                    }
                }}
                options={resultTransportTypes}
                className={className}
                test_id_prefix={`${test_id_prefix}/transport_types`}
            />
            <TextField
                label="Адрес"
                value={data.address_text || ''}
                onChange={handleChange}
                name="address_text"
                fullWidth
                variant="outlined"
                size="small"
                data-testid={`${test_id_prefix}/address_text`}
            />
        </>
    );
};

export default Form;
