import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';

import {
    clearDtpSettingByName,
    loadDtpOnRadius,
    loadDtpSettingByName,
} from 'redux/Incidents/actions';
import { incidentsSelectors } from 'redux/Incidents';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';

import MapForm from './MapForm';
import DtpList from './DtpList';

const SelectDtpModal = ({
    onClose,
    isOpen,
    dtpList = [],
    setDtpList,
    latlng,
}) => {
    const dispatch = useDispatch();
    const nearDtpList = useSelector(incidentsSelectors.dtpOnRadius);
    // console.log('nearDtpList', nearDtpList);
    const settingByName = useSelector(incidentsSelectors.settingByName);
    const loading = useSelector(incidentsSelectors.loadingCedConcentrationArea);

    const [selectedDtpList, setSelectedDtpList] = useState(dtpList);

    const onSave = () => {
        setDtpList(selectedDtpList);
    };

    useEffect(() => {
        const [lat, lon] = latlng;
        dispatch(loadDtpOnRadius({ lat, lon }));
        // запрос настройки радиуса
        dispatch(loadDtpSettingByName('dca_radius_on_map'));

        return () => {
            dispatch(clearDtpSettingByName());
        };
    }, []);

    return (
        <Modal
            title="Присвоить ДТП"
            onClose={onClose}
            fullWidth
            noPadding
            isOpen={isOpen}
            disabledDragDrop
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: onSave,
                        loading: loading
                    }
                ]}
            />}
        >
            <Grid container direction="row" style={{ height: '100%' }}>
                <Grid item xs={7}>
                    <MapForm
                        latlng={latlng}
                        selectedDtpList={selectedDtpList}
                        setSelectedDtpList={setSelectedDtpList}
                        nearDtpList={nearDtpList}
                        radius={settingByName?.value}
                    />
                </Grid>
                <Grid item xs={5}>
                    <h2>Для присвоения ДТП, кликните по карте в нужном месте</h2>
                    {selectedDtpList.length > 0
                        && <DtpList
                            items={selectedDtpList}
                            setItems={setSelectedDtpList}
                            createDtpFocus
                            draggable
                            // canEdit
                            canRemove
                        />
                    }
                </Grid>
            </Grid>
        </Modal>
    );
};

export default SelectDtpModal;
