import { Scrollbars } from 'react-custom-scrollbars';
import { Divider } from '@mui/material';

import messages from 'helpers/constants/messages';

const Vehicles = ({ data }) => {
    const arrayValue = (value) =>
        Array.isArray(value) && value.length > 0
            ? value.join(', ')
            : messages.INFO_IS_NOT_FOUND;

    return (
        <Scrollbars style={{ height: '200px' }}>
            {data?.length
                ? data?.map((item) => {
                    return (
                        <div key={item.id}>
                            <div className="info__item">
                                <span>Сведения об оставлении места ДТП:</span>
                                <span>{item.leaving_scene || messages.INFO_IS_NOT_FOUND}</span>
                            </div>
                            <div className="info__item">
                                <span>ГРЗ:</span>
                                <span>{item.grn || messages.INFO_IS_NOT_FOUND}</span>
                            </div>
                            <div className="info__item">
                                <span>Тип ТС:</span>
                                <span>{item.class_name || messages.INFO_IS_NOT_FOUND}</span>
                            </div>
                            <div className="info__item">
                                <span>Марка:</span>
                                <span>{item.brand || messages.INFO_IS_NOT_FOUND}</span>
                            </div>
                            <div className="info__item">
                                <span>Модель:</span>
                                <span>{item.model || messages.INFO_IS_NOT_FOUND}</span>
                            </div>
                            <div className="info__item">
                                <span>Цвет:</span>
                                <span>{item.color || messages.INFO_IS_NOT_FOUND}</span>
                            </div>
                            <div className="info__item">
                                <span>Места повреждения:</span>
                                <span>{arrayValue(item.places_of_damage)}</span>
                            </div>
                            <div className="info__item">
                                <span>Год выпуска:</span>
                                <span>{item.year || messages.INFO_IS_NOT_FOUND}</span>
                            </div>
                            <div className="info__item">
                                <span>Технические неисправности:</span>
                                <span>{item.technical_issues || messages.INFO_IS_NOT_FOUND}</span>
                            </div>
                            <div className="info__item">
                                <span>Расположение руля, тип привода:</span>
                                <span>{item.drive_location_type || messages.INFO_IS_NOT_FOUND}</span>
                            </div>
                            <div className="info__item">
                                <span>Форма собственности:</span>
                                <span>{item.ownership_type || messages.INFO_IS_NOT_FOUND}</span>
                            </div>
                            <div className="info__item">
                                <span>Комментарий:</span>
                                <span>{item.comment || messages.INFO_IS_NOT_FOUND}</span>
                            </div>
                            <Divider/>
                        </div>
                    );
                })
                : <div className="info__item">
                    {messages.INFO_IS_NOT_FOUND}
                </div>
            }
        </Scrollbars>
    );
};

export default Vehicles;
