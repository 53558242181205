import { TextField } from '@mui/material';

import titles from 'helpers/constants/titles';

const FilterForm = ({ data, onChange }) => {

    const handleChange = (e) => {
        const { name, value } = e.target;
        onChange({
            [name]: value,
        });
    };

    return (
        <form
            className="modal__form"
            onSubmit={(e) => { e.preventDefault(); }}
        >
            <div className="block">
                <TextField
                    required
                    label={titles.NAME}
                    variant="outlined"
                    size="small"
                    value={data?.device_name || ''}
                    name="device_name"
                    onChange={handleChange}
                />
            </div>
        </form>
    );
};

export default FilterForm;