import messages from 'helpers/constants/messages';
import { LSContainer, LSContentColumn, LSContentItem } from 'components/common/List';

import './DynamicTable.css';

const DynamicTable = ({ headers, data }) => {
    const isDataEmpty = !headers?.length || !data?.length;

    return (
        <div className="table-container">
            {isDataEmpty
                ? (
                    <p className="no-data-message">{messages.NO_DATA}</p>
                )
                : (
                    <LSContainer
                        headers={headers.map(({ value, width }) => (
                            { title: value, width }
                        ))}
                        style={{ paddingTop: 0, overflowY: 'auto', maxHeight: '210px' }}
                    >
                        {data.map((item, index) => (
                            <LSContentItem key={index}>
                                {headers.map(({ title }) => (
                                    <LSContentColumn key={title} style={{ fontSize: '0.9rem' }}>
                                        {item[title] ?? messages.NO_VALUE}
                                    </LSContentColumn>
                                ))}
                            </LSContentItem>
                        ))}
                    </LSContainer>
                )}
        </div>
    );
};

export default DynamicTable;
