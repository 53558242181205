import { getDateObject, getHumanDate, getTimeWithoutSeconds } from 'helpers/date.config';

const Information = ({ vestibuleData }) => {
    return <div className="information">
        <div className="info__item">
            <span className="info__item-title">
                Период:
            </span>
            <span className="info__item-content">
                {getHumanDate(new Date())} {vestibuleData.start_at}-{vestibuleData.end_at}
            </span>
        </div>    
        <div className="info__item">
            <span className="info__item-title">
                Пороговое значение:
            </span>
            <span className="info__item-content">
                {vestibuleData.trash_hold}
            </span>
        </div>
        <div className="info__item">
            <span className="info__item-title">
                Реальное значение:
            </span>
            <span className="info__item-content">
                {vestibuleData.count}
            </span>
        </div>
        <div className="info__item">
            <span className="info__item-title">
                Превышение:
            </span>
            <span className="info__item-content">
                {vestibuleData.excess}
            </span>
        </div>
    </div>;
};

export default Information;
