import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Grid,
    IconButton,
    Paper,
    Tooltip,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

import * as actions from 'redux/Efficiency/actions';
import { efficiencySelectors } from 'redux/Efficiency';
import {
    loadRegions,
} from 'redux/Dadata/actions';
import Loading from 'components/common/Loading';
// import { useHistory } from 'react-router-dom';
import { GeoJsonNew } from 'components/MapComponents/leaflet';
import DistrictSwitch from 'components/common/Switcher';
import { useStoreProp } from 'helpers/hooks';
import BoxContainer from 'components/common/Showcase/BoxContainer';
import HeaderTitle from 'components/common/Showcase/HeaderTitle';
import { config } from 'config';

import Filter from './Filter/index';
import HeatMap from './HeatMap';
import LengthRoad from './LengthRoad';
import ServiceLevel from './ServiceLevel';
import Graphics from './Graphics';
import HeadSelectedList from './HeadSelectedList';
import {
    getByDate,
    // getByRegion,
    filterByRegion,
} from './helper';
import Slider from './Slider/index';


const HeatGraphEfficiency = () => {
    const dispatch = useDispatch();

    const efficiencyTraffic = useSelector(efficiencySelectors.efficiencyTraffic);
    const loadingEfficiencyTraffic = useSelector(efficiencySelectors.loadingEfficiencyTraffic);

    // регионы
    const regionsAll = useStoreProp(loadRegions, 'dadata', 'regions');

    // параметры запроса
    const [params, setParams] = useState({});
    const [paramsInFilter, setParamsInFilter] = useState({});

    // районы городов или области
    const [isArea, setIsArea] = useState(false);
    const changeIsArea = (area) => {
        setIsArea(area);
        // сбрасываем регион при смене
        setSelectedRegion(null);
    };

    // выбранный временной период
    const [timeSlider, setTimeSlider] = useState('');

    // отображаемые слои
    const [showLayers, setShowLayers] = useState({});
    const changeShowLayers = (obj) => {
        setShowLayers({
            ...showLayers,
            ...obj,
        });
    };

    // выбранный регион
    const [selectedRegion, setSelectedRegion] = useState(null);
    const changeSelectedRegion = (region) => {
        setSelectedRegion((old) => region?.id === old?.id ? null : region);
    };

    // данные по выбранному региону
    const dataByRegion = isArea === false
        ? efficiencyTraffic?.cities
        : efficiencyTraffic?.areas;

    useEffect(() => {
        // загрузка списка, если поменялся фильтр - если он пустой, не загружаем
        if (Object.keys(params).length > 0) {
            dispatch(actions.loadEfficiencyTraffic(params));
        }
        return () => {
            dispatch(actions.loadedEfficiencyTraffic({}));
        };
    }, [params, dispatch]);

    const regionsGeo = useMemo(() => {
        const regions = isArea === false
            ? regionsAll?.cities || []
            : regionsAll?.areas || [];

        // преобразование
        return regions
            ?.map(({ geo_json, id, name }) => ({
                type: 'Feature',
                properties: {
                    id,
                    name,
                },
                geometry: {
                    ...geo_json,
                }
            }));
    }, [regionsAll, isArea]);

    // отображение регионов
    const componentRegion = useMemo(() => {
        if (Object.keys(regionsGeo).length > 0) {
            return (
                <GeoJsonNew
                    // данные
                    data={regionsGeo}
                    // название региона
                    toolTipTemplate={(properties) => `${properties?.name}`}
                    // параметры информации
                    toolTipOptions={{
                        direction: 'top',
                        offset: [0, -5],
                        sticky: true,
                    }}
                    // выбранный грегион (как параметры { id })
                    selected={selectedRegion}
                    // стили
                    style={{
                        opacity: 1,
                        fillOpacity: .1,
                        // fillOpacity: .7,
                        color: '#006699',
                        fillColor: '#ffffff',
                    }}
                    // кликнули
                    onClick={changeSelectedRegion}
                    // центруем
                    centerAfter={true}
                />
            );
        }
        return null;
    }, [regionsGeo, selectedRegion]);

    // данные для графиков
    const graphicsData = useMemo(() => {
        return {
            // заголовок графиков
            title: selectedRegion?.name
                || (
                    isArea
                        ? 'Данные по районам области'
                        : 'Данные по районам города'
                ),
            // кнопка
            action: selectedRegion
                ? (
                    <Tooltip title="Сбросить выбранный район">
                        <IconButton
                            aria-label="settings"
                            size="small"
                            onClick={() => {
                                setSelectedRegion(null);
                            }}
                        >
                            <ClearIcon />
                        </IconButton>
                    </Tooltip>
                )
                : null
        };
    }, [selectedRegion, isArea]);

    return (
        <BoxContainer>
            <HeaderTitle title="Эффективность использования ЕПУТС по целевым показателям"/>

            <Grid
                container
                direction="row"
                spacing={2}
                style={{ height: '100%' }}
            >
                <Grid
                    item
                    xs={8}
                    style={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'pace-around',
                    }}
                >

                    {loadingEfficiencyTraffic && (
                        <div
                            style={{
                                textAlign: 'center'
                            }}
                        >
                            <Loading />
                        </div>
                    )}

                    {loadingEfficiencyTraffic === false && (
                        <>
                            {/* выбранные слои */}
                            <HeadSelectedList
                                // данные тепловой карты
                                data={dataByRegion?.heatmap || []}
                                timeSlider={timeSlider}
                                // выбранне слои
                                value={showLayers}
                                // изменяем выбранные слои
                                onChange={changeShowLayers}
                            />

                            {/* переключатель регионов */}
                            {!config.get('disableSwitcher')
                                && <div style={{ marginBottom: '.5rem' }}>
                                    <DistrictSwitch
                                        value={isArea}
                                        onChange={changeIsArea}
                                        asButton
                                    />
                                </div>
                            }

                            {/* тепловая карта */}
                            <HeatMap
                                // данные
                                data={
                                    // фильтруем по дате
                                    getByDate(
                                        dataByRegion?.heatmap || [],
                                        timeSlider
                                    )
                                }
                                // отображаемые слои
                                showLayers={showLayers}
                                // прокидывает регионы
                                regionComponent={componentRegion}
                            />

                            {/* временной слайдер по всем датам */}
                            <Slider
                                // данные тепловой карты
                                heatmap={dataByRegion?.heatmap || []}
                                // выбранные данные
                                value={timeSlider}
                                // выбрали другой период
                                onChange={setTimeSlider}
                                filterValue={paramsInFilter}
                            />
                        </>
                    )}

                    {/* фильтр */}
                    <Paper
                        variant="outlined"
                        style={{
                            padding: '1rem 1rem 0',
                            marginBottom: '1rem',
                        }}
                    >
                        <Filter
                            onChange={setParams}
                            onChangeInline={setParamsInFilter}
                        />
                    </Paper>

                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginBottom: '1rem'
                        }}
                    >
                        {/* Протяженность участков */}
                        <LengthRoad
                            // по дате
                            data={
                                // фильтруем по дате
                                getByDate(
                                    dataByRegion?.length_road || {},
                                    timeSlider
                                )
                            }
                            // data={
                            //     // фильтруем по региону
                            //     getByRegion(
                            //         // фильтруем по дате
                            //         getByDate(
                            //             dataByRegion?.length_road,
                            //             timeSlider
                            //         ),
                            //         selectedRegion?.id || null
                            //     )
                            // }
                        />

                        {/* Уровень обслуживания по шкале ОДМ */}
                        <ServiceLevel
                            // по дате
                            data={
                                // фильтруем по дате
                                getByDate(
                                    dataByRegion?.service_level || {},
                                    timeSlider
                                )
                            }
                            // data={
                            //     // фильтруем по региону
                            //     getByRegion(
                            //         // фильтруем по дате
                            //         getByDate(
                            //             dataByRegion?.service_level,
                            //             timeSlider
                            //         ),
                            //         selectedRegion?.id || null
                            //     )
                            // }
                        />

                    </div>

                </Grid>
                <Grid item xs={4}>
                    {loadingEfficiencyTraffic === false && (
                        <>
                            {/* графики */}
                            <Graphics
                                // по всем периодам
                                {...graphicsData}
                                // список графиков
                                list={dataByRegion?.graph || []}
                                // фильтрация по регионам
                                filter={filterByRegion(selectedRegion || null)}
                                paramsInFilter={paramsInFilter}
                            />
                        </>
                    )}
                </Grid>
            </Grid>

            {/*{graphList?.length > 0*/}
            {/*    ? graphList.map((item) => <Graphic key={item.title} item={item} />)*/}
            {/*    : (!loadingGraphList && <div>{messages.DATA_IS_NOT_FOUND}</div>)*/}
            {/*}*/}
        </BoxContainer>
    );
};

export default HeatGraphEfficiency;
