import { Scrollbars } from 'react-custom-scrollbars';

import messages from 'helpers/constants/messages';

const TabInformation = ({ data, title, hideEmpty }) => {
    return (
        <Scrollbars style={{ height: '200px' }}>
            {Object.keys(title).map((key) => {
                const titleObject = title[key] || {};
                const value = titleObject?.value
                    ? titleObject.value(data)
                    : data[key];

                if (hideEmpty && !value) return null;

                return (
                    <div
                        className="info__item"
                        key={`information_${key}`}
                    >
                        <span className="info__item-title">
                            {titleObject?.title || ''}
                            {': '}
                        </span>
                        <span>
                            {value || messages.INFO_IS_NOT_FOUND}
                        </span>
                    </div>
                );
            })}
        </Scrollbars>
    );
};

export default TabInformation;
