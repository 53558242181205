import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { TableVirtuoso } from 'react-virtuoso';
import { Divider, List, ListItem, ListItemText } from '@mui/material';

import { transportRoadSelectors } from 'redux/TransportRoad';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import CustomPagination from 'components/common/CustomPagination';
import { fullDateTimeWithoutSeconds } from 'helpers/date.config';

const FuelReportModal = ({ isOpen, onClose, item }) => {

    const reportFuelData = useSelector(transportRoadSelectors.reportFuelData);
    const reportFuelMeta = useSelector(transportRoadSelectors.reportFuelMeta);

    const [params, setParams] = useState({ page: 1, data: {} });
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);

    const titleList = [`ГРЗ ${item?.number}`];
    if (item?.serial_egts) titleList.push(`EGTS ${item?.serial_egts}`);
    if (item?.serial_yandex) titleList.push(`Яндекс-Транспорт ${item?.serial_yandex}`);

    const title = reportFuelData.length === 0
        ? 'Данные не найдены'
        : `Возможный слив топлива для ТС - ${titleList.join(', ')}`;

    const renderList = (index, tableItem) => <>
        {tableItem.map((elem, i) => (
            <ListItem key={elem.id || i} divider style={{ backgroundColor: index % 2 === 0 ? '#fff' : '#ccc' }}>
                <ListItemText>
                    <div>
                        <b>Дата:</b> {elem?.created_at && fullDateTimeWithoutSeconds(elem.created_at)}
                    </div>
                    <div>
                        <span className="margin-right">
                            <b>Широта:</b> {elem?.lat}
                        </span>
                        <span className="margin-right">
                            <b>Долгота:</b> {elem?.lon}
                        </span>
                    </div>
                    <div>
                        <b>Уровень топлива:</b> {elem?.fuel}
                    </div>
                </ListItemText>
            </ListItem>
        ))}
        <Divider style={{ height: 10 }} />
    </>;

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={title}
            buttons={
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.close,
                            onClick: onClose
                        }
                    ]}
                />
            }
        >
            {reportFuelData.length > 0 && (
                <>
                    <TableVirtuoso
                        style={{ height: 400, backgroundColor: 'white' }}
                        data={reportFuelData}
                        components={{
                            Scroller: React.forwardRef((props, ref) => <List className="list" {...props} ref={ref} />),
                        }}
                        itemContent={(index, tableItem) => renderList(index, tableItem)}
                    />
                    {reportFuelMeta && (
                        <CustomPagination
                            loadList={(page) => setParams({ ...params, page })}
                            list={reportFuelMeta}
                            limit={limit}
                            setLimit={setLimit}
                        />
                    )}
                </>
            )}
        </Modal>
    );
};

export default FuelReportModal;