import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import L from 'leaflet';

import * as actions from 'modules/ExternalTransport/redux/actions';
import { externalTransportSelectors } from 'modules/ExternalTransport';
import mapHelper from 'helpers/mapHelper';
import { GeoJsonNew } from 'components/MapComponents/leaflet';
import { useDebounce, } from 'helpers/hooks';
import { createIconMarker, getFilter } from 'modules/ExternalTransport/utils/helper';
import getFilters from 'components/MapComponents/helpers/getFilters';

import config from './config';
import PopUp from './PopUp';

const Layer = (props) => {
    const { map } = props;
    const dispatch = useDispatch();
    const showPopUp = useRef(false);

    const stationPolygon = useSelector(externalTransportSelectors.stationPolygon);
    const active = useSelector(externalTransportSelectors.active);
    const filters = useSelector(externalTransportSelectors.filters);

    const fetchPolygon = useCallback(() => {
        const filter = getFilters(filters, getFilter);
        const polygon = mapHelper.getGeometryPolygon(map);
        dispatch(actions.loadStationPolygon(polygon, filter));
    },[dispatch, filters, map]);

    const debounceFetchPolygon = useDebounce(fetchPolygon, 200);
    const handleFetchPolygon = useCallback(() => debounceFetchPolygon(), [debounceFetchPolygon]);

    useEffect(() => {
        fetchPolygon();
    }, [fetchPolygon]);

    useEffect(() => {
        map
            .on('moveend', handleFetchPolygon)
            .on('zoomend', handleFetchPolygon);
        return () => {
            map
                .off('moveend', handleFetchPolygon)
                .off('zoomend', handleFetchPolygon);
        };
    }, [handleFetchPolygon, map]);

    useEffect(() => {
        if (showPopUp.current) {
            const id = showPopUp.current;
            setTimeout(() => {
                map.fire(`showBy${config.slug}${id}`);
            }, 1000);

            showPopUp.current = false;
        }
    }, [showPopUp.current]);

    useEffect(() => {
        // сдвигаем карту и зум
        if (Object.keys(active).length) {
            const { geometry } = active;
            if (geometry && geometry?.geometry?.coordinates.length > 0) {
                showPopUp.current = active.id;
                const b = L.geoJSON(geometry).getBounds();
                map.fitBounds(b);
            }
        }
    }, [active]);

    const polygonGeometry = useMemo(() => {
        return stationPolygon?.reduce((res, item) => {
            const {
                id,
                name,
                geometry = {},
                color,
                transport_type
            } = item;

            res.push({
                ...geometry,
                properties: {
                    ...geometry?.properties,
                    id,
                    name,
                    color,
                    transport_type
                },
                attribution: {
                    color,
                },
                style: {
                    color,
                },
            });

            return res;
        }, []);
    }, [stationPolygon, active]);

    return (
        <GeoJsonNew
            {...props}
            data={polygonGeometry}
            toolTipTemplate={({ name = '' }) => <div>{name ?? ''}</div>}
            icon={item => createIconMarker(item.color, item.transport_type)}
            idPrefix={config.slug}
            toolTipOptions={{
                direction: 'top',
                offset: [0, -36]
            }}
            popUpTemplate={({ id, transport_type }) => <PopUp id={id} transportType={transport_type} />}
            onClosePopup={() => {
                dispatch(actions.clearActive());
            }}
            popUpOptions={{ minWidth: 900 }}
        />
    );
};

export default Layer;
