import { useEffect, useMemo, useRef, useState } from 'react';
import { isArray, isEqual } from 'lodash';

import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

import ArrayItem from './ArrayItem';

import type { Setting } from '../../types';

interface ColorFieldProps {
    element: Setting;
    onChange: (value: string[]) => void;
    setError: (error: boolean) => void
}

const ArrayField = ({ element, onChange, setError }: ColorFieldProps) => {
    const isFirstRender = useRef(true);
    const initValue = useMemo(() => (isArray(element?.value) && element?.value.length > 0) ? element?.value : [''], []);

    const [list, setList] = useState<string[]>(initValue);
    const [changed, setChanged] = useState(false);

    const isInitEqual = () => {
        if (isArray(element?.value) && element.value.length === 0) {
            return isEqual(initValue, ['']);
        }
        return isEqual(initValue, element.value);
    };
    
    useEffect(() => {
        if (isInitEqual() && changed) {
            setList(initValue);
            setChanged(false);
        }
        
    }, [element.value]);

    const checkError = (value: string) => {
        if (element.format === 'email') {
            const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return !regex.test(value);
        }
        return false;
    };

    const returnError = () => {
        if (isEqual(list, [''])) {
            setError(false);
            return;
        }
        const filteredList = list.filter(el => checkError(el));
        setError(!!filteredList.length);
    };

    useEffect(() => {
        returnError();
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        
        const newList = list.filter(item => !!item);
        onChange(newList);
    }, [list]);
    
    const handleChange = (value: string, index: number) => {
        setChanged(true);
        setList([
            ...list.slice(0, index),
            value,
            ...list.slice(index + 1 ),
        ]);
    };

    const handleDelete = (index: number) => {
        setChanged(true);
        setList([
            ...list.slice(0, index),
            ...list.slice(index + 1 ),
        ]);
    };
    
    return (
        <>
            {list.map((field, i) =>
                <ArrayItem
                    key={i}
                    field={field}
                    checkError={checkError}
                    index={i}
                    format={element.format}
                    readOnly={element.read_only}
                    handleChange={handleChange}
                    handleDelete={handleDelete}
                />
            )}
            <FormButtonsComponent
                buttons={[
                    {
                        ...buttonsTypes.add,
                        onClick: () => setList([ ...list, ''])
                    }
                ]}
            />
        </>
    );
};

export default ArrayField;