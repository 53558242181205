import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '@mui/material';

import { createPark, editPark } from 'redux/TransportRoad/actions';
import titles from 'helpers/constants/titles';
import buttons from 'helpers/constants/buttons';
import { useValidation } from 'helpers/hooks';
import Modal from 'components/common/Modal';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import MapForm from 'components/MapComponents/MapForm';
import SelectCompany from 'components/common/Autocomplete/Companies';
import MapGeoJson from 'components/common/Location/MapGeoJson';
import FieldsModal from 'components/common/Location/FieldsModal';
import TitleValidator from 'components/common/Location/TitleValidator';
import FormInfoWrapper from 'components/common/FormInfoWrapper';

const PSEModal = ({ isNew = false, onClose, el= {}, isOpen, reloadList }) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const requestStatus = useSelector(({ validation }) => validation);

    const initialState = {
        ...el,
        name: el?.name || '',
        lat: el?.check_point?.lat || '',
        lon: el?.check_point?.lon || '',
        geometry: el?.check_point?.geometry || {},
        comment: el?.comment || '',
        organizations: el?.organizations || []
    };

    const [data, setData] = useState(initialState);
    const [openLatLonMap, setOpenLatLonMap] = useState(false);

    const onChange = (e) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value
        });
    };

    const onChangeOrganizations = (value) => {
        setData({
            ...data,
            organizations: value
        });
    };

    const handleAcceptCoordinates = ({ 0: lat, 1: lon }) => {
        setData({
            ...data,
            lat,
            lon
        });
        setOpenLatLonMap(false);
    };

    useEffect(() => {
        if (requestStatus.success) {
            onClose();
            validation.clear();
        }
    }, [validation, onClose, requestStatus]);

    const isDisabled = !data.name || !data.lat || !data.lon || !data?.geometry;

    const handleSaved = () => {
        const {
            lat, lon, geometry, organizations, ...others
        } = data;

        const result = {
            ...others,
            organization_ids: organizations.map(Item => Item.id),
            check_point: {
                lat,
                lon,
                geometry,
            },
        };

        const callback = () => {
            reloadList();
            onClose();
        };

        if (isNew) {
            dispatch(createPark(result, callback));
        } else {
            dispatch(editPark(el.id, result, callback));
        }
    };

    const getLatLonGeometry = data.lat && data.lon
        ? {
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [
                    data.lon,
                    data.lat,
                ],
            }
        } : null;

    return (
        <Modal
            title={isNew ? titles.ADD : titles.EDIT}
            onClose={onClose}
            maxWidthProp={'sm'}
            noPadding
            isOpen={isOpen}
            buttons={<FormButtonsComponent
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        disabled: isDisabled,
                        onClick: handleSaved
                    }
                ]}
            />}
        >
            <div className="modal__form">
                {openLatLonMap && (
                    <MapForm
                        isOpen={openLatLonMap}
                        latlon={[data?.lat, data?.lon]}
                        onAccept={handleAcceptCoordinates}
                        onClose={() => setOpenLatLonMap(false)}
                    />
                )}
                <FormInfoWrapper className="block" error={validation.isKey('name')} helperText={validation.get('name')} >
                    <TextField
                        required
                        label={titles.NAME}
                        variant="outlined"
                        size="small"
                        value={data.name || ''}
                        name={'name'}
                        onChange={onChange}
                        type="text"
                        InputLabelProps={{ style: { fontSize: '0.9rem' } }}
                    />
                </FormInfoWrapper>
                <div className="block">
                    <SelectCompany
                        filter={{ withDeleted: 1 }}
                        multiple
                        selected={data.organizations}
                        onChange={onChangeOrganizations}
                    />
                </div>
                <div className="row">
                    <FormInfoWrapper className="row__item" error={validation.isKey('lat')} helperText={validation.get('lat')} >
                        <TextField
                            required
                            label={titles.LAT}
                            variant="outlined"
                            size="small"
                            name="lat"
                            value={data?.lat || ''}
                            onChange={onChange}
                            error={validation.isKey('lat')}
                        />
                    </FormInfoWrapper>
                    <FormInfoWrapper className="row__item" error={validation.isKey('lon')} helperText={validation.get('lon')} >
                        <TextField
                            required
                            label={titles.LON}
                            variant="outlined"
                            size="small"
                            name="lon"
                            value={data?.lon || ''}
                            onChange={onChange}
                            error={validation.isKey('lon')}
                        />
                    </FormInfoWrapper>
                </div>
                <FormButtonsComponent
                    className="form__button"
                    noPadding
                    positionLeft
                    noMarginLeft
                    justButton
                    buttons={[
                        {
                            ...buttonsTypes.defaultInfo,
                            onClick: () => setOpenLatLonMap(true),
                            name: (data?.lat && data?.lon) ? buttons.CHANGE_PLACE : buttons.SELECT_ON_MAP
                        }
                    ]}
                />
                <div className="block">
                    <FormInfoWrapper error={validation.isKey('check_point.geometry')} helperText={validation.get('check_point.geometry')}>
                        <TitleValidator
                            fields={data}
                            validationFields={['geometry']}
                        >
                            {titles.STOP_AREA} *:
                        </TitleValidator>
                    </FormInfoWrapper>

                    <FormInfoWrapper
                        style={{ alignSelf: 'start' }}
                        error={validation.isKey('check_point.geometry.coordinates.0')}
                        helperText={validation.get('check_point.geometry.coordinates.0')}
                    >
                        <FieldsModal
                            title="Выбрать область на карте"
                            buttonText={buttons.SELECT_ON_MAP}
                            buttonVariant="contained"
                            fields={{ geometry: data?.geometry }}
                            onChange={({ geometry }) => {
                                setData({ ...data, geometry });
                                validation.deleteKeys(['check_point.geometry.coordinates.0', 'check_point.geometry']);
                            }}
                        >
                            <MapGeoJson
                                visibleGeometry={getLatLonGeometry}
                                // readOnly={true}
                                circle={true}
                                polygon={true}
                                polyline={false}
                                marker={false}
                                isHideValidation
                                // validationKey="check_point.geometry.coordinates.0"
                            />
                        </FieldsModal>
                    </FormInfoWrapper>

                </div>
                <FormInfoWrapper className="block" error={validation.isKey('comment')} helperText={validation.get('comment')} >
                    <TextField
                        label={titles.COMMENT}
                        multiline
                        rows={3}
                        variant="outlined"
                        value={data.comment || ''}
                        name="comment"
                        onChange={onChange}
                        error={validation.isKey('comment')}
                    />
                </FormInfoWrapper>
            </div>
        </Modal>
    );
};

export default PSEModal;
