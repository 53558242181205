import { IconButton, TextField } from '@mui/material';
import { Delete } from '@mui/icons-material';

import FormInfoWrapper from 'components/common/FormInfoWrapper';

import styles from '../../styles.module.scss';

interface ArrayItemProps {
    field: string;
    checkError: (field: string) => boolean;
    index: number;
    format: string | undefined;
    readOnly: boolean | undefined;
    handleChange: (value: string, index: number) => void;
    handleDelete: (index: number) => void;
}

const ArrayItem = ({ field, checkError, index, format, readOnly, handleChange, handleDelete }: ArrayItemProps) => {
    return (
        <div className={styles.arrayItem}>
            {/* <h3>{index+1}.</h3> */}
            <FormInfoWrapper
                error={checkError(field)}
                helperText={checkError(field) ? 'Проверьте форму заполнения!' : ''}
                style={{ flex: 1, margin: 10, marginLeft: 0 }}
            >
                <TextField
                    variant="outlined"
                    style={{ width: '100%' }}
                    type={format}
                    label={index+1}
                    placeholder={format === 'email' ? 'example@mail.ru' : ''}
                    size="small"
                    value={field}
                    onChange={(e) => handleChange(e.target.value, index)}
                    disabled={readOnly}
                    error={checkError(field)}
                />
            </FormInfoWrapper>
            {index !== 0
                && <IconButton onClick={() => handleDelete(index)}>
                    <Delete />
                </IconButton>
            }
        </div>
    );
};

export default ArrayItem;
