import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { meteoSelectors } from 'redux/Meteo';
import { loadMeteoForecast } from 'redux/Meteo/actions';
import Loading from 'components/common/Loading';
import messages from 'helpers/constants/messages';

import type { Device, ForecastItem } from 'types/Meteo';

interface ForecastProps {
    item: Device;
    isPopUp?: boolean;
}

const Forecast = ({ item, isPopUp = false }: ForecastProps) => {
    const dispatch = useDispatch();

    const list: ForecastItem[] = useSelector(meteoSelectors.forecastList);
    const loading = useSelector(meteoSelectors.loadingForecast);

    useEffect(() => {
        dispatch(loadMeteoForecast(item.id));
    }, [dispatch, item.id]);

    const prepareData = (): (string | number | null)[][] => {
        return list.reduce<(string | number | null)[][]>((res, elem, index) => {
            if(index === 0) {
                for (let i = 0; i < elem.parameters.length; i++) {
                    res[i] = [elem.parameters[i].name];
                }
            }

            for (let i = 0; i < elem.parameters.length; i++) {
                res[i].push(elem.parameters[i].value);
            }
            return res;
        }, []);
    };

    return (<div className={isPopUp ? 'table-information' : ''}>
        {loading
            ? isPopUp ? <Loading size={50} className="preloader center" /> : <Loading linear />
            : <TableContainer>
                <Table style={{ border: '1px solid #e5e5e5', borderRightWidth: 0 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ minWidth: 300, borderRight: '1px solid #e5e5e5', padding: 10 }} >
                                Прогнозируемые параметры
                            </TableCell>
                            {list.map((el, i) => 
                                <TableCell key={i} align="center" style={{ borderRight: '1px solid #e5e5e5', padding: 10, whiteSpace: 'nowrap' }}>
                                    {el.date}
                                </TableCell>
                            )}
                            
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {prepareData().map((els, i) => 
                            <TableRow key={'body_row_' + i} >
                                {els.map((el, i) => 
                                    <TableCell
                                        key={'body_cell_' + i}
                                        align={i === 0 ? 'left' : 'center'}
                                        style={{ borderRight: '1px solid #e5e5e5', padding: 10 }}
                                    >
                                        {el || messages.NO_VALUE}
                                    </TableCell>
                                )}
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        }
    </div>);
};

export default Forecast;
