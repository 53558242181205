import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Checkbox, IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import { editDtp, editDTPStatuses } from 'redux/Incidents/actions';
import titles from 'helpers/constants/titles';
import dtpStatusConfig from 'helpers/dtpStatus.comfig';
import { fullDateTimeWithoutSeconds } from 'helpers/date.config';
import messages from 'helpers/constants/messages';
import ModalForm from 'components/MapComponents/Layers/Incidents/ModalForm';
import ModalFormPopUp from 'components/MapComponents/Layers/Incidents/PopUp/ModalForm';
import { ActionMore, ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';

import TransportIncidentInfoModal from './TransportIncidentInfoModal';
import Info from './Info';


function Item({
    data,
    types,
    reloadList,
    onCheck = () => {},
    checked = false
}) {
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const [openStatusModal, setOpenStatusModal] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openTIInfoModal, setOpenTIInfoModal] = useState(false);

    const type = types?.get?.(data.dtp_type) || {};
    const { address_text, intersection } = data;

    const toggleOpen = (e) => {
        if (e.target.name !== 'checkbox') {
            setOpen(!open);
        }
    };

    const toggleChangeStatus = (e) => {
        e.stopPropagation();
        setOpenStatusModal(!openStatusModal);
    };

    const toggleOpenModal = (e) => {
        e.stopPropagation();
        setOpenModal(!openModal);
    };

    const disableStatusChange = data.type_load === 2;

    const renderStatus = () => {
        return (
            <p>
                <span style={ { color: dtpStatusConfig(data.status).color } }>
                    {dtpStatusConfig(data.status).icon}{' '}
                </span>
            </p>
        );
    };

    return (
        <>
            <LSContentItem style={{ paddingLeft: 5 }} onClick={toggleOpen}>
                <LSContentColumn>
                    <Checkbox
                        name="checkbox"
                        checked={checked}
                        onChange={(e) => onCheck(e, data?.id)}
                        color="primary"
                    />
                </LSContentColumn>
                <LSContentColumn>
                    <Tooltip title={dtpStatusConfig(data.status).status_text}>
                        <IconButton 
                            disableRipple={disableStatusChange}
                            size="small"
                            {...(!disableStatusChange && { onClick: toggleChangeStatus })}
                        >
                            {renderStatus()}
                        </IconButton>
                    </Tooltip>
                </LSContentColumn>
                <LSContentColumn>
                    {data.dtp_at ? fullDateTimeWithoutSeconds(data.dtp_at) : messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {type.name || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {address_text || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {intersection || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {data?.ti_id
                        ? <Button
                            onClick={(e) => {
                                e.stopPropagation();
                                setOpenTIInfoModal(true);
                            }}
                            size="small"
                            variant="text"
                            sx={{
                                textDecoration: 'underline',
                                minWidth: '10px',
                                display: 'inline-block',
                                fontSize: '1rem',
                                padding: '0 4px',
                            }}
                        >
                            {data?.ti_id}
                        </Button>
                        : messages.NO_VALUE
                    }
                </LSContentColumn>
                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                icon: <EditIcon fontSize="inherit"/>,
                                name: titles.EDIT,
                                onClick: toggleOpenModal,
                            }
                        ]}
                    />

                    <ActionMore
                        isOpen={open}
                        onClick={toggleOpen}
                    />
                </LSContentColumn>
            </LSContentItem>
            <Info isOpen={open} info={data} />
            {openModal
                && <ModalForm
                    data={data}
                    onAccept={(newFields) => {
                        dispatch(editDtp(newFields.id, newFields, reloadList));
                    }}
                    open={openModal}
                    onClose = {() => setOpenModal(false)}
                />
            }
            {openStatusModal && (
                <ModalFormPopUp
                    open={openStatusModal}
                    onClose={() => setOpenStatusModal(false)}
                    onSave={(status) => dispatch(editDTPStatuses({ ids: [data.id], new_status: status }, () => reloadList(setOpenStatusModal)))}
                    initialStatus={data?.status?.toString()}
                />
            )}
            {openTIInfoModal && (
                <TransportIncidentInfoModal
                    transportIncidentId={data.ti_id}
                    onClose={() => setOpenTIInfoModal(false)}
                />
            )}
        </>
    );
}

export default Item;
