import { useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import PlayArrow from '@mui/icons-material/PlayArrow';
import Print from '@mui/icons-material/Print';
import Save from '@mui/icons-material/Save';
import SkipPrevious from '@mui/icons-material/SkipPrevious';
import SkipNext from '@mui/icons-material/SkipNext';
import {
    Button,
    ButtonGroup,
    Tooltip
} from '@mui/material';
import cn from 'classnames';

import buttons from 'helpers/constants/buttons';
import messages from 'helpers/constants/messages';
import Modal from 'components/common/Modal';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import Image from 'components/common/Image';

import PrintInfo from './PrintInfo';
import styles from './mediaModal.module.scss';

// модалка для материалов Видеоаналитики и Розыска
// для кфвф не подходит, надо использовать MediaModalList
function MediaModal({
    isOpen,
    onClose,
    data = {},
    renderLabels,
}) {
    const {
        files = {},
        // files: { images, videos },
        // uuid
    } = data;

    // в старых эвентах images и video лежали сразу в files
    // сейчас они распределены по кодам нарушений (см. mediaViolations ниже)
    const images = files?.images;
    const videos = files?.videos || files?.video;

    const imgFiles = images
        ? Object.keys(images)?.reduce((acc, key) => {
            const media = images[key]?.map((item, i) => ({ src: item, violation: key, type: 'image', id: i + 1 }));
            return [...acc, ...media];
        }, [])
        : [];

    const videoFiles = videos
        ? Object.keys(videos)?.reduce((acc, key) => {
            const media = videos[key]?.map((item, i) => (
                { src: item, violation: key, type: 'video', id: imgFiles.length + i + 1 }
            ));
            return [...acc, ...media];
        }, [])
        : [];

    const mediaViolations = Object.keys(files);
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const mediaFiles = !(images || videos)
        ? mediaViolations?.reduce((acc, key) => {
            const img = files[key].images
                ? files[key].images?.map((item, i) => (
                    { src: item, violation: key, type: 'image', id: i + 1 }
                )) : [];
            const video = (files[key].videos || files[key].video)
                ? (files[key].videos || files[key].video)?.map((item, i) => (
                    { src: item, violation: key, type: 'video', id: img.length + i + 1 }
                )) : [];
            return [...acc, ...img, ...video];
        },
        [])
        : [];

    const allMedia = [...imgFiles, ...videoFiles, ...mediaFiles];
    const moveToFirstEl = () => setActiveItemIndex(0);
    const moveToLastEl = () => setActiveItemIndex(allMedia.length - 1);
    const moveToNextEl = () => setActiveItemIndex(activeItemIndex + 1);
    const moveToPreviousEl = () => setActiveItemIndex(activeItemIndex - 1);

    const activeItem = allMedia[activeItemIndex];

    const componentRef = useRef();
    const printImg = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: `
            @media print {
                .pagebreak {
                    page-break-before: auto;
                }
            }
        `,
    });

    const openNewWindow = () => window.open(activeItem.src);

    return (
        <Modal
            fullWidth
            isOpen={isOpen}
            noPadding
            onClose={onClose}
            buttons={
                <FormButtonsComponent
                    buttons={[
                        {
                            ...buttonsTypes.close,
                            onClick: onClose
                        }
                    ]}
                />
            }
        >
            <div className={styles.mediaModalContainer} style={{ flex: 1 }}>
                <div className={styles.navigationBar}>
                    <ButtonGroup className={styles.navigationBar__item} size="small">
                        <Tooltip title="Первая">
                            <span>
                                <Button
                                    disabled={!activeItemIndex}
                                    onClick={moveToFirstEl}
                                    size="small"
                                >
                                    <FirstPage />
                                </Button>
                            </span>
                        </Tooltip>
                        <Tooltip title="Предыдущая">
                            <span>
                                <Button
                                    disabled={!activeItemIndex}
                                    onClick={moveToPreviousEl}
                                    size="small"
                                >
                                    <SkipPrevious />
                                </Button>
                            </span>
                        </Tooltip>
                        <Tooltip title="Следующая">
                            <span>
                                <Button
                                    disabled={activeItemIndex === allMedia.length - 1}
                                    onClick={moveToNextEl}
                                    size="small"
                                >
                                    <SkipNext />
                                </Button>
                            </span>
                        </Tooltip>
                        <Tooltip title="Последняя">
                            <span>
                                <Button
                                    disabled={activeItemIndex === allMedia.length - 1}
                                    size="small"
                                    onClick={moveToLastEl}
                                >
                                    <LastPage />
                                </Button>
                            </span>
                        </Tooltip>
                    </ButtonGroup>
                    <ButtonGroup className={styles.navigationBar__item} size="small">
                        <Tooltip title="Сохранить в архив">
                            <span>
                                <Button size="small">
                                    <Save />
                                </Button>
                            </span>
                        </Tooltip>
                        <Tooltip title="Распечатать">
                            <span>
                                <Button
                                    disabled={activeItem?.type === 'video'}
                                    onClick={printImg} size="small"
                                >
                                    <Print />
                                </Button>
                            </span>
                        </Tooltip>
                    </ButtonGroup>
                </div>
                <div className={styles.content}>
                    {allMedia.length > 0
                        ? <>
                            <div className={styles.sidebar}>
                                <h2 className={styles.sidebar__header}>
                                    Все файлы
                                </h2>
                                <div className={styles.sidebar__content}>
                                    {allMedia.map((item, i) => (
                                        <div
                                            className={cn(styles.mediaBlock, activeItemIndex === i && styles.active)}
                                            key={item.id}
                                            onClick={() => setActiveItemIndex(i)}
                                        >
                                            {item.type === 'image'
                                                ? <Image
                                                    className={styles.media}
                                                    src={item.src}
                                                    alt={item.violation}
                                                />

                                                : item.type === 'video'
                                                    ? <video
                                                        src={item.src}
                                                        className={styles.media}
                                                    />
                                                    : null
                                            }
                                            {item.type === 'video'
                                                && <div className={styles.playArrow}>
                                                    <PlayArrow fontSize="large"/>
                                                </div>
                                            }
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className={styles.mainContentBlock}>
                                {activeItem?.type === 'image'
                                    ? <Image
                                        src={activeItem?.src}
                                        alt={activeItem?.violation}
                                    />
                                    : activeItem?.type === 'video'
                                        ? <video
                                            controls
                                            src={activeItem?.src}
                                        />
                                        : null
                                }
                                <FormButtonsComponent
                                    noPadding
                                    justButton
                                    buttons={[
                                        {
                                            onClick: openNewWindow,
                                            name: buttons.OPEN_IN_NEW_WINDOW,
                                        }
                                    ]}
                                />
                            </div>
                        </>
                        : <div>{messages.NO_MATERIALS}</div>
                    }
                </div>
                <div className={styles.footer}>
                    {renderLabels(data)}
                </div>
            </div>
            <div ref={componentRef}>
                <PrintInfo
                    data={data}
                    activeItem={activeItem}
                    renderLabels={renderLabels(data)}
                />
            </div>
        </Modal>
    );
}

export default MediaModal;
