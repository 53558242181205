import iconCluster from './icons/iconCluster';

const config = {
    name: 'Загруженность метрополитена',
    slug: 'metro-congestion',
    // mapContextMenu: {
    //     title: 'Добавить Загруженность метрополитена',
    //     event: 'add_metro_congestion',
    //     // className: styles.button,
    //     minWidth: 240,
    // },
    clusterIcon: iconCluster,
    layerIcon: 'fas fa-subway',
};
// добавление на карте
export default config;
