import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';

import * as actions from 'redux/WantedCars/actions';
import PageLayout from 'components/layout/PageLayout';
import { PageReportBlock, PageReportModal } from 'components/common/PageReport';
import NoData from 'components/common/Information/NoData';
import { fullDateTimeWithTimeZone, getEndOf, getStartOf } from 'helpers/date.config';
import removeEmptyFields from 'helpers/removeEmptyFields';

import Filter from './Filter.js';
import Map from './Map';
import List from './List';
import Info from './Info';

const initialFilter = {
    start_date: getStartOf('week'), // минус неделя от текущего времени
    end_date: getEndOf(),
    services: [],
    grz: '',
    // 'M714EE136'
    // 'P065EK190'
    // 'M770PT46'
};

function PassingVehicleCameras() {
    const dispatch = useDispatch();

    const eventWantedTrack = useSelector(({ carsWanted }) => carsWanted.eventWantedTrack);
    const loadingEventWantedTrack = useSelector(({ carsWanted }) => carsWanted.loadingEventWantedTrack);

    const [filter, setFilter] = useState(initialFilter);
    const [selected, setSelected] = useState({});
    const changeSelected = (v) => setSelected(v);

    const prepareData = (newFilter) => removeEmptyFields({
        ...newFilter,
        services: newFilter.services.map(item => item.id),
        grz_list: [newFilter.grz],
        start_date: fullDateTimeWithTimeZone(newFilter.start_date),
        end_date: fullDateTimeWithTimeZone(newFilter.end_date),
    });

    const onSearch = (newFilter = filter) => {
        dispatch(actions.loadEventWantedTrack(prepareData(newFilter)));
    };

    const data = useMemo(() => {
        return eventWantedTrack.reduce((res, { grz, data }) => res
            .concat(
                data?.map((i, index) => ({
                    ...i,
                    id: `${grz}:${index}`,
                    number: grz,
                })),
            )
        , []);
    }, [eventWantedTrack]);

    useEffect(() => {
        if (data?.length > 0) {
            setSelected(data?.[0] || {});
        } else {
            setSelected({});
        }
    }, [data]);

    const renderContent = () => {
        return data?.length > 0
            ?  <>
                <Grid
                    container
                    direction="row"
                    spacing={2}
                    style={{ marginBottom: '1rem' }}
                >
                    <Grid item xs={12} sm={6}>
                        <Map
                            list={data}
                            selected={selected}
                            onSelected={changeSelected}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <List
                            list={data}
                            selected={selected}
                            onSelected={changeSelected}
                        />
                    </Grid>
                </Grid>

                <Info data={selected}/>
            </>
            : !loadingEventWantedTrack && <NoData/>;
    };

    return (
        <PageLayout
            header="Прохождение ТС по камерам"
            filters={
                <Filter
                    filter={filter}
                    setFilter={setFilter}
                    onSearch={onSearch}
                />
            }
            loading={loadingEventWantedTrack}
            informPanelProps={{
                buttons: (
                    <PageReportModal>
                        <PageReportBlock
                            filter={prepareData(filter)}
                        />
                    </PageReportModal>
                ),
            }}
            content={renderContent}
        />
    );
}

export default PassingVehicleCameras;
