import { IconButton, Stack, Tooltip } from '@mui/material';
import ApartmentIcon from '@mui/icons-material/Apartment';
import HomeIcon from '@mui/icons-material/Home';
import cn from 'classnames';

import styles from './styles.module.scss';

interface SwitcherProps {
    value: boolean;
    onChange: (e: boolean) => void;
    title1?: string;
    title2?: string;
    disabled?: boolean;
    icon1?: JSX.Element;
    icon2?: JSX.Element;
    asButton?: boolean;
}

// переключалка по районам города, или в камерах мозаика-список
const Switcher = ({
    value,
    onChange,
    title1 = 'По районам города',
    title2 = 'По районам области',
    disabled,
    icon1 = <ApartmentIcon />,
    icon2 = <HomeIcon />,
    asButton = false,
}: SwitcherProps) => {
    return (<Stack spacing={0} direction="row">
        <Tooltip title={title1}>
            <IconButton
                onClick={() => onChange(false)}
                color={value ? 'secondary' : 'info'}
                disabled={disabled}
                className={cn({ [styles.button]: asButton, [styles.left]: asButton, [styles.selected]: asButton && !value })}
            >
                {icon1}
            </IconButton>
        </Tooltip>
        <Tooltip title={title2}>
            <IconButton
                onClick={() => onChange(true)}
                color={value ? 'info' : 'secondary'}
                disabled={disabled}
                className={cn({ [styles.button]: asButton, [styles.right]: asButton, [styles.selected]: asButton && value })}
            >
                {icon2}
            </IconButton>
        </Tooltip>
    </Stack>
    );
};

export default Switcher;
