import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import L from 'leaflet';

import { metroSelectors } from 'modules/Metro';
import * as actions from 'modules/Metro/redux/actions';
import mapHelper from 'helpers/mapHelper';
import { useDebounce, usePrevious, useStoreFromSelector } from 'helpers/hooks';
import { useWsSubscribe } from 'helpers/ws/hooks';
import { GeoJsonNew } from 'components/MapComponents/leaflet';
import getFilters from 'components/MapComponents/helpers/getFilters';

import config from './config.js';
import MPopup from './PopUp/index.js';
import { createIconMarker } from './helper.js';

const Layer = (props) => {
    const { map } = props;
    const dispatch = useDispatch();
    const polygon = useSelector(metroSelectors.vestibulePolygon);
    const polygonLoading = useSelector(metroSelectors.vestibuleStatuses);
    const active = useSelector(metroSelectors.activeVestibule);
    const filters = useSelector(metroSelectors.filtersVestibule);

    const statuses = useStoreFromSelector(actions.loadVestibuleStatuses, metroSelectors.vestibuleStatuses);
    //const statusesObj = useMemo(() => statuses.reduce((r, i) => ({ ...r, [i.id]: i }), {}), [statuses]);

    const [geoJson, setGeoJson] = useState([]);
    const showPopUp = useRef(false);
    const prevFilters = usePrevious(filters) || {};
    const prevPolygonLoading = usePrevious(polygonLoading) || false;
    const idPrefix = 'metroCongestion';
    const loadingPolygon = useRef(true);
    
    useWsSubscribe('transport-metro_vestibule_load_telemetry_v2', (events) => {
        if (events.length > 0) dispatch(actions.changeColorVestibulePolygon(events));
    });

    // грузим полигон
    const fetchPolygon = () => {
        if (loadingPolygon.current) {
            const polygon = mapHelper.getGeometryPolygon(map);
            const filter = getFilters(filters);
            dispatch(actions.loadVestibulePolygon(polygon, filter));
        }
    };

    // задерживаем одновременные запросы
    const debounceFetchPolygon = useDebounce(fetchPolygon, 200);
    const handleFetchPolygon = () => loadingPolygon.current && debounceFetchPolygon();

    useEffect(() => {
        fetchPolygon();

        return () => {
            dispatch(actions.clearActiveVestibule());
        };
    }, []);

    useEffect(() => {
        if (polygonLoading === false && showPopUp.current) {
            const id = showPopUp.current;
            setTimeout(() => {
                map.fire(`showBy${idPrefix}${id}`);
            }, 100);

            showPopUp.current = false;
        }

    }, [polygonLoading, prevPolygonLoading]);

    useEffect(() => {
        if (!isEqual(filters, prevFilters)) {
            debounceFetchPolygon();
        }

        map
            .on('moveend', handleFetchPolygon)
            .on('zoomend', handleFetchPolygon);

        // setMapToMarker();

        return () => {
            dispatch(actions.clearActiveVestibule());
            // dispatch(actions.clearForPolygon());
            map.closeContextMenu();
            //map.fire('context_menu_close');
            map
                .off('moveend', handleFetchPolygon)
                .off('zoomend', handleFetchPolygon);
        };
    }, [filters, prevFilters]);

    useEffect(() => {
        // сдвигаем карту и зум
        if (Object.keys(active).length) {
            const { geometry } = active?.entrances[0];

            if (geometry) {
                showPopUp.current = active.id;
                const center = L.geoJSON(geometry)?.getBounds?.().getCenter?.();
                if (center) map.setView(center, 18);
            }

            // setTimeout(() => map.fire(`showBy${active.id}`), 500);
        }
    }, [active]);

    useEffect(() => {
        const geoJson = polygon.reduce((res, { entrances, color_load, ...props }) => {
            return [
                ...res,
                ...(entrances.map(item => ({
                    ...item.geometry,
                    properties: {
                        ...props,
                        color_load: color_load
                    },
                    attribution: {
                        slug: config.slug,
                        color: color_load
                    },
                    style: {
                        color: color_load,
                        weight: 7
                    }
                })))
            ];
        }, []);

        setGeoJson(geoJson);
    }, [polygon]);

    return (
        <GeoJsonNew
            {...props}
            data={geoJson}
            toolTipTemplate={({ name }) => <div>{name ?? ''}</div>}
            icon={({ color_load }) => createIconMarker(color_load)}
            idPrefix={config.slug}
            toolTipOptions={{
                direction: 'top',
                offset: [0, -36]
            }}
            popUpTemplate={(data) => <MPopup data={data} />}
        />
    );
};

export default Layer;
