import { Scrollbars } from 'react-custom-scrollbars';

import { getHumanDate } from 'helpers/date.config';
import renderAddress from 'helpers/renderAddress';
import titles from 'helpers/constants/titles';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';

import Information from './Information';
import TypeValue from './TypeValue';

const InfoTrafficBlocking = ({
    data = {}
}) => {
    const numberValue = (value) =>
        typeof value === 'number'
            ? value || '0'
            : null;


    const labels = {
        status_text: {
            title: titles.STATUS
        },
        dtp_type: {
            title: titles.DTP_TYPE,
            value: (dtp_type) => <TypeValue id={dtp_type} />
        },
        dtp_at: {
            title: titles.DATE_TIME_DTP,
            value: (dtp_at) => getHumanDate(dtp_at)
        },
        address: {
            title: titles.ADDRESS,
            value: (address, dtp) => dtp?.address_text || Object.keys(address || {}).length > 0
                ? dtp?.address_text || renderAddress(address)
                : ''
        },
        intersection: {
            title: titles.INTERSECTION_STREET,
        },
        km: {
            title: titles.DTP_KM,
            value: numberValue
        },
        m: {
            title: titles.DTP_M,
            value: numberValue
        },
        description: {
            title: titles.DTP_DESCRIPTION
        },
        schema_img_url: {
            title: titles.SCHEMA_DTP,
            value: (value) =>
                value && value.length > 0 ? (
                    <img
                        alt="img"
                        style={{
                            width: '200px',
                            height: 'auto',
                            maxWidth: '100%',
                            padding: '10px 0'
                        }}
                        src={value}
                    />
                ) : null
        },
        type_load_text: {
            title: titles.DTP_DATA_SOURCE
        },
        ti_id: {
            title: titles.RELATIVE_EVENT,
            value: (value, action) => {
                if (value) {
                    return <FormButtons
                        buttons={[
                            {
                                ...buttonsTypes.show,
                                variant: 'default',
                                onClick: action,
                            },
                        ]}
                    />;
                }
                return null;
            },
            modal: true,
        },
        count_members: {
            title: titles.COUNT_DTP_MEMBERS,
            value: numberValue
        },
        dead: {
            title: titles.COUNT_DTP_DEAD,
            value: numberValue
        },
        wounded: {
            title: titles.COUNT_DTP_WOUNDED,
            value: numberValue
        },
        dead_child: {
            title: titles.COUNT_DTP_DEAD_KIDS,
            value: numberValue
        },
        wounded_child: {
            title: titles.COUNT_DTP_WOUNDED_KIDS,
            value: numberValue
        },
        count_ts: {
            title: titles.COUNT_DTP_TS,
            value: numberValue
        },
    };

    return (
        <Scrollbars style={{ height: '200px' }}>
            <Information
                data={data}
                // title={labels}
                // returnNull={true}
                list={labels}
                hideEmpty
            />
        </Scrollbars>
    );
};

export default InfoTrafficBlocking;