import * as types from './types';

const initialState = {
    list: {
        data: [],
        meta: {
            last_page: 0,
        },
    },
    loading: false,

    statuses: [],
    loadingStatuses: false,

    sidebar: {
        data: [],
        meta: {
            last_page: 0,
        },
    },
    sidebarLoading: false,

    polygon: [],
    polygonLoading: false,

    active: {},
    filters: {},
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case types.LIST_LOADED: {
            return {
                ...state,
                list: payload,
            };
        }

        case types.LIST_LOADING : {
            return {
                ...state,
                loading: payload
            };
        }

        case types.LOADED_STATUSES: {
            return {
                ...state,
                statuses: payload,
            };
        }

        case types.LOADING_STATUSES : {
            return {
                ...state,
                loadingStatuses: payload
            };
        }

        case types.LOADED_SIDEBAR: {
            const { data, isReplace } = payload;
    
            if (isReplace) {
                return {
                    ...state,
                    sidebar: data,
                };
            }
    
            return {
                ...state,
                sidebar: {
                    ...state.sidebar,
                    ...data,
                    data: [
                        ...state.sidebar.data,
                        ...data.data,
                    ],
                },
            };
        }
    
        case types.LOADING_SIDEBAR:
            return {
                ...state,
                sidebarLoading: payload,
            };

        case types.CLEAR_SIDEBAR:
            return {
                ...state,
                sidebar: initialState.sidebar,
            };

        case types.LOADED_POLYGON:
            return  {
                ...state,
                polygon: payload
            };

        case types.LOADING_POLYGON:
            return {
                ...state,
                polygonLoading: payload,
            };

        case types.SET_ACTIVE:
            return {
                ...state,
                active: payload,
            };

        case types.SET_FILTERS:
            return {
                ...state,
                filters: payload
            };

        default:
            return state;
    }
}
