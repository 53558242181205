import { IconButton } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// иконка подробнее
const ActionMore = ({
    isOpen = false,
    onClick = () => {},
    test_id_prefix = '',
    isPassport = false,
    size = 'small'
}) => {
    const OpenedIcon = isPassport ? ArrowBackIosIcon : ExpandLess;
    const ClosedIcon = isPassport ? ArrowForwardIosIcon : ExpandMore;
    return (
        <IconButton data-testid={`${test_id_prefix}/action-more/button`} size={size} onClick={onClick}>
            {isOpen
                ? <OpenedIcon fontSize="inherit"/>
                : <ClosedIcon fontSize="inherit"/>
            }
        </IconButton>
    );
};

export default ActionMore;
