import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SituationalPlansSelectors } from 'redux/SituationalPlans';
import { loadDistrictsByMonth } from 'redux/SituationalPlans/actions';
import messages from 'helpers/constants/messages/common';
import removeEmptyFields from 'helpers/removeEmptyFields';
import { dateWithDashYYYYMMDD } from 'helpers/date.config';
import { getDateWithDuration, getEndOf, getStartOf } from 'helpers/date.config';
import ReturnToButton from 'components/common/Buttons/ReturnToButton';
import DistrictSwitch from 'components/common/Switcher';
import PageLayout from 'components/layout/PageLayout';
import { config } from 'config';

import Filters from './Filters';
import Graph from './Graph';

function DtpGraph() {
    const districtsByMonth = useSelector(SituationalPlansSelectors.districtsByMonth);
    const loadingDistrictsByMonth = useSelector(SituationalPlansSelectors.loadingDistrictsByMonth);
    const dispatch = useDispatch();

    const initialState = {
        start_date: dateWithDashYYYYMMDD(getDateWithDuration({ month: -1 }, getStartOf('day'))),
        end_date: dateWithDashYYYYMMDD(getEndOf('day')),
        type: null,
        status: '',
        threat_level: null,
        type_creation: null,
        is_area: false,
    };

    const [params, setParams] = useState(initialState);

    const renderContent = () => {
        return Object.keys(districtsByMonth).length !== 0
            ? <Graph data={districtsByMonth} />
            : <div>{messages.NO_DATA_FOR_PERIOD}</div>;
    };

    const onChange = (value, keyName) => {
        setParams({
            ...params,
            [keyName]: value
        });
    };

    useEffect(() => {
        dispatch(loadDistrictsByMonth(removeEmptyFields(params)));
    }, [params]);

    return (
        <PageLayout
            header="Отчет по событиям по районам и месяцам"
            additionalButtons={<ReturnToButton/>}
            filters={<Filters
                setParams={setParams}
                initialState={initialState}
            />}
            content={renderContent}
            loading={loadingDistrictsByMonth}
            informPanelProps={{
                ...(!config.get('disableSwitcher')
                    ? { buttons: <DistrictSwitch
                        value={params.is_area}
                        onChange={(value) => onChange(value, 'is_area')}
                        asButton
                    />
                    }
                    : {}
                )
            }}
        />
    );
}

export default DtpGraph;
