import { useEffect, useRef, useState } from 'react';
import { useDispatch, } from 'react-redux';
import { Checkbox, } from '@mui/material';
import _ from 'lodash';

import { loadedViolationsById, loadingPddViolations, loadPddViolationsTree } from 'redux/DorisControl/actions';
import UniversalSelect from 'components/common/UniversalSelect';
import { renderArticleName } from 'components/common/DorisControl/PddViolation/renderArticleName';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';


const SelectPddViolation = ({ 
    multiple = false,
    selected = [],
    onChange = () => {},
    disabled = false,
    error = false,
    helperText = '',
    required,
    label='Список КОАП статей',
    test_id_prefix = ''
}) => {
    const dispatch = useDispatch();
    const [showList, setShowList] = useState(false);
    const [selectedItems, setSelected] = useState(selected);
    const prevData = useRef(selected);
    const [data, setData] = useState([]);
    const [initialData, setInitialData] = useState([]);
    // запрашиваем список сразу, он без пагинации - затем по нему будем делать поиск
    useEffect(() => {
        if (showList && initialData.length === 0) {
            dispatch(loadPddViolationsTree({ is_active: 1,  is_tree: 1 }, (response) => {
                setData(response);
                setInitialData(_.cloneDeep(response));// сохраняем весь массив чтобы после поиска вернуть весь спиок
                dispatch(loadedViolationsById(response)); // сохраняем их в плоский объект с ключами id чтобы легче было потом искать
                dispatch(loadingPddViolations(false));
            }));
        }
    }, [showList, initialData, dispatch]);

    useEffect(() => {
        // сброс фильтра - selected изменился нужно обновить selectedItems
        if (!_.isEqual(prevData.current, selected)) { 
            setSelected(selected);
            prevData.current = selected;
        }
    }, [selected, prevData]);


    const handleAccept = (data) => {
        onChange(data);
        setData(_.cloneDeep(initialData));
        setShowList(false);
    };

    const getPddViolations = (params) => {
        const { query } = params;
        // делаем поиск по имеющемуся списку, он без пагинации
        // ищем как по тексту так и по article.section.part
        if (query) {
            const findQuery = (arr) => arr.reduce((res, el) => {
                const queryStr = query.trim().toLowerCase();
                if (Array.isArray(el.parts)) {
                    const parts = findQuery(el.parts);
                    el.parts = parts;
                }
                if (el.violation.toLowerCase().includes(queryStr) 
                || `${el.article}${el.section && ('.' + el.section)}${el.part && ('.' + el.part)}`.toLowerCase().includes(queryStr) 
                || el.parts?.length > 0) {
                    res.push(el);
                }
                return res;
            }, []);
            setData(findQuery(_.cloneDeep(initialData))); // клон чтобы не делать в data ссылку на initialData 
        } else {
            setData(_.cloneDeep(initialData)); // клон чтобы не делать в data ссылку на initialData 
        }
    };

    const handleChange = (data) => {
        const uniqueArr = data.parts ? data.parts : [data];

        const existingItems = selectedItems.filter((item) =>
            uniqueArr.some((newItem) => newItem.id === item.id)
        );

        if (existingItems.length === uniqueArr.length) {
            const newItems = selectedItems.filter((item) => !uniqueArr.some((newItem) => newItem.id === item.id));
            setSelected(newItems);
        } else {
            const newItems = uniqueArr.filter((newItem) => !selectedItems.some((item) => item.id === newItem.id));
            setSelected([...selectedItems, ...newItems]);
        };
        
    };

    const handleRenderProps = (violations) => (
        <div>
            {Array.isArray(violations.parts) 
                ? <>
                    {violations.violation}
                    {violations.parts?.map((item) => (
                        <div style={{ display: 'flex', marginTop: '1rem' }} key={item.id}>
                            {/* <ListItemIcon> */}
                            <Checkbox
                                checked={!!selectedItems?.find((el) => el.id === item.id)}
                                onChange={() => handleChange(item)}
                                color="primary"
                                style={{ paddingTop: 0 }}
                            />
                            {/* </ListItemIcon> */}
                            {renderArticleName(item)}
                        </div>
                    ))}
                </>
                : renderArticleName(violations)}
        </div>
    );

    const onClose = () => {
        setSelected(selected);
        setData(_.cloneDeep(initialData));
        setShowList(false);
    };

    return (
        <>
            <CommonAutocomplete
                multiple={multiple}
                selected={selected}
                onChange={onChange}
                error={error}
                helperText = {helperText}
                required={required}
                inputName="pdd_violations"
                disabled={disabled}
                onReset={() => onChange(multiple ? [] : {})}
                renderLabel={(option) =>
                    Object.keys(option).length > 0
                        ? `${option?.article}.${option?.section}.${option?.part}`
                        : ''}
                limitTags={2}
                label={label}
                onShowList={() => setShowList(true)}
                filterSelectedOptions={true}
                isOpen={showList}
                test_id_prefix={test_id_prefix}
            />
            {showList
                && <UniversalSelect
                    storeName={'dorisControl'}
                    keyProp={'id'}
                    fetchList={getPddViolations}
                    withSearch={true}
                    multiple={multiple}
                    isSelected={true}
                    selected={selectedItems}
                    searchTitle="Найти в тексте статьи"
                    renderProps={handleRenderProps}
                    isOpen={showList}
                    onClose={onClose}
                    onAccept={handleAccept}
                    noPadding
                    maxWidthProp={'md'}
                    onChange={handleChange}
                    arrayInItem={'parts'}
                    renderKey={(el) => `${el.article}_${el.section}_${el?.violation}`}
                    title={label}
                    storeLoadingProps={'loading_pdd_violations'}
                    sortedList={data}
                    test_id_prefix={test_id_prefix}
                />
            }
        </>
    );
};

export default SelectPddViolation;
