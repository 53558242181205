import { ListItemIcon, ListItemText, } from '@mui/material';

import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

import { options, serviceList } from '../helper';

import Category from './Category';

import { useStyles } from './index';

import type { Checkpoint } from '../types';

interface SortableItemProps {
    item: Checkpoint;
    canEdit: boolean;
    canRemove: boolean;
    onDeleteItem: () => void;
    onChangeItem: () => void;
    service: string;
    displayType: keyof typeof options
}

const SortableItem = ({
    item,
    canEdit,
    canRemove,
    onDeleteItem,
    onChangeItem,
    service,
    displayType
}: SortableItemProps) => {
    const styles = useStyles();

    return (
        <>
            <ListItemText disableTypography className={styles.row}>
                <span style={{ display: 'flex', alignItems: 'center', minWidth: 16 }}>
                    <b>{item.number}</b>
                </span>

                <span className={styles.item} style={{ wordBreak: 'break-word' }}>
                    {item.station?.name || item.check_point?.name}
                </span>

                {(service === serviceList.transportPassenger && displayType === options.modalForm) && (
                    <Category item={item} styles={styles}/>
                )}
            </ListItemText>

            {(canEdit || canRemove) && (
                <ListItemIcon style={{ justifyContent: 'flex-end' }}>
                    <FormButtonsComponent
                        noMarginLeft
                        justButton
                        buttons={[
                            ...(canEdit
                                ? [{
                                    ...buttonsTypes.editIcon,
                                    onClick:onChangeItem,
                                }]
                                : []
                            ),
                            ...(canRemove
                                ? [{
                                    ...buttonsTypes.deleteIcon,
                                    onClick:onDeleteItem
                                }]
                                : []
                            )
                        ]}
                    />
                </ListItemIcon>
            )}
        </>
    );
};

export default SortableItem;
