import titles from 'helpers/constants/titles';

import TabInformation from './TabInformation';

const PopUp = ({ data, types }) => {
    
    return (
        <div className="map-popup">
            <div
                className="map-popup_header"
                style={{
                    backgroundColor: '#ff0000'
                }}
            >
                <div>
                    <div
                        className="img"
                        dangerouslySetInnerHTML={{ __html: '<i class="fas fa-do-not-enter" />' }}
                    />
                    <span>Перекрытие</span>
                </div>
            </div>
            <div className="map-popup_body">
                <TabInformation
                    data={data}
                    title={{
                        name: {
                            title: 'Название',
                        },
                        address_text: {
                            title: 'Адрес',
                        },
                        type: {
                            title: titles.TYPE,
                            value: ({ type }) => types[type]
                        },
                    }}
                />
            </div>
        </div>
    );
};

export default PopUp;
