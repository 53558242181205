import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    loadVehicleCategories,
    loadVehicleList
} from 'redux/TransportSpecial/actions';
import { uploadVehicleFile } from 'redux/Upload/actions';
import { transportSpecialSelectors } from 'redux/TransportSpecial';
import messages from 'helpers/constants/messages';
import { useStoreProp, useValidation } from 'helpers/hooks';
import titles from 'helpers/constants/titles';
import Loading from 'components/common/Loading';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import FileUploader from 'components/common/Upload/FileUploader';
import { LSContainer } from 'components/common/List';
import PageLayout from 'components/layout/PageLayout';
import ButtonWithPopover from 'components/common/Buttons/ButtonWithPopover';

import Filter from './Filter';
import Item from './Item';
import AddEditModal from './AddEditModal';
import UploadModal from './UploadModal';

const TSVehicles = () => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const categories = useStoreProp(loadVehicleCategories, 'transportSpecial', 'vehicleCategories');

    const vehiclesData = useSelector(transportSpecialSelectors.vehiclesData);
    const vehiclesMeta = useSelector(transportSpecialSelectors.vehiclesMeta);
    const loadingVehicles = useSelector(transportSpecialSelectors.loadingVehicles);
    const { uploadFileError, uploadedVehicleFile, uploading } = useSelector(({ upload }) => upload);

    const [limit, setLimit] = useState(Number(localStorage.getItem('limit') || 25));
    const [params, setParams] = useState({ page: 1, filter: {}, });

    const [openAddModal, setOpenAddModal] = useState(false);
    const [uploadModalOpen, setUploadModalOpen] = useState(false);

    const closeUploadModal = () => {
        validation.clear();
        setUploadModalOpen(false);
    };

    const reloadList = (isDelete) => {
        const page = isDelete && vehiclesData?.length === 1 && vehiclesMeta?.last_page > 1
            ? params.page - 1
            : params.page;

        setParams({ ...params, page });
    };

    useEffect(() => {
        dispatch(loadVehicleList(params.page, limit, params.filter));
    }, [params, dispatch, limit]);


    useEffect(() => {
        if(uploadFileError || (Object.keys(validation?.data).length && uploadedVehicleFile?.file))
            setUploadModalOpen(true);
    }, [uploadFileError, validation.data, uploadedVehicleFile]);

    // const itemCss = {
    //     width: '200px',
    //     fontSize: '1rem',
    //     color: 'rgba(0, 0, 0, 0.87)',
    //     // display: 'inline-flex',
    // };

    const renderContent = () => {
        return (vehiclesData?.length > 0
            ? (<>
                {uploading && <Loading linear/>}
                <LSContainer
                    headers={[
                        { title: titles.GRZ, width: '20%' },
                        { title: titles.BRAND, width: '20%' },
                        { title: titles.MODEL, width: '20%' },
                        { title: titles.ACTIONS, isActions: true }
                    ]}
                >
                    {vehiclesData?.map((item) => (
                        <Item
                            key={item.id}
                            item={item}
                            reloadList={reloadList}
                            categories={categories}
                        />
                    ))}
                </LSContainer>
            </>)
            : (!loadingVehicles && <div>{messages.DATA_IS_NOT_FOUND}</div>)
        );
    };

    return (
        <>
            <PageLayout
                header="Транспортные средства"
                filters={<Filter setParams={setParams}/>}
                loading={loadingVehicles}
                informPanelProps={{
                    buttons: (
                        <>
                            <FormButtons
                                buttons={[
                                    {
                                        ...buttonsTypes.add,
                                        onClick: () => setOpenAddModal(true),
                                    }
                                ]}
                                positionLeft
                                noPadding
                            />
                            <ButtonWithPopover
                                buttonType="upload"
                                actions={(callback) => <FileUploader
                                    isButton
                                    action={uploadVehicleFile}
                                    callback={callback}
                                    title="Список ТС"
                                    buttonType="defaultTextInfo"
                                />}
                            />
                        </>),

                    total: vehiclesMeta?.total
                }}
                content={renderContent}
                paginationProps={{
                    loadList: (page) => setParams({ ...params, page }),
                    list: vehiclesMeta,
                    limit,
                    setLimit
                }}
            />

            {openAddModal && (
                <AddEditModal
                    open={openAddModal}
                    onClose={() => setOpenAddModal(false)}
                    isNew
                    reloadList={() => setParams({ page: 1, filter: {} })}
                />
            )}

            {uploadModalOpen && (
                <UploadModal
                    onClose={closeUploadModal}
                    isOpen={uploadModalOpen}
                    data={uploadFileError}
                    validationData={validation.data}
                />
            )}
        </>
    );
};

export default TSVehicles;
