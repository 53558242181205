import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { monitoringSelectors } from 'redux/Monitoring';
import { loadStatuses } from 'redux/Monitoring/actions';
import { useStoreFromSelectorListToObject } from 'helpers/hooks';
import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';
import Information from 'components/common/Information';
import renderAddress from 'helpers/renderAddress';

import config from '../config';

const PopUp = ({ id, serviceIcon = config.layerIcon }) => {
    const polygon = useSelector(monitoringSelectors.polygon);
    const statusesOb = useStoreFromSelectorListToObject(loadStatuses, monitoringSelectors.statuses);


    const data = useMemo(
        () => polygon?.find((item) => item.id === id) || {},
        [id, polygon]
    );

    const fields = {
        device_name: {
            title: titles.NAME,
            value: ({ device_name }) => device_name || messages.INFO_IS_NOT_FOUND,
        },
        service_text: {
            title: 'Тип оборудования',
            value: ({ service_text }) => service_text || messages.INFO_IS_NOT_FOUND,
        },
        address_text: {
            title: titles.ADDRESS,
            value: ({ address, address_text }) => address_text || renderAddress(address),
        },
        // gk_name: {
        //     title: 'Название гос контракта',
        //     value: ({ gk_name }) => gk_name || null,
        // },
        // gk_number: {
        //     title: 'Номер гос контракта',
        //     value: ({ gk_number }) => gk_number || null,
        // },
    };

    return (
        <div className="map-popup">
            <div
                className="map-popup_header"
                style={{ backgroundColor: statusesOb.get(data?.status)?.color }}
            >
                <div>
                    <i className={serviceIcon} style={{ fontSize: '1rem' }}/>
                    <span>
                        {data?.reference_name || ''}
                    </span>
                </div>
            </div>
            <div className="map-popup_body">
                <div className="scroll">
                    <Information
                        data={data}
                        title={fields}
                        returnNull
                    />
                </div>
            </div>
        </div>
    );
};

export default PopUp;