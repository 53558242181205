import Form from 'modules/ExternalTransport/Dictionaries/Stations/Form';

const FilterForm = ({ data, onChange }) => {
    return (
        <form
            className="modal__form"
            onSubmit={(e) => { e.preventDefault(); }}
        >
            <Form data={data} onChange={onChange} className="block" />
        </form>
    );
};

export default FilterForm;
