import instance from 'helpers/axios.config';

import apiUrls from './api.urls';

const ApiMetroMethods = {
    getStationStatuses: async () => {
        const response = await instance.get(apiUrls.getStationStatuses());
        return response?.data;
    },

    loadStationList: async (params) => {
        const response = await instance.post(apiUrls.loadStationList(), { ...params });
        return response?.data;
    },

    loadStationSidebar: async (params) => {
        const response = await instance.post(apiUrls.loadStationList(), { ...params });
        return response?.data;
    },

    loadVestibulePolygon: async (params) => {
        const response = await instance.post(apiUrls.loadVestibulePolygon(), { ...params });
        return response.data;
    },

    loadVestibuleList: async (params) => {
        const response = await instance.post(apiUrls.loadVestibuleList(), { ...params });
        return response?.data;
    },

    getVestibuleStatuses: async () => {
        const response = await instance.get(apiUrls.getVestibuleStatuses());
        return response?.data;
    },

    getVestibuleTraffic: async (id) => {
        const response = await instance.post(apiUrls.loadVestibuleTraffic(id));
        return response?.data;
    },
};

export default ApiMetroMethods;
