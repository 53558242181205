import { useEffect, useMemo, useState, useRef, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import mapHelper from 'helpers/mapHelper';
import { usePrevious, useDebounce, useStoreFromSelectorListToObject } from 'helpers/hooks';
import {
    Marker,
    PopUp,
    ToolTip,
    ContextMenu,
    ContextMenuItem,
    Cluster, GeoJson,
} from 'components/MapComponents/leaflet';
import getAddressStringFromObject from 'components/common/Location/LoadAddressByCoords/getAddressStringFromObject';
import getFilters from 'components/MapComponents/helpers/getFilters';
import { iconCreateFunctionSimple } from 'components/MapComponents/helpers/iconCreateFunction';
import MapLegends from 'components/common/Transport/MapLegends';
import { loadConcentrationAreaTypes, loadConcentrationAreaStatuses } from 'redux/Incidents/actions';
import { incidentsSelectors } from 'redux/Incidents';
import * as actions from 'redux/FocusIncidents/actions';
import { focusIncidentsSelectors } from 'redux/FocusIncidents';

import { createIconMarker, getFilter, createIcon } from './helper';
import Legend from './Legend';
import config from './config.js';
import PopUpDtp from './PopUp';
import CollectorForms from './CollectorForms';

const Layer = (props) => {
    const unitZoom = 14;

    const {
        map,
        readOnly = false,
        maxClusterRadius = 50
    } = props;
    const dispatch = useDispatch();

    const polygon = useSelector(focusIncidentsSelectors.polygon);
    const active = useSelector(focusIncidentsSelectors.active);

    const saved = useSelector(focusIncidentsSelectors.saved);
    const filters = useSelector(focusIncidentsSelectors.filters);
    const polygonLoading = useSelector(focusIncidentsSelectors.polygonLoading);
    const prevLoading = usePrevious(polygonLoading);

    const [isUnit, setIsUnit] = useState((map?.getZoom?.() || null) < unitZoom);

    const [selectedItem, setSelectedItem] = useState(null);

    const [selectedGeometry, setSelectedGeometry] = useState(false);

    const prevFilters = usePrevious(filters);
    const filter = getFilters(filters, getFilter);

    const previewId = useRef(0);

    const types = useStoreFromSelectorListToObject(
        loadConcentrationAreaTypes,
        incidentsSelectors.concentrationAreaTypes,
    );
    const statuses = useStoreFromSelectorListToObject(
        loadConcentrationAreaStatuses,
        incidentsSelectors.concentrationAreaStatuses,
    );

    useEffect(() => {
        if (polygonLoading === false && prevLoading === true && previewId.current) {
            const id = previewId.current;
            setTimeout(() => {
                map.fire(`showBy${config.slug}${id}`);
            }, 200);
            previewId.current = 0;
        }
    }, [polygonLoading]);

    // грузим полигон
    const fetchPolygon = () => {
        const polygon = mapHelper.getGeometryPolygon(map);
        dispatch(actions.loadPolygon(
            polygon,
            {
                ...filter,
                with_dtp_list: 1
            }
        ));
    };

    // задерживаем одновременные запросы
    const debounceFetchPolygon = useDebounce(fetchPolygon, 400);
    const handleFetchPolygon = () => debounceFetchPolygon();

    const handleZoom = () => {
        handleFetchPolygon();
        // объединение класеров в 1 при зуме
        const zoom = map?.getZoom?.() || null;
        setIsUnit(zoom < unitZoom);
    };

    // добавить новый
    const handleAdd = ({ lat, lng }) => {
        dispatch(actions.setEditForm({
            lat,
            lon: lng,
        }));
    };

    // сдвинуться к маркеру
    const setMapToMarker = () => {
        if (Object.keys(active).length > 0) {
            const { lat, lon } = active;
            if (Math.abs(lat) && Math.abs(lon)) {
                setTimeout(() => {
                    map.setView([lat, lon]);
                }, 200);
            }
        }
    };

    const handleClickMap = () => {
        setSelectedItem(null);
        setSelectedGeometry(false);
    };

    useEffect(() => {
        if (!isEqual(filters, prevFilters)) {
            // doLoadPolygon();
            debounceFetchPolygon();
        }

        map
            .on('click', handleClickMap)
            // .on('moveend', doLoadPolygon)
            .on('moveend', handleFetchPolygon)
            .on('zoomend', handleZoom);

        setMapToMarker();

        return () => {
            dispatch(actions.resetActive());
            dispatch(actions.clearPolygon());
            // map.closeContextMenu();
            //map.fire('context_menu_close');
            map
                .off('click', handleClickMap)
                // .off('moveend', doLoadPolygon)
                .off('moveend', handleFetchPolygon)
                .off('zoomend', handleZoom);
        };
    }, [filters]);


    // добавление нового элемента с карты
    useEffect(() => {
        map.on(config.mapContextMenu.event, (e) => {
            //map.fire('context_menu_close');
            handleAdd(e.latlng);
        });

        return () => {
            map.off(config.mapContextMenu.event);
            dispatch(actions.setPolygonVisible());
            dispatch(actions.clearPolygon());
        };
    }, []);

    useEffect(() => {
        if (saved) {
            map.closeContextMenu();
            dispatch(actions.setEditForm());
            fetchPolygon();
            setSelectedItem(null);
            setSelectedGeometry(false);
        }
    }, [saved]);

    useEffect(() => {
        fetchPolygon();
    },[]);

    useEffect(() => {
        // сдвигаем карту и зум
        if (Object.keys(active).length > 0) {
            previewId.current = active?.id;
            const { lat, lon } = active;
            if (lat && lon) {
                map.setView([lat, lon], 15);
            }
            setSelectedItem(active || null);
        }
    }, [active, map]);

    // меню маркера, линии, полигона при клике
    const RenderContextMenu = ({ item, ...rcmProp }) => {
        return (
            <div>
                <ContextMenuItem
                    {...rcmProp}
                    value="Редактировать"
                    onClick={() => {
                        map.fire('context_menu_close');
                        dispatch(actions.setEditForm(item));
                    }}
                />
                <ContextMenuItem
                    {...rcmProp}
                    value="Удалить"
                    onClick={() => {
                        map.fire('context_menu_close');
                        dispatch(actions.setDeleteForm(item.id));
                    }}
                    className="red"
                />
            </div>
        );
    };

    const createItem = (item) => {
        const { id, lat, lon } = item;
        const typeColor = types.get(item.type).color;
        const statusColor = statuses.get(item.status).color;

        const componentProps = {
            ...props,
            key: `${config.slug}_${id}`,
            // для кластера
            attribution: {
                slug: config.slug,
                color: typeColor
            },
            onClick: (latlng) => {
                map.setView(latlng);
                dispatch(actions.resetActive());
            },
        };

        return (
            <Marker
                {...componentProps}
                latlng={[lat, lon]}
                icon={createIconMarker(
                    createIcon(
                        typeColor,
                        statusColor
                    )
                )}
                onClick={() => {
                    setSelectedItem(item);
                    dispatch(actions.setActive(item));
                }}

            >
                <ToolTip
                    direction="top"
                    offset={[0, -35]}
                >
                    <div>{item.address_text || getAddressStringFromObject(item.address || {}) || `${lat} / ${lon}`}</div>
                </ToolTip>

                {readOnly === false
                    && (
                        <ContextMenu
                            //minWidth={155}
                        >
                            <RenderContextMenu item={item} />
                        </ContextMenu>
                    )
                }
            </Marker>
        );
    };

    // делим на очаги и предочаги
    // 1: "Предочаг", 2: "Очаг"
    const {
        1: pfocus,
        2: focus,
    } = useMemo(() => {
        return polygon.reduce((res, item, i) => {
            if (item.id === selectedItem?.id) return res;

            // const index = i % 2 === 0 ? 0 : 1;
            // какой параметр для очага/предочага
            const index = item['type'] || 1;
            return {
                ...res,
                [index]: [
                    ...(res[index] || []),
                    createItem(item),
                ],
            };
        }, {});
    }, [polygon, readOnly, selectedItem]);

    const geoItem = useMemo(() => {

        if (selectedItem) {
            const {
                polygon = {},
                ...props
            } = selectedItem ||  {};

            const typeColor = types.get(selectedItem?.type)?.color;

            return {
                ...polygon,
                properties: {
                    ...polygon.properties,
                    data: {
                        ...props,
                    },
                    attribution: {
                        slug: config.slug,
                        color: typeColor
                    },
                },
                style: {
                    color: typeColor,
                    weight: 7
                }
            };
        }

        return null;
    }, [selectedItem]);

    useEffect(() => {
        if (
            polygon.length > 0
            && selectedItem?.id
        ) {
            const item = polygon.find(({ id }) => id === selectedItem?.id) || null;
            item && setSelectedItem(item);
        }
    }, [polygon]);

    return (
        <>
            {/* объединение кластеров */}
            {isUnit
                ? (
                    <Cluster
                        {...props}
                        iconCreateFunction={iconCreateFunctionSimple}
                        maxClusterRadius={maxClusterRadius}
                    >
                        {focus}
                        {pfocus}
                    </Cluster>
                )
                : (
                    <>
                        <Cluster
                            {...props}
                            iconCreateFunction={iconCreateFunctionSimple}
                            maxClusterRadius={maxClusterRadius}
                        >
                            {focus}
                        </Cluster>

                        <Cluster
                            {...props}
                            iconCreateFunction={iconCreateFunctionSimple}
                            maxClusterRadius={maxClusterRadius}
                        >
                            {pfocus}
                        </Cluster>
                    </>
                )
            }

            {/* маркер с попап */}
            {selectedItem && (
                <Marker
                    {...props}
                    latlng={[selectedItem.lat, selectedItem.lon]}
                    icon={createIconMarker(createIcon(
                        types.get(selectedItem?.type)?.color,
                        statuses.get(selectedItem?.status)?.color
                    ))}
                    // onClick={() => setSelectedGeometry(true)}  {/* УБИРАЮ ПОКА Т.К. НЕ РАБОТАЕТ */}
                >
                    {/* УБИРАЮ ПОКА Т.К. НЕ РАБОТАЕТ */}
                    {/* <ToolTip
                        permanent={true}
                        direction="top"
                        offset={[0, -30]}
                    >
                        <div>Кликните, чтобы отобразить объект</div>
                    </ToolTip> */}
                    <PopUp show={true} minWidth={800} offset={[0, 0]} onClose={() => {
                        dispatch(actions.resetActive());
                        setSelectedItem(null);
                    }}>
                        <PopUpDtp
                            uuid={selectedItem.id}
                            selectedItem={selectedItem}
                        />
                    </PopUp>
                    {readOnly === false
                        && (
                            <ContextMenu
                                //minWidth={155}
                            >
                                <RenderContextMenu item={selectedItem} />
                            </ContextMenu>
                        )
                    }
                </Marker>
            )}

            {/* объект */}
            {geoItem && selectedGeometry
                && (
                    <GeoJson
                        {...props}
                        parent={map}
                        data={geoItem}
                        icon={({ status }) => createIconMarker(status)}
                        contextMenuTemplate={readOnly === false ? (item, data) => <RenderContextMenu item={item} {...data} /> : null}
                        toolTipTemplate={({ address_text, address, lat, lon }) => <div>{address_text || getAddressStringFromObject(address || {}) || `${lat} / ${lon}`}</div>}
                        toolTipOptions={{
                            direction: 'top',
                            offset: [0, 0],
                            sticky: true,
                        }}
                        centerAfter={true}
                        // popUpOptions={{
                        //     minWidth: 800
                        // }}
                        // popUpTemplate={({ id }) =>
                        //     <PopUpDtp
                        //         uuid={id}
                        //     />
                        // }
                        // onClosePopup={() => {
                        //     dispatch(actions.resetActive());
                        // }}
                        // idPrefix={config.slug}
                    />
                )
            }

            <MapLegends layer="dtp-focus">
                <Legend/>
            </MapLegends>

            {/* формы */}
            <CollectorForms />
        </>
    );
};

export default Layer;
