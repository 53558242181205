import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';

import { monitoringSelectors } from 'redux/Monitoring';
import * as actions from 'redux/Monitoring/actions';
import mapHelper from 'helpers/mapHelper';
import { useStoreFromSelectorListToObject } from 'helpers/hooks';
import messages from 'helpers/constants/messages';
import { Marker, PopUp, ToolTip } from 'components/MapComponents/leaflet';
import layersLazyLoad from 'components/MapComponents/helpers/layersLazyLoad';

import { createIconMarker } from './helper';
import config from './config';
import MonitoringPopUp from './PopUp';


const Layer = (props) => {
    const { map } = props;
    const dispatch = useDispatch();

    const polygon = useSelector(monitoringSelectors.polygon);
    const active = useSelector(monitoringSelectors.active);
    const statusesOb = useStoreFromSelectorListToObject(actions.loadStatuses, monitoringSelectors.statuses);

    // const prevActive = usePrevious(active);
    const activeId = Object.keys(active || {}).length > 0 ? `${active.id}` : 0;

    // грузим полигон
    const fetchPolygon = () => {
        const polygon = mapHelper.getGeometryPolygon(map);
        dispatch(actions.loadPolygon(polygon));
    };

    // задерживаем одновременные запросы
    const debounceFetchPolygon = debounce(fetchPolygon, 200);
    const handleFetchPolygon = () => debounceFetchPolygon();


    useEffect(() => {
        fetchPolygon();

        map
            .on('moveend', handleFetchPolygon)
            .on('zoomend', handleFetchPolygon);


        return () => {
            dispatch(actions.clearActive());
            dispatch(actions.clearPolygon());
            map.fire('context_menu_close');
            map
                .off('moveend', handleFetchPolygon)
                .off('zoomend', handleFetchPolygon);
        };
    }, []);

    useEffect(() => {
        // сдвигаем карту и зум
        if (active?.lat && active?.lon) {
            const { lat, lon } = active;
            if (lat && lon) {
                map.setView([lat, lon], 15);
            }
        }
    }, [active]);

    const layerIcons = layersLazyLoad('layerIcon');

    const servicesIcons = {
        'dit': layerIcons['board'],
        'doris-control': layerIcons['camera'],
        'video-analytics': layerIcons['videoanalytics'],
        'video-cameras': layerIcons['city-cameras'],
        'ecologe': layerIcons['ecology'],
        'meteologe': layerIcons['meteo'],
        'lights': layerIcons['traffic-lights'],
        'traffic-flow': layerIcons['puid']
    };

    return (
        polygon.map((item) => {
            const { id, lat, lon, status, service } = item;

            const color = statusesOb.get(status)?.color;
            const serviceIcon = servicesIcons?.[service] || '';

            const componentProps = {
                ...props,
                key: `item_${id}`,
                attribution: {
                    slug: config.slug,
                    color
                },
                onClick: (latlng) => {
                    map.setView(latlng);
                    dispatch(actions.clearActive());
                },
            };

            const currentShow = activeId === `${id}`;

            return (
                <Marker
                    {...componentProps}
                    latlng={[lat, lon]}
                    icon={createIconMarker(color, serviceIcon)}
                >
                    <ToolTip
                        offset={[0, -40]}
                        direction="top"
                    >
                        <div><b>Название:</b> {item.device_name || messages.NO_DATA}</div>
                    </ToolTip>
                    <PopUp
                        minWidth={500}
                        show={currentShow}
                        onClose={() => {
                            if (currentShow) {
                                dispatch(actions.clearActive());
                            }
                        }}
                    >
                        <MonitoringPopUp id={item.id} serviceIcon={serviceIcon} />
                    </PopUp>
                </Marker>
            );
        })
    );
};

export default Layer;