import * as types from './types';

export const loadStationStatuses = () => ({
    type: types.LOAD_STATION_STATUSES
});

export const loadedStationStatuses = (data) => ({
    type: types.LOADED_STATION_STATUSES,
    payload: data
});

export const loadStationList = (page, limit, data) => ({
    type: types.LOAD_STATION_LIST,
    payload: { page, limit, ...data }
});

export const loadedStationList = (data) => ({
    type: types.LOADED_STATION_LIST,
    payload: data
});

export const loadStationSidebar = (page, limit, params = {}) => ({
    type: types.LOAD_STATION_SIDEBAR,
    payload: { page, limit, ...params },
});

export const loadedStationSidebar = (data, isReplace = true) => ({
    type: types.LOADED_STATION_SIDEBAR,
    payload: { data, isReplace },
});

export const loadingStationSidebar = (bool = false) => ({
    type: types.LOADING_STATION_SIDEBAR,
    payload: bool,
});

export const clearStationSidebar = () => ({
    type: types.CLEAR_STATION_SIDEBAR,
});

export const loadStationSchedules = (id, callback) => ({
    type: types.LOAD_STATION_SCHEDULES,
    payload: id,
    callback,
});

export const loadedStationSchedules = (data) => ({
    type: types.LOADED_STATION_SCHEDULES,
    payload: data
});

export const loadingStationSchedules = (bool = false) => ({
    type: types.LOADING_STATION_SCHEDULES,
    payload: bool
});

export const loadStationPolygon = (polygon, filter = {}) => ({
    type: types.LOAD_STATION_POLIGON,
    payload: { polygon, ...filter },
});

export const loadedStationPolygon = (data) => ({
    type: types.LOADED_STATION_POLIGON,
    payload: data
});

export const loading = (data) => ({
    type: types.LOADING,
    payload: data
});

export const clearStationPolygon = () => ({
    type: types.SET_CLEAR_POLYGON,
    payload: {}
});

export const setActive = (item = {}) => ({
    type: types.SET_ACTIVE,
    payload: item,
});

export const clearActive = () => ({
    type: types.SET_ACTIVE,
    payload: {}
});

export const setFilters = (data = {}) => ({
    type: types.SET_FILTERS,
    payload: data,
});

export const clearFilters = () => ({
    type: types.SET_FILTERS,
    payload: {},
});

export const loadTransportTypes = () => ({
    type: types.LOAD_TRANSPORT_TYPES,
});

export const loadedTransportTypes = (data) => ({
    type: types.LOADED_TRANSPORT_TYPES,
    payload: data
});
