import { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import { Grid } from '@mui/material';

import { meteoSelectors } from 'redux/Meteo';
import { getDeviceListMeteo } from 'redux/Meteo/actions';
import { LSContainer } from 'components/common/List';
import PageLayout from 'components/layout/PageLayout';
import NoData from 'components/common/Information/NoData';
import PassportTabs from 'components/common/PassportTabs';
import Organizations from 'components/common/CompanyLink/Organizations';
import titles from 'helpers/constants/titles';

import Filters from './Filters';
import Item from './Item';
import BasicData from './PassportPage/BasicData';
import Boards from './PassportPage/Boards';

import type { Device } from 'types/Meteo';
import Forecast from './PassportPage/Forecast';

interface Params {
    page: number;
    data: {
        uuid?: string[];
        name?: string;
        organization_ids?: number[];
    } | {};
}

const Devices = () => {
    const dispatch = useDispatch();
    const scrollRef = useRef<Scrollbars>(null);
    const history = useHistory();
    const location = useLocation();

    const list = useSelector(meteoSelectors.list);
    const listLoading = useSelector(meteoSelectors.listLoading);

    const [limit, setLimit] = useState<number>(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState<Params>({ page: 1, data: {} });
    const [selectedItem, setSelectedItem] = useState<Device | null>(null);

    const stringifyUrlFunc = useCallback((param = {}) => {
        const stringifyUrl = queryString.stringify(param, { arrayFormat: 'bracket' });
        history.replace({ pathname: '/dictionaries/meteo/devices', search: stringifyUrl });
    }, [history]);

    const onClickItem = useCallback((item: Device) => {
        if (item.id === selectedItem?.id) {
            stringifyUrlFunc({ page: params.page, limit });
        } else {
            stringifyUrlFunc({ page: params.page, limit, device_id: item.id });
        }
    }, [selectedItem, params.page, limit, stringifyUrlFunc]);

    const onChangePage = useCallback((page: number, newLimit?: number) => {
        setParams(prevParams => ({ ...prevParams, page }));
        stringifyUrlFunc({ page, limit: newLimit ?? limit });
    }, [limit, stringifyUrlFunc]);

    useEffect(() => {
        const { page, limit: limitParam } = queryString.parse(location.search, { arrayFormat: 'bracket' }) as { page?: string; limit?: string };

        setParams(prev => ({ page: page ? Number(page) : prev.page, data: prev.data }));
        setLimit(limitParam ? Number(limitParam) : limit);
    }, [location.search, limit]);

    useEffect(() => {
        if (list?.data?.length > 0) {
            const { device_id } = queryString.parse(location.search, { arrayFormat: 'bracket' }) as { device_id?: string };
            const item = device_id ? list.data.find((item: { id: number }) => item.id === Number(device_id)) : null;
            setSelectedItem(item || null);
        }
    }, [list?.data, location.search]);

    useEffect(() => {
        dispatch(getDeviceListMeteo({ page: params.page, limit, ...params.data }));
    }, [dispatch, limit, params]);

    const RenderList = () => (
        <LSContainer headers={[
            { title: 'Название', width: 'auto' },
            { title: 'Действия', isActions: true }
        ]}>
            {list.data.map((item: Device) => (
                <Item
                    key={item.id}
                    onClickItem={() => onClickItem(item)}
                    item={item}
                    selectedItem={selectedItem?.id === item.id}
                />
            ))}
        </LSContainer>
    );

    return (
        <PageLayout
            header="Метеомониторинг"
            filters={<Filters setParams={setParams} />}
            informPanelProps={{ total: list?.meta?.total }}
            loading={!!listLoading}
            content={() => (
                list?.data?.length > 0 ? (
                    <Grid container style={selectedItem?.id ? { height: '100%' } : {}}>
                        <Grid item xs={selectedItem?.id ? 3 : 12} style={{ height: '100%' }}>
                            {selectedItem?.id ? (
                                <Scrollbars style={{ height: '100%' }} ref={scrollRef}>
                                    <RenderList />
                                </Scrollbars>
                            ) : (
                                <RenderList />
                            )}
                        </Grid>
                        {selectedItem && (
                            <Grid item xs={9}>
                                <PassportTabs tabs={[
                                    {
                                        value: 'data',
                                        label: titles.BASIC_DATA,
                                        icon: <i className="fal fa-info-square"/>,
                                        contentComponent: <BasicData item={selectedItem}/>,
                                    },
                                    {
                                        value: 'organizations',
                                        label: titles.ORGANIZATIONS,
                                        icon: <i className="fal fa-file-alt" />,
                                        contentComponent: <Organizations item={selectedItem} microservice="meteo"/>,
                                    },
                                    {
                                        value: 'boards',
                                        label: 'ДИТ',
                                        icon: <i className="fas fa-border-none"/>,
                                        contentComponent: <Boards item={selectedItem}/>,
                                    },
                                    {
                                        value: 'forecast',
                                        label: 'Прогноз',
                                        icon: <i className="far fa-cloud-snow"/>,
                                        contentComponent: <Forecast item={selectedItem}/>,
                                    }
                                ]}/>
                            </Grid>
                        )}
                    </Grid>
                ) : !listLoading && <NoData />
            )}
            paginationProps={{
                loadList: onChangePage,
                list: list.meta,
                limit,
                setLimit,
            }}
        />
    );
};

export default Devices;
