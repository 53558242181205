import L from 'leaflet';

import removeEmptyFields from 'helpers/removeEmptyFields';

// получить иконку маркера
export const createIconMarker = (color) => {
    return L.divIcon({
        className: 'marker',
        iconSize: [30, 36],
        iconAnchor: [15, 36],
        popupAnchor: [0, -36],
        html: `<i class="fas fa-do-not-enter" style="color:${color || '#ff0000'};font-size:20px;background:#fff;border-radius:50%;"/>`
    });
};

export const getFilter = (filter) => {
    return removeEmptyFields(filter, false);
};