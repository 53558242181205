import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { deletePopulation } from 'redux/Incidents/actions';
import ConfirmModal from 'components/common/ConfirmModal';
import { buttonsTypes } from 'components/common/FormButtons';
import { ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';

import ModalForm from './ModalForm';

const Item = ({ item, loadList }) => {
    const dispatch = useDispatch();

    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    return (
        <>
            <LSContentItem>
                <LSContentColumn>
                    {item?.year ?? ''}
                </LSContentColumn>
                <LSContentColumn>
                    {item?.count ?? ''}
                </LSContentColumn>

                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                ...buttonsTypes.editIcon,
                                onClick: () => setOpenEditModal(true),
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: () => setOpenDeleteModal(true),
                            },
                        ]}
                    />
                </LSContentColumn>
            </LSContentItem>
            {openEditModal && (
                <ModalForm
                    isOpen={openEditModal}
                    onClose={() => setOpenEditModal(false)}
                    item={item}
                    loadList={loadList}
                />
            )}
            {openDeleteModal && (
                <ConfirmModal
                    open={openDeleteModal}
                    onSuccess={() => dispatch(deletePopulation(item.id, () => loadList(true)))}
                    onClose={() => setOpenDeleteModal(false)}
                />
            )}
        </>
    );
};

export default Item;
