import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material';
import { isEqual } from 'lodash';

import { loadDtpTypesAll, loadStreetList } from 'redux/Incidents/actions';
import { incidentsSelectors } from 'redux/Incidents';
import useStoreFromSelector from 'helpers/hooks/useStoreFromSelector';
import titles from 'helpers/constants/titles';
import { useDebounce } from 'helpers/hooks';
import DateRange from 'components/common/Dates/DateRange';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import { TypeLoad } from 'components/common/Autocomplete/DTP';

const Filter = ({ data, onChange, onDelete }) => {
    const dispatch = useDispatch();

    const getValue = (key, def = '') => data[key] || def;
    const [street, setStreet] = useState([]);

    const typesAll = useStoreFromSelector(loadDtpTypesAll, incidentsSelectors.typesAll);
    // const types = useSelector(incidentsSelectors.dtpTypesData);
    const streetList = useSelector(incidentsSelectors.streetList);

    const streetListOptions = streetList.length > 0
        ? streetList.map((item) => ({
            id: item,
            name: item
        }))
        : [{
            id: '',
            name: titles.NO_RESULTS
        }];

    const handleLoadStreetList = (query) => {
        dispatch(loadStreetList(query));
    };
    const loadStreetListDebounce = useDebounce(handleLoadStreetList, 800);

    // убираем дефолтное значение
    const typesList = typesAll
        ?.filter(({ id }) => id && id !== 'default')
        .map(({ id, name }) => ({
            id: id,
            name
        }));

    const handleEvent = (name) => (_, newValue) => {
        onChange({ [name]: newValue });
    };

    const disableOption = (option) => {
        const hasValue = (data.query || []).find(item => item.value === option.value);
        return !!hasValue || !option.id;
    };

    const handleStreet = (e, newValue) => {
        const options = newValue.reduce((res, item) => {
            if (typeof item === 'string') {
                return res;
            }
            return [
                ...res,
                item
            ];
        }, []);

        onChange({
            'street': options
        });

        setStreet([
            ...options
        ]);
    };

    const handleAddressChange = (e) => {
        const { target: { value } } = e;
        if (value?.length > 3) {
            loadStreetListDebounce(value);
        } else {
            loadStreetListDebounce.clear();
        }
    };

    useEffect(() => {
        if (data.street && !isEqual(data.street || [], street)) {
            setStreet(data.street);
        }
    }, [data]);

    return (
        <form
            className="modal__form"
            onSubmit={(e) => {
                e.preventDefault();
            }}
        >

            <div className="block">
                <Autocomplete
                    noOptionsText={titles.NO_RESULTS}
                    multiple
                    value={getValue('dtp_type', [])}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    options={typesList}
                    filterSelectedOptions={true}
                    getOptionLabel={(option) => option.name}
                    onChange={handleEvent('dtp_type')}
                    size="small"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label={titles.TYPE_EVENTS}
                        />
                    )}
                />
            </div>

            {/* поиск по адресу */}
            <FormInfoWrapper
                helperText={'Введите улицу и выберите нужную из предложенного списка'}
                className={'block'}
            >
                <Autocomplete
                    noOptionsText={titles.NO_RESULTS}
                    multiple
                    value={street}
                    options={streetListOptions}
                    onChange={handleStreet}
                    filterSelectedOptions={true}
                    getOptionLabel={(option) => option.name}
                    freeSolo={true}
                    getOptionDisabled={disableOption}
                    size="small"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label="Улица"
                        />
                    )}
                    onInputChange={handleAddressChange}
                />
            </FormInfoWrapper>

            <div className="block">
                <TypeLoad
                    multiple
                    selected={getValue('type_load', [])}
                    onChange={(type_load) => onChange({ type_load })}
                    openOnFocus
                />
            </div>

            <div className="row">
                <DateRange
                    className="row__item"
                    handleDate={(newDate) => onChange({ start_date: newDate })}
                    handleEndDate={(newDate) => onChange({ end_date: newDate })}
                    valueStartDate={getValue('start_date', null) && new Date(getValue('start_date', null))}
                    valueEndDate={getValue('end_date', null) && new Date(getValue('end_date', null))}
                    startLabel={titles.START_PERIOD}
                    endLabel={titles.END_PERIOD}
                />
            </div>

            <FormControlLabel
                control={
                    <Checkbox
                        checked={data?.with_dead?.id === 1 || false}
                        onChange={(event) =>
                            (event.target.checked)
                                ? onChange({
                                    with_dead: {
                                        id: event.target.checked ? 1 : 0,
                                        name: 'ДТП с погибшими'
                                    }
                                })
                                : onDelete('with_dead')
                        }
                        color="primary"
                    />
                }
                label={
                    <Typography variant="body2">
                        ДТП с погибшими
                    </Typography>
                }
            />

        </form>
    );
};

export default Filter;
