import * as types from './types';

// данные
export const getDeviceDataMeteo = (device_external_id) => ({
    type: types.GET_DEVICE_DATA,
    payload: device_external_id,
});

export const saveDeviceDataMeteo = (data) => ({
    type: types.DEVICE_DATA_LOADED,
    payload: data,
});

export const clearDeviceDataMeteo = () => ({
    type: types.CLEAR_DEVICE_DATA,
});

// проекты
export const getEventsMeteo = (external_id, page = 1, limit = 10, replaceStore = true) => ({
    type: types.GET_EVENTS,
    payload: {
        external_id, page, limit
    },
    replaceStore
});

export const saveEventsMeteo = (data, replaceStore = true) => ({
    type: types.EVENTS_LOADED,
    payload: data,
    replaceStore
});

export const clearEventsMeteo = () => ({
    type: types.CLEAR_EVENTS
});

// проекты
export const getProjectsMeteo = () => ({
    type: types.GET_PROJECTS,
});

export const saveProjectsMeteo = (data) => ({
    type: types.PROJECTS_LOADED,
    payload: data,
});

// типы
export const getDeviceTypesMeteo = () => ({
    type: types.GET_TYPES,
});

export const saveDeviceTypesMeteo = (typesRw) => ({
    type: types.TYPES_LOADED,
    payload: typesRw,
});

// статусы
export const getStatusesMeteo = () => ({
    type: types.GET_STATUSES,
});

export const saveStatusesMeteo = (data) => ({
    type: types.STATUSES_LOADED,
    payload: data,
});

export const getDeviceListMeteo = (params = {}, isReplace = true) => ({
    type: types.GET_LIST,
    payload: params,
    isReplace
});

export const saveDeviceListMeteo = (response, replaceStore) => ({
    type: types.LIST_LOADED,
    payload: response,
    replaceStore
});

export const getDeviceCompanyLink = (id) => ({
    type: types.GET_DEVICE_COMPANY_LINK,
    payload: id
});

export const createDeviceCompanyLink = (id, sendData, callback) => ({
    type: types.CREATE_DEVICE_COMPANY_LINK,
    payload: {
        id,
        sendData,
        callback
    }
});

export const deleteDeviceCompanyLink = (id, callback) => ({
    type: types.DELETE_DEVICE_COMPANY_LINK,
    payload: {
        id,
        callback
    }
});

export const saveDeviceCompanyLink = (data) => ({
    type: types.DEVICE_COMPANY_LINK_LOADED,
    payload: data
});

export const clearCompanyLink = () =>({
    type: types.CLEAR_COMPANY_LINK,
    payload: {}
});

export const deviceListLoading = (bool = false) => ({
    type: types.LIST_LOADING,
    payload: bool,
});

// загрузка
export const loading = (bool = false) => ({
    type: types.LOADING,
    payload: bool,
});

// полигон
export const getForPolygonMeteo = (polygon) => ({
    type: types.GET_POLYGON,
    payload: polygon,
});

export const saveForPolygonMeteo = (data) => ({
    type: types.POLYGON_LOADED,
    payload: data,
});

export const clearForPolygonMeteo = () => ({
    type: types.POLYGON_LOADED,
    payload: [],
});

// установить активный
export const setActiveMeteo = (item = {}) => ({
    type: types.SET_ACTIVE,
    payload: item,
});

export const resetActiveMeteo = () => ({
    type: types.SET_ACTIVE,
    payload: {},
});

// установить фильтр
export const setFilterMeteo = (filter) => ({
    type: types.SET_FILTER,
    payload: filter,
});

export const clearFiltersMeteo = () => ({
    type: types.SET_FILTER,
    payload: {},
});

export const wsDataMeteo = (events) => ({
    type: types.SET_WS_DATA,
    payload: events,
});

export const loadMeteoSettings = (page = 1, limit = 25, query = {}) => ({
    type: types.LOAD_METEO_SETTINGS,
    payload: { page, limit, query }
});

export const loadingMeteoSettings = (bool = false) => ({
    type: types.LOADING_METEO_SETTINGS,
    payload: bool
});

export const loadedMeteoSettings = (data) => ({
    type: types.LOADED_METEO_SETTINGS,
    payload: data
});

export const editMeteoSetting = (id, data) => ({
    type: types.EDIT_METEO_SETTING,
    payload: { id, data }
});

export const editSettingsList = (data, callback = () => {}) => ({
    type: types.EDIT_SETTINGS_LIST,
    payload: data,
    callback
});

// оповещении
export const loadMeteoAlerts = () => ({
    type: types.LOAD_METEO_ALERTS,
});

export const loadedMeteoAlerts = (data) => ({
    type: types.LOADED_METEO_ALERTS,
    payload: data,
});

// настройки вывода параметров
export const loadMeteoOutputParams = (page = 1, limit = 15, query = {}) => ({
    type: types.LOAD_METEO_OUTPUT_PARAMS,
    payload: {
        page,
        limit,
        query,
    }
});

export const loadedMeteoOutputParams = (data) => ({
    type: types.LOADED_METEO_OUTPUT_PARAMS,
    payload: data
});

export const loadMeteoOutputSettings = () => ({
    type: types.LOAD_METEO_OUTPUT_SETTINGS
});

export const loadedMeteoOutputSettings = (data) => ({
    type: types.LOADED_METEO_OUTPUT_SETTINGS,
    payload: data
});

export const loadingMeteoOutputSettings = (bool = false) => ({
    type: types.LOADING_METEO_OUTPUT_SETTINGS,
    payload: bool
});

export const editMeteoOutputSettings = (id, data, callback) => ({
    type: types.EDIT_METEO_OUTPUT_SETTINGS,
    payload: { id, data },
    callback
});

export const deleteMeteoOutputSettings = (id, data, callback) => ({
    type: types.DELETE_METEO_OUTPUT_SETTINGS,
    payload: { id, data },
    callback
});

export const loadDevicesGroup = (params) => ({
    type: types.LOAD_DEVICES_GROUP,
    payload: params
});

export const loadingDevicesGroup = (loading = false) => ({
    type: types.LOADING_DEVICES_GROUP,
    payload: loading
});

export const loadedDevicesGroup = (response) => ({
    type: types.LOADED_DEVICES_GROUP,
    payload: response
});

export const createDevicesGroup = (params, callback = () => {}) => ({
    type: types.CREATE_DEVICES_GROUP,
    payload: params,
    callback
});

export const editDevicesGroup = ({ id, params }, callback = () => {}) => ({
    type: types.EDIT_DEVICES_GROUP,
    payload: { id, params },
    callback
});

export const deleteDevicesGroup = (id, callback = () => {}) => ({
    type: types.DELETE_DEVICES_GROUP,
    payload: id,
    callback
});

export const loadingButton = (loading = false) => ({
    type: types.LOADING_BUTTON,
    payload: loading
});

export const loadShowcase = () => ({
    type: types.LOAD_SHOWCASE,
});

export const loadingShowcase = (bool = false) => ({
    type: types.LOADING_SHOWCASE,
    payload: bool
});

export const loadedShowcase = (data) => ({
    type: types.LOADED_SHOWCASE,
    payload: data
});

export const clearShowcase = () => ({
    type: types.LOADED_SHOWCASE,
    payload: {}
});

export const loadMeteoByRadius = (data, callback = () => {}) => ({
    type: types.LOAD_METEO_BY_RADIUS,
    payload: data,
    callback
});

export const loadedMeteoByRadius = (data) => ({
    type: types.LOADED_METEO_BY_RADIUS,
    payload: data
});

// Settings Group
export const loadSettingsGroup = () => ({
    type: types.LOAD_SETTINGS_GROUP,
});

export const loadingSettingsGroup = (bool) => ({
    type: types.LOADING_SETTINGS_GROUP,
    payload: bool
});

export const loadedSettingsGroup = (data) => ({
    type: types.LOADED_SETTINGS_GROUP,
    payload: data
});

export const editSettingsGroup = (id, data, callback = () => {}) => ({
    type: types.EDIT_SETTINGS_GROUP,
    payload: { id, data },
    callback
});

export const loadDeviceStatuses = () => ({
    type: types.LOAD_DEVICE_STATUSES,
});

export const loadedDeviceStatuses = (data) => ({
    type: types.LOADED_DEVICE_STATUSES,
    payload: data
});

export const loadMeteoForecast = (id) => ({
    type: types.LOAD_METEO_FORECAST,
    payload: id
});

export const loadingMeteoForecast = (bool) => ({
    type: types.LOADING_METEO_FORECAST,
    payload: bool
});

export const loadedMeteoForecast = (data) => ({
    type: types.LOADED_METEO_FORECAST,
    payload: data
});