import { useSelector } from 'react-redux';
import { LinearProgress } from '@mui/material';

import { incidentsSelectors } from 'redux/Incidents';
import { loadReportCategory } from 'redux/Incidents/actions';
import { useStoreProp } from 'helpers/hooks';
import PageLayout from 'components/layout/PageLayout';

import DtpMap from './DtpMap';
import Filters from './Filters';

const DtpReportTypes = () => {
    const loadingReportByCategory = useSelector(incidentsSelectors.loadingReportByCategory);

    const reportCategory = useStoreProp(loadReportCategory, 'incidents', 'reportCategory');

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
        }}>
            <PageLayout
                header="Отчет по категориям ДТП"
                filters={<Filters reportCategory={reportCategory} />}
                content={() => (<>
                    <div style={{ height: '100%', position: 'relative' }}>
                        {loadingReportByCategory && (
                            <LinearProgress
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    zIndex: 99
                                }}
                            />
                        )}
                        <DtpMap reportCategory={reportCategory}/>
                    </div>
                </>)
                }
                customStyles={{ padding: 0, overflowY: 'hidden' }}
            />
        </div>
    );
};

export default DtpReportTypes;
