import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select
} from '@mui/material';

import { loadBrandsList as RWBrands, loadModelsList as RWModels } from 'redux/TransportRoad/actions';
import { loadBrandsList as TPBrands, loadModelsList as TPModels }  from 'redux/TransportPassenger/actions';
import { loadBrands as TSBrands, loadModels as TSModels } from 'redux/TransportSpecial/actions';
import { useValidation } from 'helpers/hooks';
import MarkSelect from 'components/common/Autocomplete/MarkSelect';
import FormInfoWrapper from 'components/common/FormInfoWrapper';

const MarkModelComponent = ({
    moduleName = 'transportRoad',
    styleName = 'row__item',
    formData,
    setFormData,
    useModalFull,
    required = false,
    markLabel = 'Выберите марку',
    modelLabel = 'Модель ТС'
}) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const models = useSelector((store) => store[moduleName].models);

    const actionGetBrands = moduleName === 'transportPassenger'
        ? TPBrands
        : moduleName === 'transportSpecial'
            ? TSBrands
            : RWBrands;

    const actionGetModels = moduleName === 'transportPassenger'
        ? TPModels
        : moduleName === 'transportSpecial'
            ? TSModels
            : RWModels;

    useEffect(() => {
        if (formData?.brand?.id) {
            dispatch(actionGetModels('', '', { brand_id: formData.brand.id }));
        }
    }, [dispatch, formData?.brand?.id]);


    const handleChangeMark = (value) => {
        setFormData(prev => ({
            ...prev,
            brand: value,
            model_id: ''
        }));
    };

    return (<>
        <MarkSelect
            title={'name_en'}
            selected={formData.brand}
            onChange={handleChangeMark}
            required={required}
            error={validation.isKey('brand')}
            helperText={validation.get('brand')}
            className={styleName}
            moduleName={moduleName}
            action={actionGetBrands}
            label={markLabel}
        />
        <FormInfoWrapper
            error={validation.isKey('model_id')}
            helperText={validation.get('model')}
            className={styleName}
        >
            <FormControl
                disabled={!formData?.brand?.id || false}
                variant="outlined"
                size="small"
                error={validation.isKey('model_id')}
                required={required}
            >
                <InputLabel>{modelLabel}</InputLabel>
                <Select
                    name="model_id"
                    label={modelLabel}
                    required={required}
                    value={useModalFull ? formData?.model?.id || '' : formData.model_id}
                    onChange={(e) => {
                        setFormData(useModalFull
                            ? {
                                ...formData,
                                model: models?.data?.find(({ id }) => id === e.target.value)
                            }
                            : {
                                ...formData,
                                model_id: e.target.value
                            }
                        );
                    }}
                    error={validation.isKey('model_id')}
                >

                    {models?.data?.length > 0 && models.data.map(item => (
                        <MenuItem key={item.id} value={item.id}>{item.name_en}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </FormInfoWrapper>
    </>);
};

export default MarkModelComponent;