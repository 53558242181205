import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { saveStatus } from 'redux/DorisControl/actions';
import { dorisControlSelectors } from 'redux/DorisControl';
import messages from 'helpers/constants/messages';
import renderAddress from 'helpers/renderAddress';
import { ActionMore, LSContentColumn, LSContentItem } from 'components/common/List';
import CircleStatus from 'components/common/CircleStatus';

import styles from '../vfcomplex.module.scss';

import CertificateIcon from './CertificateIcon';


const Item = (props) => {
    const {
        item,
        // status,
        complexStatusesObj,
        selectedItem,
        onClickItem,
        currentComplex,
        focus,
        test_id_prefix = ''
    } = props;

    const saved = useSelector(dorisControlSelectors.saved);

    const dispatch = useDispatch();

    useEffect(() => {
        if (saved) {
            dispatch(saveStatus());
        }
    }, [saved, dispatch]);

    return (
        <>
            {currentComplex
                ? <LSContentItem
                    needFocus={focus === item?.id ? true : false}
                    onClick={onClickItem}
                    style={{
                        cursor: 'pointer',
                        backgroundColor: selectedItem ? '#0000001f' : ''
                    }}
                >
                    <LSContentColumn>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            gap: 16
                        }}>
                            <div>
                                <div>
                                    <div style={{ display: 'inline-block', marginRight: 8, marginBottom: '-0.25rem' }}>
                                        {item?.status
                                            ? <CircleStatus title={complexStatusesObj[item?.status]?.name} color={complexStatusesObj[item?.status]?.color} />
                                            : <CircleStatus title={complexStatusesObj[5]?.name} color={complexStatusesObj[5]?.color} />
                                        }
                                    </div>
                                    {item?.name || messages.INFO_IS_NOT_FOUND}
                                </div>
                                <div>
                                    <div className={styles.iconWrapper}>
                                        <CertificateIcon complex={item}/>
                                    </div>
                                    <b>Серийный №: </b><span>{item?.serial_number}</span>
                                </div>
                            </div>

                            <div className="flex-center">
                                <ActionMore test_id_prefix={test_id_prefix} isOpen={selectedItem} isPassport/>
                            </div>
                        </div>

                    </LSContentColumn>
                </LSContentItem>
                : <LSContentItem needFocus={focus === item?.id ? true : false} onClick={onClickItem} style={{ cursor: 'pointer' }}>
                    <LSContentColumn align="center">
                        <div style={{ display: 'inline-block', marginRight: 8, marginBottom: '-4px' }}>
                            {item?.status
                                ? <CircleStatus title={complexStatusesObj[item?.status]?.name} color={complexStatusesObj[item?.status]?.color} />
                                : <CircleStatus title={complexStatusesObj[5]?.name} color={complexStatusesObj[5]?.color} />
                            }
                        </div>
                    </LSContentColumn>
                    <LSContentColumn>
                        <div className="flex-center">
                            <CertificateIcon complex={item}/>
                        </div>
                    </LSContentColumn>

                    <LSContentColumn>
                        {item?.name || messages.NO_DATA}
                    </LSContentColumn>

                    <LSContentColumn>
                        {item?.serial_number || messages.NO_DATA}
                    </LSContentColumn>

                    <LSContentColumn>
                        {item?.address_text || renderAddress(item?.address, [
                            'area',
                            'city_name',
                            'district',
                            'street',
                            'house'
                        ]) || messages.NO_DATA}
                    </LSContentColumn>

                    <LSContentColumn isActions>
                        <ActionMore test_id_prefix={test_id_prefix} isOpen={selectedItem} isPassport/>
                    </LSContentColumn>
                </LSContentItem>
            }
        </>
    );
};

export default Item;
