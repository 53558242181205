import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { deleteComplexEvents } from 'redux/DorisControl/actions';
import { fullDateTimeWithTimeZone, getEndOf, getStartOf } from 'helpers/date.config';
import Modal from 'components/common/Modal';
import DateRange from 'components/common/Dates/DateRange';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

const DeleteForm = ({ isOpen, onClose, onDelete,  dateStart, dateEnd, test_id_prefix = '' }) => { // передавать сюда даты из фильтров
    const dispatch = useDispatch();

    const initialState = {
        date_start: dateStart || getStartOf('day'),
        date_end: dateEnd || getEndOf('day'),
    };
    const [params, setParams] = useState(initialState);

    const handleChangeDate = (period, value) => setParams({ ...params, [period]: value });

    const onAcceptModal = () => {
        const newParams = {
            date_start: fullDateTimeWithTimeZone(params.date_start),
            date_end: fullDateTimeWithTimeZone(params.date_end)
        };
        dispatch(deleteComplexEvents(newParams, onDelete));
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title="Удалить данные за"
            buttons={<FormButtonsComponent
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: onClose,
                        testName: 'cancel'
                    },
                    {
                        ...buttonsTypes.delete,
                        onClick: onAcceptModal,
                        testName: 'delete'
                    },
                ]}
                test_id_prefix={test_id_prefix}
            />
            }
        >
            <div className="row">
                <DateRange
                    valueStartDate={params.date_start}
                    valueEndDate={params.date_end}
                    handleDate={(date) => handleChangeDate('date_start', date)}
                    handleEndDate={(date) => handleChangeDate('date_end', date)}
                    className="row__item"
                    test_id_prefix={test_id_prefix}
                />
            </div>
        </Modal>
    );
};

export default DeleteForm;

