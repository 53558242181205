import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { loadTagList } from 'redux/DorisControl/actions';
import titles from 'helpers/constants/titles';
import removeEmptyFields from 'helpers/removeEmptyFields';
import { useValidation } from 'helpers/hooks';
import UniversalSelect from 'components/common/UniversalSelect';
import FormButtons from 'components/common/FormButtons';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';

import AddNewTag from './AddNewTag';

const SelectTags = ({
    multiple = false,
    selected = multiple ? [] : '',
    onChange = () => {},
    required = false,
    disabled = false,
    label = 'Теги',
    test_id_prefix = ''
}) => {
    const dispatch = useDispatch();
    const validation = useValidation();
    const [showList, setShowList] = useState(false);
    const [isOpenAddTag, setIsOpenAddTag] = useState(false);

    const getTags = (params) => {
        const { page, limit, query: name } = params;
        const newParams = removeEmptyFields({ page, limit, name });
        dispatch(loadTagList(newParams));
    };

    const handleAccept = (data) => {
        onChange(data);
        setShowList(false);
    };

    return (
        <>
            <CommonAutocomplete
                multiple={multiple}
                selected={selected}
                onChange={onChange}
                error={validation.isKey('tags')}
                helperText={validation.get('tags')}
                required={required}
                inputName="tags"
                disabled={disabled}
                onReset={() => onChange(multiple ? [] : {})}
                renderLabel={(option) => option?.name || ''}
                limitTags={2}
                label={label}
                onShowList={() => setShowList(true)}
                filterSelectedOptions={true}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                isOpen={showList}
                test_id_prefix={test_id_prefix}
            />
           
            {showList
                && <UniversalSelect
                    multiple={multiple}
                    fetchList={getTags}
                    storeName="dorisControl"
                    storeNameProps="tagList"
                    keyProp="id"
                    withSearch
                    isSelected
                    selected={selected}
                    renderProps={(el) => <div>{el.name}</div>}
                    isOpen={showList}
                    onClose={() => setShowList(false)}
                    onAccept={handleAccept}
                    noPadding
                    title={titles.SELECT_TAGS}
                    buttons={
                        <div style={{ paddingLeft: '1rem' }}>
                            <FormButtons
                                noPadding
                                noMarginLeft
                                positionLeft
                                justButton
                                buttons={[
                                    {
                                        onClick: () => setIsOpenAddTag(true),
                                        name: 'Добавить новый Тег'
                                    }
                                ]}
                            />
                        </div>
                    }
                    test_id_prefix={test_id_prefix}
                />
            }
            {isOpenAddTag && <AddNewTag isOpen={isOpenAddTag} onClose={() => setIsOpenAddTag(false)} />}
        </>
    );
};

export default SelectTags;