import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from './api.methods';
import * as types from './types';
import * as actions from './actions';


function* getStationListSaga({ payload }) {
    yield put(actions.loading(true));
    const response = yield call(api.loadStationList, payload);
    if (response?.success) {
        yield put(actions.loadedStationList(response));
    }
    yield put(actions.loading(false));
}

function* getStationStatusesSaga({ payload }) {
    yield put(actions.loading(true));
    const response = yield call(api.loadStationStatuses, payload);
    if (response?.success) {
        yield put(actions.loadedStationStatuses(response));
    }
    yield put(actions.loading(false));
}

function* getStationPolygonSaga({ payload }) {
    yield put(actions.loading(true));
    const response = yield call(api.loadStationPolygon, payload);
    if (response?.success) {
        yield put(actions.loadedStationPolygon(response.data));
    }
    yield put(actions.loading(false));
}

function* getStationSchedulesSaga({ payload, callback }) {
    yield put(actions.loadingStationSchedules(true));
    const response = yield call(api.loadStationSchedules, payload);
    if (response?.success) {
        callback(response.data);
    }
    yield put(actions.loadingStationSchedules(false));
}

function* getTransportTypesSaga({ payload }) {
    yield put(actions.loading(true));
    const response = yield call(api.loadTransportTypes, payload);
    if (response?.success) {
        yield put(actions.loadedTransportTypes(response.data));
    }
    yield put(actions.loading(false));
}


function* loadStationSidebarSaga({ payload }) {
    const { page } = payload;
    yield put(actions.loadingStationSidebar(true));
    const response = yield call(api.loadStationSidebar, payload);
    if (response?.success) {
        yield put(actions.loadedStationSidebar(response, page === 1));
    }
    yield put(actions.loadingStationSidebar(false));
}

export default function* saga() {
    yield all([
        takeLatest(types.LOAD_STATION_LIST, getStationListSaga),
        takeLatest(types.LOAD_STATION_STATUSES, getStationStatusesSaga),
        takeLatest(types.LOAD_STATION_POLIGON, getStationPolygonSaga),
        takeLatest(types.LOAD_STATION_SCHEDULES, getStationSchedulesSaga),
        takeLatest(types.LOAD_TRANSPORT_TYPES, getTransportTypesSaga),
        takeLatest(types.LOAD_STATION_SIDEBAR, loadStationSidebarSaga),
    ]);
}
