import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';

import titles from 'helpers/constants/titles';
import { useStoreFromSelector } from 'helpers/hooks';
import { metroSelectors } from 'modules/Metro';
import { loadVestibuleStatuses } from 'modules/Metro/redux/actions';

const Filter = ({ data, onChange }) => {
    // const statuses = useStoreFromSelector(loadVestibuleStatuses, metroSelectors.vestibuleStatuses);

    return <form
        className="modal__form"
        onSubmit={(e) => {
            e.preventDefault();
        }}
    >
        <div className="block">
            <TextField
                value={data.name || ''}
                label={titles.NAME}
                variant="outlined"
                size="small"
                name="name"
                type="text"
                onChange={(e) => onChange({ [e.target.name]: e.target.value })}
            />
        </div>
        {/* <div className="block">
            <FormControl size="small" variant="outlined" className="block">
                <InputLabel>{titles.STATUS}</InputLabel>
                <Select
                    value={data?.status_list || ''}
                    onChange={(e) => onChange({ [e.target.name]: [e.target.value] })}
                    label={titles.STATUS}
                    name="status_list"
                >
                    {statuses.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                            {item.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>                   */}
    </form>;
};

export default Filter;
