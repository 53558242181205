import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import { Grid } from '@mui/material';

import { loadOsrmCar } from 'redux/Osrm/actions';
import { useDebounce } from 'helpers/hooks';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';

import MapPassenger from './Passenger/Map';
import MapRoad from './Road/Map';
import CheckPointList from './CheckPointList';
import { options, serviceList } from './helper';

const MapModalForm = ({
    onClose,
    isOpen,
    road: currentRoad = {},
    points: currentPoints = [],
    onChange,
    service,
}) => {
    const dispatch = useDispatch();

    const convertPoints = () => {
        // для редактирования кт
        return currentPoints?.map(item => {
            if (item.station) return item;

            const check_point = {
                ...item.check_point
            };

            // delete check_point.id;

            const newData = {
                ...item,
                check_point,
            };

            delete newData.check_point_id;

            return newData;
        });
    };

    const [points, setPoints] = useState(convertPoints());
    const [road, setRoad] = useState(currentRoad);

    const fetchRoute = (coords) => {
        dispatch(loadOsrmCar(coords, (data) => {
            const geometry = data?.routes?.[0]?.geometry;
            geometry && setRoad({ type: 'Feature', geometry });
        } ));
    };
    const debounceFetchRoute = useDebounce(fetchRoute, 800);

    const changePoints = (newPoints) => {
        setPoints(newPoints);

        const coords = newPoints.reduce((res, item) => {
            const { lat = 0, lon = 0 } = item.check_point || item.station?.check_point || {};
            if (lat && lon) {
                res.push([lon, lat]);
            }
            return res;
        }, []);
        if (coords.length > 1) {
            debounceFetchRoute(coords);
        } else {
            setRoad({});
        }
    };

    // TODO проверить айдишники для остановок
    const onSave = () => {
        const result = points.map((item) => ({
            ...item,

            ...(service === serviceList.transportPassenger
                ? {
                    mileage: +(item.mileage || 0),
                    interval: item.interval || '00:00'
                }
                : {}
            )
        }));

        onChange?.(
            road,
            result
        );

        onClose?.();
    };

    const renderMap = useMemo(() => {
        switch (service) {

            // пассажирка
            case serviceList.transportPassenger: return (
                <MapPassenger
                    // остановки и кт
                    points={points}
                    setPoints={changePoints}
                    // маршрут
                    road={road}
                    setRoad={setRoad}
                />
            );

            // дорожка
            case serviceList.transportRoad: return (
                <MapRoad
                    // остановки и кт
                    points={points}
                    setPoints={changePoints}
                    // маршрут
                    road={road}
                    setRoad={setRoad}
                />
            );

            default: return null;
        }

    }, [service, points, road]);

    return (
        <Modal
            title="Изменить маршрут"
            // title={
            //     service === serviceList.transportPassenger
            //         ? 'Добавить остановку и контрольную точку'
            //         : 'Добавить контрольную точку'
            // }
            onClose={onClose}
            fullWidth
            noPadding
            isOpen={isOpen}
            disabledDragDrop
            buttons={
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.close,
                            onClick: onClose
                        },
                        {
                            ...buttonsTypes.save,
                            onClick: onSave,
                            disabled: points?.length === 0 || Object.keys(road || {}).length === 0
                        }
                    ]}
                />
            }
        >
            <Grid container direction="row" style={{ height: '100%' }}>
                <Grid item xs={8}>
                    {renderMap}
                </Grid>
                <Grid item xs={4} style={{ display: 'flex', flexDirection: 'column' }}>
                    {service === 'transportPassenger' && (
                        <h2>Для добавления контрольной точки, кликните по карте в нужном месте</h2>
                    )}
                    {points.length > 0 && (
                        <Scrollbars>
                            <CheckPointList
                                setItems={changePoints}
                                items={points}
                                service={service}
                                canEdit={service === serviceList.transportPassenger}
                                displayType={options.modalForm}
                            />
                        </Scrollbars>
                    )}
                </Grid>
            </Grid>
        </Modal>
    );
};

export default MapModalForm;
