import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadVestibuleTraffic } from 'modules/Metro/redux/actions';
import { metroSelectors } from 'modules/Metro';
import Loading from 'components/common/Loading';

import icon from '../icons/icon';

import Information from './Information';

const PopUp = ({ data }) => {
    const dispatch = useDispatch();

    const loading = useSelector(metroSelectors.loadingVestibuleTraffic);

    const [vestibuleData, setVestibuleData] = useState({});

    useEffect(() => {
        dispatch(loadVestibuleTraffic(data.id, setVestibuleData));
    }, [dispatch, data.id]);

    return (
        <div className="map-popup">
            <div className="map-popup_header" style={{ backgroundColor: data.color_load }}>
                <div className="map-popup_header_">
                    <div
                        className="img"
                        dangerouslySetInnerHTML={{ __html: icon({ fill: '#fff' }) }}
                    />
                    <span>
                        {data.name}
                    </span>
                </div>
            </div>
            <div className="map-popup_body">
                {loading
                    ? <Loading linear />
                    : <Information
                        vestibuleData={vestibuleData}
                    />
                }
            </div>
        </div>
    );
};

export default PopUp;
