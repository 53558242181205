import type { ReactElement, } from 'react';

import { usePermissions } from './usePermissions';

interface Button {
    icon?: ReactElement;
    name: string;
    size?: string;
    accessProp?: string;
    disabled?: boolean;
}

// хук - возвращаем список только доступных кнопок согласно permissions
// параметры - массив кнопок, идентификатор страницы если есть точечные права для страниц из шаблона useAccessTemplate
// третий параметр - показывать ли в списке кнопки disabled - по умолчанию выкидываем из списка
export const useFilterButtonsByPermissions = (buttons: Array<Button> = [], uri?: string, showDisabled = false, ) => {
    const access = usePermissions(uri).actions;

    return buttons.reduce((res: Array<Button>, button) => {
        // какой именно пропс надо проверить - is_create, is_delete и тп - добавлен в основные типы в buttonTypes
        const { accessProp, disabled } = button;

        if ((!accessProp || access[accessProp]) && (!disabled || showDisabled)) {
            res.push(button);
        }
        return res;
    }, []);
};
