import { useEffect, useState } from 'react';

const useMediaFiles = (event = {}) => {
    const [allMedia, setAllMedia] = useState([]);

    useEffect(() => {
        const {
            photo_list = [],
            video_extra = [],
            video_list = [],
            photo_grz = '',
            photo_ts = '',
        } = event;

        const createMediaList = (list, type) =>
            list
                ? list.map((el) => {
                    return{ src: el, type, };
                })
                : [];


        const photos = photo_list?.length > 0 ? photo_list : (photo_ts || photo_grz) ? [photo_ts, photo_grz,] : [];

        const allMedia = [...createMediaList(photos, 'image'), ...createMediaList(video_extra, 'video'), ...createMediaList(video_list, 'video')];

        setAllMedia(allMedia);
    }, [event]);

    return allMedia;
};

export default useMediaFiles;
