import { useEffect, useState } from 'react';
import {
    IconButton,
    InputAdornment,
    LinearProgress,
    TextField,
    Tooltip,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';

import buttons from 'helpers/constants/buttons';
import UniversalSelect from 'components/common/UniversalSelect';

import useUsmFirstLoad from './useUsmFirstLoad';

// текстовое поле с универсальным селектом
const USM = (props) => {
    const {
        label = '',
        value,
        onChange,
        error = false,
        helperText = '',
        hideOnSelect = false,
        options = {},
        test_id_prefix = '', // для авто тестирования элементов на разных страницах
    } = props;

    const loaderHook = useUsmFirstLoad(
        options.fetchList,
        options,
        (data) => {
            setCurrentValue(data.name);
        }
    );

    const [isOpen, setIsOpen] = useState(false);
    const [currentValue, setCurrentValue] = useState('');

    useEffect(() => {
        if (value && !currentValue) {
            loaderHook.load([ value ]);
        }
    }, [value]);

    const handleChange = (value) => {
        setCurrentValue(value.name);
        onChange(options?.keyProp ? value[options.keyProp] : value.name);
        setIsOpen(false);
    };

    return (
        <>
            <TextField
                // className="row__item"
                value={currentValue}
                variant="outlined"
                size="small"
                label={label}
                // disabled={true}
                error={error}
                helperText={helperText}
                InputProps={{
                    endAdornment: (
                        <>
                            {!!value && (
                                <InputAdornment position="end">
                                    <Tooltip title={buttons.RESET}>
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => {
                                                onChange(null);
                                                setCurrentValue('');
                                            }}
                                            edge="end"
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    </Tooltip>
                                </InputAdornment>
                            )}
                            <InputAdornment position="end">
                                <Tooltip title={buttons.FIND}>
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setIsOpen(true)}
                                        edge="end"
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                </Tooltip>
                            </InputAdornment>
                        </>
                    ),
                    'data-testid': `${test_id_prefix}/input`
                }}
            />
            {loaderHook.loading && (
                <LinearProgress/>
            )}

            {isOpen
                && <UniversalSelect
                    {...options}
                    // storeName={'transportPassenger'}
                    // storeNameProps={'employees'}
                    // keyProp={'id'}
                    // fetchList={getEmployees}
                    withSearch={true}
                    isSelected={true}
                    onAccept={handleChange}
                    onChange={handleChange}
                    selected={currentValue || {}}
                    isOpen={isOpen}
                    onClose={() => setIsOpen(false)}
                    noPadding={true}
                    title={label}
                    small={true}
                    test_id_prefix={test_id_prefix}
                />
            }
        </>
    );
};

export default USM;
