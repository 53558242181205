import makeStyles from '@mui/styles/makeStyles';
import { Paper, Popper } from '@mui/material';

import {
    loadVehicleCategories, loadVehiclesTypes
} from 'redux/TransportPassenger/actions';
import { VehicleInfo } from 'components/common/Transport/VehicleInfo';
import { useStoreProp } from 'helpers/hooks';

const useStyles = makeStyles({
    paper: {
        padding: '10px 15px',
        background: 'rgba(220, 220, 220, 1.000)'
    }
});

const VehiclePopup = ({ info, id, openWindow, anchorEl }) => {
    const classes = useStyles();
    const vehiclesTypes = useStoreProp(loadVehiclesTypes, 'transportPassenger', 'vehiclesTypes');

    return (
        <Popper
            id={id}
            open={openWindow}
            transition
            anchorEl={anchorEl}
            placement="bottom-start"
            disablePortal={false}
            modifiers={{
                flip: {
                    enabled: true,
                },
                preventOverflow: {
                    enabled: true,
                    boundariesElement: 'scrollParent',
                }
            }}
        >
            <Paper className={classes.paper}>
                <VehicleInfo
                    info={info}
                    storeName="transportPassenger"
                    loadVehicleCategories={loadVehicleCategories}
                    vehiclesTypes={vehiclesTypes}
                />
            </Paper>
        </Popper>
    );
};

export default VehiclePopup;
