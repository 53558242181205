import React, { useState } from 'react';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';

import Loading from '../../common/Loading';

const LayerItem = (props) => {
    const {
        children,
        loading = false,
        icon = null,
        buttons = {},
        sideComponent = null,
        onClick = () => {},
        active = false
    } = props;
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    };

    const handleMenuClose = (e) => {
        setAnchorEl(null);
    };

    // сгенерировать иконку
    const renderIcon = () => (
        <div className="icon">
            {icon
                ? typeof icon === 'string'
                    ? <div dangerouslySetInnerHTML={{ __html: icon }} />
                    : icon
                : null
            }
        </div>
    );

    // вывести доп компонент (например круглый статус)
    const renderSideComponent = () => (
        <div>
            {sideComponent}
        </div>
    );

    const isIcon = () => icon || false;
    const isButtons = () => Object.keys(buttons).length > 0;

    // сгенерировать кнопки
    const renderButtons = () => (
        <>
            <IconButton
                size="small"
                onClick={handleMenuClick}
                title="Меню"
            >
                <i className="fas fa-bars"/>
            </IconButton>

            <Menu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                {Object.keys(buttons).map((buttonKey) => {
                    const { title = '', icon, onClick } = buttons[buttonKey];

                    if (icon) {
                        return (
                            <MenuItem
                                key={buttonKey}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setAnchorEl(null);
                                    onClick();
                                }}
                            >
                                <ListItemIcon style={{ minWidth: '30px' }}>
                                    {icon}
                                </ListItemIcon>

                                <ListItemText primary={title} />
                            </MenuItem>
                        );
                    }
                    return null;
                })}
            </Menu>
        </>
    );

    return (
        <div className={`layers-sidebar__layer__item${active ? ' active' : ''}`} onClick={onClick}>

            {isIcon() || isButtons() || sideComponent ? (
                <div className="layers-sidebar__layer__item__icons">
                    {/* иконка */}
                    {isIcon() ? renderIcon() : null}

                    {/* доп компонент */}
                    {renderSideComponent()}

                    {/* кнопки */}
                    {isButtons() ? renderButtons() : null}
                </div>
            ) : null}

            <div className="layers-sidebar__layer__item__content" style={{ width: '100%' }}>
                {children}
            </div>

            {loading && (<Loading className="absolute bottom fill" />)}

        </div>
    );
};

export default LayerItem;
