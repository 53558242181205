import CollectionServices from '../Dictionaries/CollectionServices';

const dictionariesRoutes = [
    {
        path: '/data-bus/dictionaries/collection-services',
        component: CollectionServices,
        exact: true,
    },
];

export default dictionariesRoutes;