import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List } from '@mui/material';

import { dorisControlSelectors } from 'redux/DorisControl';
import { loadComplexEntities, loadComplexEntityTypes } from 'redux/DorisControl/actions';
import messages from 'helpers/constants/messages';
import Context from 'helpers/context';
import { useStoreProp } from 'helpers/hooks';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import PageLayout from 'components/layout/PageLayout';

import EntityForm from './EntityForm';
import Filter from './Filter';
import RenderComputerItem from './RenderComputerItem';

const Entities = ({ complex_id, readOnly = false, onChange, currentComplex, tab = null, test_id_prefix_passport = '' }) => {
    const dispatch = useDispatch();
    const { permissions } = useContext(Context);

    const entities_types = useStoreProp(loadComplexEntityTypes, 'dorisControl', 'entities_types');
    const loading_entities = useSelector(dorisControlSelectors.loading_entities);
    const entities = useSelector(dorisControlSelectors.entities);

    const [openEntityModal, setOpenEntityModal] = useState(false);


    const loadList = useCallback(() => {
        dispatch(loadComplexEntities({ complex_id }));
    }, [dispatch, complex_id]);

    useEffect(() => {
        loadList();
    },[loadList]);

    const handleAdded = () => {
        loadList();
        onChange && onChange();
    };

    const handleEdited = () => {
        loadList();
        onChange && onChange();
    };

    const handleDeleted = () => {
        loadList();
        onChange && onChange();
    };

    // добавляем в объект entity_id для каждого эл-та
    const dataList = Object.entries(entities).reduce((res, [entity_id, el]) => {
        res[entity_id] = el?.map(elem => ({ ...elem, entity_id }));
        return res;
    }, {});

    const resultArr = (entities[3] || [])?.map(elem => {
        const recognition = (dataList[4] || []).filter((el) => el.computer_id === elem.id);
        const observation = (dataList[5] || []).filter((el) => el.computer_id === elem.id);
        return {
            ...elem,
            entity_id: 3,
            entities: [...recognition, ...observation]
        };
    });

    const currentData = useMemo(() => [
        ...resultArr,
        ...(dataList[1] || []),
        ...(dataList[2] || []),
        ...(dataList[6] || [])
    ], [dataList, resultArr]);

    const renderContent = () => {
        return currentData.length > 0
            ? <>
                <List className="list">
                    {currentData?.map((item) => (
                        <RenderComputerItem
                            key={`${item.id}${item.entity_id}`}
                            item={item}
                            types={entities_types}
                            readOnly={readOnly}
                            currentComplex={currentComplex}
                            onEdited={handleEdited}
                            onDeleted={handleDeleted}
                            test_id_prefix_passport={test_id_prefix_passport}
                        />
                    ))}
                </List>
            </>
            : !loading_entities && messages.DATA_IS_NOT_FOUND;
    };

    return (
        <div style={{ padding: '1rem' }}>
            <PageLayout
                filters={<Filter
                    action={loadComplexEntities}
                    complex_id={complex_id}
                    tab={tab}
                    test_id_prefix_passport={test_id_prefix_passport}
                />}
                loading={loading_entities}
                informPanelProps={{
                    buttons: (
                        <>
                            {(!readOnly && permissions?.is_create)
                                && <FormButtons
                                    noPadding
                                    noMarginLeft
                                    buttons={[
                                        {
                                            ...buttonsTypes.add,
                                            onClick: () => setOpenEntityModal(true),
                                            testName: 'add'
                                        },
                                    ]}
                                    test_id_prefix={test_id_prefix_passport}
                                />
                            }
                        </>),
                }}
                content={renderContent}
            />

            {openEntityModal
                && <EntityForm
                    isOpen={openEntityModal}
                    onClose={() => setOpenEntityModal(false)}
                    isNew
                    types={entities_types}
                    currentComplex={currentComplex}
                    complex_id={complex_id}
                    // loadList={loadList}
                    onAdded={handleAdded}
                    withComputer={entities[3]?.length > 0}
                    test_id_prefix_passport={test_id_prefix_passport}
                />
            }

        </div>
    );
};

export default Entities;
