import instance from 'helpers/axios.config';

import apiAUrls from './api.urls';

const ApiExternalTransportMethods = {
    loadStationStatuses: async () => {
        const response = await instance.get(apiAUrls.loadStationStatuses());
        return response.data;
    },
    loadStationList: async (data) => {
        const response = await instance.get(apiAUrls.loadStationList(), { params: data });
        return response.data;
    },
    loadStationSidebar: async (data) => {
        const response = await instance.get(apiAUrls.loadStationList(),  { params: data });
        return response.data;
    },
    loadStationPolygon: async (params) => {
        const response = await instance.post(apiAUrls.loadStationPolygon(), { ...params });
        return response.data;
    },
    loadStationSchedules: async (id) => {
        const response = await instance.get(apiAUrls.loadStationSchedules(id));
        return response.data;
    },
    loadTransportTypes: async () => {
        const response = await instance.get(apiAUrls.loadTransportTypes());
        return response.data;
    },
};

export default ApiExternalTransportMethods;
