import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { boardsSelectors } from 'redux/Boards';
import BoardsList from 'components/common/Boards/BoardLinkList';
import BoardListModal from 'components/common/Boards/BoardsListModal';
import useLoadBoardsList from 'helpers/hooks/Boards/useLoadBoardList';
import Context from 'helpers/context';

const Boards = ({ readOnly = false, name, complex_id }) => {

    const { permissions } = useContext(Context);

    const [isOpenBoardListModal, setIsOpenBoardListModal] = useState(false);

    const loadingDitLink = useSelector(boardsSelectors.loadingDitLink);
    const loadingBoardsFromLink = useSelector(boardsSelectors.loadingBoardsFromLink);
    const isDisabled = loadingDitLink || loadingBoardsFromLink;
    const [isNeedUpdate, setIsNeedUpdate] = useState(false);
    const dit_list = useLoadBoardsList(complex_id, 'App\\Models\\Complex', 'dorisControl', isNeedUpdate);

    // если список линков изменился надо сбросить необходимость обновлять его
    useEffect(() => {
        setIsNeedUpdate(false);
    }, [dit_list]);

    return (
        <div className="tab__container">
            <h2>{name} Связанные ДИТ:</h2>
            <BoardsList
                dit_list={dit_list}
                loading={isDisabled}
                onClick={() => setIsOpenBoardListModal(true)}
                needButton={!readOnly && permissions?.is_update}
            />

            {isOpenBoardListModal
                && <BoardListModal 
                    name={name}
                    microservice="dorisControl"
                    id={complex_id}
                    entity_name="App\Models\Complex"
                    dit_list={dit_list}
                    isOpen={isOpenBoardListModal}
                    onClose={() => {
                        setIsOpenBoardListModal(false);
                        setIsNeedUpdate(true); // нужно перечитать список дитов
                    }}
                />
            }
        </div>
    );
};

export default Boards;