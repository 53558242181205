import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import {
    getReportTransportIncidents,
    loadReportByEventPlans,
    loadTransportIncidentById,
    loadTransportIncidents,
    loadTransportIncidentStatuses,
    setTIFilterParams,
    setTIModalData,
} from 'redux/SituationalPlans/actions';
import { uploadTransportIncidentFile } from 'redux/Upload/actions';
import { SituationalPlansSelectors } from 'redux/SituationalPlans';
import FileUploader from 'components/common/Upload/FileUploader';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import ReturnToButton from 'components/common/Buttons/ReturnToButton';
import PageLayout from 'components/layout/PageLayout';
import ButtonWithPopover from 'components/common/Buttons/ButtonWithPopover';
import ReportBlock from 'components/common/Report/ReportBlock';
import { PageReportBlock, PageReportModal } from 'components/common/PageReport';
import { useStoreProp, useValidation } from 'helpers/hooks';
import { fullDateTimeWithTimeZone, getEndOf, getStartOf } from 'helpers/date.config';
import removeEmptyFields from 'helpers/removeEmptyFields';

import Filter from './Filter';
import ModalForm from './ModalForm';
import UploadModal from './UploadModal';
import RenderContent from './RenderContent';
import GetReportFields from './Filter/GetReportFields';

const TransportIncidents = ({ isEventPlans }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const validation = useValidation();
    const location = useLocation();

    const transportIncidents = useSelector(SituationalPlansSelectors.transportIncidents);
    const loadingTransportIncidents = useSelector(SituationalPlansSelectors.loadingTransportIncidents);

    const reportByEventPlans = useSelector(SituationalPlansSelectors.reportByEventPlans);
    const loadingReportByEventPlans = useSelector(SituationalPlansSelectors.loadingReportByEventPlans);

    const list = isEventPlans ? reportByEventPlans : transportIncidents;
    const loading = isEventPlans ? loadingReportByEventPlans : loadingTransportIncidents;

    // const { data: foundTI = null } = useSelector(SituationalPlansSelectors.transportIncident);
    const tiModalData = useSelector(SituationalPlansSelectors.tiModalData);
    const tiFilterParams = useSelector(SituationalPlansSelectors.tiFilterParams);
    const statuses = useStoreProp(loadTransportIncidentStatuses, 'SituationalPlans', 'transportIncidentStatuses');

    const { uploadFileError, uploading, uploadedTransportIncidentFile  } = useSelector(({ upload }) => upload);
    const { id } = queryString.parse(location.search);

    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const initialState = {
        name: '',
        threat_level_id: '',
        incidentType: isEventPlans ? { id: 6 } : null,
        type_id: isEventPlans ? 6 : '',
        scenario: null,
        response_scenario_id: '',
        type_creation: '',
        status_id_list: [],
        address_text: '',
        start_date: getStartOf('year'),
        end_date: getEndOf(),
    };

    const [params, setParams] = useState({
        page: 1,
        data: initialState
    });

    const [openModal, setOpenModal] = useState(false);
    const [uploadModalOpen, setUploadModalOpen] = useState(false);
    const [currentTI, setCurrentTI] = useState(null);

    const handleResetFilter = (needReload) => {
        if (needReload || id) {
            setParams({ page: 1, data: initialState });
        }
        if (id) {
            // удаляем из урла и из initialState complex_id
            history.replace(location.pathname);
        }
    };

    const prepareData = (filter) => removeEmptyFields({
        ...filter,
        status_id_list: filter.status_id_list?.map((el) => el.id),
        start_date: fullDateTimeWithTimeZone(filter.start_date),
        end_date: fullDateTimeWithTimeZone(filter.end_date),
        response_scenario_id: filter.scenario?.id,
        type_id: filter.incidentType?.id,
        threat_level_id: filter.threat_level_id?.id,
        type_creation: filter?.type_creation && Number(filter.type_creation)
    });

    const loadList = useCallback((filter = params, lmt = limit) => {
        const preparedFilter = prepareData(filter.data);
        delete preparedFilter.scenario;
        delete preparedFilter.incidentType;
        const loadAction = isEventPlans ? loadReportByEventPlans : loadTransportIncidents;
        dispatch(loadAction(filter.page, lmt, preparedFilter));
    }, [params, limit, isEventPlans, dispatch]);

    const closeUploadModal = () => {
        validation.clear();
        setUploadModalOpen(false);
    };

    useEffect(() => {
        // если в загреженном файле есть ошибки, открываем модалку с описанием проблемы
        if (uploadFileError || (Object.keys(validation?.data).length && uploadedTransportIncidentFile?.file))
            setUploadModalOpen(true);
    }, [uploadFileError, validation.data, uploadedTransportIncidentFile]);

    useEffect(() => {
        // если в урле есть id загружаем этот конкретный ТИ
        if (id) {
            dispatch(loadTransportIncidentById(id, (transportIncident) => {
                setCurrentTI(transportIncident);
                setParams((prev) => ({
                    page: 1,
                    data: {
                        ...prev.data,
                        name: transportIncident?.name,
                        // threat_level_id: transportIncident.threat_level_id,
                        // type_id:transportIncident.type_id,
                        // scenario: transportIncident.response_scenario,
                        // response_scenario_id: transportIncident.response_scenario_id,
                        // type_creation: transportIncident.type_creation,
                        // status_id_list: [transportIncident.status_id],
                        // address_text:transportIncident.address_text,
                    }
                }));
            }));
        }
    }, [dispatch, id]);

    useEffect(() => {
        loadList();
    }, [loadList]);

    const handleDownload = (formats, selectedType) => {
        const preparedFilter = prepareData(params.data);
        dispatch(getReportTransportIncidents({ report: selectedType, formats, ...preparedFilter }));
    };

    const dataList = id ? (currentTI ? [currentTI] : []) :( list?.data?.length > 0 ? list?.data : []);

    return (
        <>
            <PageLayout
                additionalButtons={id && <ReturnToButton />}
                loading={loading || uploading}
                header={isEventPlans ? 'Отчет по планам мероприятий' : 'События'}
                filters={<Filter
                    isEventPlans={isEventPlans}
                    params={params.data}
                    setParams={(newValue) => setParams((prev) => ({
                        ...prev,
                        data: {
                            ...prev.data,
                            ...newValue,
                        }
                    }))}
                    onReset={handleResetFilter}
                    onSearch={loadList}
                    statuses={statuses}
                    initialState={initialState}
                    id={id}
                />
                }
                informPanelProps={{
                    buttons: isEventPlans
                        ? <PageReportModal>
                            <PageReportBlock
                                filter={prepareData(params.data)}
                                isOneTypeAuto
                            />
                        </PageReportModal>
                        : <>
                            <FormButtons
                                buttons={[
                                    {
                                        ...buttonsTypes.add,
                                        onClick: () => setOpenModal(true),
                                    }
                                ]}
                                positionLeft
                                noPadding
                            />
                            <ReportBlock
                                customComponents={(onclose) => <GetReportFields
                                    reports={list?.meta?.additional_parameters?.reports}
                                    handleDownload={handleDownload}
                                />
                                }
                            />
                            <ButtonWithPopover
                                buttonType="upload"
                                actions={(callback) => <FileUploader
                                    isButton
                                    action={uploadTransportIncidentFile}
                                    callback={callback}
                                    title="Файл ЧС (.xlsx)"
                                    buttonType="defaultTextInfo"
                                />}
                            />
                        </>,
                    total: list?.meta?.total
                }}
                content={() => <RenderContent
                    loading={loading || uploading}
                    data={dataList}
                    reloadList={loadList}
                />}
                paginationProps={{
                    loadList: (page) => {
                        setParams({ ...params, page });
                        dispatch(setTIFilterParams({ ...tiFilterParams, params: { ...params, page } }));
                    },
                    list: list?.meta,
                    limit,
                    setLimit
                }}
            />
            {openModal && (
                <ModalForm
                    isOpen={openModal}
                    onClose={() => setOpenModal(false)}
                    isNew={true}
                    reloadList={() => {
                        setParams({ page: 1, data: {} });
                        dispatch(setTIFilterParams({}));
                    }}
                />
            )}
            {uploadModalOpen && (
                <UploadModal
                    onClose={closeUploadModal}
                    isOpen={uploadModalOpen}
                    data={uploadFileError}
                    validationData={validation.data}
                    reloadList={() => {
                        setParams({ page: 1, data: {} });
                        // dispatch(setTIFilterParams({}));
                    }}
                />
            )}
            {!!tiModalData && (
                <ModalForm
                    isOpen={!!tiModalData}
                    onClose={() => dispatch(setTIModalData(null))}
                    item={tiModalData}
                    reloadList={() => {
                        setParams({ page: 1, data: {} });
                        dispatch(setTIFilterParams({}));
                    }}
                />
            )}
        </>
    );
};

export default TransportIncidents;
