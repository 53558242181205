
import iconCluster from 'modules/ExternalTransport/utils/icons/iconMap';
import { icon, moduleName } from 'modules/ExternalTransport/module-config';

const config = {
    name: 'Внешний транспорт',
    slug: moduleName,
    clusterIcon: iconCluster({ fill:'#e7e7e7', innerFill: 'rgba(0, 0, 0, 0.54)' }),
    layerIcon: icon,
};

export default config;
