import { useDispatch } from 'react-redux';

import { setShowReturnTo } from 'redux/Menu/actions';
import notificationsConfig from 'helpers/notifications.config';
import { getFullTime, getHumanDate } from 'helpers/date.config';
import { useWsAlerts } from 'helpers/ws/hooks';
import messages from 'helpers/constants/messages';
import { LSContentColumn, LSContentItem } from 'components/common/List';
import CircleStatus from 'components/common/CircleStatus';

import type { ItemTypes } from './types';

interface ItemProps {
    item: ItemTypes;
}

const Item = ({ item }: ItemProps) => {
    const dispatch = useDispatch();
    const notificationAlert = useWsAlerts();

    const handleOpenPassport = () => {
        dispatch(
            setShowReturnTo(
                '/reports/doris-control/operator-log',
                'Вернуться в журнал оператора'
            )
        );
        notificationAlert
            .onClick(
                item?.id,
                item?.link,
                item?.viewed
            );
    };

    const itemType = notificationsConfig(item?.showAlert?.type);

    return (
        <LSContentItem {...(item?.link && { onClick: handleOpenPassport })}>
            <LSContentColumn>
                <CircleStatus title={itemType?.tooltipText} color={itemType?.color}/>
            </LSContentColumn>
            <LSContentColumn>
                {getHumanDate(item?.createdAt || messages.NO_VALUE)}
            </LSContentColumn>
            <LSContentColumn>
                {getFullTime(item?.createdAt || messages.NO_VALUE)}
            </LSContentColumn>
            <LSContentColumn>
                {item?.showAlert?.message || messages.NO_VALUE}
            </LSContentColumn>
        </LSContentItem>
    );
};

export default Item;
