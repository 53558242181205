import cn from 'classnames';
import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import titles from 'helpers/constants/titles';

const useStyles = makeStyles({
    select: {
        flex: '1 0',
        minWidth: '160px',
        // '&:not(:last-child)': {
        //     marginRight: 5
        // }
    },
    autoMin: {
        flex: '1 0 auto',
        minWidth: '75px'
    }
});

const SimpleSelect = ({
    endpoint = {},
    optionLabel = '',
    optionKey = '',
    optionValue = '',
    selected = '',
    setSelected = (value) => {},
    title = titles.FORMAT,
    emptyItem = true,
    withoutStyles = false,
    withAutoMinStyles = false,
    error = false,
    helperText = '',
    required = false,
    test_id_prefix = '',
}) => {
    const classes = useStyles();
    const handleChangeFormat = (event) => setSelected(event.target.value);
    return (
        <>
            <FormControl
                required={required}
                error={error}
                size={'small'}
                variant="outlined"
                // className={withoutStyles ? classes.auto : classes.select}
                className={cn({
                    [classes.select]: !withoutStyles,
                    [classes.autoMin]: withAutoMinStyles,
                })}
                // style={{ minWidth: '70px' }}
            >
                <InputLabel data-testid={`${test_id_prefix}:${optionLabel}/label`}>{title}</InputLabel>
                <Select
                    value={selected}
                    onChange={handleChangeFormat}
                    label={title}
                    data-testid={`${test_id_prefix}:${optionLabel}/select`}
                >
                    {emptyItem && <MenuItem value={''}>{titles.NOT_CHOSEN}</MenuItem>}
                    {Array.isArray(endpoint)
                        ? endpoint?.map(item =>
                            <MenuItem key={item?.[optionKey]} value={optionValue ? item?.[optionValue] : item}>
                                {item[optionLabel]}
                            </MenuItem>
                        )
                        : endpoint && (typeof endpoint === 'object') && Object.keys(endpoint)?.map((key) => (
                            <MenuItem key={key} value={key}>{endpoint[key]}</MenuItem>
                        ))
                    }
                </Select>
                <FormHelperText>{helperText}</FormHelperText>
            </FormControl>
        </>
    );
};

export default SimpleSelect;
