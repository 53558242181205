const externalTransportTitles = {
    WEBSITE: 'Официальный сайт',
    IATA: 'Код IATA',
    PHONE_NUMBER: 'Телефон',
    DEPARTURES: 'Вылеты',
    ARRIVALS: 'Прилеты',
    ARRIVAL_SCHEDULE: 'Расписание прибытий',
    DEPARTURE_SCHEDULE: 'Расписание отправлений',
    PASS_REPORT: 'Отчет по проходам'
};

export default externalTransportTitles;
