import { Collapse } from '@mui/material';

import {
    loadVehicleCategories,
    loadVehicleTypes
} from 'redux/TransportSpecial/actions';
import { useStoreProp } from 'helpers/hooks';
import { VehicleInfo } from 'components/common/Transport/VehicleInfo';

const VehiclesInfo = ({ info, isOpen }) => {
    const vehicleTypes = useStoreProp(loadVehicleTypes, 'transportSpecial', 'vehicleTypes');

    return (
        <Collapse in={isOpen} timeout={'auto'} unmountOnExit  className="info" style={{ padding: 10 }}>
            <VehicleInfo
                info={info}
                storeName={'transportSpecial'}
                loadVehicleCategories={loadVehicleCategories}
                vehiclesTypes={vehicleTypes}
                hideCharacteristics
            />
        </Collapse>
    );
};

export default VehiclesInfo;
