import * as types from './types';

export const loadStationStatuses = () => ({
    type: types.LOAD_STATION_STATUSES
});

export const loadedStationStatuses = (data) => ({
    type: types.LOADED_STATION_STATUSES,
    payload: data
});

// station list
export const loadStationList = (page, limit, params = {}) => ({
    type: types.LOAD_STATION_LIST,
    payload: { page, limit, ...params },
});

export const loadedStationList = (data) => ({
    type: types.LOADED_STATION_LIST,
    payload: data,
});

export const loadingStationList = (bool = false) => ({
    type: types.LOADING_STATION_LIST,
    payload: bool,
});

// station sidebar
export const loadStationSidebar = (page, limit, params = {}) => ({
    type: types.LOAD_STATION_SIDEBAR,
    payload: { page, limit, ...params },
});

export const loadedStationSidebar = (data, isReplace = true) => ({
    type: types.LOADED_STATION_SIDEBAR,
    payload: { data, isReplace },
});

export const loadingStationSidebar = (bool = false) => ({
    type: types.LOADING_STATION_SIDEBAR,
    payload: bool,
});

export const clearStationSidebar = () => ({
    type: types.CLEAR_STATION_SIDEBAR,
});

export const setActive = (item = {}) => ({
    type: types.SET_ACTIVE,
    payload: item,
});

export const clearActive = () => ({
    type: types.SET_ACTIVE,
    payload: {}
});

export const setFilters = (data = {}) => ({
    type: types.SET_FILTERS,
    payload: data,
});

export const clearFilters = () => ({
    type: types.SET_FILTERS,
    payload: {},
});

// vestibule polygon
export const loadVestibulePolygon = (polygon, filter = {}) => ({
    type: types.LOAD_VESTIBULE_POLYGON,
    payload: { polygon, ...filter },
});

export const loadedVestibulePolygon = (data) => ({
    type: types.LOADED_VESTIBULE_POLYGON,
    payload: data,
});

export const clearVestibulePolygon = () => ({
    type: types.LOADED_VESTIBULE_POLYGON,
    payload: [],
});

export const changeColorVestibulePolygon = (events) => ({
    type: types.CHANGE_COLOR_VESTIBULE_POLYGON,
    payload: events,
});

export const loadingVestibulePolygon = (bool = false) => ({
    type: types.LOADING_VESTIBULE_POLYGON,
    payload: bool,
});

// vestibule list
export const loadVestibuleList = (page, limit, params = {}) => ({
    type: types.LOAD_VESTIBULE_LIST,
    payload: { page, limit, ...params },
});

export const loadedVestibuleList = (data) => ({
    type: types.LOADED_VESTIBULE_LIST,
    payload: data,
});

export const loadingVestibuleList = (bool = false) => ({
    type: types.LOADING_VESTIBULE_LIST,
    payload: bool,
});

export const loadVestibuleStatuses = () => ({
    type: types.LOAD_VESTIBULE_STATUSES
});

export const loadedVestibuleStatuses = (data) => ({
    type: types.LOADED_VESTIBULE_STATUSES,
    payload: data
});

export const loadSidebar = (page, limit, params = {}) => ({
    type: types.LOAD_SIDEBAR,
    payload: { page, limit, ...params },
});

export const loadedSidebar = (data) => ({
    type: types.LOADED_SIDEBAR,
    payload: data,
});

export const loadingSidebar = (bool = false) => ({
    type: types.LOADING_SIDEBAR,
    payload: bool,
});

export const clearSidebar = () => ({
    type: types.CLEAR_SIDEBAR,
});

export const setActiveVestibule = (item = {}) => ({
    type: types.SET_ACTIVE_VESTIBULE,
    payload: item,
});

export const clearActiveVestibule = () => ({
    type: types.SET_ACTIVE_VESTIBULE,
    payload: {}
});

export const setFiltersVestibule = (data = {}) => ({
    type: types.SET_FILTERS_VESTIBULE,
    payload: data,
});

export const clearFiltersVestibule = () => ({
    type: types.SET_FILTERS_VESTIBULE,
    payload: {},
});

// vestibule traffic
export const loadVestibuleTraffic = (id, callback) => ({
    type: types.LOAD_VESTIBULE_TRAFFIC,
    payload: id,
    callback
});

export const loadingVestibuleTraffic = (loading) => ({
    type: types.LOADING_VESTIBULE_TRAFFIC,
    payload: loading,
});

export const loadedVestibuleTraffic = (response) => ({
    type: types.LOADED_VESTIBULE_TRAFFIC,
    payload: response,
});
