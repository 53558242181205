import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Scrollbars from 'react-custom-scrollbars';
import { AppBar, Card } from '@mui/material';
import { TabContext } from '@mui/lab';

import PageLayout from 'components/layout/PageLayout';

import { CustomTab, CustomTabs } from '../Tabs';

import BasicTab from './BasicTab';
import Tab from './Tab';

import type { Action, Setting } from './types';

interface SettingsProps {
    storeProp: string
    listName: string
    loadingName: string
    loadSettingsGroupAction: Action
    editSettingsListAction: Action
    editSettingsGroupAction: Action
    title?: string
}

const Settings = ({
    title = 'Настройки',
    storeProp,
    listName,
    loadingName,
    loadSettingsGroupAction,
    editSettingsListAction,
    editSettingsGroupAction
}: SettingsProps) => {
    const dispatch = useDispatch();

    const {
        [listName]: { data: list },
        [loadingName]: loading,
        loadingButton
        // @ts-ignore
    } = useSelector(({ [storeProp]: selector }) => selector);

    const commonSettings: Setting[] = list.filter((item: Setting) => !item.settings);
    const groupSettings: Setting[] = list.filter((item: Setting) => item.settings);

    const [activeTab, setActiveTab] = useState('basic');

    const reloadList = () => dispatch(loadSettingsGroupAction());

    useEffect(() => {
        dispatch(loadSettingsGroupAction());
    }, [dispatch, loadSettingsGroupAction]);

    const renderContent = () => {
        return (<TabContext value={activeTab}>
            <Card variant="elevation" style={{ height: '100%', display: 'flex', flexDirection: 'column' }} >
                <AppBar position="sticky" color="default" style={{ zIndex: 2 }}>
                    <CustomTabs
                        value={activeTab}
                        onChange={(_, value) => setActiveTab(value)}
                        textColor="primary"
                        variant="scrollable"
                        aria-label="scrollable force tabs example"
                    >
                        <CustomTab label="Общие" value="basic" />

                        {groupSettings.map(item => (
                            <CustomTab
                                key={item.id}
                                label={item.description || item.name}
                                value={String(item.id)}
                            />
                        ))}
                    </CustomTabs>
                </AppBar>

                <Scrollbars style={{ height: '100%' }}>
                    <BasicTab
                        settings={commonSettings}
                        editAction={editSettingsListAction}
                        reloadList={reloadList}
                        loading={loading}
                        loadingButton={loadingButton}
                    />

                    {groupSettings.map(item => (
                        <Tab
                            key={item.id}
                            item={item}
                            editAction={editSettingsGroupAction}
                            reloadList={reloadList}
                            loadingButton={loadingButton}
                        />
                    ))}
                </Scrollbars>
            </Card>
        </TabContext>);
    };

    return (
        <PageLayout
            header={title}
            content={renderContent}
            customStyles={{ backgroundColor: 'inherit', padding: 0 }}
        />
    );
};

export default Settings;
