import { sub } from 'date-fns';

import * as types from './types';

const initialState = {
    loadingDtpTypes: false,
    dtp_types: {
        data: [],
        meta: {
            last_page: 0,
        },
    },
    loading: false,
    list: {
        data: [],
        meta: {
            last_page: 0,
        },
    },
    dtpReportFilter: {},
    loadTypes: {},
    settings: {
        data: [],
        meta: {
            last_page: 0,
        },
    },
    loadingDtpSettings: false,
    settingById: {},
    settingByName: {},
    dtpGraph: [],
    dtpGraphLoading: false,
    dtpStreets: [],
    dtpStreetsLoading: false,
    polygon: [],
    polygonLoading: false,
    dtpById: {},
    polygonVisible: [],
    active: {},
    // данные для удаления
    deleteForm: null,
    // данные для редактирования
    editForm: null,
    saved: false,
    filters: {},
    streetList: [],
    statuses: [],
    loadingStatuses: false,
    severity: [],
    dtpReportByArea: [],
    loadingDtpReportByArea: false,
    dtpByAreaReportFilter: {},
    dtpReportByPercent: [],
    loadingDtpReportByPercent: false,
    dtpByPercentReportFilter: {},

    dangersArea: [],
    loadingDangersArea: false,
    loadingDtpFile: false,
    dangersAreaReportFilter: {},

    reportMonthAreas: {},
    loadingReportMonthAreas: false,

    reportStatistic: [],
    loadingReportStatistic: false,

    reportByCategory: [],
    // reportByCategoryInfo: {},
    loadingReportByCategory: false,
    reportCategoryFilter: {},

    reportCategory: {},
    reportCategoryColor: {},

    reportCategoryTable: [],
    loadingReportCategoryTable: false,

    newMap: [],
    newMapLoading: false,

    population: {
        data: [],
        meta: {
            last_page: 0,
        },
    },
    loadingPopulation: false,

    concentrationArea: {
        data: [],
        meta: {
            last_page: 0,
        },
    },
    loadingConcentrationArea: false,
    loadingCedConcentrationArea: false,
    concentrationAreaStats: [],
    concentrationAreaTypes: [],
    concentrationAreaStatuses: [],
    dtpOnRadius: [],
    loadingDtpOnRadius: false,
    showcase: {},
    loadingShowcase: false,
    mkdtpModalData: null,
    mkdtpFilterParams: {},

    settingsGroup: { data: [] },
    loadingSettingsGroup: false,

    loadingButton: false,

    typesAll: [],
    dtpByTime: [],
    loadingDtpByTime: false,
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case types.LOADED_DTP_TYPES:
            return {
                ...state,
                dtp_types: payload,
            };
        case types.LOADING:
            return {
                ...state,
                loading: payload,
            };
        case types.LOADED_DTP_LIST:
            const { data, replaceStore } = payload;
            return {
                ...state,
                list: replaceStore
                    ? data
                    : {
                        ...state.list,
                        ...data,
                        data: [
                            ...state.list.data,
                            ...data.data
                        ],
                        meta: {
                            ...state.list.meta,
                            ...data.meta
                        },
                    }
            };
        case types.SET_DTP_REPORT_FILTER:
            return {
                ...state,
                dtpReportFilter: payload,
            };
        case types.LOADED_DTP_LOAD_TYPES:
            return {
                ...state,
                loadTypes: payload,
            };
        case types.LOADING_DTP_SETTINGS:
            return {
                ...state,
                loadingDtpSettings: payload,
            };
        case types.LOADED_DTP_SETTINGS:
            return {
                ...state,
                settings: payload,
            };
        case types.LOADED_DTP_SETTING_BY_ID:
            return {
                ...state,
                settingById: payload,
            };
        case types.LOADED_DTP_SETTING_BY_NAME:
            return {
                ...state,
                settingByName: payload,
            };
        case types.LOADING_DTP_GRAPH:
            return {
                ...state,
                dtpGraphLoading: payload,
            };
        case types.LOADED_DTP_GRAPH:
            return {
                ...state,
                dtpGraph: payload.data,
            };
        case types.CLEAR_DTP_GRAPH:
            return {
                ...state,
                dtpGraph: [],
            };
        case types.LOADED_DTP_STREETS:
            return {
                ...state,
                dtpStreets: payload.data,
            };
        case types.LOADING_DTP_STREETS:
            return {
                ...state,
                dtpStreetsLoading: payload,
            };
        case types.LOADED_DTP_POLYGON: {
            return {
                ...state,
                polygon: payload,
            };
        }
        case types.LOADING_DTP_POLYGON: {
            return {
                ...state,
                polygonLoading: payload,
            };
        }
        case types.LOADED_DTP_BY_ID: {
            return {
                ...state,
                dtpById: payload,
            };
        }
        case types.SET_DTP_POLYGON_VISIBLE: {
            return {
                ...state,
                polygonVisible: payload,
            };
        }
        case types.SET_DTP_ACTIVE: {
            return {
                ...state,
                active: payload,
            };
        }
        case types.RESET_DTP_ACTIVE: {
            return {
                ...state,
                active: {},
            };
        }

        case types.SET_DELETE_FORM: {
            return {
                ...state,
                deleteForm: payload,
            };
        }
        case types.SET_EDIT_FORM: {
            return {
                ...state,
                editForm: payload,
            };
        }
        case types.SAVE_DTP: {
            return {
                ...state,
                saved: payload,
            };
        }
        case types.SET_FILTERS: {
            return {
                ...state,
                filters: payload,
            };
        }
        case types.LOADED_DTP_STREET_LIST: {
            return {
                ...state,
                streetList: payload,
            };
        }
        case types.LOADED_DTP_DATES: {

            const { start_date, end_date } = payload;

            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...payload,
                    start_date: new Date(start_date) || sub(new Date(), { days: 10 }),
                    end_date: new Date(end_date) || new Date(),
                },
            };
        }
        case types.LOADED_DTP_STATUSES: {
            return {
                ...state,
                statuses: payload
            };
        }
        case types.LOADED_DTP_SEVERITY: {
            return {
                ...state,
                severity: payload
            };
        }
        case types.LOADED_DTP_BY_AREA: {
            return {
                ...state,
                dtpReportByArea: payload
            };
        }
        case types.LOADING_DTP_BY_AREA: {
            return {
                ...state,
                loadingDtpReportByArea: payload
            };
        }
        case types.SET_DTP_BY_AREA_REPORT_FILTER: {
            return {
                ...state,
                dtpByAreaReportFilter: payload
            };
        }
        case types.LOADED_DTP_BY_PERCENT: {
            return {
                ...state,
                dtpReportByPercent: payload
            };
        }
        case types.LOADING_DTP_BY_PERCENT: {
            return {
                ...state,
                loadingDtpReportByPercent: payload
            };
        }
        case types.SET_DTP_BY_PERCENT_REPORT_FILTER: {
            return {
                ...state,
                dtpByPercentReportFilter: payload
            };
        }

        case types.LOADED_DANGERS_AREA: {
            return {
                ...state,
                dangersArea: payload,
            };
        }
        case types.LOADING_DANGERS_AREA: {
            return {
                ...state,
                loadingDangersArea: payload,
            };
        }
        case types.CLEAR_DANGERS_AREA: {
            return {
                ...state,
                dangersArea: initialState.dangersArea,
                loadingDangersArea: initialState.loadingDangersArea,
            };
        }
        case types.SET_DANGERS_AREA_REPORT_FILTER: {
            return {
                ...state,
                dangersAreaReportFilter: payload,
            };
        }
        case types.LOADING_DTP_TYPES:
            return {
                ...state,
                loadingDtpTypes: payload,
            };

        case types.LOADING_DTP_FILE: {
            return {
                ...state,
                loadingDtpFile: payload,
            };
        }

        case types.LOADED_DTP_REPORT_MONTH_AREAS: {
            return {
                ...state,
                reportMonthAreas: payload,
            };
        }
        case types.CLEAR_DTP_REPORT_MONTH_AREAS: {
            return {
                ...state,
                reportMonthAreas: {},
                loadingReportMonthAreas: false,
            };
        }
        case types.LOADING_DTP_REPORT_MONTH_AREAS: {
            return {
                ...state,
                loadingReportMonthAreas: payload,
            };
        }

        case types.LOADED_DTP_REPORT_STATISTIC: {
            return {
                ...state,
                reportStatistic: payload,
            };
        }
        case types.CLEAR_DTP_REPORT_STATISTIC: {
            return {
                ...state,
                reportStatistic: [],
                loadingReportStatistic: false,
            };
        }
        case types.LOADING_DTP_REPORT_STATISTIC: {
            return {
                ...state,
                loadingReportStatistic: payload,
            };
        }

        case types.LOADED_DTP_REPORT_BY_CATEGORY: {
            return {
                ...state,
                reportByCategory: payload,

                // reportByCategoryInfo: payload.reduce((res, item) => {
                //     return {
                //         ...res,
                //         [item.id]: {
                //             id: item.id,
                //             date: getDateToFormat(item.dtp_at, 'MM.yyyy'),
                //             categories: item.categories.reduce((res, itemI) => ({
                //                 ...res,
                //                 [itemI.dtp_category]: itemI.count
                //             }), {})
                //         },
                //     };
                // }, {})
            };
        }
        case types.CLEAR_DTP_REPORT_BY_CATEGORY: {
            return {
                ...state,
                reportByCategory: [],
                // reportByCategoryInfo: {},
                loadingReportByCategory: false,
            };
        }
        case types.LOADING_DTP_REPORT_BY_CATEGORY: {
            return {
                ...state,
                loadingReportByCategory: payload,
            };
        }
        case types.SET_REPORT_CATEGORY_FILTER: {
            return {
                ...state,
                reportCategoryFilter: payload,
            };
        }

        case types.LOADED_DTP_REPORT_CATEGORY: {
            return {
                ...state,
                reportCategory: payload,
            };
        }

        case types.LOADED_DTP_REPORT_CATEGORY_TABLE: {
            return {
                ...state,
                reportCategoryTable: payload,
            };
        }

        case types.LOADING_DTP_REPORT_CATEGORY_TABLE: {
            return {
                ...state,
                loadingReportCategoryTable: payload,
            };
        }

        case types.LOADING_EDIT_STATUS: {
            return {
                ...state,
                loadingStatuses: payload,
            };
        }

        case types.LOADED_NEW_MAP: {
            return {
                ...state,
                newMap: payload,
            };
        }
        case types.LOADING_NEW_MAP: {
            return {
                ...state,
                newMapLoading: payload,
            };
        }

        case types.LOADED_POPULATION: {
            return {
                ...state,
                population: payload,
            };
        }

        case types.LOADING_POPULATION: {
            return {
                ...state,
                loadingPopulation: payload,
            };
        }

        case types.LOADED_CONCENTRATION_AREA: {
            return {
                ...state,
                concentrationArea: payload,
            };
        }

        case types.LOADING_CONCENTRATION_AREA: {
            return {
                ...state,
                loadingConcentrationArea: payload,
            };
        }

        case types.LOADING_CED_CONCENTRATION_AREA: {
            return {
                ...state,
                loadingCedConcentrationArea: payload
            };
        }

        case types.LOADED_CONCENTRATION_AREA_STATS: {
            return {
                ...state,
                concentrationAreaStats: payload,
            };
        }

        case types.LOADED_CONCENTRATION_AREA_TYPES: {
            return {
                ...state,
                concentrationAreaTypes: payload,
            };
        }

        case types.LOADED_CONCENTRATION_AREA_STATUSES: {
            return {
                ...state,
                concentrationAreaStatuses: payload,
            };
        }

        case types.LOADED_DTP_ON_RADIUS: {
            return {
                ...state,
                dtpOnRadius: payload,
            };
        }

        case types.LOADING_DTP_ON_RADIUS: {
            return {
                ...state,
                loadingDtpOnRadius: payload,
            };
        }

        case types.LOADED_SHOWCASE: {
            return {
                ...state,
                showcase: payload
            };
        }

        case types.LOADING_SHOWCASE: {
            return {
                ...state,
                loadingShowcase: payload
            };
        }

        case types.SET_MKDTP_MODAL_DATA: {
            return {
                ...state,
                mkdtpModalData: payload,
            };
        }

        case types.SET_MKDTP_FILTER_PARAMS: {
            return {
                ...state,
                mkdtpFilterParams: payload,
            };
        }

        case types.LOADED_SETTINGS_GROUP: {
            return {
                ...state,
                settingsGroup: payload
            };
        }

        case types.LOADING_SETTINGS_GROUP: {
            return {
                ...state,
                loadingSettingsGroup: payload
            };
        }

        case types.LOADING_BUTTON: {
            return {
                ...state,
                loadingButton: payload
            };
        }

        case types.LOADED_DTP_TYPES_ALL: {
            return {
                ...state,
                typesAll: payload
            };
        }

        case types.LOADED_DTP_BY_TIME: {
            return {
                ...state,
                dtpByTime: payload
            };
        }

        case types.LOADING_DTP_BY_TIME: {
            return {
                ...state,
                loadingDtpByTime: payload
            };
        }

        default:
            return state;
    }
};
