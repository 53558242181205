import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Drawer, IconButton, List, Tooltip } from '@mui/material';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import cn from 'classnames';

import titles from 'helpers/constants/titles';

import sidebarStyles from '../SideBar/sidebar.module.scss';
import Loading from '../../common/Loading';
import useMenu from '../../../helpers/hooks/useMenu';
import LayersSideBar from '../../MapComponents/SideBar';
import { addAttachLayers, saveActiveLayer, setSelectedLayer } from '../../../redux/Menu/actions';
import layersLazyLoad from '../../MapComponents/helpers/layersLazyLoad';
import { menuSelectors } from '../../../redux/Menu';

import ActiveLayers from './ActiveLayers';
import styles from './menu.module.scss';
import MenuItem from './MenuItem';

const Menu = () => {
    // хук меню
    useMenu();
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const loading = useSelector(menuSelectors.loading);
    const menuList = useSelector(menuSelectors.menuList);
    const selectedLayer = useSelector(menuSelectors.selectedLayer);
    const activeLayers = useSelector(menuSelectors.activeLayers);
    const attachList = useSelector(menuSelectors.attachList);

    const [isOpenSideBar, setIsOpenSidebar] = useState(true);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const sideOpenStyle = isOpenSideBar ? sidebarStyles.sidebar__container__opened : sidebarStyles.sidebar__container__closed;

    // подключенные слои
    const layersConfig = layersLazyLoad();

    // забираем из слоя sidebar
    const SelectedLayerComponent = layersConfig[selectedLayer]?.SideBar;

    const handleOpenSidebar = () => {
        dispatch(setSelectedLayer());
        setIsOpenSidebar(prev => !prev);
    };

    useEffect(() => {
        window.addEventListener('resize',() => setWindowWidth(window.innerWidth));
        return () => window.removeEventListener('resize', () => setWindowWidth(window.innerWidth));
    }, []);

    useEffect(() => {
        if (windowWidth < 1360 && isOpenSideBar) {
            handleOpenSidebar();
        }
    }, [windowWidth]);

    const findLayers = (list) => {
        return list?.reduce((res, item) => {
            if (item.layer) {
                res.push(item);
            } else if (item.children?.length > 0) {
                return res.concat(findLayers(item?.children));
            }
            return res;
        }, []);
    };

    // фильтруем все меню-слои
    const menuItemLayers = findLayers(menuList);
    // клик по элементу в списке, либо в панели выбранных и закрепленных слоев
    const handleChangeSelectedLayer = (current) => {
        const urlParams = {
            pathname: '/map',
            search: attachList.map((layer) => `layers[]=${layer}`).join('&'),
            state: { layer: current },
        };
        if (current !== selectedLayer) {
            // чтобы не дублировать слой убираем если он был в активных
            const currentIndex = activeLayers.indexOf(current);
            // если current нет в активных - в активных остаются все закрепленные + current
            // иначе если он в закрепленных то оставляем все закрепленные, если нет то все активные
            // тк активные включают в себя и закрепленные и нет
            const filteredActive = currentIndex < 0
                ? [...attachList, current]
                : attachList.includes(current) ? attachList : activeLayers;
            // если выбрали слой, а предыдущего выбранного нет в закрепленных,
            // то надо предыдущий из активных убрать
            const uniqLayerList = attachList.includes(selectedLayer)
                ? filteredActive
                : filteredActive.filter((el) => el !== selectedLayer);
            // обнвоялем активные
            dispatch(saveActiveLayer(uniqLayerList));
            // после этого меняем выбранный
            dispatch(setSelectedLayer(current));

            // сброс фильтра при активации слоя
            const layerConfig = layersConfig[current] || null;
            if (layerConfig && layerConfig.onAfterActivateAction) {
                dispatch(layerConfig.onAfterActivateAction());
            }
            history.push(urlParams);
        }
        // если на другой странице - редиректим на карту
        // и добавляем в урл все закрепленные слои, обновляем state в location
        if (!history.location.pathname?.includes('/map')) {
            history.push(urlParams);
        }
    };

    const handleChangeAttachList = (layer) => {
        // если этот слой уже закреплен, открепляем его. И наоброт
        const filteredLayers = attachList.includes(layer)
            ? attachList.filter(el => el !== layer)
            : [ ...attachList, layer ];
        // если слой закрепили - переходим на него, если открепили и он был selected, то сбрасываем selected
        let state = { layer: selectedLayer };
        if (filteredLayers.includes(layer)) {
            dispatch(setSelectedLayer(layer));
            state = { layer };
        } else if (layer === selectedLayer) {
            state = {};
            dispatch(setSelectedLayer());

        }
        dispatch(addAttachLayers(filteredLayers));
        dispatch(saveActiveLayer(filteredLayers));

        history.replace({
            pathname: '/map',
            search: filteredLayers.map((item) => `layers[]=${item}`).join('&'),
            state,
        });
    };

    const resetActive = () => {
        // если мы были на не закрепленном слое, и перешли на другую страницу (не слой)
        // нужно в активных оставить только закрепленные
        dispatch(saveActiveLayer(attachList));
    };

    const renderSidebar = () => {
        return (
            <div className={cn(sidebarStyles.sidebar__container, { [sidebarStyles.slide]: !!selectedLayer /*showSelectedList*/ }, sideOpenStyle)}>
                <div className={sidebarStyles.sidebar__container__menu}>
                    {/* меню */}
                    <div className={styles.menu}>
                        {loading
                            ? <Loading circular={true}/>
                            : <List className={styles.menu__list}>
                                {menuList?.map((item) => {

                                    // todo: удалить проверку когда с бэка будут убраны эти пункты из меню
                                    if (item.name === 'Профиль' || item.name === 'Выход') {
                                        return null;
                                    }

                                    return (
                                        <MenuItem
                                            key={item.id}
                                            item={item}
                                            hasChildren={item.children?.length > 0}
                                            onChangeSelected={handleChangeSelectedLayer}
                                            onChangeAttachList={handleChangeAttachList}
                                            onResetActive={resetActive}
                                            isOpenSideBar={isOpenSideBar}
                                            setIsOpenSidebar={setIsOpenSidebar}
                                        />);
                                })}
                            </List>
                        }
                    </div>
                </div>
                <div className={sidebarStyles.sidebar__container__list}>
                    {/* список */}
                    <LayersSideBar
                        selectedLayer={selectedLayer}
                    />
                </div>

            </div>
        );
    };



    return (
        <>
            <div className={cn(sidebarStyles.sidebar__header, sidebarStyles.sidebar__header__activeLayer)}>
                <Tooltip title={isOpenSideBar ? 'Закрыть меню' : 'Открыть меню'}>
                    <IconButton style={{ padding: 4, marginLeft: 4 }} onClick={handleOpenSidebar} >
                        {isOpenSideBar
                            ? <DoubleArrowIcon sx={{ color: '#39bcff', fontSize: 24, transform: 'rotate(180deg)' }}/>
                            : <DoubleArrowIcon sx={{ color: '#39bcff', fontSize: 24 }}/>
                        }
                    </IconButton>
                </Tooltip>
                {location.pathname === '/map' && isOpenSideBar
                    && <Tooltip title={titles.BACK_TO_MENU}>
                        <IconButton style={{ padding: 4, marginRight: 6 }} onClick={() => dispatch(setSelectedLayer())} >
                            <i style={{ fontSize: 18, marginRigth: '3px', color: '#39bcff' }} className="fas fa-list-ul" />
                        </IconButton>
                    </Tooltip>
                }
                <ActiveLayers
                    list={menuItemLayers}
                    onChangeSelected={handleChangeSelectedLayer}
                    onChangeAttachList={handleChangeAttachList}
                    isOpenSideBar={isOpenSideBar}
                    setIsOpenSidebar={setIsOpenSidebar}
                />
            </div>
            {(windowWidth < 1360 && isOpenSideBar)
                ? <Drawer style={{ overflowX: 'hidden' }} open={isOpenSideBar} onClose={() => setIsOpenSidebar(false)}>
                    {renderSidebar()}
                </Drawer>
                : renderSidebar()
            }

        </>
    );
};

export default Menu;
