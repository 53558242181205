import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LinearProgress, Link, Tooltip } from '@mui/material';

import { focusIncidentsSelectors } from 'redux/FocusIncidents';
import {
    clearTransportIncident,
    loadTransportIncidentById,
} from 'redux/SituationalPlans/actions';
import {
    editConcentrationArea,
    loadConcentrationAreaStatuses,
    loadConcentrationAreaTypes,
} from 'redux/Incidents/actions';
import { saveDtpFocus } from 'redux/FocusIncidents/actions';
import { incidentsSelectors } from 'redux/Incidents';
import { fullDateTimeWithoutSeconds, fullDateTimeWithTimeZone } from 'helpers/date.config';
import messages from 'helpers/constants/messages';
import { useStoreFromSelectorListToObject } from 'helpers/hooks';
import prepareCoords from 'helpers/prepareCoords';
import titles from 'helpers/constants/titles';
import Information from 'components/common/Information';
import ModalForm from 'components/pages/Dictionaries/SituationalPlans/TransportIncidents/ModalForm';
import getAddressStringFromObject from 'components/common/Location/LoadAddressByCoords/getAddressStringFromObject';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import createPointGJ from 'components/MapComponents/leaflet/helpers/createPointGJ';

import { createIcon, getItemToSave } from '../helper';

import Providers from './Providers';
import DtpListModal from './DtpListModal';
import OperationListModal from './OperationListModal';

const PopUp = ({ isReport = false, selectedItem, uuid }) => {
    const dispatch = useDispatch();
    // забираем данные из полигона
    // const polygon = useSelector(focusIncidentsSelectors.polygon);
    const polygonLoading = useSelector(focusIncidentsSelectors.polygonLoading);
    const active = useSelector(focusIncidentsSelectors.active);
    const [data, setData] = useState({});
    const { address_text, address } = data;
    const [editTIModal, setEditTIModal] = useState(false);
    const [addTIModal, setAddTIModal] = useState(false);
    const [openDtpList, setOpenDtpList] = useState(false);
    const [openOperationList, setOpenOperationList] = useState(false);

    useEffect(() => {
        if (Object.keys(active).length > 0) {
            setData((prev) => {
                if (active.id !== prev.id) return active;
                return prev;
            });
        } else if (selectedItem && Object.keys(selectedItem).length > 0) {
            setData((prev) => {
                if (selectedItem.id !== prev.id) return selectedItem;
                return prev;
            });
        }
    }, [active, selectedItem]);

    const ti = useSelector(({ SituationalPlans }) => SituationalPlans?.transportIncident || {});
    const tiLoading = useSelector(({ SituationalPlans }) => SituationalPlans?.loadingTransportIncident || false);

    const types = useStoreFromSelectorListToObject(
        loadConcentrationAreaTypes,
        incidentsSelectors.concentrationAreaTypes
    );
    const statuses = useStoreFromSelectorListToObject(
        loadConcentrationAreaStatuses,
        incidentsSelectors.concentrationAreaStatuses
    );

    const handleEditTI = () => {
        setEditTIModal(true);
    };

    const handleAddTI = () => {
        setAddTIModal(true);
    };

    const infoData = {
        id: {
            title: 'Id',
        },
        name: {
            title: titles.NAME,
        },
        type: {
            title: 'Вид',
            value: ({ type }) => types.get(type)?.name,
        },
        status: {
            title:  titles.STATUS,
            value: ({ status }) => statuses.get(status)?.name,
        },
        address_text: {
            title: titles.ADDRESS,
            value: ({ address_text = '', address = {} }) => address_text || getAddressStringFromObject(address)
        },
        gps: {
            title: titles.COORDINATES,
            value: ({ lat, lon }) => (lat && lon) ? `${prepareCoords(lat)} / ${prepareCoords(lon)}` : messages.NO_DATA,
        },
        created_at: {
            title: titles.DATE_TIME_CREATED,
            value: ({ created_at }) => fullDateTimeWithoutSeconds(created_at),
        },
        confirmed_at: {
            title: titles.DATE_TIME_CONFIRMED,
            value: ({ confirmed_at }) => fullDateTimeWithoutSeconds(confirmed_at),
        },
        ...(!isReport && {
            transport_incident_id: {
                title: 'Событие',
                value: ({ transport_incident_id }) => transport_incident_id
                    ? (
                        <Tooltip title="Редактировать событие">
                            <Link
                                component="button"
                                variant="body2"
                                onClick={handleEditTI}
                            >
                                {transport_incident_id}
                            </Link>
                        </Tooltip>
                    )
                    : (
                        <Link
                            component="button"
                            variant="body2"
                            onClick={handleAddTI}
                        >
                            Создать событие
                        </Link>
                    ),
            }
        }),
    };

    useEffect(() => {
        if (data?.transport_incident_id) {
            dispatch(loadTransportIncidentById(data?.transport_incident_id));
        }
    }, [data?.transport_incident_id, dispatch]);

    useEffect(() => () => dispatch(clearTransportIncident()), []);

    const typeColor = types.get(selectedItem?.type)?.color;
    const statusColor = statuses.get(selectedItem?.status)?.color;

    return (
        <div className="map-popup">
            <div className="map-popup_header" style={{ backgroundColor: typeColor }}>
                <div>
                    <div
                        className="img"
                        dangerouslySetInnerHTML={{ __html: createIcon(typeColor, statusColor) }}
                        // dangerouslySetInnerHTML={{ __html: createIcon(data) }}
                        // dangerouslySetInnerHTML={{ __html: icon({ fill: '#fff' }) }}
                    />
                    <span>{address_text || getAddressStringFromObject(address) || messages.NO_DATA}</span>
                </div>
            </div>
            <div className="map-popup_body">
                {tiLoading && <LinearProgress />}

                <div className="scroll" style={{ height: 'auto', maxHeight: '300px' }}>
                    <div className="custom-popup-body">
                        <Information
                            data={data}
                            title={infoData}
                        />
                    </div>

                    <div style={{ margin: '0.5rem 0.5rem 0 0' }} >
                        <FormButtons
                            noPadding
                            buttons={[
                                ...(data.transport_incident_id
                                    ? [{
                                        ...buttonsTypes.defaultPrimary,
                                        size: 'small',
                                        name: 'Мероприятия',
                                        onClick: () => setOpenOperationList(true)
                                    }]
                                    : []
                                ),
                                {
                                    ...buttonsTypes.defaultPrimary,
                                    size: 'small',
                                    name: 'Присвоенные ДТП',
                                    disabled: !data?.dtp_list?.length,
                                    onClick: () => !polygonLoading && setOpenDtpList(true)
                                },

                            ]}
                        />
                    </div>
                </div>
            </div>

            {(editTIModal || addTIModal) && (
                <Providers>
                    <ModalForm
                        isOpen={editTIModal || addTIModal}
                        isMkDtp
                        isNew={addTIModal}
                        item={addTIModal
                            ? { // добавление
                                address_text: data.address_text,
                                address: data.address,
                                lat: data.lat,
                                lon: data.lon,
                                geometry: createPointGJ(data.lat, data.lon),
                                mkdtp_id: data.id,
                                source: 'МКДТП',
                            }
                            : { // редактирование
                                ...ti?.data,
                                mkdtp_id: ti?.data?.mkdtp_id || data.id,
                                source: ti?.data?.source || 'МКДТП',
                            }
                        }
                        onClose={() => {
                            setEditTIModal(false);
                            setAddTIModal(false);
                        }}
                        reloadList={(tiData) => {
                            // добавить ти
                            if (addTIModal) {
                                dispatch(editConcentrationArea(
                                    data.id,
                                    getItemToSave({
                                        ...data,
                                        transport_incident_id: tiData.id,
                                        // status: 3,
                                        confirmed_at: data.confirmed_at || fullDateTimeWithTimeZone(new Date())
                                    }),
                                    () => {
                                        dispatch(saveDtpFocus(true));
                                    }
                                ));
                            }
                            if (editTIModal) {
                                dispatch(saveDtpFocus(true));
                            }
                        }}
                    />
                </Providers>
            )}

            {openDtpList && (
                <Providers>
                    <DtpListModal
                        isOpen={openDtpList}
                        onClose={() => setOpenDtpList(false)}
                        data={data}
                    />
                </Providers>
            )}

            {openOperationList && (
                <Providers>
                    <OperationListModal
                        isOpen={openOperationList}
                        onClose={() => setOpenOperationList(false)}
                        tiId={data.transport_incident_id}
                    />
                </Providers>
            )}
        </div>
    );
};

export default PopUp;
