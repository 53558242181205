import * as types from './types';

export const getList = (params = {}) => ({
    type: types.GET_LIST,
    payload: params,
});

export const loadingList = (response) => ({
    type: types.LIST_LOADING,
    payload: response,
});

export const loadedList = (response) => ({
    type: types.LIST_LOADED,
    payload: response,
});

export const loadStatuses = () => ({
    type: types.LOAD_STATUSES,
});

export const loadingStatuses = (isLoading) => ({
    type: types.LOADING_STATUSES,
    payload: isLoading,
});

export const loadedStatuses = (response) => ({
    type: types.LOADED_STATUSES,
    payload: response,
});

export const loadSidebar = (page, limit, params = {}) => ({
    type: types.LOAD_SIDEBAR,
    payload: {
        page,
        limit,
        ...params,
    },
});

export const loadedSidebar = (data, isReplace = true) => ({
    type: types.LOADED_SIDEBAR,
    payload: {
        data,
        isReplace,
    },
});

export const loadingSidebar = (bool = false) => ({
    type: types.LOADING_SIDEBAR,
    payload: bool,
});

export const clearSidebar = () => ({
    type: types.CLEAR_SIDEBAR,
});

export const loadPolygon = (polygon, filter = {}) => ({
    type: types.LOAD_POLYGON,
    payload: {
        polygon,
        filter
    }
});

export const loadedPolygon = (data) => ({
    type: types.LOADED_POLYGON,
    payload: data
});

export const loadingPolygon = (bool = false) => ({
    type: types.LOADING_POLYGON,
    payload: bool
});

export const clearPolygon = () => ({
    type: types.LOADED_POLYGON,
    payload: []
});

export const setActive = (item = {}) => ({
    type: types.SET_ACTIVE,
    payload: item,
});

export const clearActive = () => ({
    type: types.SET_ACTIVE,
    payload: {}
});

export const setFilters = (data = {}) => ({
    type: types.SET_FILTERS,
    payload: data,
});

export const clearFilters = () => ({
    type: types.SET_FILTERS,
    payload: {},
});
