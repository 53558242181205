import L from 'leaflet';

import { getMarker } from 'components/MapComponents/marker/Marker';

import iconMap from './icons/iconMap';



// цвет иконки от статуса
// export const getColorByStatus = (status) => {
//     return colorConfig(status).color; //config.mapMarkersColors[status] || config.mapMarkersColors.default;
// };

// получить иконку маркера
export const createIconMarker = (colorStatus, noValid) => {
    return L.divIcon({
        className: 'marker',
        iconSize: [30, 36],
        iconAnchor: [15, 36],
        popupAnchor: [0, -36],
        html: noValid === null
            ? `
                ${getMarker(colorStatus)}
                <i class="far fa-file-certificate" style="color: #aaaaaa; position: absolute; left: 6px; top: 7px; font-size: 16px;"/>
            `
            : noValid
                ? `
                    ${getMarker(colorStatus)}
                    <i class="fad fa-file-certificate" style="--fa-primary-color: #d32f2f; --fa-secondary-color: #aaaaaa; position: absolute; left: 6px; top: 7px; font-size: 16px;"/>
                `
                : iconMap({ fill: colorStatus })
    });
};
