import { config } from '../../config';

const apiControlKFVF = () => config.get('urls').apiControlKFVF;
const apiReports = () => config.get('urls').apiReports;

const apiUrls = {
    getCameras: () => `${apiControlKFVF()}/camera`,

    getCameraReportList: () => `${apiControlKFVF()}/report/cameras`,

    getCameraTypes: () => `${apiControlKFVF()}/camera/types`,

    editCamera: (id) => `${apiControlKFVF()}/camera/${id}`,

    createCamera: () => `${apiControlKFVF()}/camera`,

    getCameraComplexTypes: () => `${apiControlKFVF()}/camera/complex/types`,

    getCameraProtoText: () => `${apiControlKFVF()}/camera/proto`,

    getCameraDirType: () => `${apiControlKFVF()}/camera/dirtypes`,

    getCameraVideoType: () => `${apiControlKFVF()}/camera/video/types`,

    getCameraDataType: () => `${apiControlKFVF()}/camera/data/types`,

    getEvents: () => `${apiControlKFVF()}/camera/events`,

    getCameraViolations: () => `${apiControlKFVF()}/camera/violations`,

    getEventsGroup: () => `${apiControlKFVF()}/camera/events/group`,

    getComplexes:  () => `${apiControlKFVF()}/complex`,

    createComplex: () => `${apiControlKFVF()}/complex`,

    getComplexTypes: () => `${apiControlKFVF()}/complex/types`,

    getComplexUnitTypes: () => `${apiControlKFVF()}/complex/unittypes`,

    getComplexModelTypes: () => `${apiControlKFVF()}/complex/modeltypes`,

    // getComplexEntities: () => `${apiControlKFVF()}/complex_entities`,
    getComplexEntities: () => `${apiControlKFVF()}/complex/entities`, // новое апи

    getComplexEntitiesTypes: () => `${apiControlKFVF()}/complex_entities/types`,

    getPddViolations: () => `${apiControlKFVF()}/pdd`,

    editPddViolation: (id) => `${apiControlKFVF()}/pdd/${id}`,

    postComplexEntityData: () => `${apiControlKFVF()}/complex_entities/`,

    editComplexEntityData: (id) => `${apiControlKFVF()}/complex_entities/${id}`,

    deleteComplexEntityData: (id) => `${apiControlKFVF()}/complex_entities/${id}`,

    getDocuments:  () => `${apiControlKFVF()}/document/complex`,

    getDocumentTypes: () => `${apiControlKFVF()}/document/complex/types`,

    getComplexStatuses: () => `${apiControlKFVF()}/v2/complex/statuses`,

    createDocumentComplex: () => `${apiControlKFVF()}/document/complex`,

    deleteComplexDocument: (id) => `${apiControlKFVF()}/document/complex/${id}`,

    editComplexDocument: (id) => `${apiControlKFVF()}/document/complex/${id}`,

    deleteComplex: (id) => `${apiControlKFVF()}/complex/${id}`,

    getComplexHistory: (id) => `${apiControlKFVF()}/complex/${id}/history`,

    editComplex: (id) => `${apiControlKFVF()}/complex/${id}`,

    getPolygon: () => `${apiControlKFVF()}/complex/polygon`,

    // getComplexEvents: () => `${apiControlKFVF()}/complex/events`,
    getComplexEvents: () => `${apiControlKFVF()}/event`,

    // getEventObject: (uid) => `${apiControlKFVF()}/complex/event/${uid}`,
    getEventObject: () => `${apiControlKFVF()}/event/one`, // new api POST

    getComplexEventArchive: (uuid) => `${apiControlKFVF()}/complex/event_archive/${uuid}`,

    changeComplexStatus: (id) => `${apiControlKFVF()}/complex/${id}/status`,

    getSidebar: () => `${apiControlKFVF()}/complex`,

    // getComplexById: () => `${apiControlKFVF()}/complex`,

    editEventErrors: (id) => `${apiControlKFVF()}/event/${id}/errors`,

    getEventErrors: () => `${apiControlKFVF()}/event_errors`,

    getFormattedComplexHistory: (id) => `${apiControlKFVF()}/complex/${id}/history/human`,

    getComplexRules: (id) => `${apiControlKFVF()}/document/complex/rules/${id}`,

    getComplexTransferTypes: () => `${apiControlKFVF()}/complex/transfer/types`,

    getComplexTransferRules: (id) => `${apiControlKFVF()}/complex/transfer/rules/${id}`,

    moveServiceEventsToMain: () => `${apiControlKFVF()}/complex/service/events`,

    getServiceEvents: () => `${apiControlKFVF()}/complex/service/events`,

    getComplexStatistic: () => `${apiControlKFVF()}/complex/status_statistic`,

    deleteFromServiceEvents: () => `${apiControlKFVF()}/complex/service/events`,

    getMonthlyReport: () => `${apiControlKFVF()}/complex/monthly_report`,

    // getMonthlyReportExpanded: () => `${apiControlKFVF()}/complex/monthly_report_expanded`,
    getMonthlyReportExpanded: () => `${apiReports()}/control/control-operating-time-in-hours-by-cameras`,

    getMonthlyReportByComplex: () => `${apiReports()}/control/control-operating-time-in-hours-by-complexes`,

    // getReportByPeriod: () => `${apiControlKFVF()}/complex/report/work-by-period`,
    getReportByPeriod: () => `${apiReports()}/control/work-by-period`,

    getComparativeStatistics: () => `${apiControlKFVF()}/complex/comparative_statistics`,

    getCoordinationHistory: () => `${apiControlKFVF()}/complex/coordination_history`,

    getComplexReport: () => `${apiControlKFVF()}/complex/report`,

    getInaccuracy: () => `${apiControlKFVF()}/complex/inaccuracy`,

    getDorisControlSettings: () => `${apiControlKFVF()}/settings`,

    editDorisControlSetting: (id) => `${apiControlKFVF()}/settings/${id}`,

    editSettingsList: () => `${apiControlKFVF()}/settings/list`,

    deleteComplexEvents: () => `${apiControlKFVF()}/complex/events/manually`,

    getIncorrectComplexReport: () => `${apiControlKFVF()}/complex/report/incorrect-complexes`,

    getTsCategories: () => `${apiControlKFVF()}/complex_entities/categories`,

    getInspectionCertificate: () => `${apiControlKFVF()}/complex/report/inspection-certificate`,

    getEventSendingRequest: (id) => `${apiControlKFVF()}/event/${id}`,

    get1CNamesReport: () => `${apiControlKFVF()}/1c/name/report`,

    create1CName: () => `${apiControlKFVF()}/1c/name`,

    edit1CName: (id) => `${apiControlKFVF()}/1c/name/${id}`,

    delete1CName: (id) => `${apiControlKFVF()}/1c/name/${id}`,

    get1CNames: () => `${apiControlKFVF()}/1c/name`,

    getTagList: () => `${apiControlKFVF()}/tag`,

    createTag: () => `${apiControlKFVF()}/tag/`,

    editTag: (id) => `${apiControlKFVF()}/tag/${id}`,

    deleteTag: (id) => `${apiControlKFVF()}/tag/${id}`,

    complexSyncTags: () => `${apiControlKFVF()}/complex/sync_tags`,

    getExclusiveFiles: () => `${apiControlKFVF()}/exclusive_vehicle_files`,

    getExclusiveFilesHistoryList: () => `${apiControlKFVF()}/exclusive_vehicle_files`,

    getExclusiveFilesLogList: () => `${apiControlKFVF()}/exclusive_vehicle_files/log_list`,

    createExclusiveFile: () => `${apiControlKFVF()}/exclusive_vehicle_files/`,

    editExclusiveFile: (id) => `${apiControlKFVF()}/exclusive_vehicle_files/update/${id}`,

    deleteExclusiveFile: (id) => `${apiControlKFVF()}/exclusive_vehicle_files/${id}`,

    getExclusiveFileHistory: (id) => `${apiControlKFVF()}/exclusive_vehicle_files/${id}/history`,

    getComplexTypeGrouping: () => `${apiControlKFVF()}/complex/type-grouping`,

    getComplexMeasuring: () => `${apiControlKFVF()}/complex/measuring`,

    getRecognitionCameraDirTypes: () => `${apiControlKFVF()}/complex/recognition_camera/dirtypes`,

    createComplexEntity: (url) => `${apiControlKFVF()}${url}`,

    editComplexEntity: (url) => `${apiControlKFVF()}${url}`,

    deleteComplexEntity: (url) => `${apiControlKFVF()}${url}`,

    getComputerTransferTypes: () => `${apiControlKFVF()}/complex/computer/transfer/types`,

    getComputerTransferRules: (id) => `${apiControlKFVF()}/complex/computer/transfer/rules/${id}`,

    getDorisExportTypes: () => `${apiControlKFVF()}/complex/doris/export/types`,

    getChecklists: () => `${apiControlKFVF()}/check_boxes`,

    createChecklist: () => `${apiControlKFVF()}/check_boxes/`,

    editChecklist: (id) => `${apiControlKFVF()}/check_boxes/${id}`,

    deleteChecklist: (id) => `${apiControlKFVF()}/check_boxes/${id}`,

    getMaterialStorageGroups: () => `${apiControlKFVF()}/material-storage-group`,

    createMaterialStorageGroup: () => `${apiControlKFVF()}/material-storage-group`,

    editMaterialStorageGroup: (id) => `${apiControlKFVF()}/material-storage-group/${id}`,

    deleteMaterialStorageGroup: (id) => `${apiControlKFVF()}/material-storage-group/${id}`,

    getComplexesTree: () => `${apiControlKFVF()}/complex`,

    getViolationEvents: () => `${apiControlKFVF()}/complex/recognition_camera/event-violation`,

    getEventCheckList: (uuid) => `${apiControlKFVF()}/complex/event/${uuid}`,

    getEventPDDS: () => `${apiControlKFVF()}/complex/pdds`,

    saveEventCheckList: () => `${apiControlKFVF()}/complex/event/check`,

    getCheckListGroup: () => `${apiControlKFVF()}/check_box_groups`,

    createCheckListGroup: () => `${apiControlKFVF()}/check_box_groups`,

    editCheckListGroup: (id) => `${apiControlKFVF()}/check_box_groups/${id}`,

    deleteCheckListGroup: (id) => `${apiControlKFVF()}/check_box_groups/${id}`,

    getComplexById:  (id) => `${apiControlKFVF()}/complex/${id}/show`,

    checkFgis: () => `${apiControlKFVF()}/document/complex/check/fgis`,

    getDocumentsHistory: (id) => `${apiControlKFVF()}/document/complex/${id}/history`,

    getEventsStatistic: () => `${apiControlKFVF()}/complex/events/statistics`,

    loadComplexByRadius: () => `${apiControlKFVF()}/complex/radius`,

    getCameraViolationsTree: () => `${apiControlKFVF()}/camera/violations`,

    getPddReportExpanded: () => `${apiControlKFVF()}/complex/pdd_monthly_report`,

    getMaterialExportGroup: () => `${apiControlKFVF()}/material-export-group`,

    createMaterialExportGroup: () => `${apiControlKFVF()}/material-export-group`,

    editMaterialExportGroup: (id) => `${apiControlKFVF()}/material-export-group/${id}`,

    deleteMaterialExportGroup: (id) => `${apiControlKFVF()}/material-export-group/${id}`,

    getMaterialExportGroupTypes: () => `${apiControlKFVF()}/material-export-group/types`,

    getMaterialExportGroupProtocols: () => `${apiControlKFVF()}/material-export-group/protocols`,

    getTypeOfMistakesForCheckList: () => `${apiControlKFVF()}/checkbox/error_type`,

    createTypeOfMistakesForCheckList: () => `${apiControlKFVF()}/checkbox/error_type`,

    editTypeOfMistakesForCheckList: (id) => `${apiControlKFVF()}/checkbox/error_type/${id}`,

    deleteTypeOfMistakesForCheckList: (id) => `${apiControlKFVF()}/checkbox/error_type/${id}`,

    getRecentPddReportExpanded: () => `${apiControlKFVF()}/complex/recent_pdd_report`,

    getSettingsGroup: () => `${apiControlKFVF()}/settings_group`,

    editSettingsGroup: (id) => `${apiControlKFVF()}/settings_group/${id}/settings/update`,

    getShowCaseComplexStatus: () => `${apiControlKFVF()}/showcase/complex/status`,

    getShowCaseDocumentsVerification: () => `${apiControlKFVF()}/showcase/documents/verification`,

    getDuploCodes: () => `${apiControlKFVF()}/complex/duplo/codes`,

    editDuploCodes: (id) => `${apiControlKFVF()}/complex/duplo/codes/${id}`,

    getMaterials: () => `${apiControlKFVF()}/material`,

    createMaterial: () => `${apiControlKFVF()}/material/`,

    editMaterial: (id) => `${apiControlKFVF()}/material/${id}`,

    deleteMaterial: (id) => `${apiControlKFVF()}/material/${id}`,

    moveComputerToOtherComplex: (id) => `${apiControlKFVF()}/complex/computer/transfer/${id}`,

    getAndPostMaterialExport: () => `${apiControlKFVF()}/material-export`,

    putAndDeleteMaterialExport: (id) => `${apiControlKFVF()}/material-export/${id}`,

    getMaterialExportTypes: () => `${apiControlKFVF()}/material-export/types`,

    getMaterialExportProtocols: () => `${apiControlKFVF()}/material-export/protocols`,

    getComplexConditions: () => `${apiControlKFVF()}/complex/conditions`,

    getMaterialExportStatuses: () => `${apiControlKFVF()}/material-export/statuses`,

    cancelMaterialExport: (id) => `${apiControlKFVF()}/material-export/${id}/cancel`,

    getGRZColors: () => `${apiControlKFVF()}/grz/colors`,

    getGRZCountries: () => `${apiControlKFVF()}/grz/countries`,

};

export default apiUrls;
