import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IconButton, Tooltip } from '@mui/material';

import { setShowReturnTo } from 'redux/Menu/actions';
import { menuSelectors } from 'redux/Menu';

interface ReturnToButtonProps {
    onClick?: () => void;
    isNotMargin?: boolean;
    test_id_prefix?: string;
}

const ReturnToButton = ({ onClick, isNotMargin, test_id_prefix = '' }: ReturnToButtonProps) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const showReturnTo = useSelector(menuSelectors.showReturnTo || {});

    const { link = '', buttonText = 'Вернуться', search = '' } = showReturnTo || {};

    const clearOnClose = () => {
        onClick && onClick();
        dispatch(setShowReturnTo());
        history.push({ pathname: link, search });
    };

    useEffect(() => () => {
        dispatch(setShowReturnTo());
    }, [dispatch]);

    if (!link) return null;

    return (
        <Tooltip title={buttonText}>
            <IconButton
                style={{ width: 36, height: 36, fontSize: '1.2rem',...(!isNotMargin ? { marginBottom: '2rem' } : {}) }}
                onClick={clearOnClose}
                data-testid={`${test_id_prefix}:return/button`}
            >
                <i className="fal fa-arrow-square-left" color="#707070" />
            </IconButton>
        </Tooltip>
    );
};

export default ReturnToButton;
