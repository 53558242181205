import { useEffect, useMemo, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import Print from '@mui/icons-material/Print';
import PlayArrow from '@mui/icons-material/PlayArrow';
import { Button, ButtonGroup, Tooltip } from '@mui/material';
import cn from 'classnames';

import buttons from 'helpers/constants/buttons';
import messages from 'helpers/constants/messages';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import Modal from 'components/common/Modal';
import useMediaFiles from 'helpers/hooks/useMediaFiles';
import CommonImage from 'components/common/Image';
import PrintInfo from 'components/common/MediaModal/PrintInfo';

import styles from './mediaModal.module.scss';

// компонент с пролистыванием вперед-назад
// подгрузку новых делаем тут же и храним весь список локально
const MediaModal = ({
    isOpen,
    onClose,
    item,
    renderLabels,
}) => {
    const footerComponent = useMemo(() => renderLabels?.() || '', [renderLabels]);
    const [allMediaItem, setAllMediaItem] = useState([]);
    const [activeMediaItem, setActiveMediaItem] = useState({});

    const allMedia = useMediaFiles(item);

    useEffect(() => {
        setAllMediaItem(allMedia);
        setActiveMediaItem(allMedia[0]);
    }, [allMedia]);

    const componentRef = useRef();
    const printImg = useReactToPrint({
        content: () => componentRef.current,
        pageStyle: `
            @media print {
                .pagebreak {
                    page-break-before: auto;
                }
            }
        `,
    });

    const openNewWindow = () => window.open(activeMediaItem.src);

    return (
        <Modal
            fullWidth
            isOpen={isOpen}
            noPadding
            onClose={onClose}
            noOverflowY={window.innerHeight >= 1080}
            buttons={
                <FormButtonsComponent
                    buttons={[
                        {
                            ...buttonsTypes.close,
                            onClick: onClose
                        }
                    ]}
                />
            }
        >
            <div className={styles.mediaModalContainer} style={{ flex: 1 }}>
                <div className={styles.navigationBar}>
                    <ButtonGroup className={styles.navigationBar__item} size="small">
                        <Tooltip title={'На печать'}>
                            <span>
                                <Button
                                    onClick={printImg} size="small"
                                >
                                    <Print />
                                </Button>
                            </span>
                        </Tooltip>
                    </ButtonGroup>
                </div>
                <div className={styles.content}>
                    {allMediaItem.length > 0
                        ? <>
                            <div className={styles.sidebar}>
                                <h2 className={styles.sidebar__header}>
                                    Медиафайлы
                                </h2>
                                <div className={styles.sidebar__content}>
                                    {allMediaItem.map((el, i) => (
                                        <div
                                            className={cn(styles.mediaBlock, allMediaItem?.src === el.src && styles.active)}
                                            key={i}
                                            onClick={() => setActiveMediaItem(el)}
                                        >
                                            {el.type === 'image'
                                                ? <CommonImage
                                                    className={styles.media}
                                                    src={el.src}
                                                />
                                                : el.type === 'video'
                                                    ? <video
                                                        src={el.src}
                                                        className={styles.media}
                                                    />
                                                    : null
                                            }
                                            {el.type === 'video'
                                                && <div className={styles.playArrow}>
                                                    <PlayArrow fontSize="large"/>
                                                </div>
                                            }
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className={styles.mainContentBlock}>
                                {activeMediaItem?.type === 'image'
                                    ? <>
                                        <CommonImage
                                            src={activeMediaItem?.src}
                                            onClick={openNewWindow}
                                            style={{ cursor: 'pointer' }}
                                        />
                                        <FormButtonsComponent
                                            className={styles.openButton}
                                            noPadding
                                            justButton
                                            buttons={[
                                                {
                                                    onClick: openNewWindow,
                                                    name: buttons.OPEN_IN_NEW_WINDOW,
                                                    variant: 'contained',
                                                    color: 'infoButton',
                                                    type: 'button',
                                                }
                                            ]}
                                        />
                                    </>

                                    : activeMediaItem?.type === 'video'
                                        ? <video
                                            controls
                                            src={activeMediaItem?.src}
                                        />
                                        : null
                                }
                            </div>
                        </>
                        : <div>{messages.NO_MATERIALS}</div>
                    }
                </div>
                <div className={styles.footer}>
                    {footerComponent}
                </div>
            </div>
            <div ref={componentRef}>
                <PrintInfo
                    activeItem={activeMediaItem}
                    renderLabels={footerComponent}
                />
            </div>

        </Modal>
    );
};

export default MediaModal;
