import { useState } from 'react';
import { Popover, } from '@mui/material';
import { makeStyles } from '@mui/styles';

import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import GetReports from 'components/common/GetReports';
import GetReport from 'components/common/GetReport';

const useStyles = makeStyles({
    block: {
        width: '100%',
        padding: '1rem',
    },
});

// компонент с кнопкой Отчет и выпадашкой вниз, в котрой уже размещается выбор форматов и нопка Получить отчет
const ReportBlock = ({ onGetReport = null, reportId = null, customComponents, disabled = false, access = null, newApi = false, test_id_prefix = '' }) => {
    const styles = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [selectedFormats, setSelectedFormats] = useState([]);

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setSelectedFormats([]);
    };

    const handleGetReport = (formats) => {
        onGetReport(formats);
        handleClose();
    };

    return (<>
        <FormButtonsComponent
            noPadding
            noMarginLeft
            test_id_prefix={test_id_prefix}
            buttons={[
                {
                    ...buttonsTypes.report,
                    onClick: handleOpen,
                    testName: 'report'
                },
            ]}

        />
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <div className={styles.block}>
                {customComponents // функция renderProps в которую передадим функицю закрытия popover-a
                    ? customComponents(handleClose)
                    : reportId && (!access || access?.reportId)
                        ? <GetReports
                            onDownload={handleGetReport}
                            selected={selectedFormats}
                            onChange={setSelectedFormats}
                            reportId={reportId}
                            disabled={disabled}
                            newApi={newApi}
                            test_id_prefix={test_id_prefix}
                        />
                        : <GetReport handleDownload={handleGetReport} disabled={disabled} test_id_prefix={test_id_prefix}/>
                }
            </div>
        </Popover>
    </>);
};

export default ReportBlock;
