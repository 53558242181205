import {
    loadTransportIncidentStatuses,
} from 'redux/SituationalPlans/actions';
import titles from 'helpers/constants/titles';
import messages from 'helpers/constants/messages';
import { LSContainer } from 'components/common/List';
import { useStoreProp } from 'helpers/hooks';

import Item from './Item';

const RenderContent = ({ loading, data, reloadList, isMkDtp = false }) => {
    const statuses = useStoreProp(loadTransportIncidentStatuses, 'SituationalPlans', 'transportIncidentStatuses');
    return <>
        {
            data.length > 0
                ? (<>
                    <LSContainer
                        headers={[
                            { title: '', width: '50px' },
                            { title: titles.NAME, width: '12%' },
                            { title: 'Дата регистрации', width: '12%' },
                            { title: 'Дата решения план', width: '12%' },
                            { title: 'Дата решения факт', width: '12%' },
                            { title: titles.ADDRESS, width: 'calc(20% - 50px)' },
                            { title: titles.TYPE, width: '8%' },
                            { title: titles.THREAT_LEVEL, width: '12%' },
                            { title: 'Действия', isActions: true }
                        ]}
                    >
                        {data.map((item) => (
                            <Item
                                key={item.id}
                                item={item}
                                reloadList={reloadList}
                                statusObject={statuses.find(el => el.id === item.status_id)}
                                isMkDtp={isMkDtp}
                            />
                        ))}
                    </LSContainer>

                </>)
                : (!loading && <div>{messages.DATA_IS_NOT_FOUND}</div>)
        }
    </>;
};

export default RenderContent;
