import instance from 'helpers/axios.config';

import apiUrls from './api.urls';

const ApiDataBusMethods = {
    getCollectionServices: async (params) => {
        const response = await instance.post(apiUrls.getCollectionServices(), { ...params });
        return response?.data;
    },

    getCollectionServicesTypes: async () => {
        const response = await instance.get(apiUrls.getCollectionServicesTypes());
        return response?.data;
    },

    getCollectionServicesTemplates: async () => {
        const response = await instance.get(apiUrls.getCollectionServicesTemplates());
        return response?.data;
    },

    createCollectionService: async (params) => {
        const response = await instance.post(apiUrls.createCollectionService(), { ...params });
        return response?.data;
    },

    editCollectionService: async (id, params) => {
        const response = await instance.put(apiUrls.editCollectionService(id), { ...params });
        return response?.data;
    },

    deleteCollectionService: async (id) => {
        const response = await instance.delete(apiUrls.deleteCollectionService(id));
        return response?.data;
    },
};

export default ApiDataBusMethods;
