const iconMap = ({ fill = '#000', innerFill = '#fff' }, isAirport) => {
    return isAirport
        ? `<svg fill="${fill}" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plane" class="svg-inline--fa fa-metro fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
            <path d="M480 192H365.7L260.6 8.1A16 16 0 0 0 246.7 0h-65.5c-10.6 0-18.3 10.2-15.4 20.4L214.9 192H112l-43.2-57.6c-3-4-7.8-6.4-12.8-6.4H16C5.6 128-2 137.8 .5 147.9L32 256 .5 364.1C-2 374.2 5.6 384 16 384H56c5 0 9.8-2.4 12.8-6.4L112 320h102.9l-49 171.6c-2.9 10.2 4.8 20.4 15.4 20.4h65.5c5.7 0 11-3.1 13.9-8.1L365.7 320H480c35.4 0 96-28.7 96-64s-60.7-64-96-64z"/>
        </svg>`
        : `
        <svg viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet">
            <g>
                <g id="svg_train_3">
                    <rect fill="none" id="canvas_background" height="132" width="109" y="-1" x="-1"/>
                </g>
                <g id="svg_train_4">
                    <g fill="${fill}" id="svg_train_1" transform="translate(0,510) scale(0.4,-0.4)">
                        <path id="svg_train_2" d="m445,1286c-100,-19 -206,-75 -281,-150c-104,-104 -154,-228 -154,-381c0,-144 38,-245 149,-392c64,-85 297,-316 348,-345c30,-17 31,-17 71,12c68,49 237,216 302,299c157,200 210,350 181,517c-25,142 -121,287 -241,364c-108,70 -251,98 -375,76z"/>
                    </g>
                </g>
                <g fill="${innerFill}" stroke="null" id="svg_train_9" transform="translate(90, 90) scale(0.55)">
                    <path stroke="null" id="svg_train_8" d="M448 96v256c0 51.8-61.6 96-130 96l63 49.7C386.9 502.4 383.6 512 376 512H72c-7.6 0-10.9-9.6-5-14.3L130 448C61.8 448 0 404 0 352V96C0 43 64 0 128 0h192c65 0 128 43 128 96zm-48 136V120c0-13.3-10.7-24-24-24H72c-13.3 0-24 10.7-24 24v112c0 13.3 10.7 24 24 24h304c13.3 0 24-10.7 24-24zm-176 64c-30.9 0-56 25.1-56 56s25.1 56 56 56 56-25.1 56-56-25.1-56-56-56z"/>
                </g>
            </g>
        </svg>
    `;
};

export default iconMap;

