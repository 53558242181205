import iconCluster from './icons/iconCluster';
import { clearFilters } from 'redux/Incidents/actions';

const config = {
    name: 'ДТП',
    slug: 'incidents',
    mapContextMenu: {
        title: 'Добавить ДТП',
        event: 'add_incident',
        // className: styles.button,
        minWidth: 210
    },
    mapMarkersColors: { // от статуса маркера
        // dead: '#670926',
        default: '#000',
    },
    mapMarkersTypes: {
        dot: 2,
        line: 1,
        polygon: 3
    },
    clusterIcon: iconCluster,
    onAfterActivateAction: () => clearFilters(),
    layerIcon: 'fas fa-car-crash',
};
// добавление на карте
export default config;
