const Legend = ({ reportCategory }) => (
    <div className="sidebar-tp-legend column">
        <div className="item">
            {/*<div className="title">*/}
            {/*    Типы*/}
            {/*</div>*/}

            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                }}
            >
                {Object.keys(reportCategory).map((key) => {
                    const item = reportCategory[key];
                    const { name, color } = item;
                    return (
                        <div
                            key={key}
                            className="flex"
                            style={{
                                width: '200px',
                                alignItems: 'start',
                            }}
                        >
                            <div
                                className="image circle-color"
                                style={{ backgroundColor: color }}
                            />
                            <div className="small">
                                - {name}
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    </div>
);

export default Legend;
