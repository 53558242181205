import L from 'leaflet';


import { getMarker } from 'components/MapComponents/marker/Marker';
import removeEmptyFields from 'helpers/removeEmptyFields';

import config from './config';

export const getColor = () => {
    return config.mapMarkersColors.default;
};

// иконка маркера
export const createIconMarker = (color) => L.divIcon({
    className: 'marker',
    iconSize: [30, 36],
    iconAnchor: [15, 36],
    popupAnchor: [0, -36],
    html: `
        ${getMarker(color)}
        <i class="${config.layerIcon}"
           style="width:100%;color:#fff;position:absolute;left:0;top:0;height:calc(100% - 9px);display:flex;justify-content:center;align-items:center;font-size:1rem;"
        />
    `,
});

export const getFilter = (filter) => {
    const filters = {
        ...filter,
    };

    return removeEmptyFields(filters, false);
};

export const isShowGeometry = (zoom) => {
    return zoom >= 16;
};
