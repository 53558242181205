export const externalTransport = (state) => state.externalTransport;

export const statuses = (state) => externalTransport(state).station_statuses;

export const loading = (state) => externalTransport(state).loading;
export const stationList = (state) => externalTransport(state).stationList;
export const stationListData = (state) => stationList(state).data;
export const stationListMeta = (state) => stationList(state).meta;
export const loadingStationList = (state) => externalTransport(state).loading;

export const stationPolygon = (state) => externalTransport(state).station_polygon;
export const active = (state) => externalTransport(state).active;
export const filters = (state) => externalTransport(state).filters;

export const stationSidebar = (state) => externalTransport(state).stationSidebar;
export const loadingStationSidebar = (state) => externalTransport(state).loadingStationSidebar;


export const station_schedules = (state) => externalTransport(state).station_schedules;
export const loading_station_schedules = (state) => externalTransport(state).loading_station_schedules;
