import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';

import titles from 'helpers/constants/titles';
import { useValidation } from 'helpers/hooks';
import messages from 'helpers/constants/messages';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import { createCollectionService, editCollectionService } from 'modules/DataBus/redux/actions';
import removeEmptyFields from 'helpers/removeEmptyFields';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';
import type { ItemType } from 'components/common/Autocomplete/CommonAutocomplete';

import type { CollectionService, Template, Templates, Types } from './types';

interface ModalFormProps {
    isNew: boolean;
    open: boolean;
    data?: CollectionService;
    onClose: () => void;
    reloadList: () => void;
    types: Types[];
    templates: Templates[];
}

const ModalForm = ({ data, isNew, open, onClose, reloadList, types, templates }: ModalFormProps) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    // превращаем список шаблонов в плоский массив, расширяем каждый элемент доступным для него типом  type_id
    const convertedTemplates = templates.reduce<Template[]>((res, item) => {
        const itemArray = item.template_list.map(el => ({
            ...el,
            type_id: item.type_id,
        }));
        return res.concat(itemArray);
    }, []);

    const findTemplate = (id?: number) => {
        return convertedTemplates.find(item => item.id === id) || null;
    };
    // const [templateFields, setTemplateFields] = useState<TemplateRules[]>([]);
    const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(findTemplate(data?.template_id));

    const [formData, setFormData] = useState({
        name: data?.name || '',
        template: data?.template || {},
        type_id: data?.type_id || '',
        template_id: data?.template_id || '',
    });

    const handleChange = (name: string, value: string | number, isTemplateField: boolean = false) => {
        // if (name === 'template_id') {
        // const templateObj = templates.find((item: Templates) => item.template_list[0].id === value);
        // setTemplateFields(templateObj?.template_list[0].rules || []);
        //     setFormData({ ...formData, [name]: value, type_id: templateObj?.type_id || '' });
        // } else
        if (isTemplateField) {
            setFormData({ ...formData, template: { ...formData.template, [name]: value } });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    function isTemplate(el: ItemType<Template>): el is Template {
        return (el as Template).id !== undefined;
    };

    const handleChangeTemplate = (el: ItemType<Template>) => {
        if (el === null || isTemplate(el)) {
            setSelectedTemplate(el);
            setFormData({ ...formData, template_id: el?.id || '', type_id: el?.type_id || '', template: {} });
        }
    };

    const handleSave = () => {
        const callback = () => {
            reloadList();
            onClose();
        };

        const preparedData = removeEmptyFields({
            ...formData,
            template: removeEmptyFields(formData.template),
        });

        dispatch(isNew
            ? createCollectionService(preparedData, callback)
            : editCollectionService(data?.id, preparedData, callback)
        );
    };

    return (
        <Modal
            isOpen={open}
            onClose={onClose}
            title={isNew ? titles.ADD : titles.EDIT}
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: handleSave,
                        disabled: !(formData.name || formData.template_id || formData.type_id )
                    }
                ]}
            />}
        >
            <form className="modal__form">
                <FormInfoWrapper className="block" error={validation.isKey('name')} helperText={validation.get('name')}>
                    <TextField
                        value={formData.name || ''}
                        name={'name'}
                        onChange={(e) => handleChange('name', e.target.value)}
                        label={'Название'}
                        variant={'outlined'}
                        size={'small'}
                        required={true}
                        error={validation.isKey('name')}
                    />
                </FormInfoWrapper>

                <CommonAutocomplete<Template>
                    multiple={false}
                    selected={selectedTemplate}
                    onChange={handleChangeTemplate}
                    required
                    disabled={!isNew && !!formData.template_id}
                    renderLabel={(opt) => opt?.name || ''}
                    label="Шаблон обработки"
                    error={validation.isKey('template_id')}
                    helperText={validation.get('template_id')}
                    options={convertedTemplates}
                    isOptionEqualToValue={(el, val) => el?.id === val?.id}
                    filterSelectedOptions
                    showAsSelect
                    inputName="template_id"
                    className="block"
                />
                {/* <FormInfoWrapper className="block" error={validation.isKey('template_id')} helperText={validation.get('template_id')}>
                    <FormControl
                        variant="outlined"
                        size="small"
                        error={validation.isKey('template_id')}
                        required={true}
                    >
                        <InputLabel>Шаблон обработки</InputLabel>
                        <Select
                            label="Шаблон обработки"
                            value={formData.template_id}
                            onChange={(e) => handleChange('template_id', e.target.value)}
                            name={'template_id'}
                            required={true}
                        >
                            {templates?.length > 0
                                ? templates.map((el) =>
                                    <MenuItem key={el.template_list[0].id} value={el.template_list[0].id}>{el.template_list[0].name}</MenuItem>
                                )
                                : <MenuItem disabled={true} value="">{messages.NO_DATA}</MenuItem>
                            }
                        </Select>
                    </FormControl>
                </FormInfoWrapper> */}

                <FormInfoWrapper className="block" error={validation.isKey('type_id')} helperText={validation.get('type_id')}>
                    <FormControl
                        variant="outlined"
                        size="small"
                        error={validation.isKey('type_id')}
                        required
                        disabled
                    >
                        <InputLabel>{titles.TYPE}</InputLabel>
                        <Select
                            label={titles.TYPE}
                            value={formData.type_id}
                            onChange={(e) => handleChange('type_id', e.target.value)}
                            name={'type_id'}
                            required
                        >
                            {types?.length > 0
                                ? types.map((el) =>
                                    <MenuItem key={el.id} value={el.id}>{el.name}</MenuItem>
                                )
                                : <MenuItem disabled={true} value="">{messages.NO_DATA}</MenuItem>
                            }
                        </Select>
                    </FormControl>
                </FormInfoWrapper>

                {selectedTemplate?.rules.map((item, index) =>
                    <FormInfoWrapper
                        key={index}
                        className="block"
                        error={validation.isKey(item.field)}
                        helperText={validation.get(item.field)}
                    >
                        <TextField
                            value={formData.template[item.field] || ''}
                            name={item.field}
                            onChange={(e) => handleChange(item.field, e.target.value, true)}
                            label={item.name}
                            variant={'outlined'}
                            size={'small'}
                            required={item.require}
                            type={item.type}
                            error={validation.isKey(item.field)}
                        />
                    </FormInfoWrapper>
                )}


            </form>
        </Modal>
    );
};

export default ModalForm;
