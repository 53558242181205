export const monitoring = (state) => state.monitoring;

export const list = (state) => monitoring(state).list;
export const data = (state) => list(state).data;
export const meta = (state) => list(state).meta;
export const loading = (state) => monitoring(state).loading;

export const statuses = (state) => monitoring(state).statuses;

export const sidebar = (state) => monitoring(state).sidebar;
export const sidebarLoading = (state) => monitoring(state).sidebarLoading;

export const polygon = (state) => monitoring(state).polygon;
export const polygonLoading = (state) => monitoring(state).polygonLoading;

export const active = (state) => monitoring(state).active;
export const filters = (state) => monitoring(state).filters;