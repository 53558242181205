import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import _ from 'lodash';

import { loadGRZCountries } from 'redux/DorisControl/actions';
import { loadingGRZCountries } from 'redux/DorisControl/selectors';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';
import UniversalSelect from 'components/common/UniversalSelect';
import type { CommonAutocompleteProps } from 'components/common/Autocomplete/CommonAutocomplete';

interface GRZCountry {
    id: string;
    name: string;
}

function SelectGRZCountry({
    multiple,
    selected,
    onChange,
    label = 'Страны',
    renderLabel = (option: GRZCountry | null) => (option ? option.name : ''),
}: CommonAutocompleteProps<GRZCountry>) {
    const dispatch = useDispatch();

    const loading: boolean = useSelector(loadingGRZCountries);

    const prevData = useRef(selected);

    const [showList, setShowList] = useState(false);
    const [selectedItems, setSelected] = useState(selected);

    const getList = (params: { page: number; limit: number; query?: string }) => {
        const { page, limit, query: name } = params;

        dispatch(loadGRZCountries(page, limit, { ...(name && { name }) },));
    };

    const handleClose = () => {
        setShowList(false);
    };

    const handleAccept = (data: any) => {
        onChange(data);
        handleClose();
    };

    const onReset = () => {
        const initialState = multiple ? [] : '';
        onChange(initialState);
    };

    useEffect(() => {
        if (!_.isEqual(prevData.current, selected)) {
            setSelected(selected);
            prevData.current = selected;
        }
    }, [selected, prevData]);

    return (
        <>
            <CommonAutocomplete
                multiple={multiple}
                selected={selected}
                onChange={onChange}
                label={label}
                renderLabel={renderLabel}
                onShowList={() => setShowList(true)}
                isOptionEqualToValue={(option, value) => Number(option?.id) === Number(value?.id)}
                onReset={onReset}
                loading={loading}
                limitTags={1}
            />

            {showList && (
                <UniversalSelect
                    multiple={multiple}
                    fetchList={getList}
                    storeName="dorisControl"
                    storeNameProps="grzCountries"
                    keyProp="id"
                    withSearch
                    isSelected
                    selected={selectedItems}
                    storeLoadingProps="loadingGRZCountries"
                    searchTitle="Поиск по названию"
                    renderProps={(el) => <div>{el.name}</div>}
                    title={label}
                    onAccept={handleAccept}
                    isOpen={showList}
                    onClose={handleClose}
                    noPadding
                    small
                />
            )}
        </>
    );
}

export default SelectGRZCountry;
