import {
    IconButton,
    InputAdornment,
    TextField,
} from '@mui/material';

const Form = ({ data, onChange }) => {
    const getValue = (key) => data?.[key] || '';

    const handleChange = (e) => {
        const { name, value } = e.target;
        onChange({
            [name]: value,
            id_list: [306, 292, 307]
        });
    };

    return (
        <form
            className="modal__form"
            onSubmit={(e) => {
                e.preventDefault();
            }}
        >
            <div className="block">
                <TextField
                    label="Название"
                    value={data?.name || ''}
                    onChange={handleChange}
                    name="name"
                    fullWidth
                    className="row__item"
                    variant="outlined"
                    size="small"
                    InputProps={{
                        endAdornment: (
                            getValue('query').length > 0 ? (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => onChange({ name: '' })}
                                        edge="end"
                                        size="large">
                                        <span style={{ fontSize: '1rem' }}>x</span>
                                    </IconButton>
                                </InputAdornment>
                            ) : ''
                        )
                    }}
                />
            </div>
        </form>
    );
};

export default Form;
