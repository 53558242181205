import L from 'leaflet';
import { reduce } from 'lodash';

import { fullDateTimeWithTimeZone } from 'helpers/date.config';
import colorExtend from 'helpers/mapHelper/colorExtend';

import iconMap from './icons/iconMap';
import config from './config';
/*
    если есть dead в ДТП - то всегда цвет красный #ad1818
    остальные цвета - примерно
    1: "Наезд на пешехода" - оранжевый #daa655
    2: "Столкновение" - синий #006195
    3: "Наезд на стоящее ТС" - голубой 0495e3
    4: "Иной вид ДТП" - зеленый #00AE5C
    5: "Наезд на препятствие " - серый 7b7b7b
    6: "Наезд на велосипедиста" - оранжевый c37700
*/

export const getColor = ({ dead, dtp_type }, fnGettype) => {
    // if (dead) {
    //     return config.mapMarkersColors.dead;
    // }
    const currentType = fnGettype?.(dtp_type) || null;
    return currentType ? colorExtend(currentType.color) : config.mapMarkersColors.default;
};

// получить иконку маркера
export const createIconMarker = (color) => {
    return L.divIcon({
        className: 'marker',
        iconSize: [30, 36],
        iconAnchor: [15, 36],
        popupAnchor: [0, -36],
        html: iconMap({ fill: color }),
    });
};

export const getFilter = (filter) => {
    const filters = {
        ...filter,
        start_date: filter.start_date ? fullDateTimeWithTimeZone(filter.start_date) : null,
        end_date: filter.end_date ? fullDateTimeWithTimeZone(filter.end_date) : null,
    };
    if (filters.camera) delete filters.camera;

    const paramsWithoutEmpty = reduce(
        filters,
        (res, el, key) => {
            if (el) {
                res[key] = el;
            }
            return res;
        },
        {}
    );

    return paramsWithoutEmpty;
};
