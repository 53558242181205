import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { TextField } from '@mui/material';

import titles from 'helpers/constants/titles';
import removeEmptyFields from 'helpers/removeEmptyFields';
import MultipleInput from 'components/common/Autocomplete/MultipleInput';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';

const Filter = ({ action, complex_id, tab = null, test_id_prefix_passport = '' }) => {
    const dispatch = useDispatch();
    const initialState = {
        name: '',
        uuid: []
    };

    const [filters, setFilters] = useState(initialState);
    const [isSearch, setIsSearch] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFilters({
            ...filters,
            [name]: value
        });
        if (isSearch && e.target.filters === '') {
            resetSearch();
        }
    };

    const onChangeUuid = (name, newValue) => {
        setFilters({ ...filters, [name]: newValue });
    };

    const search = () => {
        if (Object.keys(filters).length > 0) {
            setIsSearch(true);
            const prepareData = removeEmptyFields(filters, false);
            dispatch(action({ ...prepareData, complex_id }));
        }
        else {
            resetSearch();
        }
    };

    const resetSearch = (needRefresh) => {
        setFilters(initialState);
        if (needRefresh) {
            dispatch(action({ complex_id }));
        }
    };

    const updateFilter = (filter) => {
        const params = {
            ...initialState.current,
            ...filter,
        };
        setFilters(params);
        if (Object.keys(filter).length > 0) {
            search(params);
        }

    };

    return (
        <LayoutFilter
            onResetFilter={resetSearch}
            onSearch={search}
            filter={filters}
            setUserFilter={updateFilter}
            tab={tab}
            test_id_prefix={test_id_prefix_passport}
        >
            <LayoutFilter.Visible>
                <TextField
                    value={filters.name}
                    onChange={handleChange}
                    label={titles.NAME}
                    variant="outlined"
                    size="small"
                    name="name"
                    InputProps={{
                        'data-testid': `${test_id_prefix_passport}:name/input`,
                    }}
                />
                <MultipleInput
                    value={filters?.uuid || []}
                    onChange={(e) => onChangeUuid('uuid', e) }
                    label="UUID"
                    guidetext="Добавление одного или нескольких элементов, разделяя запятой"
                    testIdPrefix={test_id_prefix_passport}
                    testIdName="uuid"
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
