import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import titles from 'helpers/constants/titles';
import { useStoreProp } from 'helpers/hooks';
import PageLayout from 'components/layout/PageLayout';
import NoData from 'components/common/Information/NoData';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import { LSContainer } from 'components/common/List';
import { dataBusSelectors } from 'modules/DataBus';
import { loadCollectionServicesList, loadCollectionServicesTemplates, loadCollectionServicesTypes } from 'modules/DataBus/redux/actions';

import Item from './Item';
import ModalForm from './ModalForm';

import type { CollectionService } from './types';

const CollectionServices = () => {
    const dispatch = useDispatch();

    const types = useStoreProp(loadCollectionServicesTypes, 'dataBus', 'types');
    const templates = useStoreProp(loadCollectionServicesTemplates, 'dataBus', 'templates');

    const data = useSelector(dataBusSelectors.colectionServicesListData);
    const meta = useSelector(dataBusSelectors.colectionServicesListMeta);
    const loading = useSelector(dataBusSelectors.loadingColectionServices);

    const [params, setParams] = useState({ });
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        dispatch(loadCollectionServicesList());
    }, [dispatch, params]);

    const renderContent = () => (
        data?.length > 0
            ? <LSContainer
                headers={[
                    { title: titles.NAME, width: '20%' },
                    { title: titles.TYPE, width: '20%' },
                    { title: 'Урл получения', width: '30%' },
                    { title: 'Шаблон обработки', width: '20%' },
                    { title: titles.ACTIONS, isActions: true }
                ]}
            >
                {data.map((item: CollectionService) => (
                    <Item
                        key={item.id}
                        item={item}
                        types={types}
                        templates={templates}
                    />
                ))}
            </LSContainer>
            : (!loading && <NoData/>)
    );
    
    return (<>
        <PageLayout
            header="Сервис сбора данных"
            informPanelProps={{
                buttons: 
                    <FormButtonsComponent
                        buttons={[
                            {
                                ...buttonsTypes.add,
                                onClick: () => setOpenModal(true),
                            }
                        ]}
                        positionLeft
                        noPadding
                    />,
                total: meta?.total
            }}
            loading={loading}
            content={renderContent}
        />
        {openModal
            && <ModalForm
                open={openModal}
                onClose={() => setOpenModal(false)}
                isNew={true}
                reloadList={() => dispatch(loadCollectionServicesList())}
                types={types}
                templates={templates}
            />
        }
    </>);
};

export default CollectionServices;