import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
    Collapse,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Tooltip,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';

import { loadStatusStatistic } from 'redux/Parking/actions';
import { parkingSelectors } from 'redux/Parking';
import { menuSelectors } from 'redux/Menu';
import { authSelectors } from 'redux/Auth';

import Lock from './Lock';
import MenuItemInfo from './MenuItemInfo';

const useStyles = makeStyles({
    children: {
        padding: '0 0 0 25px !important',
    },
});

const iconStyle = {
    openedIcon: { fontSize: '18px', minWidth: '38px', justifyContent: 'center', marginRight: 4 },
    // closedIcon: { fontSize: '18px', minWidth: '24px', paddingLeft: 8 }
};

const MenuItem = ({ item, hasChildren, isChildren, onChangeSelected, onChangeAttachList, onResetActive, isOpenSideBar, setIsOpenSidebar = () => {} }) => {
    const history = useHistory();
    const location = useLocation();

    const classes = useStyles();
    const activeLayers = useSelector(menuSelectors.activeLayers);
    const attachList = useSelector(menuSelectors.attachList);
    const user = useSelector(authSelectors.authUserInfo);

    const isActive = (el) => {
        return (el.layer
            ? activeLayers.includes(el.layer) && location.pathname === '/map'
            : el.link === history.location.pathname);
    };

    const findActiveChild = (el) => el.children?.find((child) => {
        return (
            child.children.length
                ? findActiveChild(child)
                : isActive(child)
        );
    });
    const hasActiveChild = findActiveChild(item);
    const [open, setIsActive] = useState(Boolean(hasActiveChild));

    useEffect(() => {
        if (!isOpenSideBar && open) {
            setIsActive(false);
        } else if (isOpenSideBar) {
            setIsActive(!!hasActiveChild);
        }
    }, [isOpenSideBar]);

    const toggleActive = () => setIsActive(!open);

    const pushLink = (e) => {
        e.preventDefault();
        if (item.layer) {
            onChangeSelected(item.layer);
        // } else if (item.link.includes('logout')) {
        //     history.replace({
        //         pathname: item.link,
        //         search: '',
        //         state: {},
        //     });
        } else if (item.target === '_blank') {
            window.open(item.link, item.target).focus();
        } else {
            history.push({
                pathname: item.link,
                search: '',
                state: {},
            });
            onResetActive();
        }
    };

    const handleClick = (e) => {
        e.preventDefault();

        if (!isOpenSideBar) {
            setIsOpenSidebar(true);
            setTimeout(() => {
                if (hasChildren) {
                    toggleActive();
                } else {
                    pushLink(e);
                }
            }, 100); // задержка для завершения анимации открытия сайдбара
        } else {
            if (hasChildren) {
                toggleActive();
            } else {
                pushLink(e);
            }
        }
        if (item.layer && user.is_map_merge) {
            onChangeAttachList(item.layer);
        }
    };

    // проверка записи в меню
    const itemCheck = (item) => (layer, name) => item?.layer === layer
        || item?.name?.toLowerCase?.() === name?.toLowerCase?.();

    const host = window.location.origin;

    const renderButton = () => {
        return <ListItemButton
            style={{ paddingLeft: 8 }}
            selected={isActive(item)}
        >
            {!!item.icon && (
                <Tooltip title={!isOpenSideBar ? item.name : ''}>
                    <ListItemIcon style={iconStyle.openedIcon}>
                        <i className={item.icon} />
                    </ListItemIcon>
                </Tooltip>
            )}
            {/* {item.link.indexOf('1c') >= 0 && (
            <ListItemIcon classes={{ root: classes.root }}>
                <b>1C</b>
            </ListItemIcon>
        )} */}
            {isOpenSideBar && <ListItemText disableTypography primary={item.name} style={{ lineHeight: 1.43 }} />}

            {itemCheck(item)('parking','Парковочное пространство') && isOpenSideBar && (
                <MenuItemInfo
                    action={loadStatusStatistic}
                    selector={parkingSelectors.statusStatistic}
                />
            )}

            {(isOpenSideBar && hasChildren) && (open
                ? <ExpandLess fontSize="small" />
                : <ExpandMore fontSize="small" />)
            }
            {item.layer && (
                <Lock onClick={() => onChangeAttachList(item.layer)} isLocked={attachList.includes(item.layer)} />
            )}
        </ListItemButton>;
    };
    return (
        <List className={isChildren && classes.children} style={{ padding: 0 }}>
            <ListItem
                disablePadding
                onClick={handleClick}
                // button
            >
                {hasChildren
                    ? renderButton()
                    : <Link
                        to={`${item?.link}`}
                        target="_blank"
                        rel="noreferrer"
                        style={{
                            textDecoration: 'none',
                            color: 'inherit',
                            width: '100%'
                        }}
                        onClick={(e)=> e.preventDefault()}
                    >
                        {renderButton()}
                    </Link>

                }
            </ListItem>
            {hasChildren && (
                <Collapse in={open} timeout="auto">
                    {item.children.map((child) => (
                        <MenuItem
                            key={child.id}
                            item={child}
                            hasChildren={child.children?.length > 0}
                            isChildren={true}
                            onChangeSelected={onChangeSelected}
                            onChangeAttachList={onChangeAttachList}
                            onResetActive={onResetActive}
                            isOpenSideBar={isOpenSideBar}
                        />
                    ))}
                </Collapse>
            )}
        </List>
    );
};

export default MenuItem;
