import { config } from '../../config';

const storageUrl = () => config.getGeneral('urls').apiStorageUrl;

const apiControlKFVF = () => config.get('urls').apiControlKFVF;

const apiTransportUrl = () => config.get('urls').apiTransportPassengerUrl;

const apiRwUrl = () => config.get('urls').apiRwUrl;

const apiSituationalPlans = () => config.get('urls').apiSituationalPlans;

const apiUrls = {
    uploadDocumentToStorage: () => `${storageUrl()}/document`,

    createComplex: () => `${apiControlKFVF()}/complex/upload`,

    uploadVehicle: () => `${apiTransportUrl()}/vehicle/upload_vehicles`,

    uploadRoadWork: () => `${apiRwUrl()}/road-work/upload`,

    uploadTransportIncident: () => `${apiSituationalPlans()}/transport-incident/upload_go_and_chs`,

    uploadCSODD:  () => `${apiRwUrl()}/csodd/upload`,

    getCSODDFile:  () => `${apiRwUrl()}/csodd`,

    deleteCSODDFile:  (id) => `${apiRwUrl()}/csodd/${id}`,

    getRoadLabTypes: () => `${apiRwUrl()}/road-lab/types`,

    uploadRoadLab: () => `${apiRwUrl()}/road-lab/`,
};

export default apiUrls;
