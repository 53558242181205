import { useState } from 'react';
import { FormControl, TextField } from '@mui/material';

import DateRange from 'components/common/Dates/DateRange';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import titles from 'helpers/constants/titles';
import removeEmptyFields from 'helpers/removeEmptyFields';
import { setDateTime } from 'helpers/date.config';

const Filter = ({ setParams, loading, initialState }) => {

    const [fields, setFields] = useState(initialState);

    const handleSearch = (filters) => {
        const result = filters ? filters : fields;
        setParams({ page: 1, data: removeEmptyFields(result, false) });
    };

    const handleChange = (name, value) => {
        setFields({
            ...fields,
            [name] : value,
        });
    };

    const resetFilter = (needRefresh) => {
        if (needRefresh) {
            handleSearch(initialState);
        }
        setFields(initialState);
    };

    const updateFilter = (filters) => {
        const params = {
            ...initialState,
            ...filters,
        };

        setFields(params);

        if (Object.keys(filters).length > 0) {
            handleSearch(params); // отправляем в родителя, чтобы произошел запрос
        }
    };

    const isDisabled = Object.keys(removeEmptyFields(fields, false)).length === 0;

    return (
        <LayoutFilter
            onResetFilter={resetFilter} 
            onSearch={handleSearch} 
            filter={fields} 
            filterException={['start_date', 'end_date']} // поля которые не надо сохранять пользователю на бэк (даты точно никогда не сохраняем)
            setUserFilter={updateFilter}  // если у пользователя сохранен фильтр для этой страницы, мы его из общего компонента сетаем сюда
            disabled={isDisabled || loading}
        >
            <LayoutFilter.Visible>
                <DateRange
                    valueStartDate={fields.start_date || null}
                    valueEndDate={fields.end_date || null}
                    handleDate={(newValue) => handleChange('start_date', setDateTime(newValue, { seconds: '00' }))}
                    handleEndDate={(newValue) => handleChange('end_date', setDateTime(newValue, { seconds: '00' }))}
                    startLabel={titles.START_PERIOD}
                    endLabel={titles.END_PERIOD}
                    disableFuture={false}
                />
                <FormControl size="small" variant="outlined">
                    <TextField
                        label="Поиск по базе"
                        variant="outlined"
                        size="small"
                        value={fields.grz}
                        name={'grz'}
                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                        type="text"
                    />
                </FormControl>
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
