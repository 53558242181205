import { messageTypes } from '../redux/Message/actions';

const notificationsConfig = (importance) => {
    const options = {
        [messageTypes.success]: {
            messageType: messageTypes.success,
            // color: '#51a351',
            // icon: 'fa fa-check-circle'
            color: '#00ae5c',
            icon: 'far fa-exclamation-circle',
            tooltipText: 'Успешно',
        },
        [messageTypes.info]:  {
            messageType: messageTypes.info,
            // color: '#2f96b4',
            // icon: 'fa fa-info-circle'
            color: '#00ae5c',
            icon: 'far fa-exclamation-circle',
            tooltipText: 'Информация',
        },
        [messageTypes.warning]: {
            messageType: messageTypes.warning,
            // color: '#f89406',
            // icon: 'far fa-exclamation-triangle'
            color: '#daa655',
            icon: 'far fa-exclamation-circle',
            tooltipText: 'Предупреждение',
        },

        [messageTypes.error]:  {
            messageType: messageTypes.error,
            // color: '#bd362f',
            // icon: 'fa fa-times-circle'
            color: '#ad1818',
            icon: 'far fa-exclamation-circle',
            tooltipText: 'Ошибка',
        },
    };
    return options[importance] || '';
};

export default notificationsConfig;
