import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    IconButton,
    Tooltip,
} from '@mui/material';

import { menuSelectors } from 'redux/Menu';
import layersLazyLoad from 'components/MapComponents/helpers/layersLazyLoad';

import PopoverList from './PopoverList';

const ActiveLayers = ({ list, onChangeSelected, onChangeAttachList, setIsOpenSidebar, isOpenSideBar }) => {
    const layersConfig = layersLazyLoad();

    const activeLayers = useSelector(menuSelectors.activeLayers);

    const selectedLayer = useSelector(menuSelectors.selectedLayer);
    const [anchorEl, setAnchorEl] = useState(null);
    const blueColor = 'rgba(0, 0, 255, 0.54)';
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    useEffect(() => {
        if (activeLayers.length === 0) {
            setAnchorEl(null);
        }
    }, [activeLayers]);

    const onClose = () => setAnchorEl(null);

    return (
        <>
            <div>
                {isOpenSideBar
                    && <Tooltip title={'Выбранные слои'}>
                        <IconButton
                            size="small"
                            onClick={handleClick}
                            aria-describedby={id}
                        >
                            <i className="fas fa-map-marked" />{activeLayers?.length > 0 && ':'}
                        </IconButton>
                    </Tooltip>
                }
            </div>
            {(activeLayers?.length > 0 && isOpenSideBar)
                && <div>
                    {
                        activeLayers.map((layer) => {
                            return (
                                <Tooltip key={layer} title={layersConfig[layer]?.name || ''}>
                                    <IconButton
                                        size="small"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            onChangeSelected(layer);
                                            // onClose();
                                        }}
                                    >
                                        <i
                                            style={{ color: selectedLayer === layer ? blueColor : 'rgba(0, 0, 0, 0.54)' }}
                                            className={layersConfig[layer]?.layerIcon || ''}
                                        />
                                    </IconButton>
                                </Tooltip>
                            );
                        })
                    }
                </div>
            }
            <PopoverList
                id={id}
                onClose={onClose}
                anchorEl={anchorEl}
                open={open}
                layersList={list}
                handleChangeSelectedLayer={onChangeSelected}
                handleChangeAttachedList={onChangeAttachList}
                selectedLayer={selectedLayer}
            />
        </>
    );
};

export default ActiveLayers;
