import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { IconButton, Tooltip } from '@mui/material';
import { ExpandLess, ExpandMore, Map } from '@mui/icons-material';

import { deleteWorkorderEventStatus, loadWorkorderEvents, setHistoryOption } from 'redux/TransportPassenger/actions';
import messages from 'helpers/constants/messages';
import buttons from 'helpers/constants/buttons';
import { getHumanDate, getTimeWithoutSeconds } from 'helpers/date.config';
import useValidation from 'helpers/hooks/useValidation';
import ConfirmDelete from 'components/common/ConfirmModal';
import types from 'components/common/Transport/TransportHistory/types';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';

import styles from '../workOrderControl.module.scss';

import ManualStatusesModal from './StatusesModal';

const Item = ({
    item,
    index,
    openEntityList,
    setOpenEntityList,
    workOrder,
    // выбранный элемент для перехода с тс на карте - подсвечивает элемент
    isSelected = false
}) => {
    const dispatch = useDispatch();
    const [openChangeStatus, setOpenChangeStatus] = useState(false);
    const [openDeleteStatus, setOpenDeleteStatus] = useState(false);

    const currentDate = new Date().setHours(23,59,59,0);
    const itemIsShowMap = currentDate >= new Date(workOrder?.date_at).setHours(0,0,0,0);
    const itemIsCurrent = getHumanDate(new Date()) === getHumanDate(new Date(workOrder?.date_at));

    const validation = useValidation();
    const requestStatus = useSelector(({ validation }) => validation);

    useEffect(() => {
        if (requestStatus.success) {
            // после сохранения закрыть модалку и перечитать список эвентов
            setOpenDeleteStatus(false);
            validation.clear();
            dispatch(loadWorkorderEvents(item.work_order_id));
        }
    }, [validation, requestStatus, dispatch, item]);

    const currentRoute = workOrder?.traffic_schedule?.[
        item?.is_direct
            ? 'route_direct'
            : 'route_back'
    ];

    return (
        <>
            {openChangeStatus && (
                <ManualStatusesModal
                    isOpen={openChangeStatus}
                    isNew={!item.manual_status_id}
                    onClose={() => setOpenChangeStatus(false)}
                    item={item}
                    isFlight={item.entity_type === 'App\\Models\\Flight'}
                />
            )}
            {openDeleteStatus &&  (
                <ConfirmDelete
                    message={messages.CONFIRM_DELETE}
                    open={openDeleteStatus}
                    onSuccess={() => dispatch(deleteWorkorderEventStatus(item?.id))}
                    onClose={() => setOpenDeleteStatus(false)}
                />
            )}
            <div
                className={cn({
                    [styles.info_block]: true,
                    [styles.selected]: isSelected
                })}
            >
                <div className={cn(styles.info_block__item, styles.info_block__item_first)}>{index + 1}</div>
                <div className={styles.info_block__item} style={{ wordBreak: 'break-word' }}>
                    {item?.schedule_event_text}
                    {' '}
                    {item?.name}
                    {item?.entity_list?.length > 0 && (
                        <IconButton
                            size="small"
                            onClick={() => setOpenEntityList(!openEntityList)}
                        >
                            {openEntityList
                                ? <ExpandLess fontSize="inherit"/>
                                : <ExpandMore fontSize="inherit"/>
                            }
                        </IconButton>
                    )}
                </div>
                <div  className={styles.info_block__item}>{item?.milestone_text}</div>
                <div  className={styles.info_block__item}>{item?.auto_status_text}</div>
                <div className={cn(styles.info_block__item, styles.info_block__statuses)}>
                    {item?.manual_status_text && <div>{item?.manual_status_text}</div>}
                    <div>
                        {item.manual_status_id
                            ? <FormButtonsComponent
                                buttons={[
                                    {
                                        ...buttonsTypes.editIcon,
                                        onClick: () => setOpenChangeStatus(true),
                                        style: { fontSize: '0.875rem' }
                                    },
                                    {
                                        ...buttonsTypes.deleteIcon,
                                        onClick: () => setOpenDeleteStatus(true),
                                        style: { fontSize: '0.875rem' }
                                    },
                                ]}
                                noMarginLeft
                                justButton
                            />
                            : <FormButtonsComponent
                                buttons={[
                                    {
                                        ...buttonsTypes.addIcon,
                                        onClick: () => setOpenChangeStatus(true),
                                        style: { fontSize: '0.875rem' }
                                    }
                                ]}
                                noMarginLeft
                                justButton
                            />
                        }
                    </div>
                </div>
                <div className={styles.info_block__item}>
                    {item?.scheduled_time_start
                        ? getTimeWithoutSeconds(item?.scheduled_time_start)
                        : messages.NO_VALUE
                    }
                </div>
                <div className={styles.info_block__item}>
                    {item?.actual_time_start
                        ? getTimeWithoutSeconds(item?.actual_time_start)
                        : messages.NO_VALUE
                    }
                </div>
                <div className={styles.info_block__item}>
                    {item?.scheduled_time_end
                        ? getTimeWithoutSeconds(item?.scheduled_time_end)
                        : messages.NO_VALUE
                    }
                </div>
                <div className={styles.info_block__item}>
                    {item?.actual_time_end
                        ? getTimeWithoutSeconds(item?.actual_time_end)
                        : messages.NO_VALUE
                    }
                </div>
                <div style={{ width: '46px' }}>
                    {itemIsShowMap && item?.entity_list?.length > 0 && (
                        <Tooltip title={buttons.SHOW_ON_MAP}>
                            <IconButton
                                onClick={(e) => {
                                    e.stopPropagation();

                                    const option = itemIsCurrent
                                        ? [
                                            // {
                                            //     type: types.ws,
                                            //     uuid: workOrder?.vehicle?.characteristics?.serial_yandex || workOrder?.vehicle?.characteristics?.serial_egts
                                            // },
                                            {
                                                type: types.history,
                                                id: workOrder?.vehicle_id,
                                                flight_id: item?.entity_id,
                                                work_order_id: workOrder?.id,
                                                category_id: workOrder?.status === 3
                                                    ? null
                                                    : workOrder?.vehicle?.category_id,
                                            },
                                            {
                                                type: types.route,
                                                data: currentRoute?.road,
                                                // isGoTo: true,
                                            },
                                            {
                                                type: types.stations,
                                                id: currentRoute?.id,
                                                entity_list: item?.entity_list,
                                                isArea: true,
                                                // isGoTo: true,
                                            }
                                        ]
                                        : [
                                            {
                                                type: types.history,
                                                id: workOrder?.vehicle_id,
                                                flight_id: item?.entity_id,
                                                work_order_id: workOrder?.id,
                                                category_id: workOrder?.status === 3
                                                    ? null
                                                    : workOrder?.vehicle?.category_id,
                                            },
                                            {
                                                type: types.route,
                                                data: currentRoute?.road,
                                                // isGoTo: true,
                                            },
                                            {
                                                type: types.stations,
                                                id: currentRoute?.id,
                                                entity_list: item?.entity_list,
                                                isArea: true,
                                                // isGoTo: true,
                                            }
                                        ];

                                    dispatch(setHistoryOption(
                                        `История ТС по наряду (${workOrder.traffic_schedule?.name})`,
                                        option,
                                        true,
                                    ));
                                }}
                            >
                                <Map />
                            </IconButton>
                        </Tooltip>
                    )}
                </div>
            </div>
        </>
    );
};

export default Item;
