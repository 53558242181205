import { useState } from 'react';

import { getYear, getYearDiff } from 'helpers/date.config';
import titles from 'helpers/constants/titles';
import SingleKeyboardDateTimePicker from 'components/common/Dates/SingleKeyboardDateTimePicker';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';

const Filter = ({ setParams }) => {
    const [data, setData] = useState({});
    const [isSearch, setIsSearch] = useState(false);

    const handleSearch = () => {
        setParams((prev) => ({ ...prev, page: 1, query: data }));
        setIsSearch(true);
    };

    const handleResetFilter = () => {
        setData({});
        if(isSearch){
            setParams((prev) => ({ ...prev, page: 1, query: {} }));
            setIsSearch(false);
        }
    };

    return (
        <LayoutFilter
            onResetFilter={handleResetFilter} 
            onSearch={handleSearch} 
            filter={data}
        >
            <LayoutFilter.Visible>
                <SingleKeyboardDateTimePicker
                    value={data.year || null}
                    onChange={(newDate) => {
                        setData({
                            ...data,
                            year: getYear(newDate)
                        });
                    }}
                    label={titles.YEAR}
                    dateOnly={true}
                    views={['year']}
                    pickerFormat="yyyy"
                    disableFuture
                    minDate={{ year: getYearDiff(50) }}
                />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default Filter;
