import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { incidentsSelectors } from 'redux/Incidents';
import { loadPopulation } from 'redux/Incidents/actions';
import titles from 'helpers/constants/titles';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import { LSContainer } from 'components/common/List';
import PageLayout from 'components/layout/PageLayout';
import NoData from 'components/common/Information/NoData';

import Filter from './Filter';
import Item from './Item';
import ModalForm from './ModalForm';

const Population = () => {
    const dispatch = useDispatch();

    const data = useSelector(incidentsSelectors.populationData);
    const meta = useSelector(incidentsSelectors.populationMeta);
    const loading = useSelector(incidentsSelectors.loadingPopulation);

    const [params, setParams] = useState({ 
        page: 1, 
        limit: Number(localStorage.getItem('limit') || 25), 
        query: {} 
    });
    const [openAddModal, setOpenAddModal] = useState(false);

    const loadList = useCallback((shouldGoToPreviousPage = false) => {        
        if (shouldGoToPreviousPage) {            
            setParams((prev) => ({ ...prev, page: prev.page - 1 }));
        } else {            
            dispatch(loadPopulation(params.page, params.limit, params.query));
        }
    }, [dispatch, params]);

    useEffect(() => {
        loadList();
    }, [loadList]);

    const renderContent = () => {
        return <>
            {data?.length > 0
                ? (
                    <LSContainer
                        headers={[
                            { title: titles.YEAR, width: '100px' },
                            { title: titles.POPULATION, width: '200px' },
                            { title: titles.ACTIONS, isActions: true },
                        ]}
                    >
                        {data.map((item) => (
                            <Item
                                key={item.id}
                                item={item}
                                loadList={(isDelete) => loadList(isDelete && data.length === 1)}
                            />
                        ))}
                    </LSContainer>
                )
                : (!loading && <NoData/>)
            }
        </>;
    };
    

    return (
        <>
            <PageLayout
                header="Количество населения в регионе по годам"
                filters={<Filter setParams={setParams}/>}
                loading={loading}
                content={renderContent}
                informPanelProps={{
                    buttons: (
                        <FormButtonsComponent
                            positionLeft
                            noPadding
                            buttons={[
                                {
                                    ...buttonsTypes.add,
                                    onClick: () => setOpenAddModal(true),
                                }
                            ]}
                        />
                    ),
                    total: meta?.total
                }}
                paginationProps={{
                    loadList: (page) => setParams((prev) => ({ ...prev, page })),
                    list: meta,
                    limit: params.limit,
                    setLimit: (limit) => setParams((prev) => ({ ...prev, limit })),
                }}
            />
            {openAddModal && (
                <ModalForm
                    isOpen={openAddModal}
                    onClose={() => setOpenAddModal(false)}
                    loadList={loadList}
                    isNew
                />
            )}
        </>
    );
};

export default Population;
