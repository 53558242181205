import { icon, moduleName } from 'modules/Metro/module-config';

import iconCluster from './icons/iconCluster';

const config = {
    name: 'Проходы метрополитена',
    slug: moduleName,
    clusterIcon: iconCluster({ fill:'#e7e7e7', innerFill: 'rgba(0, 0, 0, 0.54)' }),
    layerIcon: icon,
};

export default config;
