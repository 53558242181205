import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography } from '@mui/material';

import titles from 'helpers/constants/titles';
import {
    dateNow,
    fullDateTimeWithoutSeconds,
} from 'helpers/date.config';
import { metroSelectors } from 'modules/Metro';
import { loadVestibuleTraffic } from 'modules/Metro/redux/actions';
import FormButtons from 'components/common/FormButtons';
import { buttonsTypes } from 'components/common/FormButtons';
import { LSContainer, LSContentColumn, LSContentItem } from 'components/common/List';

import type { Traffic } from '../types';

interface ReportsListProps {
    id: number;
}

function ReportsList({ id }: ReportsListProps) {
    const dispatch = useDispatch();

    const vestibuleTraffic: Traffic = useSelector(metroSelectors.vestibuleTraffic);

    const [isShowList, setIsShowList] = useState(false);

    useEffect(() => {
        dispatch(loadVestibuleTraffic(id));
    }, [dispatch, id]);

    const now = fullDateTimeWithoutSeconds(dateNow());

    return (
        <>
            <div style={{ padding: '0 15px' }}>
                <FormButtons
                    justButton
                    buttons={[
                        {
                            ...buttonsTypes.defaultInfo,
                            name: 'Отчет по проходам',
                            endIcon: <ExpandMoreIcon />,
                            color: 'primary',
                            onClick: () => setIsShowList(!isShowList),
                        },
                    ]}
                />
            </div>

            {isShowList && (
                <Typography style={{ color: '#17b1e0' }} variant={'h6'} align="center">
                    {now}
                </Typography>
            )}

            {isShowList && (
                <div className={isShowList ? 'scroll' : ''}>
                    <LSContainer
                        headers={[
                            { title: titles.TIME, width: '50%' },
                            { title: 'Количество проходов', align: 'center', width: '50%' },
                        ]}
                    >
                        <LSContentItem>
                            <LSContentColumn>
                                {vestibuleTraffic.start_at}-
                                {vestibuleTraffic.end_at}
                            </LSContentColumn>

                            <LSContentColumn>{vestibuleTraffic.count}</LSContentColumn>
                        </LSContentItem>
                    </LSContainer>
                </div>
            )}
        </>
    );
}

export default ReportsList;
