export const meteo = (state) => state.meteo;

export const list = (state) => meteo(state).list;
export const data = (state) => list(state).data;
export const meta = (state) => list(state).meta;

export const loadingButton = (state) => meteo(state).loadingButton;

export const loading = (state) => meteo(state).loading;
export const listLoading = (state) => meteo(state).listLoading;
export const dataDevice =  (state) => meteo(state).dataDevice;
export const companyLink = (state) => meteo(state).companyLink;
export const eventsDevice = (state) => meteo(state).eventsDevice;
export const projects = (state) => meteo(state).projects;
export const statuses = (state) => meteo(state).statuses;
export const types = (state) => meteo(state).types;
export const polygon = (state) => meteo(state).polygon;
export const active = (state) => meteo(state).active;
export const filters = (state) => meteo(state).filters;
export const meteoSettings = (state) => meteo(state).meteoSettings;
export const loadingMeteoSettings = (state) => meteo(state).loadingMeteoSettings;
export const alerts = (state) => meteo(state).alerts;
export const meteoOutputParams = (state) => meteo(state).meteoOutputParams;
export const meteoOutputSettings = (state) => meteo(state).meteoOutputSettings;
export const loadingMeteoOutputSettings = (state) => meteo(state).loadingEcologyOutputSettings;
export const devicesGroup = (state) => meteo(state).devicesGroup;
export const loadingDevicesGroup = (state) => meteo(state).loadingDevicesGroup;

export const showcase = (state) => meteo(state).showcase;
export const loadingShowcase = (state) => meteo(state).loadingShowcase;

export const deviceStatuses = (state) => meteo(state).deviceStatuses;

export const forecastList = (state) => meteo(state).forecastList;
export const loadingForecast = (state) => meteo(state).loadingForecast;
