import {
    Checkbox,
    FormControl,
    FormHelperText,
    InputAdornment,
    ListItem,
    TextField,
    Tooltip,
} from '@mui/material';

import { fullDateTime } from 'helpers/date.config';
import { useValidation } from 'helpers/hooks';

import styles from '../styles.module.scss';
import { inputTypes } from '../helper';

import ColorField from './ColorField';
import ArrayField from './ArrayField';

import type { Setting } from '../types';

interface ItemProps {
    item: Setting
    onChange: (id: number, value: boolean | string | number | string[]) => void;
    setError?: (error: boolean) => void;
}

const Item = ({ item, onChange, setError = () => {} }: ItemProps) => {
    const validation = useValidation();

    const handleChange = (value: boolean | string | number | string[]) => {
        onChange(item.id, value);
    };

    const inputAdornment = typeof item?.measurement === 'string' && item.measurement.length > 11 ? (
        <Tooltip title={item.measurement}>
            <InputAdornment position="end">
                {`${item.measurement.slice(0, 11)}...`}
            </InputAdornment>
        </Tooltip>
    ) : (
        <InputAdornment position="end">{item.measurement}</InputAdornment>
    );

    const renderField = () => {
        if (item.type === 'boolean') {
            return <div>
                <Checkbox
                    checked={!!item.value}
                    onChange={(e) => handleChange(e.target.checked)}
                    disabled={item.read_only}
                />
            </div>;
        } else if (item.type === 'color' || item.format === 'color') {
            return <ColorField
                color={String(item.value)}
                onChange={handleChange}
            />;
        } else if (item.type === 'array') {
            return <ArrayField
                element={item}
                onChange={handleChange}
                setError={setError}
            />;
        } else {
            return <TextField
                variant="outlined"
                type={inputTypes(item.type || 'string').type}
                size="small"
                className={styles.input}
                value={item.value}
                InputProps={{
                    inputProps: { min: 0 },
                    endAdornment: inputAdornment,
                }}
                inputProps={item.type === 'integer' ? { min: 0 } : {}}
                onChange={(e) => handleChange(item.type === 'integer' ? Number(e.target.value) : e.target.value)}
                helperText={`Обновлено: ${fullDateTime(item.updated_at)}`}
                error={validation.isKey(item.name)}
                disabled={item.read_only}
            />;
        }
    };

    return (
        <ListItem className={styles.item}>
            <h3>{item.description}:</h3>

            <FormControl className={styles['form-control']} variant="outlined">
                {renderField()}

                {validation.isKey(item.name) && (
                    <FormHelperText error>{validation.get(item.name)}</FormHelperText>
                )}
            </FormControl>
        </ListItem>
    );
};

export default Item;
