import { loadFormattedComplexHistory } from 'redux/DorisControl/actions';
import HistoryList from 'components/common/HistoryList';

import styles from '../../vfcomplex.module.scss';

import HistoryItemInfo from './HistoryItemInfo';

const History = ({ complex_id, onChange, test_id_prefix_passport = '' }) => {

    return (
        <div className={styles.container}>
            <HistoryList
                storeName="dorisControl"
                storeProp="formattedComplexHistory"
                loadingProp="loadingFormattedComplexHistory"
                loadAction={loadFormattedComplexHistory}
                id={complex_id}
                ItemInfo={HistoryItemInfo}
                test_id_prefix={test_id_prefix_passport}
            />
        </div>
    );

};

export default History;
