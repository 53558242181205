import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    Marker,
    ToolTip,
} from '../../../../MapComponents/leaflet';
import mapHelper from '../../../../../helpers/mapHelper';
import { useDebounce } from '../../../../../helpers/hooks';
import * as tpActions from '../../../../../redux/TransportPassenger/actions';
import { transportPassengerSelectors } from '../../../../../redux/TransportPassenger';
import { colors, icon } from '../helper';
import MarkerToolTip from '../MarkerToolTip';

const Stations = ({
    selected = [],
    onClick,

    onAddStation,
    onAddCheckPoint,
    onDragEndCheckPoint,
    onDelete,
    ...props
}) => {
    const currentService = {
        load: tpActions.loadStationPolygon,
        clear: tpActions.loadedStationPolygon,
        polygon: transportPassengerSelectors.stationPolygon,
    };

    const { map } = props;
    const dispatch = useDispatch();
    const polygon = useSelector(currentService.polygon);

    // грузим полигон
    const fetchPolygon = () => {
        const polygon = mapHelper.getGeometryPolygon(map);
        dispatch(currentService.load({ polygon }));
    };

    // задерживаем одновременные запросы
    const debounceFetchPolygon = useDebounce(fetchPolygon, 800);
    const handleFetchPolygon = (e) => debounceFetchPolygon();

    useEffect(() => {
        fetchPolygon();
    }, []);

    const handleClickOnMap = (value) => {
        const { latlng: { lat, lng } } = value;
        onAddCheckPoint?.({ lat, lon: lng });
    };

    useEffect(() => {
        map
            .on('click', handleClickOnMap)
            .on('moveend', handleFetchPolygon)
            .on('zoomend', handleFetchPolygon);

        return () => {
            dispatch(currentService.clear([]));
            map
                .off('click', handleClickOnMap)
                .off('moveend', handleFetchPolygon)
                .off('zoomend', handleFetchPolygon);
        };
    }, []);

    const getKey = (item) => {
        if (item?.id) return `item_${item?.id}`;
        if (item?.station?.id) return `station_${item?.station?.id}`;
        if (item?.check_point?.id) return `check_point_${item?.check_point?.id}`;
        return '';
    };

    // создание маркера
    const createMarker = (isStationList = true) => (item, index) => {
        // выбранная остановка
        const isSelectStation = !isStationList && !!item.station;
        // выбранная кт
        const isSelectedCP = !isStationList && !!item.check_point;
        // координаты
        const coord = item.check_point || item.station?.check_point || {};
        // цвет
        const color = isStationList
            ?  colors.disabled
            : (
                isSelectStation
                    ? colors.isStation
                    : colors.isCheckPoint
            );

        const key = getKey(item) || `index_${index + 1}`;

        const markerProps = {
            key,
            icon: icon(color, isSelectedCP),
            latlng: [coord.lat, coord.lon],
            onClick: () => {
                isSelectStation || isSelectedCP
                    ? onDelete(index)
                    : onAddStation(item);
            }
        };

        // сдвиганье контрольной точки
        if (isSelectedCP) {
            markerProps.onDragEnd = (newLatLng) => {
                const { lat, lon } = newLatLng;
                onDragEndCheckPoint?.(item, { lat, lon });
            };
        }

        return (
            <Marker
                {...props}
                {...markerProps}
            >
                <ToolTip
                    offset={[0, -30]}
                    direction="top"
                >
                    <MarkerToolTip
                        isStation={isStationList || isSelectStation}
                        name={item?.name || item?.check_point?.name || item?.station?.name || ''}
                        direction={item?.direction || item?.station?.direction || ''}
                        interval={item?.interval}
                        mileage={item?.mileage}
                    />
                </ToolTip>
            </Marker>
        );
    };

    // индексы выбранных остановок
    const selectedIndex = useMemo(() => selected
        ?.map(item => item?.check_point_id || item?.check_point?.id)
    , [selected]);

    // собираем остановки для отображения
    const stationsList = useMemo(() => {
        return polygon.reduce((res, item, index) => {
            if (
                selectedIndex.length === 0
                || !selectedIndex.includes(item.check_point_id)
            ) {
                res.push(createMarker(true)(item, index));
            }

            return res;
        }, []);

    }, [polygon, selectedIndex]);

    const selectedList = useMemo(() => selected?.map(createMarker(false)), [selected]);

    return (
        <>
            {/* все остановки не выбранные */}
            {stationsList}
            {/* выбранные остановки */}
            {selectedList}
        </>
    );
};

export default Stations;
