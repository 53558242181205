import moduleName from './module';

const prefix = `${moduleName}`;

/**
 * Constants
 * */
export const LOAD_DTP_TYPES_ALL = `${prefix}LOAD_DTP_TYPES_ALL`;
export const LOADED_DTP_TYPES_ALL = `${prefix}LOADED_DTP_TYPES_ALL`;

export const LOAD_DTP_TYPES = `${prefix}/LOAD/DTP/TYPES`;
export const LOADING_DTP_TYPES = `${prefix}/LOADING/DTP/TYPES`;
export const LOADED_DTP_TYPES = `${prefix}/LOADED/DTP/TYPES`;
export const EDIT_DTP_TYPE = `${prefix}/EDIT/DTP/TYPE`;
export const LOADING = `${prefix}/LOADING`;
export const LOAD_DTP_LIST = `${prefix}/DTP/LOAD`;
export const LOADED_DTP_LIST = `${prefix}/DTP/LOADED`;
export const SET_DTP_REPORT_FILTER = `${prefix}/SET/DTP/REPORT/FILTER`;
export const LOAD_DTP_LOAD_TYPES = `${prefix}/LOAD/DTP/LOAD/TYPES`;
export const LOADED_DTP_LOAD_TYPES = `${prefix}/LOADED/DTP/LOAD/TYPES`;
export const LOAD_DTP_SETTINGS = `${prefix}/LOAD/DTP/SETTINGS`;
export const LOADING_DTP_SETTINGS = `${prefix}/LOADING/DTP/SETTINGS`;
export const LOADED_DTP_SETTINGS = `${prefix}/LOADED/DTP/SETTINGS`;
export const LOAD_DTP_SETTING_BY_ID = `${prefix}/LOAD/DTP/SETTING/BY/ID`;
export const LOADED_DTP_SETTING_BY_ID = `${prefix}/LOADED/DTP/SETTING/BY/ID`;
export const EDIT_DTP_SETTING = `${prefix}/EDIT/DTP/SETTING`;
export const EDIT_SETTINGS_LIST = `${prefix}/EDIT_SETTINGS_LIST`;
export const LOADING_DTP_GRAPH = `${prefix}/LOADING/DTP/GRAPH`;
export const LOADED_DTP_GRAPH = `${prefix}/LOADED/DTP/GRAPH`;
export const LOAD_DTP_GRAPH = `${prefix}/LOAD/DTP/GRAPH`;
export const CLEAR_DTP_GRAPH = `${prefix}/CLEAR/DTP/GRAPH`;
export const LOAD_DTP_STREETS = `${prefix}/LOAD/DTP/STREETS`;
export const LOADED_DTP_STREETS = `${prefix}/LOADED/DTP/STREETS`;
export const LOADING_DTP_STREETS = `${prefix}/LOADING/DTP/STREETS`;

export const LOAD_DTP_POLYGON = `${prefix}/LOAD/DTP/POLYGON`;
export const LOADED_DTP_POLYGON = `${prefix}/LOADED/DTP/POLYGON`;
export const LOADING_DTP_POLYGON = `${prefix}/LOADING/DTP/POLYGON`;
export const SET_DTP_POLYGON_VISIBLE = `${prefix}/SET/DTP/POLYGON/VISIBLE`;
export const SET_DTP_ACTIVE = `${prefix}/SET/DTP/ACTIVE`;
export const RESET_DTP_ACTIVE = `${prefix}/RESET/DTP/ACTIVE`;
export const SET_DELETE_FORM = `${prefix}/SET/DELETE/FORM`;
export const SET_EDIT_FORM = `${prefix}/SET/EDIT/FORM`;
export const CREATE_NEW_DTP = `${prefix}/CREATE/NEW/DTP`;
export const EDIT_DTP = `${prefix}/EDIT/DTP`;
export const SAVE_DTP = `${prefix}/SAVE/DTP`;
export const DELETE_DTP = `${prefix}/DELETE/DTP`;
export const LOAD_DTP_BY_ID = `${prefix}/LOAD/DTP/BY/ID`;
export const LOADED_DTP_BY_ID = `${prefix}/LOADED/DTP/BY/ID`;
export const SET_FILTERS = `${prefix}/SET/FILTERS`;
export const LOAD_DTP_STREET_LIST = `${prefix}/LOAD/DTP/STREET/LIST`;
export const LOADED_DTP_STREET_LIST = `${prefix}/LOADED/DTP/STREET/LIST`;
export const LOAD_DTP_DATES = `${prefix}/LOAD/DTP/DATES`;
export const LOADED_DTP_DATES = `${prefix}/LOADED/DTP/DATES`;
export const LOAD_DTP_STATUSES = `${prefix}/LOAD/DTP/STATUSES`;
export const LOADED_DTP_STATUSES = `${prefix}/LOADED/DTP/STATUSES`;
export const LOAD_DTP_SEVERITY = `${prefix}/LOAD/DTP/SEVERITY`;
export const LOADED_DTP_SEVERITY = `${prefix}/LOADED/DTP/SEVERITY`;
export const LOAD_DTP_BY_AREA = `${prefix}/LOAD/DTP/BY/AREA`;
export const LOADING_DTP_BY_AREA = `${prefix}/LOADING/DTP/BY/AREA`;
export const LOADED_DTP_BY_AREA = `${prefix}/LOADED/DTP/BY/AREA`;
export const SET_DTP_BY_AREA_REPORT_FILTER = `${prefix}/SET/DTP/BY/AREA/REPORT/FILTER`;
export const LOAD_DTP_BY_PERCENT = `${prefix}/LOAD/DTP/BY/PERCENT`;
export const LOADING_DTP_BY_PERCENT = `${prefix}/LOADING/DTP/BY/PERCENT`;
export const LOADED_DTP_BY_PERCENT = `${prefix}/LOADED/DTP/BY/PERCENT`;
export const SET_DTP_BY_PERCENT_REPORT_FILTER = `${prefix}/SET/DTP/BY/PERCENT/REPORT/FILTER`;

export const LOAD_DANGERS_AREA = `${prefix}/LOAD_DANGERS_AREA`;
export const LOADED_DANGERS_AREA = `${prefix}/LOADED_DANGERS_AREA`;
export const CLEAR_DANGERS_AREA = `${prefix}/CLEAR_DANGERS_AREA`;
export const LOADING_DANGERS_AREA = `${prefix}/LOADING_DANGERS_AREA`;
export const SET_DANGERS_AREA_REPORT_FILTER = `${prefix}/SET_DANGERS_AREA_REPORT_FILTER`;

export const LOAD_DTP_FILE = `${prefix}/LOAD_DTP_FILE`;
export const LOADING_DTP_FILE = `${prefix}/LOADING_DTP_FILE`;

export const LOAD_DTP_REPORT_MONTH_AREAS = `${prefix}/LOAD_DTP_REPORT_MONTH_AREAS`;
export const LOADED_DTP_REPORT_MONTH_AREAS = `${prefix}/LOADED_DTP_REPORT_MONTH_AREAS`;
export const LOADING_DTP_REPORT_MONTH_AREAS = `${prefix}/LOADING_DTP_REPORT_MONTH_AREAS`;
export const CLEAR_DTP_REPORT_MONTH_AREAS = `${prefix}/CLEAR_DTP_REPORT_MONTH_AREAS`;

export const LOAD_DTP_REPORT_STATISTIC = `${prefix}/LOAD_DTP_REPORT_STATISTIC`;
export const LOADED_DTP_REPORT_STATISTIC = `${prefix}/LOADED_DTP_REPORT_STATISTIC`;
export const CLEAR_DTP_REPORT_STATISTIC = `${prefix}/CLEAR_DTP_REPORT_STATISTIC`;
export const LOADING_DTP_REPORT_STATISTIC = `${prefix}/LOADING_DTP_REPORT_STATISTIC`;

export const LOAD_DTP_REPORT_BY_CATEGORY = `${prefix}/LOAD_DTP_REPORT_BY_CATEGORY`;
export const LOADED_DTP_REPORT_BY_CATEGORY = `${prefix}/LOADED_DTP_REPORT_BY_CATEGORY`;
export const CLEAR_DTP_REPORT_BY_CATEGORY = `${prefix}/CLEAR_DTP_REPORT_BY_CATEGORY`;
export const LOADING_DTP_REPORT_BY_CATEGORY = `${prefix}/LOADING_DTP_REPORT_BY_CATEGORY`;
export const SET_REPORT_CATEGORY_FILTER = `${prefix}/SET_REPORT_CATEGORY_FILTER`;

export const LOAD_DTP_REPORT_CATEGORY = `${prefix}/LOAD_DTP_REPORT_CATEGORY`;
export const LOADED_DTP_REPORT_CATEGORY = `${prefix}/LOADED_DTP_REPORT_CATEGORY`;

export const LOAD_DTP_REPORT_CATEGORY_TABLE = `${prefix}/LOAD_DTP_REPORT_CATEGORY_TABLE`;
export const LOADED_DTP_REPORT_CATEGORY_TABLE = `${prefix}/LOADED_DTP_REPORT_CATEGORY_TABLE`;
export const LOADING_DTP_REPORT_CATEGORY_TABLE = `${prefix}/LOADING_DTP_REPORT_CATEGORY_TABLE`;

export const EDIT_DTP_STATUSES = `${prefix}/EDIT_DTP_STATUSES`;
export const LOADING_EDIT_STATUS = `${prefix}/LOADING_EDIT_STATUS`;

export const LOAD_NEW_MAP = `${prefix}/LOAD_NEW_MAP`;
export const LOADED_NEW_MAP = `${prefix}/LOADED_NEW_MAP`;
export const LOADING_NEW_MAP = `${prefix}/LOADING_NEW_MAP`;

export const LOAD_POPULATION = `${prefix}/LOAD_POPULATION`;
export const LOADED_POPULATION = `${prefix}/LOADED_POPULATION`;
export const LOADING_POPULATION = `${prefix}/LOADING_POPULATION`;
export const CREATE_POPULATION = `${prefix}/CREATE_POPULATION`;
export const EDIT_POPULATION = `${prefix}/EDIT_POPULATION`;
export const DELETE_POPULATION = `${prefix}/DELETE_POPULATION`;

export const LOAD_CONCENTRATION_AREA = `${prefix}/LOAD_CONCENTRATION_AREA`;
export const LOADED_CONCENTRATION_AREA = `${prefix}/LOADED_CONCENTRATION_AREA`;
export const LOADING_CONCENTRATION_AREA = `${prefix}/LOADING_CONCENTRATION_AREA`;
export const CREATE_CONCENTRATION_AREA = `${prefix}/CREATE_CONCENTRATION_AREA`;
export const EDIT_CONCENTRATION_AREA = `${prefix}/EDIT_CONCENTRATION_AREA`;
export const DELETE_CONCENTRATION_AREA = `${prefix}/DELETE_CONCENTRATION_AREA`;
export const LOADING_CED_CONCENTRATION_AREA = `${prefix}/LOADING_CED_CONCENTRATION_AREA`;

export const LOAD_CONCENTRATION_AREA_STATS = `${prefix}/LOAD_CONCENTRATION_AREA_STATS`;
export const LOADED_CONCENTRATION_AREA_STATS = `${prefix}/LOADED_CONCENTRATION_AREA_STATS`;

export const LOAD_CONCENTRATION_AREA_TYPES = `${prefix}/LOAD_CONCENTRATION_AREA_TYPES`;
export const LOADED_CONCENTRATION_AREA_TYPES = `${prefix}/LOADED_CONCENTRATION_AREA_TYPES`;

export const LOAD_CONCENTRATION_AREA_STATUSES = `${prefix}/LOAD_CONCENTRATION_AREA_STATUSES`;
export const LOADED_CONCENTRATION_AREA_STATUSES = `${prefix}/LOADED_CONCENTRATION_AREA_STATUSES`;

export const LOAD_DTP_ON_RADIUS = `${prefix}/LOAD_DTP_ON_RADIUS`;
export const LOADED_DTP_ON_RADIUS = `${prefix}/LOADED_DTP_ON_RADIUS`;
export const LOADING_DTP_ON_RADIUS = `${prefix}/LOADING_DTP_ON_RADIUS`;

export const LOAD_SHOWCASE = `${prefix}/LOAD_SHOWCASE`;
export const LOADING_SHOWCASE = `${prefix}/LOADING_SHOWCASE`;
export const LOADED_SHOWCASE = `${prefix}/LOADED_SHOWCASE`;

export const LOAD_DTP_SETTING_BY_NAME = `${prefix}/LOAD_DTP_SETTING_BY_NAME`;
export const LOADED_DTP_SETTING_BY_NAME = `${prefix}/LOADED_DTP_SETTING_BY_NAME`;

export const SET_MKDTP_MODAL_DATA = `${prefix}/SET_MKDTP_MODAL_DATA`;
export const SET_MKDTP_FILTER_PARAMS = `${prefix}/SET_MKDTP_FILTER_PARAMS`;

export const LOAD_SETTINGS_GROUP = `${prefix}/LOAD_SETTINGS_GROUP`;
export const LOADING_SETTINGS_GROUP = `${prefix}/LOADING_SETTINGS_GROUP`;
export const LOADED_SETTINGS_GROUP = `${prefix}/LOADED_SETTINGS_GROUP`;
export const EDIT_SETTINGS_GROUP = `${prefix}/EDIT_SETTINGS_GROUP`;

export const LOADING_BUTTON = `${prefix}/LOADING_BUTTON`;

export const LOAD_DTP_BY_TIME = `${prefix}/LOAD_DTP_BY_TIME`;
export const LOADING_DTP_BY_TIME = `${prefix}/LOADING_DTP_BY_TIME`;
export const LOADED_DTP_BY_TIME = `${prefix}/LOADED_DTP_BY_TIME`;
