import { useMemo, useState } from 'react';

import { fullDateTime } from 'helpers/date.config';
import messages from 'helpers/constants/messages';
import prepareCoords from 'helpers/prepareCoords';
import { ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';
import MediaModal from 'components/common/MediaModal';
import { buttonsTypes } from 'components/common/FormButtons';
import Preview from 'components/pages/Reports/DorisControl/ComplexEvents/Preview';

import MapModal from './MapModal';

const Item = ({ data }) => {
    const [openMapModal, setOpenMapModal] = useState(false);
    const [openMediaModal, setOpenMediaModal] = useState(false);

    const columns = useMemo(() => [
        ['created_at', 'complex_name', 'address_text', 'latlon', 'violation_type_text'],
        ['vehicle_brand', 'vehicle_model', 'vehicle_color', 'vehicle_speed', 'grz']
    ],[]);

    const labels = {
        created_at: {
            title: 'Дата и время фиксации',
            value: (value) => value && fullDateTime(value)
        },
        // cameraDirection: {
        //     title: 'Направление камеры'
        // },
        // vehicle_direction_start: {
        //     title: 'Направление движения ТС'
        // },
        complex_name: {
            title: 'Название КФВФ/камеры'
        },
        address_text: {
            title: 'Адрес'
        },
        latlon: {
            title: 'Координаты',
            value: () => (data?.lat && data?.lon) ? `${prepareCoords(data?.lat)} / ${prepareCoords(data?.lon)}` : messages.NO_DATA
        },
        vehicle_brand: {
            title: 'Марка ТС'
        },
        vehicle_model: {
            title: 'Модель ТС'
        },
        vehicle_color: {
            title: 'Цвет ТС'
        },
        vehicle_speed: {
            title: 'Скорость ТС',
            value: (value) => (value !== null && value !== undefined) ? `${value.toFixed(1)} км/ч` : null,
        },
        grz: {
            title: 'Номер ТС',
        },
        violation_type_text: {
            title: 'Тип событий',
            value: (value) => value?.length > 0 && value?.map(item => <span key={item}>{item} </span>),
        }
    };

    const renderItems = (column) => (
        column.map(key => {
            const { title, value } = labels[key];
            const field = data?.[key];

            return (
                <div key={key} className="row">
                    <strong>{title}:&nbsp;</strong>
                    <span>{(value && value(field)) || field || messages.NO_DATA}</span>
                </div>
            );
        })
    );

    const renderEventInfo = () => (
        <div className="row">
            {columns.map((column) => (
                <div key={column} style={{ width: '50%' }}>
                    {renderItems(column)}
                </div>
            ))}
        </div>
    );

    return <>
        <LSContentItem>
            <LSContentColumn>
                {renderEventInfo()}
            </LSContentColumn>
            <Preview data={data} onOpenMedia={() => setOpenMediaModal(true)}/>
            <ActionsButtons
                buttons={[
                    {
                        ...buttonsTypes.mapIcon,
                        onClick: () => setOpenMapModal(true),
                    },
                ]}
            />
        </LSContentItem>

        {openMapModal
            && <MapModal
                onClose={setOpenMapModal}
                isOpen={openMapModal}
                address={data.address_text}
                complexName={data.complex_name}
                lat={data.lat}
                lon={data.lon}
            />
        }
        {openMediaModal
           && <MediaModal
               data={data}
               isOpen={openMediaModal}
               onClose={() => setOpenMediaModal(false)}
               renderLabels={renderEventInfo}
           />
        }
    </>;
};

export default Item;
