import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import makeStyles from '@mui/styles/makeStyles';
import GetIcon from 'img/GetIcon';

import { loadCurrentVehicles } from 'redux/TransportPassenger/actions';
import { transportPassengerSelectors } from 'redux/TransportPassenger';
import transportTypes from 'helpers/transport.types';
import titles from 'helpers/constants/titles';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';

import RouteVehicleList from './RouteVehicleList';
import AssignVehicleModal from './AssignVehicleModal';

const useStyles = makeStyles({
    img: {
        padding: 7,
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
        width: 30,
        height: 30,
        borderRadius: '50%',
        marginRight: 15,
        '& > img': {
            marginRight: 0
        }
    },
    wrap: {
        '&:not(:last-child)': {
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        }
    },
    titleList: {
        cursor: 'pointer',
        '& > span': {
            display: 'flex',
            '& > *:not(:first-child):not(:last-child)': {
                maxWidth: 90,
                width: '100%'
            }
        }
    }
});

const RouteItem = ({ item }) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const currentVehiclesList = useSelector( transportPassengerSelectors.currentVehiclesList);

    const [open, setOpen] = useState(false);
    const [openAssignModal, setOpenAssignModal] = useState(false);

    const handleOpenAccordion = () => {
        if (!open) {
            dispatch(loadCurrentVehicles(item.num));
        }
        setOpen(!open);
    };

    const openModalWindow = () => {
        dispatch(loadCurrentVehicles(item.num));
        setOpenAssignModal(true);
    };

    const typeObj = transportTypes[item.type] ? transportTypes[item.type] : transportTypes.default;

    return (
        <div className={classes.wrap}>
            <ListItem className={classes.item}>
                {openAssignModal && <AssignVehicleModal
                    type={item.type}
                    onClose={() => setOpenAssignModal(false)}
                    routeNum={item.num}
                    isOpen={openAssignModal}
                    currentVehiclesList={currentVehiclesList[item.num]}
                />}
                <ListItemIcon>
                    <div className={classes.img} style={{ backgroundColor: typeObj?.color }}>
                        <GetIcon
                            iconName={typeObj.iconName}
                            format="svg"
                            category="transport"
                        />
                    </div>
                </ListItemIcon>
                <ListItemText className={classes.titleList} onClick={handleOpenAccordion}>
                    <strong>{titles.ROUTE}:</strong>
                    <strong>{titles.NUM}: {item.num}</strong>
                    <span>{typeObj?.name}</span>
                    <strong>{titles.ROUTE}: <span>{item.from} - {item.to}</span></strong>
                </ListItemText>
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.assignVehicle,
                            onClick: () => openModalWindow(item.type)
                        }
                    ]}
                />
                <IconButton size="small" onClick={handleOpenAccordion}>
                    {open ? <ExpandLess fontSize="inherit" /> : <ExpandMore fontSize="inherit" />}
                </IconButton>
            </ListItem>
            <RouteVehicleList
                isOpen={open}
                item={item}
                itemId={item.num}
                currentVehiclesList={currentVehiclesList[item.num]}
            />
        </div>
    );
};

export default RouteItem;
