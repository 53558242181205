import { useCallback, useEffect, useMemo, useState } from 'react';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import _ from 'lodash';

import { loadCameraViolationsTree } from 'redux/DorisControl/actions';
import { usePrevious, useStoreProp } from 'helpers/hooks';
import UniversalSelect from 'components/common/UniversalSelect';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';

const SelectPddViolation = ({
    multiple = false,
    selected = [],
    onChange = () => {},
    disabled = false,
    error = false,
    helperText = '',
    required,
    label='Тип событий',
    test_id_prefix = ''
}) => {
    const violationsTree = useStoreProp(loadCameraViolationsTree, 'dorisControl', 'violationsTree');
    // const loadingViolationsTree = useSelector(dorisControlSelectors.loadingViolationsTree);

    const [showList, setShowList] = useState(false);
    const [query, setQuery] = useState('');
    const [allCount, setAllCount] = useState(0);

    const [selectedItems, setSelected] = useState(selected ?? []);
    const prevSelected = usePrevious(selected);

    const selectedItemsMap = useMemo(() => selectedItems.reduce((res, el) => {
        res[el.id] = el;
        return res;
    },{}), [selectedItems]);

    useEffect(() => {
        // сброс фильтра - selected изменился нужно обновить selectedItems
        if (!_.isEqual(prevSelected, selected)) {
            setSelected(selected);
        }
    }, [selected, prevSelected]);

    const handleAccept = (data) => {
        onChange(data);
        setQuery('');
        setShowList(false);
    };

    const getPddViolations = (params) => {
        const { query } = params;
        setQuery(query);
    };
    
    const handleChange = (data) => {
        const getAllParts = (item) => item.parts ? item.parts.flatMap(getAllParts) : [item];

        const uniqueIds = {};
        const uniqueArr = getAllParts(data).filter(({ id }) => !uniqueIds[id] && (uniqueIds[id] = true));

        const existingItems = selectedItems.filter((item) =>
            uniqueArr.some((newItem) => newItem.id === item.id)
        );

        if (existingItems.length === uniqueArr.length) {
            const newItems = selectedItems.filter((item) => !uniqueArr.some((newItem) => newItem.id === item.id));
            setSelected(newItems);
        } else {
            const newItems = uniqueArr.filter((newItem) => !selectedItems.some((item) => item.id === newItem.id));
            setSelected([...selectedItems, ...newItems]);
        };
        
    };

    const renderName = (violation) => <Typography variant="body2">{violation}</Typography>;

    const handleRenderProps = ({ parts, violation }, isFirst = true) => {
        const partsChecked = parts && parts.every(el => selectedItemsMap[el.id]);
        const partsIndeterminate = parts && parts.some(el => selectedItemsMap[el.id]);

        return Array.isArray(parts)
            ?   <>
                {isFirst
                    ? renderName(violation)
                    : <FormControlLabel
                        sx={{ marginTop: '1rem' }}
                        label={renderName(violation)}
                        control={
                            <Checkbox 
                                indeterminate={!partsChecked && partsIndeterminate}
                                checked={partsChecked}
                                onChange={() => handleChange({ parts })}
                                data-testid={`${test_id_prefix}:parts/checkbox`}
                            />
                        }
                    />}
                
                {parts?.map((item) => (
                    <ul style={{ paddingInlineStart: 20, listStyle: 'none' }}>
                        {item?.parts?.length
                            ? handleRenderProps(item, false)
                            : <FormControlLabel
                                key={item.id}
                                sx={{ marginTop: '1rem' }}
                                label={renderName(item.violation)}
                                control={
                                    <Checkbox 
                                        checked={!!selectedItems?.find((el) => el.id === item.id)}
                                        onChange={() => handleChange(item)}
                                        data-testid={`${test_id_prefix}:part/checkbox`}
                                    />
                                }
                            />}
                    </ul>
                ))}
            </>
            : renderName(violation);
    };

    const onClose = () => {
        setSelected(selected);
        setShowList(false);
        setQuery('');
    };

    // фильтрованный список
    const data = useMemo(() => {
        if (query) {
            const queryStr = query.trim().toLowerCase();

            // рекурсивная функция фильтрации
            const findQuery = (arr) => arr
                .reduce((res, el) => {
                    if (Array.isArray(el.parts)) {
                        el.parts = findQuery(el.parts);
                    }
                    if (
                        el?.violation?.toLowerCase()?.includes(queryStr)
                        || el.parts?.length > 0
                    ) {
                        res.push(el);
                    }
                    return res;
                }, []);

            return findQuery(_.cloneDeep(violationsTree));
        }
        return violationsTree;
    }, [violationsTree, query]);

    const handleSelectAll = useCallback(() => {
        const convertTree = arr => arr
            .reduce((res, el) => {
                if (Array.isArray(el.parts)) {
                    return [
                        ...res,
                        ...convertTree(el.parts)
                    ];
                }
                return [
                    ...res,
                    el,
                ];
            }, []);

        const listAll = convertTree(violationsTree);

        if (selectedItems?.length === listAll.length) {
            setSelected([]);
        } else {
            setSelected(listAll);
        }
        setAllCount(listAll?.length);
    }, [violationsTree, selectedItems]);

    return (
        <>
            <CommonAutocomplete 
                multiple={multiple}
                selected={selected}
                onChange={onChange}
                error={error}
                helperText = {helperText}
                required={required}
                inputName="contract_id"
                disabled={disabled}
                onReset={() => onChange(multiple ? [] : {})}
                renderLabel={(option) => Object.keys(option).length > 0
                    ? `${option?.violation}`
                    : ''
                }
                limitTags={1}
                label={label}
                onShowList={() => setShowList(true)}
                isOpen={showList}
                test_id_prefix={test_id_prefix}
            />
            {showList
                && (
                    <UniversalSelect
                        storeName={'dorisControl'}
                        keyProp={'id'}
                        fetchList={getPddViolations}
                        withSearch={true}
                        multiple={multiple}
                        isSelected={true}
                        // selected={selectedElements}
                        selected={selectedItems}
                        searchTitle="Найти в тексте статьи"
                        renderProps={handleRenderProps}
                        isOpen={showList}
                        onClose={onClose}
                        onAccept={handleAccept}
                        noPadding
                        maxWidthProp={'md'}
                        onChange={handleChange}
                        arrayInItem={'parts'}
                        renderKey={(el) => `${el.id}-${el.violation}`}
                        title={label}
                        storeLoadingProps={'loadingViolationsTree'}
                        sortedList={data}
                        selectAll={multiple}
                        onSelectAll={handleSelectAll}
                        selectAllChecked={selectedItems?.length === allCount && allCount > 0}
                        test_id_prefix={test_id_prefix}
                    />
                )
            }
        </>
    );
};

export default SelectPddViolation;
