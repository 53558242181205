import { useState } from 'react';

import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import removeEmptyFields from 'helpers/removeEmptyFields';
import { uri } from 'modules/ExternalTransport/module-config';

import Form from './Form';

import type { FilterFields } from './types';

export interface Filter {
    data: FilterFields;
    onChange: (filter: FilterFields) => void;
}

const test_id_prefix=`${uri}/dictionaries/stations`;

const StationsFilter = ({ data, onChange }: Filter) => {

    const [filter, setFilter] = useState(data);

    const handleChange = (data: FilterFields) => {

        setFilter((prev) => removeEmptyFields({
            ...prev,
            ...data,
        }, false));
    };

    const resetFilter = (needRefresh: boolean) => {
        if (needRefresh) {
            onChange({});
        }

        setFilter({});
    };

    const search = (val = filter) => {
        onChange(val);
    };

    const updateFilter = (filters: FilterFields) => {
        setFilter({ ...filter, ...filters });

        if (Object.keys(filters).length > 0) {
            search({ ...filter, ...filters });
        }
    };

    return (
        <LayoutFilter
            onResetFilter={resetFilter}
            onSearch={search}
            filter={filter}
            setUserFilter={updateFilter}
            test_id_prefix={test_id_prefix}
        >
            <LayoutFilter.Visible>
                <Form data={filter} onChange={handleChange} test_id_prefix={`${test_id_prefix}:filter`} />
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
};

export default StationsFilter;
