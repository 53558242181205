const dates = {
    WORK_DAYS: 'Рабочие дни',
    WEEKEND: 'Выходные дни',
    START_PERIOD: 'Начало периода',
    END_PERIOD: 'Конец периода',
    DATE: 'Дата',
    TIME: 'Время',
    DATA_FROM: 'Данные от',
    DATE_OF_CREATION:'Дата создания',
    DATE_OF_REGISTRATION:'Дата регистрации',
    SELECT_YEAR: 'Выберите год',
    MINUTES: 'Минуты',
    SELECT_PERIOD: 'Выберите период',
    YEAR: 'Год',
    START_TIME: (
        <>
          Время<br />начала
        </>
    ),
    END_TIME: (
        <>
          Время<br />окончания
        </>
    ),
    DATE_PLAN_FROM: 'Плановая дата выполнения от',
    DATE_PLAN_TO: 'Плановая дата выполнения до',
    DATE_FACT_FROM: 'Фактическая дата выполнения от',
    DATE_FACT_TO: 'Фактическая дата выполнения до',
    DATE_TIME_CREATED: 'Дата, время создания',
    DATE_TIME_CONFIRMED: 'Дата, время подтверждения',
    END_ACCESS_DATE: 'Дата окончания доступа',
};

export default dates;
