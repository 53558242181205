import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// import titles from 'helpers/constants/titles';
import { loadCurrentCompany } from 'redux/Companies/actions';
import { loadRoadWorkOrders } from 'redux/RoadWorks/actions';
import { loadCategoriesVehicle, loadVehicle, setActive } from 'redux/TransportRoad/actions';
import * as actions from 'redux/TransportRoad/actions';
import { transportRoadSelectors } from 'redux/TransportRoad';
import { companiesSelectors } from 'redux/Companies';
import { fullDateTime } from 'helpers/date.config';
import SelectDateHistory from 'components/common/Transport/TransportHistory/SelectDateHistory';
import useTransportCategory from 'helpers/hooks/Transport/useTransportCategory';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';

import config from '../config';
import icon from '../icons/icon';

import TabInformation from './TabInformation';


const PopUp = (props) => {
    const dispatch = useDispatch();
    const { uuid, history, readOnly } = props;

    const currentCompany = useSelector(companiesSelectors.currentCompany);

    const wsData = useSelector(transportRoadSelectors.wsData);
    const active = useSelector(transportRoadSelectors.active);
    const vehicleActive = useSelector(transportRoadSelectors.vehicleActive);
    const [telemetryData] = useSelector(transportRoadSelectors.telemetryReceiversData);

    const data = wsData?.[uuid]?.vehicle || null;
    const telemetry_receiver = telemetryData?.name || '';

    const vehicleId = data?.id || null;

    const {
        external_id,
        in_registry = false,
        in_work_order = false,
        speed = 0,
        navigation_at = null,
    } = wsData[uuid] || {};

    const category_id = data?.category_id;

    const getTransportCategory = useTransportCategory(
        loadCategoriesVehicle,
        'transportRoad',
        'categoriesVehicle'
    );

    // const {
    //     getImageTpl,
    //     color: categoryColor,
    // } = transportCategoryHelper(getTransportCategory)
    //     .getCategory(category_id, in_registry, true);
    // const categoryName = transportCategoryHelper(getTransportCategory).getName(category_id);

    const {
        getImageTpl,
        color: categoryColor,
    } = getTransportCategory
        .getHelper
        .getCategory(category_id, in_registry, in_work_order);

    const categoryName = getTransportCategory
        .getHelper
        .getName(category_id);

    const dataInfo = {
        ...data,
        ...vehicleActive,
        external_id,
        speed,
        navigation_at,
        categoryTS: categoryName,
        telemetry_receiver
    };

    useEffect(() => {
        if(data?.egts_receiver_id) {
            dispatch(actions.loadTelemetryReceivers({ id: data.egts_receiver_id }));
        }
    }, []);

    const loadRWOrders = () => dispatch(loadRoadWorkOrders(1, 25, { grz_list: [dataInfo.number.trim()] }));

    useEffect(() => {
        if (dataInfo?.number) {
            loadRWOrders();
        }
    }, []);

    useEffect(() => {
        if (active) {
            dispatch(loadVehicle({ page: 1, limit: 10, serial_egts: uuid }));
        }
    }, [dispatch]);

    useEffect(() => {
        if (
            data
            && active?.serial_egts !== data?.serial_egts
            && active?.serial_yandex !== data?.serial_yandex
            && Object.keys(active).length === 0
            && !active?.findVehicle
        ) {
            dispatch(setActive({
                ...data,
                isClick: true
            }));
        }
    }, []);

    useEffect(() => {
        if (!currentCompany[dataInfo?.organization_id] && dataInfo?.organization_id) {
            dispatch(loadCurrentCompany(dataInfo.organization_id));
        }
    }, [dispatch, currentCompany, dataInfo?.organization_id]);

    // const currentShow = client_full === active?.characteristics?.serial_egts
    //     || client_full === active?.characteristics?.serial_yandex
    //     || null;

    const titleHistory = () => {
        const title = [];
        const categoryNameCurrent = categoryName || config.name;

        title.push(categoryNameCurrent);
        const garage_number = data?.garage_number || null;
        if (garage_number) {
            title.push(`номер ${garage_number}`);
        } else if (uuid) {
            title.push(uuid);
        }
        return title.join(', ');
    };


    return (
        <div className="map-popup">
            <div
                className="map-popup_header"
                style={{ backgroundColor: categoryColor }}
                // style={{
                //     backgroundColor: currentShow
                //         ? getColorByStatus('active')
                //         : categoryColor //getColorByStatus(null)
                // }}
            >
                <div>
                    <div className="img">
                        {getImageTpl('img', icon({ fill: '#000' }, in_registry))}
                    </div>

                    <span>{categoryName || config.name} ({external_id})</span>
                </div>
            </div>

            <div className="map-popup_body">
                {/*
                    // todo добавить
                    - номер наряда
                    - дата наряда
                    - статус наряда
                    - маршрут
                    - номер заявки
                    - название заявки.

                    Если статус наряда или заявки "Выполнен", то данные блока информации не отображаются.
                    Номер заявки и номер наряда кликабельны, при клике происходит переход в соответствующие заявку или наряд.
                */}

                <TabInformation
                    history={history}
                    data={dataInfo}
                    readOnly={readOnly}
                    reloadList={loadRWOrders}
                    title={{
                        external_id: {
                            title: 'Идентификатор'
                        },
                        number: {
                            title: 'Номер',
                        },
                        garage_number: {
                            title: 'Гаражный номер',
                            // value: (value) =>
                            //     value ? (
                            //         <span>{props.statuses[value]}</span>
                            //     ) : (
                            //         messages.INFO_IS_NOT_FOUND
                            //     ),
                        },
                        // name: {
                        //     title: 'Название',
                        // },
                        brand_name: {
                            title: 'Марка',
                        },
                        model_name: {
                            title: 'Модель',
                        },
                        categoryTS: {
                            title: titles.TYPE_OF_VEHICLE,
                        },
                        engine: {
                            title: 'Двигатель запущен'
                        },
                        fuel: {
                            title: 'Уровень топлива в баке'
                        },
                        organization_id: {
                            title: 'Организация',
                            value: ({ organization_id }) =>
                                organization_id
                                    ? (<span>{currentCompany[organization_id]?.title}</span>)
                                    : (messages.INFO_IS_NOT_FOUND),
                        },
                        telemetry_receiver: {
                            title: 'Приемник/организация телеметрии',
                        },
                        speed: {
                            title: 'Последняя скорость',
                            value: ({ speed, navigation_at }) => `${speed} км/ч ${navigation_at ? `(на ${fullDateTime(navigation_at)})` : ''}`
                        },
                    }}
                />
                {/*dd <div style={{
                        padding: '1rem',
                        fontSize: '16px',
                    }}>Данных не найдено</div>
                )*/}

                <SelectDateHistory
                    uuid={vehicleId}
                    title={titleHistory()}
                    actions={{
                        storeProp: 'transportRoad',
                        history: {
                            action: actions.loadVehicleHistoryModal,
                            clear: actions.clearVehicleHistoryModal,
                            loadingProp: 'historyModalLoading',
                            dataProp: 'historyModal',
                        },
                        speedList: {
                            action: actions.loadColorSpeed,
                            dataProp: 'colorSpeed',
                        }
                    }}
                />

            </div>
        </div>
    );
};

export default PopUp;
