import instance from '../../helpers/axios.config';
import { onlyDateYYYYMMDD } from '../../helpers/date.config';

import apiUrls from './api.urls';

const ApiMethods = {
    getCameras: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getCameras(), {
            params: { page, limit, ...data },
        });
        return response?.data;
    },
    getCameraTypes: async () => {
        const response = await instance.get(apiUrls.getCameraTypes());
        return response?.data;
    },
    editCamera: async (id, data) => {
        const response = await instance.put(apiUrls.editCamera(id), data);
        return response?.data;
    },
    createCamera: async (data) => {
        const response = await instance.post(apiUrls.createCamera(), data);
        return response?.data;
    },
    getCameraComplexTypes: async () => {
        const response = await instance.get(apiUrls.getCameraComplexTypes());
        return response?.data;
    },
    getCameraProtoText: async () => {
        const response = await instance.get(apiUrls.getCameraProtoText());
        return response?.data;
    },
    getCameraDirType: async () => {
        const response = await instance.get(apiUrls.getCameraDirType());
        return response?.data;
    },
    getCameraVideoType: async () => {
        const response = await instance.get(apiUrls.getCameraVideoType());
        return response?.data;
    },
    getCameraDataType: async () => {
        const response = await instance.get(apiUrls.getCameraDataType());
        return response?.data;
    },
    getEvents: async (config = {}) => {
        const response = await instance.get(apiUrls.getEvents(), config);
        return response?.data;
    },
    getCameraViolations: async () => {
        const response = await instance.get(apiUrls.getCameraViolations());
        return response?.data;
    },
    getEventsGroup: async (params) => {
        const response = await instance.get(apiUrls.getEventsGroup(), {
            params
        });
        return response.data;
    },
    getCameraReportList: async () => {
        const response = await instance.get(apiUrls.getCameraReportList());
        return response?.data;
    },
    getCameraReport: async (params = {}) => {
        const response = await instance.get(apiUrls.getCameraReportList(), {
            params,
            responseType: 'blob',
        });
        return response?.data;
    },
    getComplexes: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getComplexes(), {
            params: {
                ...(page ? { page } : {}), // на случай запроса без пагинации
                ...(limit ? { limit } : {}),
                ...data
            },
        });
        return response?.data;
    },
    createComplex: async (data) => {
        const response = await instance.post(apiUrls.createComplex(), data);
        return response?.data;
    },
    getComplexTypes: async () => {
        const response = await instance.get(apiUrls.getComplexTypes());
        return response?.data;
    },
    getComplexUnitTypes: async () => {
        const response = await instance.get(apiUrls.getComplexUnitTypes());
        return response?.data;
    },
    getComplexModelTypes: async () => {
        const response = await instance.get(apiUrls.getComplexModelTypes());
        return response?.data;
    },
    getComplexEntities: async (params) => {
        const response = await instance.get(apiUrls.getComplexEntities(), {
            params,
        });
        return response?.data;
    },
    getComplexEntitiesTypes: async () => {
        const response = await instance.get(apiUrls.getComplexEntitiesTypes());
        return response?.data;
    },
    getPddViolations: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getPddViolations(), {
            params: { page, limit, ...data }
        });
        return response?.data;
    },
    editPddViolation: async (id, data) => {
        const response = await instance.put(apiUrls.editPddViolation(id), data);
        return response?.data;
    },
    postComplexEntityData: async (data) => {
        const response = await instance.post(apiUrls.postComplexEntityData(), data);
        return response?.data;
    },
    editComplexEntityData: async (id, data) => {
        const response = await instance.put(apiUrls.editComplexEntityData(id), data);
        return response?.data;
    },
    deleteComplexEntityData: async (id, data) => {
        const response = await instance.delete(apiUrls.deleteComplexEntityData(id), { data });
        return response?.data;
    },
    getDocumentTypes: async () => {
        const response = await instance.get(apiUrls.getDocumentTypes());
        return response?.data;
    },
    getComplexStatuses: async () => {
        const response = await instance.get(apiUrls.getComplexStatuses());
        return response?.data;
    },
    getDocuments: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getDocuments(), {
            params: { page, limit, ...data },
        });
        return response?.data;
    },
    createDocumentComplex: async (data) => {
        const response = await instance.post(apiUrls.createDocumentComplex(), data);
        return response?.data;
    },
    deleteComplexDocument: async (id, data) => {
        const response = await instance.delete(apiUrls.deleteComplexDocument(id), { data });
        return response?.data;
    },
    editComplexDocument: async (id, data) => {
        const response = await instance.put(apiUrls.editComplexDocument(id), data);
        return response?.data;
    },
    deleteComplex: async (id, data) => {
        const response = await instance.delete(apiUrls.deleteComplex(id), { data });
        return response?.data;
    },
    getComplexHistory: async (page, limit, id) => {
        const response = await instance.get(apiUrls.getComplexHistory(id), {
            params: { page, limit },
        });
        return response?.data;
    },
    editComplex: async (id, data) => {
        const response = await instance.put(apiUrls.editComplex(id), data);
        return response?.data;
    },
    getForPolygon: async (params) => {
        const response = await instance.post(
            apiUrls.getPolygon(),
            {
                ...params,
                simple: 1
            },
            {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        );
        return response?.data?.data;
    },
    getComplexEvents: async (params) => {
        const response = await instance.post(apiUrls.getComplexEvents(), params);
        return response?.data;
    },
    getEventObject: async (uid, params) => {
        const response = await instance.post(apiUrls.getEventObject(uid), params);
        return response?.data;
    },
    getComplexEventArchive: async (id, params) => {
        const response = await instance.get(apiUrls.getComplexEventArchive(id), params);
        return response;
    },
    changeComplexStatus: async (id, data) => {
        const response = await instance.put(apiUrls.changeComplexStatus(id), data);
        return response?.data;
    },
    getSidebar: async (params) => {
        const response = await instance.get(apiUrls.getSidebar(), {
            params: {
                ...params,
                simple: 1
            },
        });
        return response?.data;
    },
    getComplexById: async (id) => {
        const response = await instance.get(apiUrls.getComplexById(id));
        return response?.data;
    },
    editEventErrors: async (id, data) => {
        const response = await instance.put(apiUrls.editEventErrors(id), data);
        return response?.data;
    },
    getEventErrors: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getEventErrors(), {
            params: { page, limit, ...data },
        });
        return response?.data;
    },
    getFormattedComplexHistory: async (page, limit, id) => {
        const response = await instance.get(apiUrls.getFormattedComplexHistory(id), {
            params: { page, limit }
        });
        return response?.data;
    },

    getComplexRules: async (id) => {
        const response = await instance.get(apiUrls.getComplexRules(id));
        return response?.data;
    },

    getCurrentComplexes: async (page, limit, data) => {
        const response = await instance.get(apiUrls.getComplexes(), {
            params: { page, limit, ...data },
        });
        return response?.data;
    },

    getComplexTransferTypes: async () => {
        const response = await instance.get(apiUrls.getComplexTransferTypes());
        return response?.data;
    },

    getCurrentComplexTransferTypes: async (data) => {
        const response = await instance.get(apiUrls.getComplexTransferTypes(), {
            params: { ...data }
        });
        return response?.data;
    },

    getComplexTransferRules: async (id) => {
        const response = await instance.get(apiUrls.getComplexTransferRules(id));
        return response?.data;
    },

    moveServiceEventsToMain: async (data) => {
        const response = await instance.post(apiUrls.moveServiceEventsToMain(), data);
        return response?.data;
    },

    getServiceEvents: async (data) => {
        const response = await instance.get(apiUrls.getServiceEvents(), {
            params: { ...data },
        });
        return response?.data;
    },

    getComplexStatistic: async (params) => {
        const response = await instance.get(apiUrls.getComplexStatistic(), { params });
        return response?.data;
    },

    deleteFromServiceEvents: async (data) => {
        const response = await instance.delete(apiUrls.deleteFromServiceEvents(), { data });
        return response?.data;
    },

    getMonthlyReport: async (data) => {
        const response = await instance.get(apiUrls.getMonthlyReport(), data);
        return response.data;
    },

    getMonthlyReportExpanded: async (data) => {
        const response = await instance.post(apiUrls.getMonthlyReportExpanded(), data);
        return response.data;
    },

    getMonthlyReportByComplex: async (data) => {
        const response = await instance.post(apiUrls.getMonthlyReportByComplex(), data);
        return response.data;
    },

    getReportByPeriod: async (data) => {
        const response = await instance.post(apiUrls.getReportByPeriod(), data);
        return response.data;
    },

    getComparativeStatistics: async (data) => {
        // преобразуем даты в нужный формат в одной точке, чтобы легче было менять при необходимости
        const result = Object.keys(data).reduce((res, key) => {
            const el = data[key];
            if (key !== 'complex_id') {
                return ({ ...res, [key]: onlyDateYYYYMMDD(el) });
            }
            return ({ ...res, [key]: el });
        }, {});
        const response = await instance.get(apiUrls.getComparativeStatistics(), {
            params: { ...result }
        });
        return response.data;
    },

    getCoordinationHistory: async (data) => {
        const response = await instance.get(apiUrls.getCoordinationHistory(), {
            params: { ...data }
        });
        return response.data;
    },

    getComplexReport: async (config) => {
        const response = await instance.get(apiUrls.getComplexReport(), config);
        return response?.data;
    },

    getInaccuracy: async () => {
        const response = await instance.get(apiUrls.getInaccuracy());
        return response?.data;
    },

    getDorisControlSettings: async (page, limit, query) => {
        const response = await instance.get(apiUrls.getDorisControlSettings(), {
            params: { page, limit, ...query }
        });
        return response?.data;
    },

    editDorisControlSetting: async (id, data) => {
        const response = await instance.put(apiUrls.editDorisControlSetting(id), data);
        return response?.data;
    },

    editSettingsList: async (data) => {
        const response = await instance.put(apiUrls.editSettingsList(), data);
        return response?.data;
    },

    deleteComplexEvents: async (data) => {
        const response = await instance.delete(apiUrls.deleteComplexEvents(), { data });
        return response?.data;
    },

    getIncorrectComplexReport: async (data) => {
        const response = await instance.get(apiUrls.getIncorrectComplexReport(), data);
        return response.data;
    },

    getTsCategories: async () => {
        const response = await instance.get(apiUrls.getTsCategories());
        return response.data;
    },

    getInspectionCertificate: async (config) => {
        const response = await instance.get(apiUrls.getInspectionCertificate(), config);
        return response.data;
    },

    getEventSendingRequest: async (id) => {
        const response = await instance.get(apiUrls.getEventSendingRequest(id));
        return response.data;
    },

    loadFile: async (url, config) => {
        const response = await instance.get(url, config);
        return response.data;
    },

    get1CNames: async (page, limit, query) => {
        const response = await instance.get(apiUrls.get1CNames(), {
            params: { page, limit, ...query }
        });
        return response.data;
    },

    create1CName: async ({ name }) => {
        const response = await instance.post(apiUrls.create1CName(), { name });
        return response.data;
    },

    edit1CName: async ({ data, id }) => {
        const response = await instance.put(apiUrls.edit1CName(id), data);
        return response.data;
    },

    delete1CName: async (id) => {
        const response = await instance.delete(apiUrls.delete1CName(id));
        return response.data;
    },

    get1CNamesReport: async (data) => {
        const response = await instance.get(apiUrls.get1CNamesReport(), data);
        return response.data;
    },

    getTagList: async (params) => {
        const response = await instance.get(apiUrls.getTagList(), { params });
        return response.data;
    },

    createTag: async (data) => {
        const response = await instance.post(apiUrls.createTag(), data);
        return response.data;
    },

    editTag: async ({ id, name }) => {
        const response = await instance.put(apiUrls.editTag(id), { name });
        return response.data;
    },

    deleteTag: async (id) => {
        const response = await instance.delete(apiUrls.deleteTag(id));
        return response.data;
    },

    complexSyncTags: async (params) => {
        const response = await instance.post(apiUrls.complexSyncTags(), params);
        return response.data;
    },

    getExclusiveFiles: async (params) => {
        const response = await instance.get(apiUrls.getExclusiveFiles(), { params });
        return response?.data;
    },

    getExclusiveFilesHistoryList: async (params) => {
        const response = await instance.get(apiUrls.getExclusiveFilesHistoryList(), { params });
        return response?.data;
    },

    getExclusiveFilesLogList: async (params) => {
        const response = await instance.get(apiUrls.getExclusiveFilesLogList(), { params });
        return response?.data;
    },

    createExclusiveFile: async (data) => {
        const formData = new FormData();
        formData.append('file', data.file);
        data.pdd_ids.forEach( id => {
            formData.append('pdd_ids[]', id);
        });

        const response = await instance.post(apiUrls.createExclusiveFile(),
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
        return response?.data;
    },

    editExclusiveFile: async ({ id, data }) => {
        const formData = new FormData();
        data?.file && formData.append('file', data.file);
        data.pdd_ids.forEach( id => {
            formData.append('pdd_ids[]', id);
        });

        const response = await instance.post(apiUrls.editExclusiveFile(id),
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
        return response?.data;
    },

    deleteExclusiveFile: async (id) => {
        const response = await instance.delete(apiUrls.deleteExclusiveFile(id));
        return response?.data;
    },

    getExclusiveFileHistory: async (page, limit, id) => {
        const response = await instance.get(apiUrls.getExclusiveFileHistory(id), {
            params: { page, limit }
        });
        return response?.data;
    },

    getComplexTypeGrouping: async () => {
        const response = await instance.get(apiUrls.getComplexTypeGrouping());
        return response?.data;
    },

    getComplexMeasuring: async () => {
        const response = await instance.get(apiUrls.getComplexMeasuring());
        return response?.data;
    },

    getRecognitionCameraDirTypes: async () => {
        const response = await instance.get(apiUrls.getRecognitionCameraDirTypes());
        return response?.data;
    },

    createComplexEntity: async (url, data) => {
        const response = await instance.post(apiUrls.createComplexEntity(url), data);
        return response.data;
    },

    editComplexEntity: async (url, data) => {
        const response = await instance.put(apiUrls.editComplexEntity(url), data);
        return response.data;
    },

    deleteComplexEntity: async (url, data) => {
        const response = await instance.delete(apiUrls.deleteComplexEntity(url), { data });
        return response.data;
    },

    getComputerTransferTypes: async (params) => {
        const response = await instance.get(apiUrls.getComputerTransferTypes(), {
            params
        });
        return response?.data;
    },

    getComputerTransferRules: async (id) => {
        const response = await instance.get(apiUrls.getComputerTransferRules(id));
        return response?.data;
    },

    getDorisExportTypes: async () => {
        const response = await instance.get(apiUrls.getDorisExportTypes());
        return response?.data;
    },

    getViolationEvents: async (params) => {
        const response = await instance.get(apiUrls.getViolationEvents(), { params });
        return response?.data;
    },

    getChecklists: async (page, limit, params) => {
        const response = await instance.get(apiUrls.getChecklists(),{
            params: { page, limit, ...params }
        });
        return response?.data;
    },

    createChecklist: async (params) => {
        const response = await instance.post(apiUrls.createChecklist(), params);
        return response?.data;
    },

    editChecklist: async (id, params) => {
        const response = await instance.put(apiUrls.editChecklist(id), params);
        return response?.data;
    },

    deleteChecklist: async (id) => {
        const response = await instance.delete(apiUrls.deleteChecklist(id));
        return response?.data;
    },

    getMaterialStorageGroups: async (params) => {
        const response = await instance.get(apiUrls.getMaterialStorageGroups(), { params });
        return response?.data;
    },

    createMaterialStorageGroup: async (data) => {
        const response = await instance.post(apiUrls.createMaterialStorageGroup(), data);
        return response?.data;
    },

    editMaterialStorageGroup: async (id, data) => {
        const response = await instance.put(apiUrls.editMaterialStorageGroup(id), data);
        return response?.data;
    },

    deleteMaterialStorageGroup: async (id) => {
        const response = await instance.delete(apiUrls.deleteMaterialStorageGroup(id));
        return response?.data;
    },

    getComplexesTree: async (params) => {
        const response = await instance.get(apiUrls.getComplexesTree(), {
            params,
        });
        return response?.data;
    },

    getEventCheckList: async (base_name, params) => {
        const response = await instance.get(apiUrls.getEventCheckList(base_name), { params } );
        return response?.data;
    },

    getEventPDDS: async (data) => {
        const response = await instance.post(apiUrls.getEventPDDS(), data);
        return response?.data;
    },

    saveEventCheckList: async (data) => {
        const response = await instance.post(apiUrls.saveEventCheckList(), data);
        return response?.data;
    },

    getPddViolationsTree: async (data) => {
        const response = await instance.get(apiUrls.getPddViolations(), {
            params: data
        });
        return response?.data;
    },

    checkFgis: async (data) => {
        const response = await instance.post(apiUrls.checkFgis(), data);
        return response?.data;
    },

    getDocumentsHistory: async (id, page, limit) => {
        const response = await instance.get(apiUrls.getDocumentsHistory(id), {
            params: { page, limit }
        });
        return response?.data;
    },

    getCheckListGroup: async (page, limit, params) => {
        const response = await instance.get(apiUrls.getCheckListGroup(),{
            params: { page, limit, ...params }
        });
        return response?.data;
    },

    createCheckListGroup: async (params) => {
        const response = await instance.post(apiUrls.createCheckListGroup(), params);
        return response?.data;
    },

    editCheckListGroup: async (id, params) => {
        const response = await instance.put(apiUrls.editCheckListGroup(id), params);
        return response?.data;
    },

    deleteCheckListGroup: async (id) => {
        const response = await instance.delete(apiUrls.deleteCheckListGroup(id));
        return response?.data;
    },

    getEventsStatistic: async () => {
        const response = await instance.post(apiUrls.getEventsStatistic());
        return response?.data;
    },

    loadComplexByRadius: async (params) => {
        const response = await instance.post(apiUrls.loadComplexByRadius(), params);
        return response?.data;
    },

    getCameraViolationsTree: async () => {
        const response = await instance.get(apiUrls.getCameraViolationsTree(), { params: { is_tree: 1 } });
        return response?.data;
    },

    getPddReportExpanded: async (data) => {
        const response = await instance.get(apiUrls.getPddReportExpanded(), data);
        return response.data;
    },

    getMaterialExportGroup: async (page, limit, params) => {
        const response = await instance.get(apiUrls.getMaterialExportGroup(),{
            params: { page, limit, ...params }
        });
        return response?.data;
    },

    createMaterialExportGroup: async (params) => {
        const response = await instance.post(apiUrls.createMaterialExportGroup(), params);
        return response?.data;
    },

    editMaterialExportGroup: async (id, params) => {
        const response = await instance.put(apiUrls.editMaterialExportGroup(id), params);
        return response?.data;
    },

    deleteMaterialExportGroup: async (id) => {
        const response = await instance.delete(apiUrls.deleteMaterialExportGroup(id));
        return response?.data;
    },

    getMaterialExportGroupTypes: async (params) => {
        const response = await instance.get(apiUrls.getMaterialExportGroupTypes(), params);
        return response?.data;
    },

    getMaterialExportGroupProtocols: async (params) => {
        const response = await instance.get(apiUrls.getMaterialExportGroupProtocols(), params);
        return response?.data;
    },

    getTypeOfMistakesForCheckList: async (data) => {
        const response = await instance.get(apiUrls.getTypeOfMistakesForCheckList(), {
            params: data
        });
        return response.data;
    },

    createTypeOfMistakesForCheckList: async (data) => {
        const response = await instance.post(apiUrls.createTypeOfMistakesForCheckList(), data);
        return response.data;
    },

    editTypeOfMistakesForCheckList: async (id, data) => {
        const response = await instance.put(apiUrls.editTypeOfMistakesForCheckList(id), data);
        return response.data;
    },

    deleteTypeOfMistakesForCheckList: async (id) => {
        const response = await instance.delete(apiUrls.deleteTypeOfMistakesForCheckList(id));
        return response.data;
    },

    getRecentPddReportExpanded: async (data) => {
        const response = await instance.get(apiUrls.getRecentPddReportExpanded(), data);
        return response.data;
    },

    getSettingsGroup: async () => {
        const response = await instance.get(apiUrls.getSettingsGroup());
        return response?.data;
    },

    editSettingsGroup: async (id, data) => {
        const response = await instance.put(apiUrls.editSettingsGroup(id), data);
        return response?.data;
    },

    getShowCaseComplexStatus: async () => {
        const response = await instance.get(apiUrls.getShowCaseComplexStatus());
        return response?.data;
    },

    getShowCaseDocumentsVerification: async () => {
        const response = await instance.get(apiUrls.getShowCaseDocumentsVerification());
        return response?.data;
    },

    getDuploCodes: async (params) => {
        const response = await instance.get(apiUrls.getDuploCodes(), params);
        return response?.data;
    },

    editDuploCodes: async (id, data) => {
        const response = await instance.put(apiUrls.editDuploCodes(id), data);
        return response?.data;
    },

    getMaterials: async (params) => {
        const response = await instance.get(apiUrls.getMaterials(), { params });
        return response.data;
    },

    createMaterial: async (data) => {
        const response = await instance.post(apiUrls.createMaterial(), data);
        return response.data;
    },

    editMaterial: async (id, data) => {
        const response = await instance.put(apiUrls.editMaterial(id), data);
        return response.data;
    },

    deleteMaterial: async (id) => {
        const response = await instance.delete(apiUrls.deleteMaterial(id));
        return response.data;
    },

    moveComputerToOtherComplex: async (id, data) => {
        const response = await instance.post(apiUrls.moveComputerToOtherComplex(id), data);
        return response.data;
    },

    getMaterialExport: async (params) => {
        const response = await instance.get(apiUrls.getAndPostMaterialExport(), { params });
        return response?.data;
    },

    createMaterialExport: async (data) => {
        const response = await instance.post(apiUrls.getAndPostMaterialExport(), data);
        return response?.data;
    },

    editMaterialExport: async (id, data) => {
        const response = await instance.put(apiUrls.putAndDeleteMaterialExport(id), data);
        return response?.data;
    },

    deleteMaterialExport: async (id) => {
        const response = await instance.delete(apiUrls.putAndDeleteMaterialExport(id));
        return response?.data;
    },

    getMaterialExportTypes: async () => {
        const response = await instance.get(apiUrls.getMaterialExportTypes());
        return response?.data;
    },

    getMaterialExportProtocols: async () => {
        const response = await instance.get(apiUrls.getMaterialExportProtocols());
        return response?.data;
    },

    loadComplexConditions: async () => {
        const response = await instance.get(apiUrls.getComplexConditions());
        return response.data;
    },

    getMaterialExportStatuses: async () => {
        const response = await instance.get(apiUrls.getMaterialExportStatuses());
        return response?.data;
    },

    cancelMaterialExport: async (id) => {
        const response = await instance.put(apiUrls.cancelMaterialExport(id));
        return response?.data;
    },

    getGRZColors: async () => {
        const response = await instance.get(apiUrls.getGRZColors());
        return response?.data;
    },

    getGRZCountries: async (page, limit, data) => {
        const response = await instance.post(apiUrls.getGRZCountries(), { page, limit, ...data });
        return response?.data;
    },
};

export default ApiMethods;
