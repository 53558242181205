import React from 'react';
import {
    ListItem,
    ListItemText,
} from '@mui/material';
import cn from 'classnames';

import styles from '../workOrderControl.module.scss';

const TableHeader = ({ isStations }) => {
    return (
        <>
            <ListItem divider dense>
                <ListItemText className={cn(styles.info_block, styles.info_block__header)} disableTypography>
                    <div className={cn(styles.info_block__item, styles.info_block__item_first)}>№</div>
                    <div className={styles.info_block__item}>
                        {isStations
                            ? <>Остановки или контрольные точки</>
                            : <>Контрольные точки по маршруту движения ТС</>
                        }
                    </div>
                    <div className={styles.info_block__item}>
                      Контрольные события по журналу прохождения КТ
                    </div>
                    <div className={styles.info_block__item}>Статус по журналу прохождения КТ</div>
                    <div className={styles.info_block__item}>Ручной статус от диспетчера</div>
                    <div className={styles.info_block__item}>Время прибытия плановое по наряду</div>
                    <div className={styles.info_block__item}>Время прибытия фактическое по наряду</div>
                    <div className={styles.info_block__item}>Время отправления плановое по наряду</div>
                    <div className={styles.info_block__item}>Время отправления фактическое по наряду</div>
                    <div style={{ width: '46px' }}></div>
                </ListItemText>
            </ListItem>
        </>
    );
};

export default TableHeader;
