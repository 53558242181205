import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List } from '@mui/material';

import { loadVehicleDowntime } from 'redux/TransportRoad/actions';
import { transportRoadSelectors } from 'redux/TransportRoad';
import CustomPagination from 'components/common/CustomPagination';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Loading from 'components/common/Loading';
import Modal from 'components/common/Modal';

import Item from './DowntimeInfoModalItem';

const DowntimeInfoModal = ({ isOpen, onClose, item, prepareData }) => {
    const dispatch = useDispatch();
    const vehicleDowntimeData = useSelector(transportRoadSelectors.vehicleDowntimeData);
    const vehicleDowntimeMeta = useSelector(transportRoadSelectors.vehicleDowntimeMeta);
    const vehicleLoading = useSelector(transportRoadSelectors.vehicleLoading);

    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 10);
    const titleArray = [`ГРЗ ${item?.number}`];
    if (item?.serial_egts) titleArray.push(`EGTS ${item?.serial_egts}`);
    if (item?.serial_yandex) titleArray.push(`Яндекс-Транспорт ${item?.serial_yandex}`);
    const title = vehicleDowntimeData.length === 0
        ? 'Данные не найдены'
        : `Возможные простои для ТС - ${titleArray.join(', ')}`;

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={title}
            buttons={
                <FormButtons
                    buttons={[
                        {
                            ...buttonsTypes.close,
                            onClick: onClose
                        }
                    ]}
                />
            }
        >
            {vehicleDowntimeData.length > 0 && (
                <>
                    {vehicleLoading && <Loading circular/>}
                    <List className="list">
                        {vehicleDowntimeData.map((el, index) => (
                            <Item el={el} key={index} item={item} />
                        ))}
                    </List>
                    <CustomPagination
                        loadList={(page, limit) => dispatch(loadVehicleDowntime(item?.id, { ...prepareData, page, limit } ))}
                        list={vehicleDowntimeMeta}
                        limit={limit}
                        setLimit={setLimit}
                    />
                </>
            )}
        </Modal>
    );
};

export default DowntimeInfoModal;
