import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { trafficFlowSelectors } from 'redux/TrafficFlow';
import { loadReport } from 'redux/TrafficFlow/actions';
import ReturnToButton from 'components/common/Buttons/ReturnToButton';
import PageLayout from 'components/layout/PageLayout';
import NoData from 'components/common/Information/NoData';
import { HeaderPageVisible } from 'components/common/Showcase/HeaderPage';

import { paramsWithoutEmpty } from '../../Puid/TransportFlow/CommonFilter/helpers';

import Filters from './Filters';
import Page from './Page';
import { initialState } from './helpers';

function PUIDReport() {
    const dispatch = useDispatch();

    const report = useSelector(trafficFlowSelectors.report);
    const loadingReport = useSelector(trafficFlowSelectors.loadingReport);

    const [params, setParams] = useState(initialState);

    useEffect(() => {
        dispatch(loadReport(paramsWithoutEmpty(params)));
    }, [dispatch, params]);

    return (
        <>
            <HeaderPageVisible/>
            
            <PageLayout
                additionalButtons={<ReturnToButton/>}
                header="Статистика по ПУИД"
                filters={<Filters setParams={setParams} />}
                loading={loadingReport}
                content={() => (
                    <>
                        {report?.length > 0
                            ? <Page data={report} filter={params} />
                            : !loadingReport && <NoData />
                        }
                    </>
                )}
            />
        </>
    );
}

export default PUIDReport;
