
import { config } from 'config';

const externalTransportUrl = () => config.get('urls').externalTransportUrl;

const apiUrls = {
    loadStationStatuses: () => `${externalTransportUrl()}/station/statuses`,

    loadStationList: () => `${externalTransportUrl()}/station`,

    loadStationPolygon: () => `${externalTransportUrl()}/station/polygon`,

    loadStationSchedules: (id) => `${externalTransportUrl()}/station/${id}/schedules`,

    loadTransportTypes: () => `${externalTransportUrl()}/station/transport_types`,
};

export default apiUrls;
