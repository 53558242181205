import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ListAltIcon from '@mui/icons-material/ListAlt';
import TableViewIcon from '@mui/icons-material/TableView';

import { deleteWorkorder, editWorkorder, editWorkorderStatus } from 'redux/TransportPassenger/actions';
import { transportPassengerSelectors } from 'redux/TransportPassenger';
import messages from 'helpers/constants/messages';
import { getHumanDate } from 'helpers/date.config';
import colorStatus from 'helpers/workorder.config';
import parseByLink from 'helpers/parseByLink';
import ConfirmDelete from 'components/common/ConfirmModal';
import { ActionsButtons, LSContentColumn, LSContentItem } from 'components/common/List';
import { buttonsTypes } from 'components/common/FormButtons';

import AddEditRequisites from './AddEditRequisites/AddEditRequisites';
import TrafficScheduleModal from './TrafficScheduleModal';
import AddEditModal from './AddEditModal';

const ItemsList = ({ loadList, data = {}, reloadList, test_id_prefix }) => {
    const dispatch = useDispatch();

    const loadingButton = useSelector(transportPassengerSelectors.loadingButton);

    const [openEdit, setOpenEdit] = useState(false);
    const [editRequisites, setEditRequisites] = useState(false);
    const [confirmSelected, setConfirmSelected] = useState(false);
    const [openTrafficSchedule, setOpenTrafficSchedule] = useState(false);
    const [editStatus, setEditStatus] = useState(false);

    const deleteItem = () => {
        dispatch(deleteWorkorder(data.id, reloadList));
        setConfirmSelected(false);
    };

    const onSave = (id, data) => {
        const closeModal = () => {
            setOpenEdit(false);
            loadList();
        };

        dispatch(editWorkorder(id, data, closeModal));
    };

    const onEditStatus = () => {
        const callback = () => {setEditStatus(false); reloadList();};
        dispatch(editWorkorderStatus(data.id, { status: 5 }, callback));
    };

    const noDataMessage = <span style={{ color: 'red' }}>{messages.NOT_CHOSEN}</span>;

    return (
        <>
            <LSContentItem>
                <LSContentColumn title={'Дата'}>
                    {data?.date_at ? getHumanDate(data?.date_at) : noDataMessage}
                </LSContentColumn>
                <LSContentColumn title={'ID'}>
                    {data?.id || noDataMessage}
                </LSContentColumn>
                <LSContentColumn
                    title={'Статус'}
                    align={'center'}
                    style={{ color: colorStatus(data.status) }}
                >
                    {data.status_text || noDataMessage}
                </LSContentColumn>
                <LSContentColumn title={'Организация'}>
                    {parseByLink(data)('organization.title', noDataMessage)}
                </LSContentColumn>
                <LSContentColumn title={'Маршрут'}>
                    {parseByLink(data)('traffic_schedule.route_direct.num', noDataMessage)}
                </LSContentColumn>
                <LSContentColumn title={'Наименование'}>
                    {parseByLink(data)('traffic_schedule.route_direct.name', noDataMessage)}
                </LSContentColumn>
                <LSContentColumn title={'№ графика'}>
                    {parseByLink(data)('traffic_schedule.diagram', noDataMessage)}
                </LSContentColumn>
                <LSContentColumn title={'Гос.номер'}>
                    {parseByLink(data)('vehicle.number', noDataMessage)}
                </LSContentColumn>
                <LSContentColumn title={'Начало'}>
                    {data?.traffic_schedule?.start_at
                        ? getHumanDate(data?.traffic_schedule?.start_at)
                        : noDataMessage
                    }
                </LSContentColumn>
                <LSContentColumn title={'Окончание'}>
                    {data?.traffic_schedule?.end_at
                        ? getHumanDate(data?.traffic_schedule?.end_at)
                        : noDataMessage
                    }
                </LSContentColumn>

                <LSContentColumn isActions>
                    <ActionsButtons
                        test_id_prefix={test_id_prefix}
                        buttons={[
                            {
                                icon: <TableViewIcon />,
                                name: 'Просмотр графика',
                                onClick: () => setOpenTrafficSchedule(true),
                                testName: 'show_schedule'
                            },
                            {
                                icon: <ListAltIcon/>,
                                name: 'Реквизиты заказа-наряда',
                                onClick: () => setEditRequisites(true),
                                testName: 'show_details'
                            },
                            {
                                ...buttonsTypes.editIcon,
                                onClick: () => setOpenEdit(true),
                                testName: 'edit'
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: () => setConfirmSelected(true),
                                testName: 'delete'
                            },
                            {
                                ...buttonsTypes.changeStatusIcon,
                                name: 'Изменить статус',
                                disabled: data.status !== 1,
                                onClick: () => setEditStatus(true),
                                testName: 'change_status'
                            }
                        ]}
                    />
                </LSContentColumn>
            </LSContentItem>
            {openTrafficSchedule
                && <TrafficScheduleModal
                    id={data?.traffic_schedule_id}
                    isOpen={openTrafficSchedule}
                    onClose={() => setOpenTrafficSchedule(false)}
                />
            }
            {openEdit
                && <AddEditModal
                    isNew={false}
                    data={data}
                    isOpen={openEdit}
                    onClose={() => setOpenEdit(false)}
                    onSave={(item) => onSave(data.id, item)}
                    test_id_prefix={test_id_prefix}
                />
            }
            {editRequisites
                && <AddEditRequisites
                    data={data}
                    isOpen={editRequisites}
                    onClose={() => setEditRequisites(false)}
                    test_id_prefix={test_id_prefix}
                />
            }
            {confirmSelected
                && <ConfirmDelete
                    onClose={() => setConfirmSelected(false)}
                    onSuccess={deleteItem}
                    open={confirmSelected}
                />
            }
            {editStatus &&  (
                <ConfirmDelete
                    message='Вы точно хотите изменить текущий статус на "Отменен"'
                    open={editStatus}
                    onSuccess={onEditStatus}
                    onClose={() => setEditStatus(false)}
                    loadingButton={loadingButton}
                />
            )}
        </>
    );
};

export default ItemsList;
