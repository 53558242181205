import { useSelector } from 'react-redux';
import { useMemo, } from 'react';
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import { LocalizationProvider } from '@mui/lab';

import { externalTransportSelectors } from 'modules/ExternalTransport';
import icon from 'modules/ExternalTransport/utils/icons/icon';
import { iconByType } from 'modules/ExternalTransport/utils/helper';

import Content from './Content';

const PopUp = ({ id = 0, transportType }) => {
    const stationPolygon = useSelector(externalTransportSelectors.stationPolygon);
    const isAirport = (transportType === 2 ) ? true : false;

    const data = useMemo(
        () => stationPolygon?.find((item) => item.id === id) || {},
        [id, stationPolygon]
    );
    return (
        <LocalizationProvider dateAdapter={AdapterLuxon} locale={'ru'}>
            <div className="map-popup">
                <div className="map-popup_header">
                    <div>
                        <i className={iconByType[data.transport_type]} style={{ fontSize: '1rem' }} />
                        {/* <div
                            className="img"
                            dangerouslySetInnerHTML={{ __html: icon({ fill: '#fff' },  data.transport_type === 2) }}
                        /> */}
                        {data?.name && <span>{data?.name}</span>}
                    </div>
                </div>
                <div className="map-popup_body">
                    <Content data={data} isAirport={isAirport} />
                </div>
            </div>
        </LocalizationProvider>
    );
};

export default PopUp;
