import * as types from './types';

const initialState = {
    loading: false,
    station_statuses: {},

    stationList: {
        data: [],
        meta: { last_page: 0 },
    },

    stationSidebar: {
        data: [],
        meta: { last_page: 0 },
    },
    loadingStationSidebar: false,

    station_polygon: [],
    station_schedules: [],
    loading_station_schedules: false,
    active: {},
    filters: {},
    transport_types: []
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {

        case types.LOADED_STATION_STATUSES: {
            return {
                ...state,
                station_statuses: payload,
            };
        }
        case types.LOADED_STATION_LIST: {
            return {
                ...state,
                stationList: payload,
            };
        }
        case types.LOADING: {
            return {
                ...state,
                loading: payload,
            };
        }
        case types.LOADED_STATION_POLIGON: {
            return {
                ...state,
                station_polygon: payload,
            };
        }
        case types.LOADED_STATION_SCHEDULES: {
            return {
                ...state,
                station_schedules: payload,
            };
        }
        case types.LOADING_STATION_SCHEDULES: {
            return {
                ...state,
                loading_station_schedules: payload,
            };
        }
        case types.SET_ACTIVE: {
            return {
                ...state,
                active: payload,
            };
        }
        case types.SET_FILTERS: {
            return {
                ...state,
                filters: payload,
            };
        }
        case types.LOADED_TRANSPORT_TYPES: {
            return {
                ...state,
                transport_types: payload,
            };
        }
        case types.LOADED_STATION_SIDEBAR: {
            const { data, isReplace } = payload;

            if (isReplace) {
                return {
                    ...state,
                    stationSidebar: data,
                };
            }

            return {
                ...state,
                stationSidebar: {
                    ...state.stationSidebar,
                    ...data,
                    data: [
                        ...state.stationSidebar.data,
                        ...data.data,
                    ],
                },
            };
        }
        case types.LOADING_STATION_SIDEBAR: {
            return {
                ...state,
                loadingStationSidebar: payload,
            };
        }
        case types.CLEAR_STATION_SIDEBAR: {
            return {
                ...state,
                stationSidebar: initialState.sidebar,
            };
        }

        default:
            return state;
    }
}
