import { loadEquipmentsList } from 'redux/TransportPassenger/actions';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import { useStoreProp } from 'helpers/hooks';
import { stringifyRenderData } from 'helpers/stringifyRenderData';
import useCompany from 'helpers/hooks/useCompany';

// инфо для пассажирки!
export const VehicleInfo = ({ info, storeName = 'transportPassenger', loadVehicleCategories, hideCharacteristics = false, vehiclesTypes }) => {
    const vehiclesCategories = useStoreProp(loadVehicleCategories, storeName, 'vehicleCategories');
    const characteristics = useStoreProp(loadEquipmentsList, 'transportPassenger', 'equipments');
    const company = useCompany(info.organization_id, info.organization);

    const category = vehiclesCategories.find(el => el.id === info.category_id);

    return (
        <div>
            <b>{titles.GRZ}:</b> {info.number}
            <div><b>{titles.GARAGE_NUM}:</b> {info.garage_number}</div>
            <div><b>{titles.TYPE_OF_VEHICLE}:</b> {category?.name || messages.INFO_IS_NOT_FOUND}</div>
            <div><b>{titles.EGTS_SERIAL}:</b> {info?.serial_egts || messages.INFO_IS_NOT_FOUND}</div>
            <div><b>{titles.YANDEX_SERIAL}:</b> {info?.serial_yandex || messages.INFO_IS_NOT_FOUND}</div>
            {vehiclesTypes && <><b>{titles.TYPE}:</b> {vehiclesTypes[info?.type] || messages.INFO_IS_NOT_FOUND}</>}
            {!hideCharacteristics 
                && <div>
                    <b>{titles.OPTIONAL_EQUIPMENT}:</b>
                    {Object.keys(info.characteristics)?.length > 0
                        ? (Object.keys(info.characteristics).map((key, index) => {
                            if (key === 'serial_egts' || key === 'serial_yandex') return null;
                            const equipments = (
                                typeof info.characteristics[key] == 'boolean' 
                                    || key === 'is_invalid'
                                    || key === 'bicycle_racks'
                            )
                                ? +info.characteristics[key] ? 'Да' : 'Нет' || messages.INFO_IS_NOT_FOUND
                                : info.characteristics[key] || messages.INFO_IS_NOT_FOUND;
                            return <div key={index}>{characteristics[key]}: {equipments}</div>;
                        }))
                        : <div>{messages.INFO_IS_NOT_FOUND}</div>
                    }
                </div>
            }
            

            {company
                && <div>
                    <b>Организация:</b>
                    <div className="info" style={{ paddingLeft: 10 }}>
                        <p><b>{company.title}</b></p>
                        <p><b>{titles.INN}:</b> {company?.inn ?? messages.INFO_IS_NOT_FOUND}</p>
                        <p>
                            <b>{titles.PHONES}:</b> {stringifyRenderData(company?.phones)}
                        </p>
                        <p>
                            <b>{titles.EMAIL}:</b> {stringifyRenderData(company?.persons)}
                        </p>
                    </div>
                </div>
            }
        </div>
    );
};
