import { Collapse } from '@mui/material';

import { loadVehicleCategories } from 'redux/TransportRoad/actions';
import { VehicleInfo } from 'components/common/Transport/VehicleInfo';

const Info = ({ info, isOpen }) => {
    return (
        <Collapse in={isOpen} timeout={'auto'} unmountOnExit className="info">
            <VehicleInfo
                info={info}
                loadVehicleCategories={loadVehicleCategories}
                storeName={'transportRoad'}
                hideCharacteristics
            />
        </Collapse>
    );
};

export default Info;
