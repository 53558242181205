import { useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import Loading from 'components/common/Loading';

const PopupTabs = ({ tabList, loading, date }) => {
    const [tabIndex, setTabIndex] = useState(0);

    return (<div style={{ textAlign: 'center' }}>
        {loading
            ? <Loading />
            : <Tabs
                selectedIndex={tabIndex}
                onSelect={(selectTabIndex) => {
                    setTabIndex(selectTabIndex);
                }}
            >
                <TabList style={{ display: 'flex' }}>
                    {tabList.map(({ value, label }, index) => (
                        <Tab
                            key={`tabtitle_${value}`}
                            style={{ width: `100/${tabList.length}%`, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        >
                            <b style={{ color: '#55818e', fontSize: '0.9rem' }}>{label}</b>
                        </Tab>
                    ))}
                </TabList>
                {tabList.map(({ contentComponent, value }, index) => (
                    <TabPanel key={value}>
                        <h3>{date}</h3>
                        {tabIndex === index && (
                            <div className="custom-popup-body">{contentComponent}</div>
                        )}
                    </TabPanel>
                ))}
            </Tabs>
        }
    </div>
    );
};

export default PopupTabs;
