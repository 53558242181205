import { Collapse, List, ListItem, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import messages from 'helpers/constants/messages';
import useDuploCodes from 'helpers/hooks/useDuploCodes';
import RenderDuploCodesItem from 'components/common/DorisControl/RenderDuploCodesItem';

const useStyles = makeStyles({
    violations: {
        display: 'flex',
        flexDirection: 'column',
        '& > span': {
            padding: '5px 0',
        },
    },
    root: {
        margin: 12
    }
});

const CameraDetails = ({ item, isInfoOpen }) => {
    const classes = useStyles();
    const duploCodes = useDuploCodes(item);

    return (
        <Collapse className="info" in={isInfoOpen} timeout={'auto'}>
            {item?.lat && item?.lon
                && <Typography variant="body2">
                    <strong>Широта: </strong>{item?.lat || messages.NO_DATA}
                    {' '}
                    <strong>Долгота: </strong>{item?.lon || messages.NO_DATA}
                </Typography>
            }
            {Array.isArray(item?.direction) && item?.direction.length > 0
                && <Typography variant="body2">
                    <span><strong>Градус отклонения: </strong>
                        {/* {item?.direction.map((el, index) => ( */}
                        <span key={item?.direction[0].id}>
                            {item?.direction[0]?.degree}
                            {/* {index !== item.direction.length - 1 && index >= 2 && ','} */}
                        </span>
                        {/* )) || messages.NO_DATA} */}
                    </span>
                </Typography>
            }
            {(item?.dir_type || item?.dir_type === 0) && <Typography variant="body2">
                <strong>Направление фиксации: </strong>
                {item?.dir_type === 0 && 'От камеры'}
                {item?.dir_type === 1 && 'К камере'}
                {item?.dir_type === 2 && 'В любую сторону'}
            </Typography>}
            {Array.isArray(item?.direction) && item?.direction.length
                && <Typography variant="body2">
                    <strong>Текстовое описание направления: </strong>
                    {item?.direction.map((el, index) => (
                        <span key={el.id}>
                            {el.direction}
                            {index !== item.direction.length - 1 && index >= 2 && ','}
                        </span>
                    )) || messages.NO_DATA}
                </Typography>
            }
            {item?.comment && <Typography variant="body2">
                <strong>Дополнительная информация: </strong>{item?.comment || messages.NO_DATA}
            </Typography>}
            {item?.duplo_codes && Object.keys(item?.duplo_codes).length > 0
                && (
                    <div className={classes.violations}>
                        <Typography variant="body2">
                            <b>Фиксируемые нарушения:</b>
                        </Typography>
                        {duploCodes.length > 0
                            ? (
                                <List>
                                    {(duploCodes.map((el) => {
                                        return el && (
                                            <ListItem key={el.id}>
                                                <Typography variant="body2">
                                                    <RenderDuploCodesItem
                                                        item={el}
                                                        hideImage
                                                        className=""
                                                    />
                                                </Typography>
                                            </ListItem>
                                        );
                                    }))}
                                </List>
                            )
                            : (!duploCodes.loading && <span>{messages.DATA_IS_NOT_FOUND}</span>)
                        }
                    </div>
                )
            }
        </Collapse>
    );
};

export default CameraDetails;
