const transportTitles = {
    PASSENGER_TRANSPORT: 'Пассажирский транспорт',
    BRAND_NAME_RU: 'Название марки(Ru)',
    BRAND_NAME_EN: 'Название марки(En)',
    MODEL_NAME_RU: 'Название модели(Ru)',
    MODEL_NAME_EN: 'Название модели(En)',
    SLUG: 'Алиас',
    REG_NUMBER: 'Регистрационный номер ТС',
    TRANSPORTATION_TYPE: 'Вид перевозок',
    PARAMETER: 'Вид ТС и его основные параметры',
    TYPE_OF_VEHICLE: 'Вид ТС',
    CATEGORY_VEHICLE: 'Категория ТС',
    TRANSPORT_TYPE: 'Тип ТС',
    GARAGE_NUM: 'Гаражный номер ТС',
    BRAND: 'Марка',
    MODEL: 'Модель',
    COLOR: 'Цвет',
    CHARACTERISTICS: 'Характеристики',
    CURRENT_TRANSPORT_REPORT: 'Отчет по данному ТС',
    LIST_VEHICLES: 'Список ТС',
    WRITE_TRANSPORT_NUMBER_ONE: `Ввод номера целиком или частично,
                        замещая пропущенные символы знаком
                        вопроса или звездочкой, например *1?3`,
    WRITE_TRANSPORT_NUMBER: `Ввод номера целиком или частично,
                        замещая пропущенные символы знаком
                        вопроса или звездочкой, например *1?3 (после ввода поставьте запятую, чтобы добавить)`,
    PRESS_ENTER_TO_ADD: 'после ввода нажмите Enter чтобы добавить',
    OPTIONAL_EQUIPMENT: 'Дополнительное оборудование',
    ADD_SCHEDULE_TEMPLATE: 'Добавить шаблон расписаний',
    EDIT_SCHEDULE_TEMPLATE: 'Редактировать шаблон расписаний',
    COPY_SCHEDULE_TEMPLATE: 'Создать копию шаблона расписаний',
    TRANSPORT_INCIDENTS: 'События',
    SELECT_TYPE_TRANSPORTATION: 'Выберите тип перевозки',
    ADD_ROUTE_REQUEST: 'Добавить заявку на открытие маршрута',
    EDIT_ROUTE_REQUEST: 'Редактировать заявку на открытие маршрута',
    ADD_INFRASTRUCTURE_TYPES: 'Добавить тип объекта инфраструктуры',
    EDIT_INFRASTRUCTURE_TYPES: 'Редактировать тип объекта инфраструктуры',
    SELECT_MARK: 'Выберите марку',
    SELECT_TYPE_TS: 'Выберите тип транспортного средства',
    SELECT_TRAFFIC_SCHEDULE: 'Выберите график движения',
    TRAFFIC_SCHEDULE: 'График движения',
    SELECT_BRIGADE: 'Выберите бригаду',
    VEHICLE: 'Транспортное средство',
    SELECT_VEHICLE: 'Выберите транспортное средство',
    SELECT_VEHICLES: 'Выберите транспортные средства',
    SEARCH_BY_NAME: 'Найти по названию',
    SEARCH_BY_DIAGRAM: 'Найти по номеру графика',
    SEARCH_BY_ROUTE_NUM: 'Найти по номеру маршрута',
    GRZ: 'ГРЗ',
    ADD_CONNECTION_POINT: 'Добавить точку подключения',
    EDIT_CONNECTION_POINT: 'Редактировать точку подключения',
    MILEAGE: 'Пробег',
    PARK: 'Парк',
    PARKS: 'Парки',
    EGTS_SERIAL: 'Серийный номер для EGTS',
    YANDEX_SERIAL: 'Серийный номер для Яндекса'
};

export default transportTitles;
