import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Point } from 'leaflet/dist/leaflet-src.esm';
import L from 'leaflet';
import { renderToString } from 'react-dom/server';

import * as actions from 'redux/TransportSpecial/actions';
import { messageTypes, showMessage } from 'redux/Message/actions';
import { transportSpecialSelectors } from 'redux/TransportSpecial';
import {
    loadVehicleCategories,
    setWsVehicle as setWsVehicleTS,
} from 'redux/TransportSpecial/actions';
import useTransportCoords from 'helpers/hooks/Transport/useTransportCoords';
import { useWsSubscribe } from 'helpers/ws/hooks';
import useTransportCategory from 'helpers/hooks/Transport/useTransportCategory';
import HistoryMap from 'components/common/Transport/TransportHistory/HistoryMap';
import MapLegends from 'components/common/Transport/MapLegends';
import MapLegendList from 'components/common/Transport/MapLegendList';

import Markers from './Markers';
import icon from './icons/icon';
import CPopUp from './PopUp';
import './style.scss';


const Layer = (props) => {
    const { map } = props;
    const dispatch = useDispatch();
    const history = useHistory();

    const active = useSelector(transportSpecialSelectors.active);
    const wsData = useSelector(transportSpecialSelectors.wsData);
    const routesHistory = useSelector(transportSpecialSelectors.routesHistory);

    const iconSize = new Point(30, 30);

    const getTransportCategory = useTransportCategory(
        loadVehicleCategories,
        'transportSpecial',
        'vehicleCategories'
    );

    useWsSubscribe('transport-special_vehicle_update_model_v2', (events) => {
        dispatch(setWsVehicleTS(events));
    });

    const specialCoords = useTransportCoords(
        actions.loadCoords,
        actions.clearCoords,
        actions.setMarkers,
        'transportSpecial.coords'
    );

    useEffect(() => {
        specialCoords.load();

        return () => {
            // map.off('show');

            map.closeContextMenu();
            dispatch(actions.clearWsTelemetry());
        };
    }, []);

    // setInterval(() => {
    //     map.fire('show', { 1: '111' });
    // }, 2000);

    useEffect(() => {
        // сдвигаем карту к машине
        if (active && Object.keys(active).length > 0 && !active.isClick) {
            // const number = active?.characteristics?.serial_egts || 0;
            // const number = active?.characteristics?.serial_egts !== '' ? `2_${active?.characteristics?.serial_egts}`
            //     : (active?.characteristics?.serial_yandex !== '' ? `1_${active?.characteristics?.serial_yandex}` : 0);

            const number = active?.serial_egts !== '' ? `${active?.serial_egts}`
                : (active?.serial_yandex !== '' ? `${active?.serial_yandex}` : 0);

            const current = wsData[number] || null;
            // const current = values(wsData).find((item) =>
            //     item?.vehicle?.characteristics?.serial_egts === number
            //     || item?.vehicle?.characteristics?.serial_yandex === number
            // );

            if (current) {
                const { lat, lon } = current;
                if (lat && lon) {
                    map.setView([lat, lon], 14);
                }
            } else {
                dispatch(showMessage(messageTypes.warning, '', 'В данный момент выбранного транспорта нет на карте.', null, 2000));
            }
        }
    }, [active]);

    const createIcon = ({ currentShow, in_registry, category_id, in_work_order }) => {
        const {
            getImageTpl,
            color,
        } = getTransportCategory
            ?.getHelper
            ?.getCategory(
                category_id,
                in_registry,
                in_work_order
            );

        const imageTpl = (
            <div className="circle" style={{ background: color }}>
                <div className="icon">
                    {getImageTpl('image-inner margin', icon({ fill: '#000' }, in_registry))}
                </div>
            </div>
        );

        return L.divIcon({
            html: renderToString(imageTpl),
            className: `transport-special-marker-icon ${currentShow ? 'active' : ''}`,
            iconSize
        });
    };

    if (getTransportCategory?.isLoaded === false) return null;

    return (
        <>
            {/* машинки */}
            <Markers
                {...props}
                // data={markers}
                icon={createIcon}
                popUpTemplate={(key) => (
                    <CPopUp
                        uuid={key}
                        history={history}
                    />
                )}
                toolTipTemplate={({ external_id, vehicle = null }) => (
                    <div>
                        {vehicle?.number ? <span>ГРЗ Номер: {vehicle?.number}</span> : <span><strong>Идентификатор:</strong>&nbsp;{external_id}</span>}
                    </div>
                )}
                toolTipOption={{
                    offset: [0, -15],
                    direction: 'top'
                }}
                onDestroy={actions.clearWsTelemetry}
                onClosePopUp={() => {
                    dispatch(actions.clearActive());
                }}
            />

            {/* история маршрута - новый */}
            {Object.keys(routesHistory).map((key) => (
                <HistoryMap
                    key={key}
                    {...props}
                    data={routesHistory[key]}
                />
            ))}
            {/* история маршрута - старый */}
            {/*{Object.keys(routesHistory).map((key) => (*/}
            {/*    <PolyLine*/}
            {/*        key={`route_history_${key}`}*/}
            {/*        {...props}*/}
            {/*        coordinates={routesHistory[key]}*/}
            {/*        style={{*/}
            {/*            color: '#00442C',*/}
            {/*            weight: 5,*/}
            {/*            smoothFactor: 1*/}
            {/*        }}*/}
            {/*        moveToAfterInit={true}*/}
            {/*    >*/}
            {/*        <ToolTip*/}
            {/*            // offset={[0, -15]}*/}
            {/*            offset={[0, 0]}*/}
            {/*            direction="top"*/}
            {/*            sticky={true}*/}
            {/*        >*/}
            {/*            <div>*/}
            {/*                <strong>Идентификатор:</strong>&nbsp;{key}*/}
            {/*            </div>*/}
            {/*        </ToolTip>*/}
            {/*    </PolyLine>*/}
            {/*))}*/}

            {/* легенда */}
            <MapLegends
                layer="transport-special"
            >
                <MapLegendList
                    category={getTransportCategory?.categories}
                    noImage={{
                        color: '#8c8c8c',
                        name: 'нет иконки в категории',
                        image: icon({ fill: '#000' })
                    }}
                    fromService={'transportSpecial'}
                />
            </MapLegends>
        </>
    );
};

export default Layer;
