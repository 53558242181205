import { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash';

import * as actions from 'redux/RoadWorks/actions';
import { roadworksSelectors } from 'redux/RoadWorks';
import { usePrevious } from 'helpers/hooks';
import messages from 'helpers/constants/messages';
import SidebarItem from 'components/MapComponents/SidebarItem';
import SidebarFilter from 'components/MapComponents/SidebarFilter';
import SidebarTitle from 'components/MapComponents/SidebarTitle';
import getFilters from 'components/MapComponents/helpers/getFilters';
import Loading from 'components/common/Loading';

import { getFilter } from '../helper';
import config from '../config';

import Item from './Item';
import Form from './Form';

const SideBar = (props) => {
    const dispatch = useDispatch();
    const [parameters, setParameters] = useState({
        page: 1,
        limit: Number(localStorage.getItem('limit')) || 25,
    });

    const [sidebar, setSideBar] = useState({});
    const loadingSidebar = useSelector(roadworksSelectors.loadingOverlapListRW);
    const filters = useSelector(roadworksSelectors.filtersOverlap);
    const active = useSelector(roadworksSelectors.activeOverlapRW);

    const prevFilters = usePrevious(filters);

    const activeId = active?.id ?? 0;

    const fetchList = () => {
        const filter = getFilters(filters, getFilter);
        dispatch(actions.loadOverlap(
            {
                ...parameters,
                ...filter
            },
            setSideBar
        ));
    };

    const handleScrollUpdate = (values) => {
        const {
            last_page = 0
        } = sidebar?.meta || {};
        if (
            values.top > 0.95
            && loadingSidebar === false
            && parameters.page < last_page
        ) {
            setParameters(old => ({
                ...old,
                page: old.page + 1,
            }));
        }
    };

    useEffect(() => () => {
        setSideBar([]);
    }, []);

    useEffect(() => {
        if (!isEqual(filters, prevFilters)) {
            setParameters(old => ({
                ...old,
                page: 1,
            }));
        } else {
            fetchList();
        }
    }, [parameters, filters]);

    const resetParameters = () => {
        setParameters(old => ({
            ...old,
            page: 1,
        }));
    };

    const renderList = (dataList) => (
        dataList.map((item) => {
            const color = item?.status_color;
            return (
                <SidebarItem
                    key={item.id}
                    active={activeId === item.id}
                    icon={`<i class="${config.layerIcon}" style="color: ${color}"/>`}
                    onClick={() => {
                        dispatch(actions.setActiveOverlapRW(item));
                    }}
                >
                    <Item
                        {...item}
                    />
                </SidebarItem>
            );
        })
    );

    return (
        <div className="layers-sidebar__flex-column">
            <SidebarFilter
                filters={filters}
                resetListPage={resetParameters}
                onSetFilter={(filter) => dispatch(actions.setFilterOverlap(filter))}
                onClearFilter={() => dispatch(actions.clearFiltersOverlap())}
                content={({ data, onChange, onDelete }) => <Form data={data} onChange={onChange} onDelete={onDelete} />}
                layer={config.slug}
            />

            {loadingSidebar && sidebar?.data?.length === 0 ? (
                <Loading className="absolute bottom fill" />
            ) : (
                <Scrollbars
                    onUpdate={handleScrollUpdate}
                    renderTrackHorizontal={(props) => (
                        <div {...props} className="track-horizontal" />
                    )}
                >
                    <div className="layers-sidebar__layer">
                        <SidebarTitle
                            title={config.name}
                            list={sidebar}
                        />
                        {sidebar?.data?.length === 0
                            ? <div className="layers-sidebar__empty">{messages.REQUEST_DATA_IS_NOT_FOUND}</div>
                            : renderList(sidebar?.data || [])}
                    </div>
                    {loadingSidebar && <Loading className="center" />}
                </Scrollbars>
            )}
        </div>
    );
};

export default SideBar;
