import ExternalTransportStations from 'modules/ExternalTransport/Dictionaries/Stations';

const externalTransport = [
    {
        path: '/external-transports/dictionaries/stations',
        component: ExternalTransportStations,
        exact: true,
    },
];

export default externalTransport;