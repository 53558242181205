import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { deletePark } from 'redux/Parking/actions';
import messages from 'helpers/constants/messages';
import ConfirmDelete from 'components/common/ConfirmModal';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import CircleStatus from 'components/common/CircleStatus';
import Modal from 'components/common/Modal';
import MapGeoJson from 'components/common/Location/MapGeoJson';
import { 
    ActionMore, 
    ActionsButtons, 
    LSContentColumn, 
    LSContentItem 
} from 'components/common/List';

import ModalForm from './ModalForm';
import Info from './Info';

import type { ItemProps } from './types';

const Item = ({ item, tariffs, categories, reloadList }: ItemProps) => {
    const dispatch = useDispatch();
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openMap, setOpenMap] = useState(false);
    const [openInfo, setOpenInfo] = useState(false);

    const currentCategory = categories.find(el => el.id === item?.category_id);

    return (
        <>
            <LSContentItem onClick={() => setOpenInfo(!openInfo)}>
                <LSContentColumn>
                    <CircleStatus 
                        title={item.status_text} 
                        color={item.status_color}
                    />
                </LSContentColumn>
                <LSContentColumn>
                    {item.name || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {currentCategory ? currentCategory.name_ru : messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {item.address_text || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {item.spaces?.total || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn>
                    {item.free_spaces || messages.NO_VALUE}
                </LSContentColumn>
                <LSContentColumn isActions>
                    <ActionsButtons
                        buttons={[
                            {
                                ...buttonsTypes.mapIcon,
                                onClick: () => setOpenMap(true),
                            },
                            {
                                ...buttonsTypes.editIcon,
                                onClick: () => setOpenEditModal(true),
                            },
                            {
                                ...buttonsTypes.deleteIcon,
                                onClick: () => setOpenDeleteModal(true),
                            },
                        ]}
                    />
                    <ActionMore isOpen={openInfo}/>
                </LSContentColumn>
            </LSContentItem>

            {openInfo && <Info isOpen={openInfo} item={item}/>}

            {openMap && (
                <Modal
                    isOpen={openMap}
                    onClose={() => setOpenMap(false)}
                    noPadding
                    title={item.name}
                    buttons={
                        <FormButtonsComponent
                            buttons={[
                                {
                                    ...buttonsTypes.close,
                                    onClick: () => setOpenMap(false)
                                },
                            ]}
                        />
                    }
                >
                    <MapGeoJson
                        geometry={item.location || {}}
                        readOnly={true}
                    />
                </Modal>
            )}

            {openDeleteModal && (
                <ConfirmDelete
                    open={openDeleteModal}
                    onSuccess={() => {
                        dispatch(deletePark(item.id, () => {reloadList(); setOpenDeleteModal(false);}));
                    }}
                    onClose={() => setOpenDeleteModal(false)}
                />
            )}

            {openEditModal && ( 
                <ModalForm
                    isOpen={openEditModal}
                    onClose={() => setOpenEditModal(false)}
                    item={item}
                    tariffs={tariffs}
                    reloadList={reloadList}
                    categories={categories}
                />
            )}
        </>
    );
};

export default Item;
