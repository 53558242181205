import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { loadVehiclesCB, setDockCurrentShow } from 'modules/TransportWater/redux/actions';
import { dockCurrentShow }  from 'modules/TransportWater/redux/selectors';

import { getShipColor, getShipIcon } from '../helper';

import LocationTab from './LocationTab';
import PropertiesTab from './PropertiesTab';

const PopUp = ({ tab = 0 }) => {
    const dispatch = useDispatch();

    const marker = useSelector(dockCurrentShow);

    // const [vehicle, setVehicle] = useState({});
    const [tabIndex, setTabIndex] = useState(tab);
    // const [loading, setLoading] = useState(false);


    // useEffect(() => {
    //     if (marker.vehicle_id) {
    //         const filter = { id_list: [marker.vehicle_id] };
    //         dispatch(loadVehiclesCB(
    //             1,
    //             1,
    //             filter,
    //             setLoading,
    //             (newData) => setVehicle(newData?.data?.[0] || {})
    //         ));
    //     }
    // }, [dispatch, marker.vehicle_id]);

    useEffect(() => () => {
        // setVehicle({});
        dispatch(setDockCurrentShow({}));
    }, [dispatch]);

    const tabList = [
        {
            key: 'location',
            title: 'Местоположение',
            component: <LocationTab marker={marker} />,
            
        },
        {
            key: 'properties',
            title: 'Свойства судна',
            component: <PropertiesTab marker={marker} />
        },
    ];

    return (
        <div className="map-popup">
            <div className="map-popup_header" style={{ backgroundColor: marker.color || getShipColor }}>
                <div>
                    <div className="img">
                        <i className={getShipIcon}></i>
                    </div>
                    <span>{marker?.vehicle?.name || ''}</span>
                </div>
            </div>
            <div className="map-popup_body" style={{ fontSize: '1rem' }}>
                {/* {loading && <Loading circular/>} */}
                <Tabs
                    selectedIndex={tabIndex}
                    onSelect={setTabIndex}
                >
                    <TabList>
                        {tabList.map(({ title, key }) => (
                            <Tab key={`tabtitle_${key}`}>{title}</Tab>
                        ))}
                    </TabList>

                    {tabList.map(({ component, key }, index) => (
                        <TabPanel key={key}>
                            {tabIndex === index && (
                                <div className="custom-popup-body">{component}</div>
                            )}
                        </TabPanel>
                    ))}
                </Tabs>
            </div>
        </div>
    );
};

export default PopUp;
