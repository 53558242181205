import type { Type } from './types';

export const inputTypes = (type: Type): { type: string, wrapper: Function } => {
    const types = {
        boolean: {
            type: 'boolean',
            wrapper: Boolean
        },
        integer: {
            type: 'number',
            wrapper: Number
        },
        string: {
            type: 'text',
            wrapper: String
        },
        float: {
            type: 'number',
            wrapper: Number
        },
        json: {
            type: 'text',
            wrapper: String
        },
        color: {
            type: 'color',
            wrapper: String
        },
        array: {
            type: 'array',
            wrapper: String
        }
    };

    return types[type] ?? types.string;
};
