import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    Button,
    Grid,
    IconButton,
    Paper,
} from '@mui/material';

import { authSelectors } from 'redux/Auth';
import ProjectTime from 'components/common/ProjectTime';
import { config } from 'config';

import './style.scss';
import logo from './logo.jpg';
// верхняя шапка витрины
const HeaderPage = ({ hideMain = false }) => {
    const history = useHistory();

    const project = useSelector(authSelectors.project);

    // на главную
    const handleGoToHome = () => {
        hideMain === false && history.push('/');
    };

    // выход
    const handleLogOut = () => {
        history.push('/logout');
    };

    const title = config.get('title') || project.title || config.getGeneral('title');
    const logoIcon = config.get('icon') || logo;
    return (
        <Paper elevation={0} className="statistic-header-page">
            <Grid container spacing={2} style={{ display: 'flex', flexGrow: 1 }}>
                <Grid item xs={12} sm={6} lg={4} className="row noMargin align-items-center  justify-content-between">
                    <div className="row noMargin align-items-center">
                        <IconButton onClick={handleGoToHome}>
                            <img src={logoIcon} alt={title} />
                        </IconButton>
                        <Button size="small" onClick={handleGoToHome} variant="text" style={{ color: '#000', marginRight: '1rem' }} >
                            <b>{config.get('title') || config.getGeneral('title')}</b>
                        </Button>
                        {hideMain === false && (
                            <Button size="small" onClick={handleGoToHome} variant="outlined">
                                Главный экран
                            </Button>
                        )}
                    </div>
                    <span className="separator" />
                </Grid>
                <Grid item xs={12} sm={6} lg={8}  className="row noMargin align-items-center justify-content-between">
                    <div className="item">
                        <i className="fa fa-map-marker" aria-hidden="true"/>
                        <strong>{project.description}</strong>
                    </div>
                    <div className="item">
                        <ProjectTime onlyProject withTitle={false} className="" />
                    </div>

                    <div className="item">
                        <Button size="small" onClick={handleLogOut} variant="text">
                            Выход
                        </Button>
                        <IconButton size="small" onClick={handleLogOut} className="logout">
                            <i className="fa fa-sign-out" aria-hidden="true"/>
                        </IconButton>
                    </div>
                </Grid>
            </Grid>
            {/* <div className="item">
                <IconButton onClick={handleGoToHome}>
                    <img src={logo} alt={title} />
                </IconButton>
                <Button size="small" onClick={handleGoToHome} variant="text" style={{ color: '#000', marginRight: '1rem' }} >
                    <strong>ИТС &laquo;ДОРИС&raquo;</strong>
                </Button>
                {hideMain === false && (
                    <Button size="small" onClick={handleGoToHome} variant="outlined">
                        Главный экран
                    </Button>
                )}
            </div>

            <div className="item">
                <i className="separator"/>
                <i className="fa fa-map-marker" aria-hidden="true"/>
                <strong>{project.description}</strong>
            </div>

            <div className="item">
                <Clock
                    locale="ru"
                    format={'D MMM YYYY, HH:mm:ss'}
                    ticking={true}
                    timezone={projectTimezone}
                    style={{ fontSize: '0.9rem' }}
                />
            </div>

            <div className="item">
                <Button size="small" onClick={handleLogOut} variant="text">
                    Выход
                </Button>
                <IconButton size="small" onClick={handleLogOut} className="logout">
                    <i className="fa fa-sign-out" aria-hidden="true"/>
                </IconButton>
            </div> */}
        </Paper>
    );
};

export default HeaderPage;
