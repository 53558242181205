import React, { useState } from 'react';
import { TextField } from '@mui/material';

import Modal from '../Modal';
import FormButtons, { buttonsTypes } from '../FormButtons';

interface CommentActionProps {
    open: boolean;
    onClose: () => void;
    onAccept: (comment: string) => void;
    title?: string;
    inputLabel?: string;
    description?: React.ReactElement;
    minTextLength?: number;
    test_id_prefix?: string;
}

const CommentAction: React.FC<CommentActionProps> = ({
    open,
    onClose,
    onAccept,
    title = 'Укажите причину удаления',
    inputLabel = 'Причина удаления',
    description = null,
    minTextLength = 6,
    test_id_prefix = ''
}) => {
    const [comment, setComment] = useState('');

    const onSuccess = () => {
        onAccept(comment);
        onClose();
    };

    return (
        <Modal
            isOpen={open}
            onClose={onClose}
            title={title}
            buttons={
                <FormButtons
                    test_id_prefix={test_id_prefix}
                    buttons={[
                        {
                            ...buttonsTypes.cancel,
                            onClick: onClose,
                            testName: 'cancel'
                        },
                        {
                            ...buttonsTypes.save,
                            onClick: onSuccess,
                            disabled: comment.length < minTextLength,
                            testName: 'save'
                        },
                    ]}
                />
            }
        >
            <div className="modal__form">
                <div>{description}</div>

                <TextField
                    label={inputLabel}
                    variant="outlined"
                    className="block"
                    size="small"
                    required
                    value={comment}
                    name="comment"
                    onChange={(e) => setComment(e.target.value)}
                    type="text"
                    multiline
                    InputLabelProps={{ style: { fontSize: '0.9rem' } }}
                    helperText={`Введите не менее ${minTextLength} символов`}
                    InputProps={{
                        // @ts-ignore
                        'data-testid': `${test_id_prefix}:comment/input`,
                    }}
                />
            </div>
        </Modal>
    );
};

export default CommentAction;
