import { Collapse } from '@mui/material';

import {
    loadVehicleCategories,
    loadVehiclesTypes
} from 'redux/TransportPassenger/actions';
import { VehicleInfo } from 'components/common/Transport/VehicleInfo';
import { useStoreProp } from 'helpers/hooks';

const VehiclesInfo = ({ info, isOpen }) => {
    const vehiclesTypes = useStoreProp(loadVehiclesTypes, 'transportPassenger', 'vehiclesTypes');
    return (
        <Collapse in={isOpen} timeout={'auto'} unmountOnExit className="info" style={{ paddingLeft: 20, paddingTop: 10 }}>
            <VehicleInfo
                info={info}
                storeName="transportPassenger"
                loadVehicleCategories={loadVehicleCategories}
                vehiclesTypes={vehiclesTypes}
            />
        </Collapse>
    );
};

export default VehiclesInfo;
