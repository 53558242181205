import * as types from './types';

export const loadCollectionServicesList = (page, limit, params = {}) => ({
    type: types.LOAD_COLLECTION_SERVICES,
    payload: { page, limit, ...params },
});

export const loadedCollectionServicesList = (data) => ({
    type: types.LOADED_COLLECTION_SERVICES,
    payload: data,
});

export const loadingCollectionServicesList = (bool = false) => ({
    type: types.LOADING_COLLECTION_SERVICES,
    payload: bool,
});

export const loadCollectionServicesTypes = () => ({
    type: types.LOAD_COLLECTION_SERVICES_TYPES,
});

export const loadedCollectionServicesTypes = (data) => ({
    type: types.LOADED_COLLECTION_SERVICES_TYPES,
    payload: data,
});

export const loadCollectionServicesTemplates = () => ({
    type: types.LOAD_COLLECTION_SERVICES_TEMPLATES,
});

export const loadedCollectionServicesTemplates = (data) => ({
    type: types.LOADED_COLLECTION_SERVICES_TEMPLATES,
    payload: data,
});

export const createCollectionService = (data, callback) => ({
    type: types.CREATE_COLLECTION_SERVICE,
    payload: data,
    callback
});

export const editCollectionService = (id, data, callback) => ({
    type: types.EDIT_COLLECTION_SERVICE,
    payload: {
        id,
        data
    },
    callback
});

export const deleteCollectionService = (id, callback) => ({
    type: types.DELETE_COLLECTION_SERVICE,
    payload: id,
    callback
});