import ComplexEvents from 'components/pages/Reports/DorisControl/ComplexEvents';

import styles from '../../vfcomplex.module.scss';

const Events = ({ complex_id, currentComplex = {}, tab, test_id_prefix = '' }) => {
    return (<div className={styles.container}>
        <ComplexEvents complex_id={complex_id} currentComplex={currentComplex} tab={tab} test_id_prefix={test_id_prefix} />
    </div>);
};

export default Events;
