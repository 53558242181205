import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    IconButton,
    Paper,
    Tooltip,
    Typography,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

import { efficiencySelectors } from 'redux/Efficiency';
import * as actions from 'redux/Efficiency/actions';
import {
    loadRegions,
} from 'redux/Dadata/actions';
import { useStoreProp } from 'helpers/hooks';
import {
    dateNow,
    fullDateTimeWithTimeZone,
    getDateWithDuration,
    getEndOf,
    getStartOf,
    getTimeUnitValue,
    hasSameUnitOfTime,
} from 'helpers/date.config';
import formatDate from 'helpers/constants/dateFormat';
import {
    filterByRegion,
    // getByRegion,
} from 'components/pages/Showcase/HeatGraphEfficiency/helper';
import BoxContainer from 'components/common/Showcase/BoxContainer';
import SingleKeyboardDateTimePicker from 'components/common/Dates/SingleKeyboardDateTimePicker';
import Loading from 'components/common/Loading';
import DistrictSwitch from 'components/common/Switcher';
import { GeoJsonNew } from 'components/MapComponents/leaflet';
import { config } from 'config';

import GraphicList from './GraphicList';
import RegionsMap from './RegionsMap';
import Graphics from './Graphics';
import RadioList from './RadioList';


const HeatGraphReport = () => {
    const dispatch = useDispatch();

    const efficiencyTraffic = useSelector(efficiencySelectors.efficiencyTraffic);
    const loadingEfficiencyTraffic = useSelector(efficiencySelectors.loadingEfficiencyTraffic);
    const trafficForecast = useSelector(efficiencySelectors.trafficForecast);
    const loadingTrafficForecast = useSelector(efficiencySelectors.loadingTrafficForecast);

    useEffect(() => {
        dispatch(actions.loadTrafficForecast());
    }, []);

    // регионы
    const regionsAll = useStoreProp(loadRegions, 'dadata', 'regions');

    const current = useMemo(() => {
        return dateNow();
    }, []);
    const feature = getDateWithDuration({ months: 1 }, current); // правый заголовок над графиком

    const featureDate = `${getTimeUnitValue('month', feature, true)} ${getTimeUnitValue('year', feature)}`;

    const [currentMonth, setCurrentMonth] = useState(current); // переключалка месяцев слева над графиком
    // районы городов или области
    const [isArea, setIsArea] = useState(false);
    const changeIsArea = (area) => {
        setIsArea(area);
        // сбрасываем регион при смене
        setSelectedRegion(null);
    };

    // отображаемые слои
    const [showLayer, setShowLayer] = useState('');

    // выбранный регион
    const [selectedRegion, setSelectedRegion] = useState(null);
    const changeSelectedRegion = (region) => {
        setSelectedRegion((old) => region?.id === old?.id ? null : region);
    };

    // данные по выбранному региону
    const dataByRegion = isArea === false
        ? efficiencyTraffic?.cities
        : efficiencyTraffic?.areas;

    // прогнозирование по выбранному региону
    const forecastByRegion = isArea === false
        ? trafficForecast?.cities
        : trafficForecast?.areas;

    const regionsGeo = useMemo(() => {
        const regions = isArea === false
            ? regionsAll?.cities || []
            : regionsAll?.areas || [];

        // преобразование
        return regions
            ?.map(({ geo_json, id, name }) => ({
                type: 'Feature',
                properties: {
                    id,
                    name,
                },
                geometry: {
                    ...geo_json,
                }
            }));
    }, [regionsAll, isArea]);

    const filter = filterByRegion(selectedRegion || null);

    // отображение регионов
    const componentRegion = useMemo(() => {
        if (Object.keys(regionsGeo).length > 0) {
            return (
                <GeoJsonNew
                    // данные
                    data={regionsGeo}
                    // название региона
                    toolTipTemplate={(properties) => `${properties?.name}`}
                    // параметры информации
                    toolTipOptions={{
                        direction: 'top',
                        offset: [0, -5],
                        sticky: true,
                    }}
                    // выбранный грегион (как параметры { id })
                    selected={selectedRegion}
                    // стили
                    style={{
                        // opacity: .4,
                        // fillOpacity: .4,
                        // color: '#888888',
                        opacity: 1,
                        fillOpacity: .7,
                        color: '#006699',
                        fillColor: '#ffffff',
                    }}
                    // кликнули
                    onClick={changeSelectedRegion}
                    // центруем
                    centerAfter={true}
                    // selectedStyle={{
                    //     color: '#bababa'
                    // }}
                />
            );
        }
        return null;
    }, [regionsGeo, selectedRegion]);

    // данные для графиков
    const graphicsData = useMemo(() => {
        return {
            // заголовок графиков
            title: selectedRegion?.name
                || (
                    isArea
                        ? 'Данные по районам области'
                        : 'Данные по районам города'
                ),
            // кнопка
            action: selectedRegion
                ? (
                    <Tooltip title="Сбросить выбранный район">
                        <IconButton
                            aria-label="settings"
                            size="small"
                            onClick={() => {
                                setSelectedRegion(null);
                            }}
                        >
                            <ClearIcon />
                        </IconButton>
                    </Tooltip>
                )
                : null
        };
    }, [selectedRegion, isArea]);

    // текущий график
    const currentGraph = useMemo(() => {
        const item = dataByRegion
            ?.graph
            ?.find(({ id }) => id.toString() === showLayer.toString()) || {};
        return item;
    }, [dataByRegion, showLayer]);

    // прогноз график
    const featureGraph = useMemo(() => {
        const item = forecastByRegion
            ?.find(({ id }) => id.toString() === showLayer.toString()) || {};
        return item;
    }, [forecastByRegion, showLayer]);

    // данные для двойного графика
    const graphicsList = useMemo(() => {
        if (currentGraph && featureGraph) {
            return [
                {
                    title: 'Статистика',
                    data: currentGraph,
                    labelAfter: `.${getTimeUnitValue('month', currentMonth)}.${getTimeUnitValue('year', currentMonth)}`,
                },
                {
                    title: 'Прогноз',
                    data: featureGraph,
                    opacity: .3,
                    yScale: 'right',
                }
            ];
        }

        return [];
    }, [currentGraph, featureGraph, currentMonth]);


    const regionTitle = useMemo(() => {
        return selectedRegion?.name
        || (
            isArea
                ? 'Данные по районам области'
                : 'Данные по районам города'
        );
    }, [selectedRegion, isArea]);

    useEffect(() => {
        const start_date = getStartOf('month', currentMonth);
        const end_date = (() => {
            if (hasSameUnitOfTime(current, currentMonth, 'month')) {
                return getEndOf('day', currentMonth);
            }
            return getEndOf('month',currentMonth);
        })();
        dispatch(actions.loadEfficiencyTraffic({
            start_date: fullDateTimeWithTimeZone(start_date),
            end_date: fullDateTimeWithTimeZone(end_date),
        }));
    }, [dispatch, currentMonth, current]);

    useEffect(() => {
        dispatch(actions.loadTrafficForecast());

        return () => {
            dispatch(actions.loadedEfficiencyTraffic({}));
            dispatch(actions.loadedTrafficForecast({}));
        };
    }, [dispatch]);

    return (
        <BoxContainer>
            <h1 style={{ textAlign: 'center' }}>
                Построение прогнозной оценки эффективности
            </h1>

            {(loadingEfficiencyTraffic || loadingTrafficForecast) ? (
                <div
                    style={{
                        textAlign: 'center'
                    }}
                >
                    <Loading />
                </div>
            ) : (
                <>
                    <RadioList
                        // выбранный слой
                        data={dataByRegion?.graph || []}
                        // выбранне слои
                        value={showLayer}
                        // изменяем выбранные слои
                        onChange={(k) => {
                            setShowLayer(k);
                        }}
                    />

                    <div
                        className="row"
                        style={{
                            justifyContent: 'space-between',
                        }}
                    >
                        {/* переключатель регионов */}
                        {!config.get('disableSwitcher')
                            && <DistrictSwitch
                                value={isArea}
                                onChange={changeIsArea}
                                asButton
                            />
                        }

                        {/* фильтр */}
                        {/*<Filter*/}
                        {/*    onChange={setParams}*/}
                        {/*    onChangeInline={setParamsInFilter}*/}
                        {/*/>*/}
                    </div>

                    {/* карта регионов */}
                    <RegionsMap
                        // прокидывает регионы
                        regionComponent={componentRegion}
                    />

                    <div
                        className="row"
                        style={{
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <div>
                            <SingleKeyboardDateTimePicker
                                onChange={(value) => setCurrentMonth(value)}
                                value={currentMonth}
                                pickerFormat={formatDate.MONTH_FOR_PICKER}
                                dateOnly={true}
                                views={['month', 'year']}
                                maxDate={new Date()}
                                label="Месяц/год"
                            />
                        </div>
                        <div className="row">
                            <Typography variant="h5">
                                {regionTitle}
                            </Typography>

                            {selectedRegion
                                ? (
                                    <Tooltip title="Сбросить выбранный район">
                                        <IconButton
                                            aria-label="settings"
                                            size="small"
                                            onClick={() => {
                                                setSelectedRegion(null);
                                            }}
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    </Tooltip>
                                )
                                : null
                            }
                        </div>
                        <div>{featureDate}</div>
                    </div>

                    <Paper
                        variant="outlined"
                        style={{
                            padding: '1rem 1rem 0',
                            marginBottom: '1rem',
                        }}
                    >
                        {graphicsList?.length > 0 && (
                            <GraphicList
                                list={graphicsList}
                                filter={filter}
                            />
                        )}
                    </Paper>
                </>
            )}
        </BoxContainer>
    );
};

export default HeatGraphReport;
