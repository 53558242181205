import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { TextField } from '@mui/material';

import { createPopulation, editPopulation } from 'redux/Incidents/actions';
import { useValidation } from 'helpers/hooks';
import titles from 'helpers/constants/titles';
import { getYear, getYearDiff } from 'helpers/date.config';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import SingleKeyboardDateTimePicker from 'components/common/Dates/SingleKeyboardDateTimePicker';
import FormInfoWrapper from 'components/common/FormInfoWrapper';

const ModalForm = ({ isNew, isOpen, onClose, item = {}, loadList }) => {
    const dispatch = useDispatch();
    const validation = useValidation();

    const initState = {
        year: item?.year ? String(item.year) : null,
        count: item?.count || 0,
    };

    const [data, setData] = useState(initState);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSave = () => {
        const callback = () => {
            onClose();
            loadList();
        };

        isNew
            ? dispatch(createPopulation(data, callback))
            : dispatch(editPopulation(item.id, data, callback));
    };

    const isDisabled = !data.year || (!data.count && data.count !== 0);

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={isNew ? titles.ADD : titles.EDIT}
            noPadding
            small
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.close,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: handleSave,
                        disabled: isDisabled
                    }
                ]}
            />}
        >
            <form className="modal__form">
                <div className="block">
                    <FormInfoWrapper
                        error={!data.year}
                        helperText={validation.get('year')}
                    >
                        <SingleKeyboardDateTimePicker
                            value={data.year || null}
                            onChange={(newDate) => {
                                setData({
                                    ...data,
                                    year: getYear(newDate)
                                });
                            }}
                            label={titles.YEAR}
                            dateOnly={true}
                            views={['year']}
                            pickerFormat="yyyy"
                            required
                            disableFuture
                            minDate={{ year: getYearDiff(50) }}
                        />
                    </FormInfoWrapper>
                </div>
                <div className="block">
                    <FormInfoWrapper
                        error={validation.isKey('count') || (!data.count && data.count !== 0)}
                        helperText={validation.get('count')}
                    >
                        <TextField
                            value={data?.count}
                            onChange={handleChange}
                            variant="outlined"
                            size="small"
                            label={titles.POPULATION}
                            required
                            name="count"
                            type="number"
                        />
                    </FormInfoWrapper>
                </div>
            </form>
        </Modal>
    );
};

export default ModalForm;
