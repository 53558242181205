import React, { useMemo, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';

import { loadRoutesStatuses, loadVehicleCategories } from 'redux/TransportPassenger/actions';
import removeEmptyFields from 'helpers/removeEmptyFields';
import titles from 'helpers/constants/titles';
import { useStoreProp } from 'helpers/hooks';
import { useValidation } from 'helpers/hooks';
import SelectCompany from 'components/common/Autocomplete/Companies';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import StatusesSelect from 'components/common/Autocomplete/Statuses';

const initialState = {
    num_list: [],
    status_list: [
        {
            id: 1,
            name: 'В разработке'
        },
        {
            id: 2,
            name: 'Действующий'
        },
    ],
    category_id_list: [],
    organization_id_list: [],
};

function Filters({ loadParams, test_id_prefix }) {
    const [filterValues, setFilterValues] = useState(initialState);
    const validation = useValidation();

    const statuses = useStoreProp(loadRoutesStatuses, 'transportPassenger', 'statuses');
    const transportTypes = useStoreProp(loadVehicleCategories, 'transportPassenger', 'vehicleCategories');

    const statusesList = useMemo(() => Object
        .entries(statuses)
        ?.map(([id, name]) => ({
            id: Number(id),
            name,
        }))
    , [statuses]);

    // useEffect(() => {
    //     if (statusesList.length > 0) {
    //         setFilterValues(prev => (
    //             {
    //                 ...prev,
    //                 status_list: statusesList.filter((el) => el.id === 1 || el.id === 2)
    //             }
    //         ));
    //     }
    // }, [statusesList]);

    const handleChange = (key, value) => {
        setFilterValues({
            ...filterValues,
            [key]: value
        });
        validation.deleteKey(key);
    };

    const search = (params = filterValues) => {
        const prepareData = removeEmptyFields({
            ...params,
            category_id_list:  params.category_id_list.map(({ id }) => id),
            status_list:  params.status_list.map(({ id }) => id),
            organization_id_list:  params.organization_id_list.map(({ id }) => id),
        }, false);

        loadParams(prepareData);
    };

    const resetSearch = (needRefresh) => {
        if (needRefresh && Object.keys(filterValues).length > 0) {
            search(initialState);
        }
        setFilterValues(initialState);
    };

    const updateFilter = (filters = {}) => {
        setFilterValues({
            ...filterValues,
            ...filters,
        });

        if (Object.keys(filters).length > 0) {
            search({
                ...filterValues,
                ...filters,
            });
        }
    };

    return (
        <LayoutFilter
            onResetFilter={resetSearch}
            onSearch={search}
            filter={filterValues}
            setUserFilter={updateFilter}
        >
            <LayoutFilter.Visible>
                <FormInfoWrapper
                    helperText={validation.get('num_list')}
                    error={validation.isKey('num_list')}
                >
                    <Autocomplete
                        multiple
                        value={filterValues?.num_list}
                        freeSolo
                        autoSelect
                        options={[]}
                        onChange={(_, value) => handleChange('num_list', value)}
                        size="small"
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size="small"
                                variant="outlined"
                                label="№ маршрута"
                            />
                        )}
                        inputProps={{
                            'data-testid': `${test_id_prefix}:route_num/input`,
                        }}
                    />
                </FormInfoWrapper>

                <StatusesSelect
                    multiple
                    selected={filterValues?.status_list}
                    options={statusesList}
                    renderLabel={(option) => option.name || ''}
                    onChange={(value) => handleChange('status_list', value)}
                    filterSelectedOptions
                    isOptionEqualToValue={(opt, val) => opt.id === val.id}
                    onReset={() => handleChange('status_list', [])}
                    test_id_prefix={test_id_prefix}
                />
                <FormInfoWrapper
                    helperText={validation.get('category_id_list')}
                    error={validation.isKey('category_id_list')}
                >
                    <Autocomplete
                        multiple
                        value={filterValues?.category_id_list}
                        options={transportTypes}
                        noOptionsText={titles.NO_RESULTS}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        filterSelectedOptions={true}
                        getOptionLabel={(option) => option?.name}
                        onChange={(_, value) => handleChange('category_id_list', value)}
                        size="small"
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size="small"
                                variant="outlined"
                                label={titles.TYPE_OF_VEHICLE}
                            />
                        )}
                        inputProps={{
                            'data-testid': `${test_id_prefix}:category/input`,
                        }}
                    />
                </FormInfoWrapper>
                <FormInfoWrapper
                    helperText={validation.get('organization_id_list')}
                    error={validation.isKey('organization_id_list')}
                >
                    <SelectCompany
                        multiple
                        selected={filterValues.organization_id_list}
                        onChange={(value) => handleChange('organization_id_list', value)}
                        filter={{ withDeleted: 1 }}
                        selectDeletedCompanies
                        test_id_prefix={test_id_prefix}
                    />
                </FormInfoWrapper>
            </LayoutFilter.Visible>
        </LayoutFilter>
    );
}

export default Filters;
