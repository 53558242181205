import moduleName from './module';

const prefix = `${moduleName}`;

/**
 * Constants
 * */
export const LOAD_CAMERAS = `${prefix}/LOAD/CAMERAS`;
export const LOADING_CAMERAS = `${prefix}/LOADING/CAMERAS`;
export const LOADED_CAMERAS = `${prefix}/LOADED/CAMERAS`;
export const LOAD_CAMERA_TYPES = `${prefix}/LOAD/CAMERA/TYPES`;
export const LOADED_CAMERA_TYPES = `${prefix}/LOADED/CAMERA/TYPES`;
export const EDIT_CAMERA = `${prefix}/EDIT/CAMERA`;
export const CREATE_CAMERA = `${prefix}/CREATE/CAMERA`;
export const LOAD_CAMERA_COMPLEX_TYPES = `${prefix}/LOAD/CAMERA/COMPLEX/TYPES`;
export const LOADED_CAMERA_COMPLEX_TYPES = `${prefix}/LOADED/CAMERA/COMPLEX/TYPES`;
export const LOAD_CAMERA_PROTO_TEXT = `${prefix}/LOAD/CAMERA/PROTO/TEXT`;
export const LOADED_CAMERA_PROTO_TEXT = `${prefix}/LOADED/CAMERA/PROTO/TEXT`;
export const LOAD_CAMERA_DIR_TYPE = `${prefix}/LOAD/CAMERA/DIR/TYPE`;
export const LOADED_CAMERA_DIR_TYPE = `${prefix}/LOADED/CAMERA/DIR/TYPE`;
export const LOAD_CAMERA_VIDEO_TYPE = `${prefix}/LOAD/CAMERA/VIDEO/TYPE`;
export const LOADED_CAMERA_VIDEO_TYPE = `${prefix}/LOADED/CAMERA/VIDEO/TYPE`;
export const LOAD_CAMERA_DATA_TYPE = `${prefix}/LOAD/CAMERA/DATA/TYPE`;
export const LOADED_CAMERA_DATA_TYPE = `${prefix}/LOADED/CAMERA/DATA/TYPE`;
export const LOADED_CAMERA_VIOLATIONS = `${prefix}/LOADED/CAMERA/VIOLATIONS`;
export const LOAD_CAMERA_VIOLATIONS = `${prefix}/LOAD/CAMERA/VIOLATIONS`;
export const LOAD_CAMERA_EVENTS = `${prefix}/LOAD/CAMERA/EVENTS`;
export const LOADING_CAMERA_EVENTS = `${prefix}/LOADING/CAMERA/EVENTS`;
export const LOADED_CAMERA_EVENTS = `${prefix}/LOADED/CAMERA/EVENTS`;
export const LOAD_CAMERA_EVENTS_GROUP = `${prefix}/LOAD/CAMERA/EVENTS/GROUP`;
export const LOADED_CAMERA_EVENTS_GROUP = `${prefix}/LOADED/CAMERA/EVENTS/GROUP`;
export const LOAD_CAMERA_REPORT_LIST = `${prefix}/LOAD_CAMERA_REPORT_LIST`;
export const LOADED_CAMERA_REPORT_LIST = `${prefix}/LOADED_CAMERA_REPORT_LIST`;
export const GET_CAMERA_REPORT = `${prefix}/GET_CAMERA_REPORT`;
export const LOADING_COMPLEXES = `${prefix}/LOADING/COMPLEXES`;
export const LOADED_COMPLEXES = `${prefix}/LOADED/COMPLEXES`;
export const LOAD_COMPLEXES = `${prefix}/LOAD/COMPLEXES`;
export const CREATE_COMPLEX = `${prefix}/CREATE/COMPLEX`;
export const LOAD_COMPLEX_TYPES = `${prefix}/LOAD/COMPLEX/TYPES`;
export const LOADED_COMPLEX_TYPES = `${prefix}/LOADED/COMPLEX/TYPES`;
export const LOAD_COMPLEX_UNIT_TYPES = `${prefix}/LOAD/COMPLEX/UNIT/TYPES`;
export const LOADED_COMPLEX_UNIT_TYPES = `${prefix}/LOADED/COMPLEX/UNIT/TYPES`;
export const LOAD_COMPLEX_MODEL_TYPES = `${prefix}/LOAD/COMPLEX/MODEL/TYPES`;
export const LOADED_COMPLEX_MODEL_TYPES = `${prefix}/LOADED/COMPLEX/MODEL/TYPES`;
export const LOAD_COMPLEX_ENTITIES = `${prefix}/LOAD/COMPLEX/ENTITIES`;
export const LOADING_COMPLEX_ENTITIES = `${prefix}/LOADING/COMPLEX/ENTITIES`;
export const LOADED_COMPLEX_ENTITIES = `${prefix}/LOADED/COMPLEX/ENTITIES`;
export const LOAD_COMPLEX_ENTITY_TYPES = `${prefix}/LOAD/COMPLEX/ENTITY/TYPES`;
export const LOADING_COMPLEX_ENTITY_TYPES = `${prefix}/LOADING/COMPLEX/ENTITY/TYPES`;
export const LOADED_COMPLEX_ENTITY_TYPES = `${prefix}/LOADED/COMPLEX/ENTITY/TYPES`;
export const POST_COMPLEX_ENTITY_DATA = `${prefix}UPLOAD/COMPLEX/ENTITY/DATA`;
export const EDIT_COMPLEX_ENTITY_DATA = `${prefix}EDIT/COMPLEX/ENTITY/DATA`;
export const DELETE_COMPLEX_ENTITY_DATA = `${prefix}DELETE/COMPLEX/ENTITY/DATA`;
export const LOAD_DOCUMENTS = `${prefix}/LOAD/DOCUMENTS`;
export const LOADING_DOCUMENTS = `${prefix}/LOADING/DOCUMENTS`;
export const LOADED_DOCUMENTS = `${prefix}/LOADED/DOCUMENTS`;
export const LOAD_DOCUMENT_TYPES = `${prefix}/LOAD/DOCUMENT/TYPES`;
export const LOADED_DOCUMENT_TYPES = `${prefix}/LOADED/DOCUMENT/TYPES`;
export const CREATE_DOCUMENT_COMPLEX = `${prefix}/CREATE/DOCUMENT/COMPLEX`;
export const LOAD_COMPLEX_STATUSES = `${prefix}/LOAD/COMPLEX/STATUSES`;
export const LOADED_COMPLEX_STATUSES = `${prefix}/LOADED/COMPLEX/STATUSES`;
export const LOAD_PDD_VIOLATIONS = `${prefix}/LOAD/PDD/VIOLATIONS`;
export const LOADING_PDD_VIOLATIONS = `${prefix}/LOADING/PDD/VIOLATIONS`;
export const LOADED_PDD_VIOLATIONS = `${prefix}/LOADED/PDD/VIOLATIONS`;
export const LOADED_FILTERED_PDD_VIOLATIONS = `${prefix}/LOADED/FILTERED/PDD/VIOLATIONS`;
export const EDIT_PDD_VIOLATION = `${prefix}/EDIT/PDD/VIOLATION`;
export const LOAD_CAMERAS_PDD_VIOLATIONS = `${prefix}/LOAD_CAMERAS_PDD_VIOLATIONS`;
export const LOADING_CAMERAS_PDD_VIOLATIONS = `${prefix}/LOADING_CAMERAS_PDD_VIOLATIONS`;
export const LOADED_CAMERAS_PDD_VIOLATIONS = `${prefix}/LOADED_CAMERAS_PDD_VIOLATIONS`;
export const DELETE_COMPLEX_DOCUMENT = `${prefix}/DELETE/COMPLEX/DOCUMENT`;
export const EDIT_COMPLEX_DOCUMENT = `${prefix}/EDIT/COMPLEX/DOCUMENT`;
export const DELETE_COMPLEX = `${prefix}/DELETE/COMPLEX`;
export const LOAD_COMPLEX_HISTORY = `${prefix}/LOAD/COMPLEX/HISTORY`;
export const LOADED_COMPLEX_HISTORY = `${prefix}/LOADED/COMPLEX/HISTORY`;
export const LOADING_COMPLEX_HISTORY = `${prefix}/LOADING/COMPLEX/HISTORY`;
export const EDIT_COMPLEX = `${prefix}/EDIT/COMPLEX`;
export const GET_POLYGON = `${prefix}/GET/POLYGON`;
export const SET_POLYGON_LOADING = `${prefix}/SET/POLYGON/LOADING`;
export const SAVE_POLYGON = `${prefix}/SAVE/POLYGON`;
export const SET_ACTIVE = `${prefix}/SET/ACTIVE`;
export const SAVED = `${prefix}/SAVED`;
export const SET_EDIT_FORM = `${prefix}/SET/EDIT/FORM`;
export const SET_DELETE_FORM = `${prefix}/SET/DELETE/FORM`;
export const LOAD_COMPLEX_EVENTS = `${prefix}/LOAD/COMPLEX/EVENTS`;
export const LOADED_COMPLEX_EVENTS = `${prefix}/LOADED/COMPLEX/EVENTS`;
export const CLEAR_COMPLEX_EVENTS = `${prefix}/CLEAR_COMPLEX_EVENTS`;
export const LOADING_COMPLEX_EVENTS = `${prefix}/LOADING/COMPLEX/EVENTS`;
export const LOADED_CURRENT_EVENT = `${prefix}/LOADED/CURRENT/EVENTS`;
export const SET_COMPLEX_EVENTS_REPORT_FILTER = `${prefix}/SET/COMPLEX/EVENTS/REPORT/FILTER`;
export const CLEAR_COMPLEX_EVENTS_REPORT_FILTER = `${prefix}/CLEAR/COMPLEX/EVENTS/REPORT/FILTER`;
export const CHANGE_COMPLEX_STATUS = `${prefix}/CHANGE/COMPLEX/STATUS`;
export const LOADED_SIDEBAR = `${prefix}/LOADED/SIDEBAR`;
export const LOAD_SIDEBAR = `${prefix}/LOAD/SIDEBAR`;
export const LOADING_SIDEBAR = `${prefix}/LOADING/SIDEBAR`;
export const CLEAR_SIDEBAR = `${prefix}/CLEAR/SIDEBAR`;
export const EDIT_EVENT_ERRORS = `${prefix}/EDIT/EVENT/ERRORS`;
export const EDITING_EVENT_ERRORS = `${prefix}/EDITING/EVENT/ERRORS`;
export const LOAD_EVENT_ERRORS = `${prefix}/LOAD/EVENT/ERRORS`;
export const LOADED_EVENT_ERRORS = `${prefix}/LOADED/EVENT/ERRORS`;
export const LOAD_1C_NAMES = `${prefix}/LOAD/1C/NAMES`;
export const LOADING_1C_NAMES = `${prefix}/LOADING/1C/NAMES`;
export const LOADED_1C_NAMES = `${prefix}/LOADED/1C/NAMES`;
export const CLEAR_1C_NAMES = `${prefix}/CLEAR/1C/NAMES`;
export const CREATE_1C_NAME = `${prefix}/CREATE/1C/NAME`;
export const EDIT_1C_NAME = `${prefix}/EDIT/1C/NAME`;
export const DELETE_1C_NAME = `${prefix}/DELETE/1C/NAME`;
//COMPLEXES
export const LOAD_FORMATTED_COMPLEX_HISTORY = `${prefix}/LOAD/FORMATTED/COMPLEX/HISTORY`;
export const LOADING_FORMATTED_COMPLEX_HISTORY = `${prefix}/LOADING/FORMATTED/COMPLEX/HISTORY`;
export const LOADED_FORMATTED_COMPLEX_HISTORY = `${prefix}/LOADED/FORMATTED/COMPLEX/HISTORY`;

export const LOAD_COMPLEX_RULES = `${prefix}/LOAD/COMPLEX/RULES`;
export const LOADING_COMPLEX_RULES = `${prefix}/LOADING/COMPLEX/RULES`;
export const LOADED_COMPLEX_RULES = `${prefix}/LOADED/COMPLEX/RULES`;
export const CLEAR_COMPLEX_RULES = `${prefix}/CLEAR/COMPLEX/RULES`;

//CURRENT COMPLEX
export const LOAD_CURRENT_COMPLEX = `${prefix}/LOAD_CURRENT_COMPLEX`;
export const LOADED_CURRENT_COMPLEX = `${prefix}/LOADED_CURRENT_COMPLEX`;
export const LOAD_COMPLEX_TRANSFER_TYPES = `${prefix}/LOAD_COMPLEX_TRANSFER_TYPES`;
export const LOADED_COMPLEX_TRANSFER_TYPES = `${prefix}/LOADED_COMPLEX_TRANSFER_TYPES`;
export const LOAD_COMPLEX_TRANSFER_RULES = `${prefix}/LOAD_COMPLEX_TRANSFER_RULES`;
export const LOADED_COMPLEX_TRANSFER_RULES = `${prefix}/LOADED_COMPLEX_TRANSFER_RULES`;
export const CLEAR_COMPLEX_TRANSFER_RULES = `${prefix}/CLEAR_COMPLEX_TRANSFER_RULES`;
export const LOAD_CURRENT_COMPLEX_TRANSFER_TYPES = `${prefix}/LOAD/CURRENT/COMPLEX/TRANSFER/TYPES`;
export const LOADED_CURRENT_COMPLEX_TRANSFER_TYPES = `${prefix}/LOADED/CURRENT/COMPLEX/TRANSFER/TYPES`;
// service base
export const MOVE_SERVICE_EVENTS_TO_MAIN = `${prefix}/MOVE_SERVICE_EVENTS_TO_MAIN`;
export const DELETE_FROM_SERVICE_EVENTS = `${prefix}/DELETE_FROM_SERVICE_EVENTS`;
// complex statistic
export const LOAD_COMPLEX_STATISTIC = `${prefix}/LOAD_COMPLEX_STATISTIC`;
export const LOADED_COMPLEX_STATISTIC = `${prefix}/LOADED_COMPLEX_STATISTIC`;

export const LOAD_MONTHLY_REPORT = `${prefix}/LOAD/MONTHLY/REPORT`;
export const LOAD_MONTHLY_REPORT_EXPANDED = `${prefix}/LOAD/MONTHLY/REPORT/EXPANDED`;
export const LOAD_MONTHLY_REPORT_BY_COMPLEX = `${prefix}/LOAD/MONTHLY/REPORT/BY/COMPLEX`;

export const LOAD_REPORT_BY_PERIOD = `${prefix}/LOAD_REPORT_BY_PERIOD`;

export const LOAD_COMPARATIVE_STATISTICS = `${prefix}/LOAD_COMPARATIVE_STATISTICS`;
export const LOADING_COMPARATIVE_STATISTICS = `${prefix}/LOADING_COMPARATIVE_STATISTICS`;
export const LOADED_COMPARATIVE_STATISTICS = `${prefix}/LOADED_COMPARATIVE_STATISTICS`;

export const SET_SHOW_DORIS_CAMERA_EVENTS = `${prefix}/SET_SHOW_DORIS_CAMERA_EVENTS`;

export const SET_ACTIVE_EVENT = `${prefix}/SET_ACTIVE_EVENT`;

export const LOAD_COORDINATION_HISTORY = `${prefix}/LOAD_COORDINATION_HISTORY`;
export const LOADED_COORDINATION_HISTORY = `${prefix}/LOADED_COORDINATION_HISTORY`;
export const LOADING_COORDINATION_HISTORY = `${prefix}/LOADING_COORDINATION_HISTORY`;
export const SET_COORDINATION_HISTORY_DATES = `${prefix}/SET_COORDINATION_HISTORY_DATES`;
export const SET_FILTERS = `${prefix}/SET_FILTERS`;
export const CLEAR_FILTERS = `${prefix}/CLEAR_FILTERS`;

export const LOAD_COMPLEX_REPORT = `${prefix}/LOAD_COMPLEX_REPORT`;

export const LOAD_INACCURACY = `${prefix}/LOAD/INACCURACY`;
export const LOADED_INACCURACY = `${prefix}/LOADED/INACCURACY`;

//DORIS_CONTROL_SETTINGS
export const LOAD_DORIS_CONTROL_SETTINGS = `${prefix}LOAD/DORIS/CONTROL/SETTINGS`;
export const LOADING_DORIS_CONTROL_SETTINGS = `${prefix}LOADING/DORIS/CONTROL/SETTINGS`;
export const LOADED_DORIS_CONTROL_SETTINGS = `${prefix}LOADED/DORIS/CONTROL/SETTINGS`;
export const EDIT_DORIS_CONTROL_SETTING = `${prefix}/EDIT/DORIS/CONTROL/SETTING`;
export const EDIT_SETTINGS_LIST = `${prefix}/EDIT_SETTINGS_LIST`;

export const DELETE_COMPLEX_EVENTS = `${prefix}/DELETE/COMPLEX/EVENTS`;

export const LOAD_COMPLEX_EVENT_ARCHIVE = `${prefix}/LOAD/COMPLEX/EVENT/ARCHIVE`;

export const GET_INCORRECT_COMPLEX_REPORT = `${prefix}/GET/INCORRECT/COMPLEX/REPORT`;

export const LOAD_TS_CATEGORIES = `${prefix}/LOAD/TS/CATEGORIES`;
export const LOADED_TS_CATEGORIES = `${prefix}/LOADED/TS/CATEGORIES`;

export const LOAD_INSPECTION_CERTIFICATE = `${prefix}/LOAD_INSPECTION_CERTIFICATE`;
export const LOADING_INSPECTION_CERTIFICATE = `${prefix}/LOADING_INSPECTION_CERTIFICATE`;

export const EVENT_SENDING_REQUEST  = `${prefix}/EVENT_SENDING_REQUEST`;

export const LOAD_FILE = `${prefix}/LOAD_FILE`;
export const LOADING_FILE = `${prefix}/LOADING_FILE`;

export const LOAD_REPORT_BY_1C_NAMES = `${prefix}/LOAD_REPORT_BY_1C_NAMES`;
export const LOADING_REPORT_BY_1C_NAMES = `${prefix}/LOADING_REPORT_BY_1C_NAMES`;

export const SET_OPERATOR_LOG_REPORT_FILTER = `${prefix}/SET_OPERATOR_LOG_REPORT_FILTER`;
export const CLEAR_OPERATOR_LOG_REPORT_FILTER = `${prefix}/CLEAR_OPERATOR_LOG_REPORT_FILTER`;
export const SET_DORIS_CONTROL_WS = `${prefix}/SET_DORIS_CONTROL_WS`;

export const SET_SIDEBAR_PARAMS = `${prefix}/SET_SIDEBAR_PARAMS`;

export const LOAD_COMPLEX_BY_ID = `${prefix}/LOAD_COMPLEX_BY_ID`;
export const LOADING_COMPLEX_BY_ID = `${prefix}/LOADING_COMPLEX_BY_ID`;
export const LOADED_COMPLEX_BY_ID = `${prefix}/LOADED_COMPLEX_BY_ID`;

export const LOAD_TAG_LIST = `${prefix}/LOAD_TAG_LIST`;
export const LOADING_TAG_LIST = `${prefix}/LOADING_TAG_LIST`;
export const LOADED_TAG_LIST = `${prefix}/LOADED_TAG_LIST`;
export const CREATE_TAG_LIST = `${prefix}/CREATE_TAG_LIST`;
export const EDIT_TAG_LIST = `${prefix}/EDIT_TAG_LIST`;
export const DELETE_TAG_LIST = `${prefix}/DELETE_TAG_LIST`;
export const COMPLEX_SYNC_TAGS = `${prefix}/COMPLEX_SYNC_TAGS`;

export const LOAD_EXCLUSIVE_FILES = `${prefix}/LOAD_EXCLUSIVE_FILES`;
export const LOADED_EXCLUSIVE_FILES = `${prefix}/LOADED_EXCLUSIVE_FILES`;
export const LOADING_EXCLUSIVE_FILES = `${prefix}/LOADING_EXCLUSIVE_FILES`;

export const CREATE_EXCLUSIVE_FILE = `${prefix}/CREATE_EXCLUSIVE_FILE`;
export const EDIT_EXCLUSIVE_FILE = `${prefix}/EDIT_EXCLUSIVE_FILE`;
export const DELETE_EXCLUSIVE_FILE = `${prefix}/DELETE_EXCLUSIVE_FILE`;

export const LOAD_EXCLUSIVE_FILE_HISTORY = `${prefix}/LOAD_EXCLUSIVE_FILE_HISTORY`;
export const LOADING_EXCLUSIVE_FILE_HISTORY = `${prefix}/LOADING_EXCLUSIVE_FILE_HISTORY`;
export const LOADED_EXCLUSIVE_FILE_HISTORY = `${prefix}/LOADED_EXCLUSIVE_FILE_HISTORY`;

export const LOAD_COMPLEX_TYPE_GROUPING = `${prefix}/LOAD_COMPLEX_TYPE_GROUPING`;
export const LOAD_COMPLEX_MEASURING = `${prefix}/LOAD_COMPLEX_MEASURING`;
export const LOADED_COMPLEX_TYPE_GROUPING = `${prefix}/LOADED_COMPLEX_TYPE_GROUPING`;
export const LOADED_COMPLEX_MEASURING = `${prefix}/LOADED_COMPLEX_MEASURING`;

export const LOAD_RECOGNITION_CAMERA_DIR_TYPES = `${prefix}/LOAD_RECOGNITION_CAMERA_DIR_TYPES`;
export const LOADED_RECOGNITION_CAMERA_DIR_TYPES = `${prefix}/LOADED_RECOGNITION_CAMERA_DIR_TYPES`;

export const CREATE_COMPLEX_ENTITY = `${prefix}/CREATE_COMPLEX_ENTITY`;
export const EDIT_COMPLEX_ENTITY = `${prefix}/EDIT_COMPLEX_ENTITY`;
export const DELETE_COMPLEX_ENTITY = `${prefix}/DELETE_COMPLEX_ENTITY`;

export const LOAD_COMPUTER_TRANSFER_TYPES = `${prefix}/LOAD_COMPUTER_TRANSFER_TYPES`;
export const LOADED_COMPUTER_TRANSFER_TYPES = `${prefix}/LOADED_COMPUTER_TRANSFER_TYPES`;
export const LOAD_COMPUTER_TRANSFER_RULES = `${prefix}/LOAD_COMPUTER_TRANSFER_RULES`;
export const LOADED_COMPUTER_TRANSFER_RULES = `${prefix}/LOADED_COMPUTER_TRANSFER_RULES`;
export const CLEAR_COMPUTER_TRANSFER_RULES = `${prefix}/CLEAR_COMPUTER_TRANSFER_RULES`;

export const LOAD_DORIS_EXPORT_TYPES = `${prefix}/LOAD/DORIS/EXPORT/TYPES`;
export const LOADED_DORIS_EXPORT_TYPES = `${prefix}/LOADED/DORIS/EXPORT/TYPES`;

export const LOAD_CHECKLISTS = `${prefix}/LOAD_CHECKLISTS`;
export const CREATE_CHECKLISTS = `${prefix}/CREATE_CHECKLISTS`;
export const EDIT_CHECKLISTS = `${prefix}/EDIT_CHECKLISTS`;
export const DELETE_CHECKLISTS = `${prefix}/DELETE_CHECKLISTS`;
export const LOADING_CHECKLISTS = `${prefix}/LOADING_CHECKLISTS`;
export const LOADED_CHECKLISTS = `${prefix}/LOADED_CHECKLISTS`;
export const LOAD_MATERIAL_STORAGE_GROUPS = `${prefix}/LOAD_MATERIAL_STORAGE_GROUPS`;
export const LOADING_MATERIAL_STORAGE_GROUPS = `${prefix}/LOADING_MATERIAL_STORAGE_GROUPS`;
export const LOADED_MATERIAL_STORAGE_GROUPS = `${prefix}/LOADED_MATERIAL_STORAGE_GROUPS`;
export const CREATE_MATERIAL_STORAGE_GROUP = `${prefix}/CREATE_MATERIAL_STORAGE_GROUP`;
export const EDIT_MATERIAL_STORAGE_GROUP = `${prefix}/EDIT_MATERIAL_STORAGE_GROUP`;
export const DELETE_MATERIAL_STORAGE_GROUP = `${prefix}/DELETE_MATERIAL_STORAGE_GROUP`;

export const LOADING_BUTTON = `${prefix}/LOADING_BUTTON`;

export const LOAD_CURRENT_EVENT_LIST = `${prefix}/LOAD_CURRENT_EVENT_LIST`;
export const LOAD_EVENT = `${prefix}/LOAD_EVENT`;
export const LOADING_EVENT = `${prefix}/LOADING_EVENT`;

export const LOAD_COMPLEXES_TREE = `${prefix}/LOAD_COMPLEXES_TREE`;
export const LOADED_COMPLEXES_TREE = `${prefix}/LOADED_COMPLEXES_TREE`;
export const LOADING_COMPLEXES_TREE = `${prefix}/LOADING_COMPLEXES_TREE`;
export const CLEAR_COMPLEXES_TREE = `${prefix}/CLEAR_COMPLEXES_TREE`;

export const LOAD_VIOLATION_EVENTS = `${prefix}/LOAD_VIOLATION_EVENTS`;
export const LOADING_VIOLATION_EVENTS = `${prefix}/LOADING_VIOLATION_EVENTS`;
export const LOADED_VIOLATION_EVENTS = `${prefix}/LOADED_VIOLATION_EVENTS`;

export const LOAD_EVENT_CHECK_LIST = `${prefix}/LOAD_EVENT_CHECK_LIST`;
export const LOADING_EVENT_CHECK_LIST = `${prefix}/LOADING_EVENT_CHECK_LIST`;
export const LOADED_EVENT_CHECK_LIST = `${prefix}/LOADED_EVENT_CHECK_LIST`;

export const SAVE_EVENT_CHECK_LIST = `${prefix}/SAVE_EVENT_CHECK_LIST`;

export const LOAD_EXCLUSIVE_FILES_HISTORY_LIST = `${prefix}/LOAD_EXCLUSIVE_FILES_HISTORY_LIST`;
export const LOADING_EXCLUSIVE_FILES_HISTORY_LIST = `${prefix}/LOADING_EXCLUSIVE_FILES_HISTORY_LIST`;
export const LOADED_EXCLUSIVE_FILES_HISTORY_LIST = `${prefix}/LOADED_EXCLUSIVE_FILES_HISTORY_LIST`;

export const LOAD_PDD_VIOLATIONS_TREE = `${prefix}/LOAD_PDD_VIOLATIONS_TREE`;

export const LOAD_EXCLUSIVE_FILES_LOG_LIST = `${prefix}/LOAD_EXCLUSIVE_FILES_LOG_LIST`;
export const LOADING_EXCLUSIVE_FILES_LOG_LIST = `${prefix}/LOADING_EXCLUSIVE_FILES_LOG_LIST`;
export const LOADED_EXCLUSIVE_FILES_LOG_LIST = `${prefix}/LOADED_EXCLUSIVE_FILES_LOG_LIST`;

export const LOAD_CHECKLIST_GROUP = `${prefix}/LOAD_CHECKLIST_GROUP`;
export const LOADING_CHECKLIST_GROUP = `${prefix}/LOADING_CHECKLIST_GROUP`;
export const LOADED_CHECKLIST_GROUP = `${prefix}/LOADED_CHECKLIST_GROUP`;
export const CREATE_CHECKLIST_GROUP = `${prefix}/CREATE_CHECKLIST_GROUP`;
export const EDIT_CHECKLIST_GROUP = `${prefix}/EDIT_CHECKLIST_GROUP`;
export const DELETE_CHECKLIST_GROUP = `${prefix}/DELETE_CHECKLIST_GROUP`;

export const CHECK_FGIS = `${prefix}/CHECK_FGIS`;

export const LOAD_DOCUMENTS_HISTORY = `${prefix}/LOAD_DOCUMENTS_HISTORY`;
export const LOADED_DOCUMENTS_HISTORY = `${prefix}/LOADED_DOCUMENTS_HISTORY`;
export const LOADING_DOCUMENTS_HISTORY = `${prefix}/LOADING_DOCUMENTS_HISTORY`;

export const LOAD_EVENTS_STATISTICS = `${prefix}/LOAD_EVENTS_STATISTICS`;
export const LOADED_EVENTS_STATISTICS = `${prefix}/LOADED_EVENTS_STATISTICS`;
export const LOADING_EVENTS_STATISTICS = `${prefix}/LOADING_EVENTS_STATISTICS`;

export const LOAD_COMPLEX_BY_RADIUS = `${prefix}/LOAD_COMPLEX_BY_RADIUS`;
export const LOADED_COMPLEX_BY_RADIUS = `${prefix}/LOADED_COMPLEX_BY_RADIUS`;

export const LOAD_CAMERA_VIOLATIONS_TREE = `${prefix}/LOAD_CAMERA_VIOLATIONS_TREE`;
export const LOADED_CAMERA_VIOLATIONS_TREE = `${prefix}/LOADED_CAMERA_VIOLATIONS_TREE`;
export const LOADING_CAMERA_VIOLATIONS_TREE = `${prefix}/LOADING_CAMERA_VIOLATIONS_TREE`;

export const LOAD_PDD_REPORT_EXPANDED = `${prefix}/LOAD/PDD/REPORT/EXPANDED`;

export const LOAD_MATERIAL_EXPORT_GROUP = `${prefix}/LOAD_MATERIAL_EXPORT_GROUP`;
export const LOADING_MATERIAL_EXPORT_GROUP = `${prefix}/LOADING_MATERIAL_EXPORT_GROUP`;
export const LOADED_MATERIAL_EXPORT_GROUP = `${prefix}/LOADED_MATERIAL_EXPORT_GROUP`;
export const CREATE_MATERIAL_EXPORT_GROUP = `${prefix}/CREATE_MATERIAL_EXPORT_GROUP`;
export const EDIT_MATERIAL_EXPORT_GROUP = `${prefix}/EDIT_MATERIAL_EXPORT_GROUP`;
export const DELETE_MATERIAL_EXPORT_GROUP = `${prefix}/DELETE_MATERIAL_EXPORT_GROUP`;
export const LOAD_MATERIAL_EXPORT_GROUP_TYPES = `${prefix}/LOAD_MATERIAL_EXPORT_GROUP_TYPES`;
export const LOADED_MATERIAL_EXPORT_GROUP_TYPES = `${prefix}/LOADED_MATERIAL_EXPORT_GROUP_TYPES`;
export const LOAD_MATERIAL_EXPORT_GROUP_PROTOCOLS = `${prefix}/LOAD_MATERIAL_EXPORT_GROUP_PROTOCOLS`;
export const LOADED_MATERIAL_EXPORT_GROUP_PROTOCOLS = `${prefix}/LOADED_MATERIAL_EXPORT_GROUP_PROTOCOLS`;

export const LOAD_RECENT_PDD_REPORT_EXPANDED = `${prefix}LOAD_RECENT_PDD_REPORT_EXPANDED`;

export const LOAD_TYPE_OF_MISTAKES_FOR_CHECKLIST = `${prefix}LOAD_TYPE_OF_MISTAKES_FOR_CHECKLIST`;
export const LOADED_TYPE_OF_MISTAKES_FOR_CHECKLIST = `${prefix}LOADED_TYPE_OF_MISTAKES_FOR_CHECKLIST`;
export const LOADING_TYPE_OF_MISTAKES_FOR_CHECKLIST = `${prefix}LOADING_TYPE_OF_MISTAKES_FOR_CHECKLIST`;
export const CREATE_TYPE_OF_MISTAKES_FOR_CHECKLIST = `${prefix}CREATE_TYPE_OF_MISTAKES_FOR_CHECKLIST`;
export const EDIT_TYPE_OF_MISTAKES_FOR_CHECKLIST = `${prefix}EDIT_TYPE_OF_MISTAKES_FOR_CHECKLIST`;
export const DELETE_TYPE_OF_MISTAKES_FOR_CHECKLIST = `${prefix}DELETE_TYPE_OF_MISTAKES_FOR_CHECKLIST`;

export const LOAD_EVENT_PDDS = `${prefix}LOAD_EVENT_PDDS`;
export const LOADING_EVENT_PDDS = `${prefix}LOADING_EVENT_PDDS`;
export const LOADED_EVENT_PDDS = `${prefix}LOADED_EVENT_PDDS`;
export const CLEAR_EVENT_PDDS = `${prefix}CLEAR_EVENT_PDDS`;

export const LOAD_SETTINGS_GROUP = `${prefix}/LOAD_SETTINGS_GROUP`;
export const LOADING_SETTINGS_GROUP = `${prefix}/LOADING_SETTINGS_GROUP`;
export const LOADED_SETTINGS_GROUP = `${prefix}/LOADED_SETTINGS_GROUP`;
export const EDIT_SETTINGS_GROUP = `${prefix}/EDIT_SETTINGS_GROUP`;

export const LOAD_SHOW_CASE_COMPLEX_STATUS = `${prefix}/LOAD_SHOW_CASE_COMPLEX_STATUS`;
export const LOADED_SHOW_CASE_COMPLEX_STATUS = `${prefix}/LOADED_SHOW_CASE_COMPLEX_STATUS`;
export const LOADING_SHOW_CASE_COMPLEX_STATUS = `${prefix}/LOADING_SHOW_CASE_COMPLEX_STATUS`;

export const LOAD_SHOW_CASE_DOCUMENTS_VERIFICATION = `${prefix}/LOAD_SHOW_CASE_DOCUMENTS_VERIFICATION`;
export const LOADED_SHOW_CASE_DOCUMENTS_VERIFICATION = `${prefix}/LOADED_SHOW_CASE_DOCUMENTS_VERIFICATION`;
export const LOADING_SHOW_CASE_DOCUMENTS_VERIFICATION = `${prefix}/LOADING_SHOW_CASE_DOCUMENTS_VERIFICATION`;

export const LOAD_DUPLO_CODES = `${prefix}/LOAD_DUPLO_CODES`;
export const LOADED_DUPLO_CODES = `${prefix}/LOADED_DUPLO_CODES`;
export const LOADING_DUPLO_CODES = `${prefix}/LOADING_DUPLO_CODES`;
export const EDIT_DUPLO_CODES = `${prefix}/EDIT_DUPLO_CODES`;

export const LOAD_MATERIALS = `${prefix}/LOAD_MATERIALS`;
export const LOADED_MATERIALS = `${prefix}/LOADED_MATERIALS`;
export const LOADING_MATERIALS = `${prefix}/LOADING_MATERIALS`;
export const CREATE_MATERIAL = `${prefix}/CREATE_MATERIAL`;
export const EDIT_MATERIAL = `${prefix}/EDIT_MATERIAL`;
export const DELETE_MATERIAL = `${prefix}/DELETE_MATERIAL`;

export const MOVE_COMPUTER_TO_OTHER_COMPLEX = `${prefix}/MOVE_COMPUTER_TO_OTHER_COMPLEX`;

export const LOAD_MATERIAL_EXPORT = `${prefix}/LOAD_MATERIAL_EXPORT`;
export const LOADED_MATERIAL_EXPORT = `${prefix}/LOADED_MATERIAL_EXPORT`;
export const LOADING_MATERIAL_EXPORT = `${prefix}/LOADING_MATERIAL_EXPORT`;
export const CREATE_MATERIAL_EXPORT = `${prefix}/CREATE_MATERIAL_EXPORT`;
export const EDIT_MATERIAL_EXPORT = `${prefix}/EDIT_MATERIAL_EXPORT`;
export const DELETE_MATERIAL_EXPORT = `${prefix}/DELETE_MATERIAL_EXPORT`;
export const LOAD_MATERIAL_EXPORT_TYPES = `${prefix}/LOAD_MATERIAL_EXPORT_TYPES`;
export const LOADED_MATERIAL_EXPORT_TYPES = `${prefix}/LOADED_MATERIAL_EXPORT_TYPES`;
export const LOAD_MATERIAL_EXPORT_PROTOCOLS = `${prefix}/LOAD_MATERIAL_EXPORT_PROTOCOLS`;
export const LOADED_MATERIAL_EXPORT_PROTOCOLS = `${prefix}/LOADED_MATERIAL_EXPORT_PROTOCOLS`;
export const LOAD_MATERIAL_EXPORT_STATUSES = `${prefix}/LOAD_MATERIAL_EXPORT_STATUSES`;
export const LOADED_MATERIAL_EXPORT_STATUSES = `${prefix}/LOADED_MATERIAL_EXPORT_STATUSES`;
export const CANCEL_MATERIAL_EXPORT = `${prefix}/CANCEL_MATERIAL_EXPORT`;

export const LOAD_COMPLEX_CONDITIONS = `${prefix}/LOAD_COMPLEX_CONDITIONS`;
export const LOADED_COMPLEX_CONDITIONS = `${prefix}/LOADED_COMPLEX_CONDITIONS`;

export const LOAD_GRZ_COLORS = `${prefix}/LOAD_GRZ_COLORS`;
export const LOADED_GRZ_COLORS = `${prefix}/LOADED_GRZ_COLORS`;

export const LOAD_GRZ_COUNTRIES = `${prefix}/LOAD_GRZ_COUNTRIES`;
export const LOADING_GRZ_COUNTRIES = `${prefix}/LOADING_GRZ_COUNTRIES`;
export const LOADED_GRZ_COUNTRIES = `${prefix}/LOADED_GRZ_COUNTRIES`;
