import React from 'react';
import { useDispatch } from 'react-redux';
import {
    difference,
} from 'lodash';

import { loadRadioTypeList } from 'redux/TransportPassenger/actions';
import { useValidation } from 'helpers/hooks';
import FormInfoWrapper from 'components/common/FormInfoWrapper';

import Field from './Field';
import fieldsTypes from './fieldsTypes';



const CharacteristicsFields = (props) => {
    const {
        equipments,
        values,
        onChange,
        prefix,
        test_id_prefix = '',
    } = props;
    const dispatch = useDispatch();
    const validation = useValidation();
    const getRadioList = (params) => {
        const { page, limit, query, ids } = params;
        dispatch(loadRadioTypeList(page, limit, { title: query, ids }));
    };

    const fieldsOption = {
        carnum: null,
        communication_type: {
            type: fieldsTypes.universalSelect,
            params: {
                options: {
                    storeName: 'transportPassenger',
                    storeNameProps: 'radioType',
                    storeLoadingProps: 'loadingRadioType',
                    keyProp: 'id',
                    fetchList: getRadioList,
                    renderProps: (el) => <div>{el.name}</div>
                },
            },
        },
        phone: {
            type: fieldsTypes.textPhoneMask,
        },
        number_sim: null,
        serial: null,
        caption: null,
        bicycle_racks: {
            type: fieldsTypes.radioBox,
        },
        is_invalid: {
            type: fieldsTypes.radioBox,
        },
        useful_area: {
            type: fieldsTypes.textFieldInteger,
        },
        curb_weight: {
            type: fieldsTypes.textFieldInteger,
        },
        speed: {
            type: fieldsTypes.textFieldInteger,
            params: {
                small: 0,
            }
        },
        overall_height: {
            type: fieldsTypes.textFieldInteger,
            params: {
                small: 2,
            }
        },
        overall_length: {
            type: fieldsTypes.textFieldInteger,
            params: {
                small: 2,
            }
        },
        overall_width: {
            type: fieldsTypes.textFieldInteger,
            params: {
                small: 2,
            }
        },

    };

    const keysList = [
        ...Object.keys(fieldsOption),
        ...difference(Object.keys(equipments), Object.keys(fieldsOption)),
    ];

    const handleChange = (name, value) => {
        onChange({
            [name]: value,
        });
    };

    return (
        <>
            {keysList.map((key) => {
                if (key === 'serial_egts' || key === 'serial_yandex') return null;
                const label = equipments[key] || '';
                const field = fieldsOption[key] || {};

                const keyValidation = prefix ? `${prefix}.${key}` : key;

                const {
                    type = null,
                    params = {},
                } = field;

                const value = values[key] ?? '';

                return (
                    <div key={key} className="block">
                        <FormInfoWrapper error={validation.isKey(keyValidation)} helperText={validation.get(keyValidation)}>
                            <Field
                                label={label}
                                type={type}
                                params={params}
                                error={validation.isKey(keyValidation)}
                                value={value}
                                onChange={(val) => handleChange(key, val)}
                                test_id_prefix={`${test_id_prefix}/${key}`}
                            />
                        </FormInfoWrapper>
                    </div>
                );
            })}
        </>
    );
};

export default CharacteristicsFields;
