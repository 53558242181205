import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    List,
    ListItem,
    TextField
} from '@mui/material/';
import { Autocomplete } from '@mui/material';

import { loadOrganization } from 'redux/Companies/actions';
import { authSelectors } from 'redux/Auth';
import { companiesSelectors } from 'redux/Companies';
import { useValidation } from 'helpers/hooks';
import titles from 'helpers/constants/titles';
import TextMaskPhone from 'components/common/TextMaskPhone';
import AutocompleteCompanies from 'components/common/Autocomplete/Companies';
import Modal from 'components/common/Modal';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Attachments from 'components/common/Upload/Attachments';
import SingleKeyboardDateTimePicker from 'components/common/Dates/SingleKeyboardDateTimePicker';
import { fullDateTimeWithTimeZone } from 'helpers/date.config';

import RenderPasswords from './RenderPasswords';
import RenderInput from './RenderInput';
import SelectRole from './SelectRole';

const Form = ({ formData = {}, onSuccess, onClose, isOpen = false, title = titles.EDIT_USER }) => {
    const omitEmptyData = (el) => {
        return Object.keys(el).reduce((res, key) => { 
            if (el[key] || key === 'is_notification') {
                res[key] = el[key];
            };
            return res;
        }, {});
    };

    const uniqSelectedRoles = (roles) => roles.reduce((result, el) => {
        result = result || [];
        if (result.findIndex((i) => i.id === el.id) < 0) {
            result.push(el);
        }
        return result;
    }, []);

    const initialState = {
        first_name: '',
        last_name: '',
        middle_name: '',
        position: '',
        email: '',
        password: '',
        password_confirmation:'',
        phone: '',
        organization_id_list: [],
        is_notification: false,
        justification: '',
        comment: '',
        end_access_at: null,
        ...omitEmptyData(formData),
        document_link: formData?.document_link ? [formData?.document_link] : [],
        role_list: uniqSelectedRoles(formData.role_list || formData.roles || []),
    };

    const dispatch = useDispatch();
    const validation = useValidation();
    const [companyList, setCompanyList] = useState([]);
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [data, setData] = useState(initialState);
    const isNewUser = !data.id;
    const authUser = useSelector(authSelectors.authUser);
    const projects = authUser?.info?.currentService?.entity_list || [];    
    const company = useSelector(companiesSelectors.company);
    const loadingCompany = useSelector(companiesSelectors.loadingCompany);
    const [needCompanies, setNeedCompanies] = useState([]);
    
    const userOrganizations = useCallback(() => {
        return data.organization_id_list?.reduce((res, organization_id) => {
            if (company[organization_id]) res.push(company[organization_id]);
            return res;
        }, []);
    }, [company, data.organization_id_list]);
    
    const userCodes = (formData.role_list || []).reduce((res, el) => {
        res[el.id] = res[el.id] || [];
        const userProject = projects.find((e) => (e.code === el.entity_code));
        if (userProject) {
            res[el.id].push(userProject);
        }

        return res;
    }, {});
    const [entity_codes, setEntityCode] = useState(userCodes);

    useEffect(() => {
        const needLoadOrganizations = data?.organization_id_list?.reduce((res, organization_id) => {
            if (!company[organization_id]) res.push(organization_id);
            return res;
        }, []);
        setNeedCompanies(needLoadOrganizations);
    }, [data.organization_id_list]);

    useEffect(() => {        
        if (!loadingCompany && needCompanies?.length > 0) {
            dispatch(loadOrganization(needCompanies));
            setNeedCompanies([]);
        }
    }, [loadingCompany, needCompanies, dispatch]);
    
    useEffect(() => {
        if (!loadingCompany) {
            setCompanyList(userOrganizations());
        }
    }, [loadingCompany, userOrganizations]);
    
    const handleChange = (event) => {
        setButtonDisabled(false);
        event.preventDefault();
        const { name, value } = event.target;
        if (validation[name]) validation.deleteKey(name);
        setData({ ...data, [name]: value });
        if (name === 'password_confirmation' && value !== data.password) {
            validation['password_confirmation'] = 'Значения полей Подтверждение пароля и Пароль должны совпадать.';
        }
    };

    const handleChangeDocs = (value) => {
        setData({ ...data, document_link: [value] });
    };

    const handleChangeCompanyList = (orgList) => {
        setButtonDisabled(false);
        setCompanyList(orgList);
    };

    const saveUser = () => {
        setButtonDisabled(true);
        // находим роли для проектов, недоступных текущему authUser, чтобы эти роли не потерять
        const rolesFromOtherProjects = (formData.role_list || []).filter((element) => {
            return !projects.find((item) => item.code === element.entity_code);
        }) || [];
        
        const newRoleList = data.role_list.reduce((result, el) => {
            if (entity_codes[el.id]) {
                // если код роли в выбранных проектах есть - делаем массив ролей добаляя им коды     
                entity_codes[el.id].forEach((item) => result.push({ ...el, entity_code: item.code }));
            }
            return result;
        }, []);
        const newData = {
            ...data,
            role_list: newRoleList.concat(rolesFromOtherProjects),
            organization_id_list: companyList?.map((el) => el.id),
            document_link: data?.document_link[0],
            end_access_at: data?.end_access_at && fullDateTimeWithTimeZone(data?.end_access_at)
        };
        const result = omitEmptyData(newData);
        validation.clear();
        onSuccess(result);
    };

    const handleRoleChange = (list) => {
        setButtonDisabled(false);
        const codes = list.reduce((res, role) => {
            res[role.id] = projects;
            return res;
        }, {});
        handleChangeCodes(codes);
        setData({ ...data, role_list: list });
        if (validation.role_id_list) validation.deleteKey('role_id_list');
    };

    const handleChangeCodes = (newCodes = {}) => {
        setEntityCode({
            ...entity_codes,
            ...newCodes
        });
        setButtonDisabled(false);
    };
    
    const handleChangeCheckbox = (e) => {
        const { name } = e.target;
        setButtonDisabled(false);
        setData({ ...data, [name]: !data[name] });
    };

    const fields = {
        first_name: {
            label: 'Имя',
            name: 'first_name',
            required: false,
            disabled: false,
            type: 'text'
        },
        last_name: {
            label: 'Фамилия',
            name: 'last_name',
            required: false,
            disabled: false,
            type: 'text'
        },
        middle_name: {
            label: 'Отчество',
            name: 'middle_name',
            required: false,
            disabled: false,
            type: 'text'
        },
        email: {
            label: 'Email',
            name: 'email',
            required: true,
            disabled: !isNewUser,
            type: 'email'
        },
        position: {
            label: 'Должность',
            name: 'position',
            required: false,
            disabled: false,
            type: 'text'
        },
        justification: {
            label: 'Обоснование предоставления прав',
            name: 'justification',
            required: true,
            disabled: false,
            type: 'text'
        },
        comment: {
            label: 'Комментарий ',
            name: 'comment',
            required: false,
            disabled: false,
            type: 'text'
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            title={title}
            noPadding
            buttons={<FormButtons
                buttons={[
                    {
                        ...buttonsTypes.cancel,
                        onClick: onClose
                    },
                    {
                        ...buttonsTypes.save,
                        onClick: saveUser,
                        disabled: isButtonDisabled
                    }
                ]}
            />}
        >
            <div className="modal__form">
                <FormGroup className="row">
                    <RenderInput
                        field={fields.last_name}
                        onChange={handleChange}
                        validation={validation.get('last_name')}
                        value={data.last_name}
                    />
                    <RenderInput
                        field={fields.first_name}
                        onChange={handleChange}
                        validation={validation.get('first_name')}
                        value={data.first_name}
                    />
                    <RenderInput
                        field={fields.middle_name}
                        onChange={handleChange}
                        validation={validation.get('middle_name')}
                        value={data.middle_name}
                    />
                </FormGroup>
                <FormGroup className="row">
                    <FormControl
                        className="row__item"
                        style={{ marginRight: 10 }}
                    >
                        <TextField
                            label={titles.PHONE_NUMBER}
                            // helperText={helperText}
                            error={validation.isKey('phone')}
                            value={data.phone}
                            onChange={handleChange}
                            variant="outlined"
                            size="small"
                            name="phone"
                            InputProps={{
                                inputComponent: TextMaskPhone,
                            }}
                        />
                    </FormControl>
                    <RenderInput
                        className="row__item"
                        field={fields.email}
                        onChange={handleChange}
                        validation={validation.get('email')}
                        value={data.email}
                    />
                </FormGroup>
                {isNewUser && (
                    <RenderPasswords
                        password={data.password}
                        onChange={handleChange}
                        validation={validation}
                        password_confirmation={data.password_confirmation}
                    />
                )}
                <FormGroup className="block">
                    <RenderInput
                        field={fields.position}
                        onChange={handleChange}
                        validation={validation.get('position')}
                        value={data.position}
                    />
                </FormGroup>
                <div className="block">
                    <AutocompleteCompanies
                        onChange={handleChangeCompanyList}
                        selected={companyList}
                        helperText={validation.get('organization_id_list')}
                        error={validation.isKey('organization_id_list')}
                        multiple
                        // required
                        filter={{ withDeleted: 1 }}
                    />
                </div>
                <SingleKeyboardDateTimePicker
                    onChange={(value) => setData({ ...data, end_access_at: value })}
                    value={data.end_access_at}
                    label={titles.END_ACCESS_DATE}
                    error={validation.isKey('end_access_at')}
                    helperText={validation.get('end_access_at')}
                    className="block"
                />
                <div className="block">
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={data.is_notification}
                                name={'is_notification'}
                                onChange={handleChangeCheckbox}
                            />
                        } 
                        label="Информировать пользователя об изменениях" 
                    />
                </div>
                {/* role */}
                <SelectRole
                    role_list={data.role_list}
                    onRoleChange={handleRoleChange}
                    validation={validation.get('role_list')}
                    multiple
                    required
                />
                {Object.keys(entity_codes)?.length > 0
                    && <div>
                        <h3>Укажите, в каких проектах будут использоваться роли</h3>
                        <List>
                            {data.role_list.map((item) => {

                                return (
                                    <ListItem key={`${item.id}_${item.entity_code}`} dense divider className="row align-items-center">
                                        <div className="row__item">{item?.description}</div>
                                        <Autocomplete
                                            className="row__item"
                                            multiple
                                            value={entity_codes[item.id] || projects}
                                            options={projects}
                                            filterSelectedOptions
                                            getOptionLabel={(option) => option.name}
                                            isOptionEqualToValue={(option, value) => (option.id === value.id)}
                                            size="small"
                                            onChange={(e, v) => handleChangeCodes({ [item.id]: v })}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    size="small"
                                                    variant="outlined"
                                                    disabled
                                                    label="Проект для роли"
                                                />
                                            )}
                                        />
                                    </ListItem>
                                );
                            })}
                        </List>
                    </div>
                }
                <h3>Укажите причину предоставления прав</h3>
                <div className="block">
                    <RenderInput
                        field={fields.justification}
                        onChange={handleChange}
                        validation={validation.get('justification')}
                        value={data.justification}
                    />
                </div>
                <div className="block">
                    <RenderInput
                        field={fields.comment}
                        onChange={handleChange}
                        validation={validation.get('comment')}
                        value={data.comment}
                    />
                </div>
                <Attachments
                    label={'Документы'}
                    files={data?.document_link || []}
                    onChange={handleChangeDocs}
                    onlyLink
                    singleFile
                />
            </div>
        </Modal>
    );
};

export default Form;
