import React from 'react';
import Scrollbars from 'react-custom-scrollbars';

import Information from 'components/common/Information';
import messages from 'helpers/constants/messages';

const LocationTab = ({ marker }) => {
    return (
        <Scrollbars style={{ height: '300px' }}>
            <Information
                data={{ ...marker, ...marker.vehicle }}
                title={{
                    short_name: {
                        title: 'Позывной',
                    },
                    name: {
                        title: 'Название',
                    },
                    type_text: {
                        title: 'Тип судна и вид груза',
                    },
                    navigation_status_text: {
                        title: 'Статус навигации',
                    },
                    turning_speed: {
                        title: 'Скорость поворота',
                    },
                    speed: {
                        title: 'Скорость движения',
                    },
                    navigation_precision_text: {
                        title: 'Точность определения позиции',
                    },
                    lon: {
                        title: 'Долгота',
                    },
                    lat: {
                        title: 'Широта',
                    },
                    course: {
                        title: 'Курс',
                    },
                    heading: {
                        title: 'Истинный курс (HDG)',
                    },
                    timestamp: {
                        title: 'Отметка времени',
                    },
                    maneuver_indicator_text: {
                        title: 'Индикатор маневра',
                    },
                    raim_flag: {
                        title: 'Флаг РАИМ',
                        value: ({ raim_flag }) => raim_flag ? 'Да' : 'Нет'
                    },
                    radio_status: {
                        title: 'Статус Радио',
                    },
                }}
                defaultValue={messages.NO_VALUE}
                returnNull
            />
        </Scrollbars>
    );
};

export default LocationTab;
