export const metro = (state) => state.metro;

export const stationStatuses = (state) => metro(state).stationStatuses;

export const stationList = (state) => metro(state).stationList;
export const stationListData = (state) => stationList(state).data;
export const stationListMeta = (state) => stationList(state).meta;
export const loadingStationList = (state) => metro(state).loadingStationList;

export const stationSidebar = (state) => metro(state).stationSidebar;
export const loadingStationSidebar = (state) => metro(state).loadingStationSidebar;

export const active = (state) => metro(state).active;
export const filters = (state) => metro(state).filters;

export const vestibulePolygon = (state) => metro(state).vestibulePolygon;
export const loadingVestibulePolygon = (state) => metro(state).loadingVestibulePolygon;

export const vestibuleList = (state) => metro(state).vestibuleList;
export const vestibuleListtData = (state) => vestibuleList(state).data;
export const vestibuleListtMeta = (state) => vestibuleList(state).meta;
export const loadingVestibuleList = (state) => metro(state).loadingVestibuleList;

export const vestibuleStatuses = (state) => metro(state).vestibuleStatuses;

export const sidebar = (state) => metro(state).sidebar;
export const sidebarLoading = (state) => metro(state).sidebarLoading;

export const activeVestibule = (state) => metro(state).activeVestibule;
export const filtersVestibule = (state) => metro(state).filtersVestibule;

export const vestibuleTraffic = (state) => metro(state).vestibuleTraffic;
export const loadingVestibuleTraffic = (state) => metro(state).loadingVestibuleTraffic;
