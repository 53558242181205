import { loadGRZColors } from 'redux/DorisControl/actions';
import { useStoreProp } from 'helpers/hooks';
import CommonAutocomplete from 'components/common/Autocomplete/CommonAutocomplete';
import type { CommonAutocompleteProps } from 'components/common/Autocomplete/CommonAutocomplete';

interface GRZColor {
    id: number;
    name: string;
}

function SelectGrzColor({
    multiple,
    selected,
    onChange,
    label = 'Цвет номера',
    renderLabel = (option: GRZColor | null) => option ? option.name : '',
}: CommonAutocompleteProps<GRZColor>) {
    const grzColors: GRZColor[] = useStoreProp(loadGRZColors, 'dorisControl', 'grzColors');

    return (
        <CommonAutocomplete
            multiple={multiple}
            selected={selected}
            onChange={onChange}
            limitTags={1}
            label={label}
            showAsSelect
            options={grzColors}
            renderLabel={renderLabel}
            isOptionEqualToValue={(option, value) => Number(option?.id) === Number(value?.id)}
        />
    );
}

export default SelectGrzColor;
