import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { loadDuploCodes } from 'redux/DorisControl/actions';

const useDuploCodes = (item = {}) => {
    const dispatch = useDispatch();

    // данные
    const [codes, setCodes] = useState([]);
    // загрузка
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const pddKeys = Object
            .keys(item.duplo_codes || {})
            .reduce((res, key) => {
                if (item.duplo_codes[key]) {
                    res.push(key);
                }
                return res;
            }, []);

        if (pddKeys.length > 0) {
            dispatch(
                loadDuploCodes(
                    1,
                    pddKeys.length,
                    { ids: pddKeys },
                    setCodes,
                    setLoading
                )
            );
        }
    }, [dispatch, item.duplo_codes, item.id]);

    // объединение данных
    const returnData = codes;
    returnData.loading = loading;

    return returnData;
};

export default useDuploCodes;
