import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadStationsList } from 'redux/TransportPassenger/actions';
import { transportPassengerSelectors } from 'redux/TransportPassenger';
import messages from 'helpers/constants/messages';
import titles from 'helpers/constants/titles';
import Context from 'helpers/context';
import FormButtonsComponent, { buttonsTypes } from 'components/common/FormButtons';
import Loading from 'components/common/Loading';
import { LSContainer } from 'components/common/List';
import PageLayout from 'components/layout/PageLayout';

import StationModal from './StationModal';
import StationItem from './StationItem';
import Filters from './Filters';

const Stations = () => {
    const dispatch = useDispatch();
    const { permissions } = useContext(Context);

    const stationsListData = useSelector(transportPassengerSelectors.stationsListData);
    const stationsListMeta = useSelector(transportPassengerSelectors.stationsListMeta);
    const loading = useSelector(transportPassengerSelectors.loading);

    const [openModal, setOpenModal] = useState(false);
    const [limit, setLimit] = useState(Number(localStorage.getItem('limit')) || 25);
    const [params, setParams] = useState({ page: 1, data: {} });

    const reloadList = (shouldGoToPreviousPage = false) => {
        dispatch(loadStationsList(
            shouldGoToPreviousPage ? params.page - 1 : params.page,
            limit,
            params.data
        ));
    };

    useEffect(() => {
        dispatch(loadStationsList(params.page, limit, params.data));
    }, [dispatch, limit, params]);

    const renderContent = () => {
        return <>
            {loading && <Loading circular={true}/>}
            {stationsListData?.length > 0
                ? (
                    <LSContainer
                        headers={[
                            { title: 'ID', width: '5%' },
                            { title: titles.NAME, width: '20%' },
                            { title: titles.TYPE_OF_VEHICLE, width: '20%' },
                            { title: 'Обязательная', width: '20%' },
                            { title: titles.DIRECTION, width: '20%' },
                            { title: 'Действия', align: 'right', isActions: true }
                        ]}
                    >
                        {stationsListData.map((item, index, arr) => (
                            <StationItem
                                key={item.id}
                                item={item}
                                reloadList={(isDelete) => reloadList(isDelete && !arr.at(index + 1))}
                            />
                        ))}
                    </LSContainer>
                )
                : (!loading && <div>{messages.DATA_IS_NOT_FOUND}</div>)
            }
        </>;
    };

    return (
        <>
            <PageLayout
                header={titles.STATIONS}
                filters={<Filters setParams={setParams}/>}
                informPanelProps={{
                    buttons:  (
                        <>
                            <FormButtonsComponent
                                buttons={[
                                    {
                                        ...buttonsTypes.add,
                                        onClick: () => setOpenModal(true),
                                        disabled: !permissions?.is_create,
                                    }
                                ]}
                                positionLeft
                                noPadding
                            />
                        </>),

                    total: stationsListMeta?.total
                }}
                content={renderContent}
                paginationProps={{
                    loadList: (page) => setParams({ ...params, page }),
                    list: stationsListMeta,
                    limit,
                    setLimit
                }}
            />

            {openModal && (
                <StationModal
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                    isNew={true}
                    reloadList={() => setParams({ page: 1, data: {} })}
                />
            )}
        </>
    );
};

export default Stations;
