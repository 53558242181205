import messages from 'helpers/constants/messages';

import type { ClassNameMap } from '@mui/styles';
import type { Checkpoint } from '../types';

interface RenderCategoryProps {
    item: Checkpoint;
    styles: ClassNameMap<'item' | 'item_text' | 'row'>;
}

const Category = ({ item, styles }: RenderCategoryProps) => {
    return (
        <span className={styles.item} style={{ wordBreak: 'break-word' }}>
            {item.station?.type_list && item.station?.type_list.length > 0
                ? item.station.type_list.map((el) => (
                    <div key={el.id}>{el.station_name}</div>
                ))
                : messages.NO_VALUE
            }
        </span>
    );
};

export default Category;
