import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    Marker,
    ToolTip,
} from '../../../../MapComponents/leaflet';
import mapHelper from '../../../../../helpers/mapHelper';
import { useDebounce } from '../../../../../helpers/hooks';
import * as trActions from '../../../../../redux/TransportRoad/actions';
import { transportRoadSelectors } from '../../../../../redux/TransportRoad';
import { colors, icon } from '../helper';
import MarkerToolTip from '../MarkerToolTip';


const Stations = ({
    selected = [],
    onAdd,
    onDelete,
    ...props
}) => {
    const currentService = {
        load: trActions.loadCheckPointPolygon,
        clear: trActions.loadedCheckPointPolygon,
        polygon: transportRoadSelectors.checkPointsPolygon,
    };

    const { map } = props;
    const dispatch = useDispatch();
    const polygon = useSelector(currentService.polygon);
    // const polygon = useSelector(transportPassengerSelectors.stationPolygon);

    // грузим полигон
    const fetchPolygon = () => {
        const polygon = mapHelper.getGeometryPolygon(map);
        dispatch(currentService.load({ polygon }));
    };

    // задерживаем одновременные запросы
    const debounceFetchPolygon = useDebounce(fetchPolygon, 800);
    const handleFetchPolygon = (e) => debounceFetchPolygon();

    useEffect(() => {
        fetchPolygon();
    }, []);

    useEffect(() => {
        map
            .on('moveend', handleFetchPolygon)
            .on('zoomend', handleFetchPolygon);

        return () => {
            dispatch(currentService.clear([]));
            map
                .off('moveend', handleFetchPolygon)
                .off('zoomend', handleFetchPolygon);
        };
    }, []);

    const getKey = (item) => {
        if (item?.id) return `item_${item?.id}`;
        if (item?.check_point?.id) return `check_point_${item?.check_point?.id}`;
        return '';
    };

    // создание маркера
    const createMarker = (isSelect = true) => (item, index) => {
        const {
            lat,
            lon
        } = item.check_point || item;

        // цвет
        const color = isSelect
            ? colors.isStation
            : colors.disabled;

        const key = getKey(item) || `index_${index + 1}`;

        return (
            <Marker
                {...props}
                key={key}
                icon={icon(color)}
                latlng={[lat, lon]}
                onClick={() => {
                    isSelect
                        ? onDelete(index)
                        : onAdd(item);
                }}
            >
                <ToolTip
                    offset={[0, -30]}
                    direction="top"
                >
                    <MarkerToolTip
                        isStation={false}
                        name={item?.name || item?.check_point?.name || ''}
                        interval={item?.interval}
                        mileage={item?.mileage}
                    />
                </ToolTip>
            </Marker>
        );
    };

    // индексы выбранных остановок
    const selectedIndex = useMemo(() => selected
        ?.map(item => item?.check_point_id || item?.check_point?.id)
    , [selected]);

    // собираем остановки для отображения
    const stationsList = useMemo(() => {
        return polygon.reduce((res, item, index) => {
            if (
                selectedIndex.length === 0
                || !selectedIndex.includes(item.id)
            ) {
                res.push(createMarker(false)(item, index));
            }

            return res;
        }, []);

    }, [polygon, selectedIndex]);

    const selectedList = useMemo(() => selected?.map(createMarker(true)), [selected]);

    return (
        <>
            {/* все остановки не выбранные */}
            {stationsList}
            {/* выбранные остановки */}
            {selectedList}
        </>
    );
};

export default Stations;
