import { useState } from 'react';
import {
    TextField
} from '@mui/material';

import { loadOrderStatuses } from 'redux/RoadWorks/actions';
import titles from 'helpers/constants/titles';
import {
    useStoreProp,
    useValidation
} from 'helpers/hooks';
import DateRange from 'components/common/Dates/DateRange';
import LayoutFilter from 'components/layout/PageLayout/LayoutFilter';
import OrderTypesRWSelect from 'components/common/Autocomplete/OrderTypesRW';
import StatusesSelect from 'components/common/Autocomplete/Statuses';
import FormInfoWrapper from 'components/common/FormInfoWrapper';
import MultipleInput from 'components/common/Autocomplete/MultipleInput';


const Filter = ({ setFilterValues, initState }) => {
    const validation = useValidation();
    const orderStatuses = useStoreProp(loadOrderStatuses, 'roadworks', 'orderStatuses');
    const [data, setData] = useState(initState);

    const [disabled, setDisabled] = useState(false);

    const handleChange = (value, key) => {
        validation.clear(key);
        setData({
            ...data,
            [key]: value
        });
    };

    const search = () => {
        setFilterValues(data);
    };

    const resetFilter = (needSearch) => {
        validation.clear();
        setData(initState);

        if (needSearch) {
            setFilterValues(initState);
        }
    };

    const updateFilter = (filters) => {
        setData({
            ...initState,
            ...filters,
        });
        if (Object.keys(filters).length > 0) {
            setFilterValues({
                ...initState,
                ...filters,
            });
        }
    };

    return (
        <LayoutFilter
            onResetFilter={resetFilter}
            onSearch={search}
            disabled={disabled}
            filter={data}
            filterException={['date_start', 'date_end']} // поля которые не надо сохранять пользователю на бэк (даты точно никогда не сохраняем)
            setUserFilter={updateFilter}
        >
            <LayoutFilter.Visible>
                <DateRange
                    valueStartDate={data.start_date}
                    valueEndDate={data.end_date}
                    handleDate={(newDate) => handleChange(newDate, 'start_date')}
                    handleEndDate={(newDate) => handleChange(newDate, 'end_date')}
                    dateOnly
                    // required={selectedReportFormats.length > 0}
                    renderError={() => validation.isKeys(['start_date', 'end_date'])}
                    errorMessage={() => (
                        <>
                            <span>{validation.get('start_date')}</span><br/>
                            <span>{validation.get('end_date')}</span>
                        </>
                    )}
                    isDisabledHandler={setDisabled}
                />
                <TextField
                    variant="outlined"
                    size="small"
                    value={data.name}
                    label="Название"
                    onChange={({ target: { value } }) => handleChange(value, 'name')}
                />

                <OrderTypesRWSelect
                    onChange={(value) => setData({ ...data, type: value })}
                    selected={data.type}
                    helperText={validation.get('type')}
                    error={validation.isKey('type')}
                    label="Тип"
                />
            </LayoutFilter.Visible>
            <LayoutFilter.Invisible>
                <StatusesSelect
                    selected={data.status}
                    options={orderStatuses}
                    renderLabel={(option) => option.name || ''}
                    onChange={(value) =>setData((prev) => ({
                        ...prev,
                        status: value
                    }))
                    }
                    filterSelectedOptions
                    isOptionEqualToValue={(opt, val) => opt.id === val.id}
                    onReset={() =>setData((prev) => ({
                        ...prev,
                        status: {}
                    }))}
                />
                <div className="grid-column-2-4 row noMargin">
                    <DateRange
                        valueStartDate={data.plan_start_date}
                        valueEndDate={data.plan_end_date}
                        handleDate={(e) => setData({ ...data, plan_start_date: e })}
                        handleEndDate={(e) => setData({ ...data, plan_end_date: e })}
                        dateOnly={true}
                        startLabel={titles.DATE_PLAN_FROM}
                        endLabel={titles.DATE_PLAN_TO}
                    />
                </div>
                <div className="grid-column-1-4 row noMargin">
                    <DateRange
                        valueStartDate={data.fact_start_date}
                        valueEndDate={data.fact_end_date}
                        handleDate={(e) => setData({ ...data, fact_start_date: e })}
                        handleEndDate={(e) => setData({ ...data, fact_end_date: e })}
                        dateOnly={true}
                        startLabel={titles.DATE_FACT_FROM}
                        endLabel={titles.DATE_FACT_TO}
                    />
                </div>

                <FormInfoWrapper
                    helperText={'После ввода номера поставьте запятую, чтобы добавить.'}
                >
                    <MultipleInput
                        // label={titles.SERIAL_NUMBER}
                        label="№ заявки"
                        value={data.id_list}
                        onChange={(value) => setData({ ...data, id_list: value })}
                        blurSelect={false}
                        needClearInput={data.id_list.length === 0}
                    />
                </FormInfoWrapper>
            </LayoutFilter.Invisible>
        </LayoutFilter>
    );
};

export default Filter;
