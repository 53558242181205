import { useDispatch } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';

import {
    loadDuploCodes
} from 'redux/DorisControl/actions';
import SelectDuploCodes from 'components/common/Autocomplete/DorisControl/DuploCodes';
import RenderDuploCodesItem from 'components/common/DorisControl/RenderDuploCodesItem';

const RenderDuploCodesSelect = ({ item = [], onChange, error = false, helperText = '', field }) => {
    const dispatch = useDispatch();
    const [codes, setCodes] = useState([]);

    const duplo = useMemo(() => {
        return item.reduce((res, id) => {
            const code = codes.find((item) => item.id === id);
            if (code) {
                res.push(code);
            }
            return res;
        }, []);
    }, [item, codes]);

    const handleChange = (value) => {
        onChange(value.map(({ id }) => id));
    };

    useEffect(() => {
        // загружаем коды по их id
        if (item.length > 0){
            dispatch(loadDuploCodes(1, item.length, { ids: item }, setCodes));
        }
    }, [item]);

    return (
        <SelectDuploCodes
            multiple
            label={field.name}
            selected={duplo}
            onChange={handleChange}
            required={field.require}
            error={(duplo?.length === 0 && field.require) || error}
            helperText={helperText}
            renderLabel={(option) => <RenderDuploCodesItem item={option} hideImage className="" />}
        />
    );
};

export default RenderDuploCodesSelect;
