import { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@mui/styles';
import {
    MenuItem,
    Pagination,
    Select,
    TextField,
} from '@mui/material';
import PropTypes from 'prop-types';

import titles from 'helpers/constants/titles';

const useStyles = makeStyles({
    wrap: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#e4edef',
        padding: '0.5rem 1rem',
        borderRadius: 4,
        margin: '8px 0', // 8px 0 35px 0
        fontSize: '0.8rem'
    },
    countBlock: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'flex-end',
        '& .MuiPaginationItem-root': {
            '&.Mui-selected': {
                backgroundColor: '#6d777d',
                color: '#ffffff',
            },
            fontSize: '0.8rem'
        },
    },
    selectComponent: {
        marginLeft: 10,
        fontSize: '0.8rem'
    },
    textFieldComponent: {
        height: '26px',
        minWidth: '26px',
        marginLeft: 10,
        '& > div': {
            height: '100%'
        },
        '& input': {
            padding: '0 4px',
            fontSize: '0.8rem',
            textAlign: 'center',
        }
    },
    textFieldButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: '2px',
        padding: '0 4px',
        fontSize: '14px',
        backgroundColor: 'transparent',
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: '4px',
        height: '26px',
        width: '26px',
        color: 'rgba(0, 0, 0, 0.54)',
        cursor: 'pointer'
    }
});

const selectStyle = {
    select: {
        padding: 5,
    }
};

const CustomSelect = withStyles(selectStyle)(Select);

const CustomPagination = ({
    list,
    loadList,
    countPageElements = [10, 25, 50, 100],
    setLimit,
    isWrap,
    isUniversal,
    test_id_prefix = ''
}) => {
    const classes = useStyles();
    const last_page = (list && list.last_page) || 0;

    const [page, setPage] = useState(null);
    const [selectedPage, setSelectedPage] = useState(list.current_page || 1);

    const storageLimit = !!localStorage.getItem('limit')
        ? Number(localStorage.getItem('limit'))
        : countPageElements[1];
    const [currentLimit, setCurrentLimit] = useState(storageLimit);

    const wrapStyle = isWrap
        ? { justifyContent: 'flex-start' }
        : {};

    const handleChange = (_, value) => {
        loadList(value, currentLimit);
    };

    const handleChangeLimit = (value) => {
        localStorage.setItem('limit', value.toString());
        setCurrentLimit(Number(value));
        setLimit?.(Number(value));
        loadList(1, Number(value));
    };

    const handleChangeSelectedPage = (e) => {
        const { value } = e.target;

        if (+value || value === '') {
            setSelectedPage(+value < +list.last_page ? value : list.last_page);
        }
    };

    const handleSelectedPage = () => {
        loadList(selectedPage || 1, currentLimit);

        if (!selectedPage) {
            setSelectedPage(1);
        }
    };
    
    const onKeyDownSelectedPage = (e) => {
        if (e?.key === 'Enter') {
            loadList(selectedPage || 1, currentLimit);

            if (!selectedPage) {
                setSelectedPage(1);
            }
        }
    };

    useEffect(() => {
        return () => {
            setPage(null);
        };
    }, []);

    useEffect(() => {
        if (list.current_page && !page) {
            setPage(list.current_page);
            setSelectedPage(list.current_page);
            setCurrentLimit(storageLimit);
        }
    }, [list.current_page, page, storageLimit]);

    return (
        <>
            {last_page > 0 && (
                <div className={classes.wrap}>
                    <span>{titles.TOTAL}: {list.total}</span>
                    <div className={classes.countBlock} style={wrapStyle}>
                        {!isUniversal
                            && <div className={classes.countBlock} style={wrapStyle}>
                                <span>
                                    На странице:
                                </span>
                                <CustomSelect
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={currentLimit}
                                    onChange={(e) => handleChangeLimit(e.target.value)}
                                    className={classes.selectComponent}
                                    size="small"
                                    data-testid={`${test_id_prefix}:pagination/select`}
                                >
                                    {countPageElements.map(count => <MenuItem key={count} value={count}>{count}</MenuItem>)}
                                </CustomSelect>
                            </div>
                        }
                        {last_page > 1 && list.current_page && (
                            <>
                                <Pagination
                                    variant="outlined"
                                    shape="rounded"
                                    page={list.current_page}
                                    count={last_page}
                                    onChange={handleChange}
                                    className={classes.selectComponent}
                                    size="small"
                                />
                                <TextField
                                    value={selectedPage}
                                    className={classes.textFieldComponent}
                                    onChange={handleChangeSelectedPage}
                                    onKeyDown={onKeyDownSelectedPage}
                                    sx={{ width: `${14 + 6 * selectedPage.toString().length}px` }}
                                />
                                <button onClick={handleSelectedPage} className={classes.textFieldButton + ' fas fa-caret-right'}></button>
                            </>
                        )}
                        
                    </div>

                </div>
            )}
        </>
    );
};

export default CustomPagination;

CustomPagination.propTypes = {
    loadList: PropTypes.func,
    list: PropTypes.object,
    limit: PropTypes.number,
    setLimit: PropTypes.func,
    isUniversal: PropTypes.bool,
    isWrap: PropTypes.bool,
};

CustomPagination.defaultProps = {
    loadList: () => {},
    list: {},
    isUniversal: false,
    isWrap: false,
};
