import React from 'react';
import { ListItemSecondaryAction, ListItemText } from '@mui/material';

interface HeaderColumnProps {
    children: React.ReactNode;
    isActions?: boolean;
    width?: React.CSSProperties['width'];
    align?: React.CSSProperties['textAlign'];
}

// шапка колонка
const HeaderColumn = ({
    isActions = false,
    width = 'auto',
    align = 'left',
    children,
}: HeaderColumnProps) => {
    const ch = <strong>{children}</strong>;

    return isActions
        ? (
            <ListItemSecondaryAction className="item no-padding">
                {ch}
            </ListItemSecondaryAction>
        )
        : (
            <ListItemText
                style={{
                    width,
                    maxWidth: width,
                    textAlign: align,
                }}
                className="item"
                disableTypography
            >
                {ch}
            </ListItemText>
        );
};

export default HeaderColumn;
