import React from 'react';
import { Paper, Typography } from '@mui/material';
import cn from 'classnames';

import styles from './infoBlock.module.scss';

interface InfoBlockProps {
    label?: string;
    mt?: React.CSSProperties['marginTop']
    children: React.ReactNode;
    className?: string
}

// Блок для отрисовки информации с заголовком и border.
function InfoBlock({ label, mt, children, className }: InfoBlockProps) {
    return (
        <Paper className={cn(styles.paper, className)} style={{ marginTop: mt }} variant="outlined">
            <Typography className={styles.label}>
                {label}
            </Typography>

            <div>{children}</div>
        </Paper>
    );
}

export default InfoBlock;
