import { moduleName } from '../module-config';

const prefix = `${moduleName}`;

export const LOADING = `${prefix}/LOADING`;
export const LOAD_STATION_STATUSES = `${prefix}/LOAD_STATION_STATUSES`;
export const LOADED_STATION_STATUSES = `${prefix}/LOADED_STATION_STATUSES`;
export const LOADING_STATION_STATUSES = `${prefix}/LOADING_STATION_STATUSES`;

export const LOAD_STATION_LIST = `${prefix}/LOAD_STATION_LIST`;
export const LOADED_STATION_LIST = `${prefix}/LOADED_STATION_LIST`;

export const LOAD_STATION_POLIGON = `${prefix}/LOAD_STATION_POLIGON`;
export const LOADED_STATION_POLIGON = `${prefix}/LOADED_STATION_POLIGON`;
export const LOADING_STATION_POLIGON = `${prefix}/LOADING_STATION_POLIGON`;

export const LOAD_STATION_SCHEDULES = `${prefix}/LOAD_STATION_SCHEDULES`;
export const LOADED_STATION_SCHEDULES = `${prefix}/LOADED_STATION_SCHEDULES`;
export const LOADING_STATION_SCHEDULES = `${prefix}/LOADING_STATION_SCHEDULES`;

export const LOAD_TRANSPORT_TYPES = `${prefix}/LOAD_TRANSPORT_TYPES`;
export const LOADED_TRANSPORT_TYPES = `${prefix}/LOADED_TRANSPORT_TYPES`;

export const SET_ACTIVE = `${prefix}/SET_ACTIVE`;
export const SET_FILTERS = `${prefix}/SET_FILTERS`;
export const SET_CLEAR_POLYGON = `${prefix}/SET_CLEAR_POLYGON`;

export const LOAD_STATION_SIDEBAR = `${prefix}/LOAD_STATION_SIDEBAR`;
export const LOADED_STATION_SIDEBAR = `${prefix}/LOADED_STATION_SIDEBAR`;
export const LOADING_STATION_SIDEBAR = `${prefix}/LOADING_STATION_SIDEBAR`;
export const CLEAR_STATION_SIDEBAR = `${prefix}/CLEAR_STATION_SIDEBAR`;
