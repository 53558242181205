import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TextField } from '@mui/material';

import { editComplex } from 'redux/DorisControl/actions';
import titles from 'helpers/constants/titles';
import useValidation from 'helpers/hooks/useValidation';
import usePddViolations from 'helpers/hooks/usePddViolations';
import messages from 'helpers/constants/messages/common';
import removeEmptyFields from 'helpers/removeEmptyFields';
import Context from 'helpers/context';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import { renderArticleName } from 'components/common/DorisControl/PddViolation/renderArticleName';

import styles from '../../vfcomplex.module.scss';

const NotificationSettings = (props) => {
    const {
        complex_id,
        currentComplex,
        onChange,
        test_id_prefix_passport = ''
    } = props;
    const { permissions } = useContext(Context);

    const dispatch = useDispatch();

    const { notification, name, pdd_violations = {} } = currentComplex;
    const violations = usePddViolations(currentComplex);
    const validation = useValidation();

    const initialState = {
        no_data_interval: notification?.no_data_interval || 15,
        two_percent_violations_to_passage: notification?.two_percent_violations_to_passage || 15,
        one_percent_violations_to_passage: notification?.one_percent_violations_to_passage || 15,
        no_violation: notification?.no_violation || Object.keys(pdd_violations).map((el) => ({
            violation: el,
            interval: 15
        })),
        interval: notification?.interval || 0,
        violation_percentage: notification?.violation_percentage || 0,
        ping_interval: notification?.ping_interval || 1,
        hourly_passage_created: notification?.hourly_passage_created || 0,
        hourly_passage_saved: notification?.hourly_passage_saved || 0,
        hourly_violations_created: notification?.hourly_violations_created || 0,
        hourly_violations_saved: notification?.hourly_violations_saved || 0,
    };
    const [notifyItems, setNotifyItems] = useState(initialState);

    useEffect(() => {
        setNotifyItems(initialState);
    }, [complex_id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNotifyItems({
            ...notifyItems,
            [name]: value
        });
    };

    const onCancel = () => {
        setNotifyItems(initialState);
    };

    const onSave = () => {
        const dataToNum = (list) => Object.keys(list).reduce((result, key) => {
            if (key === 'no_violation') {
                result[key] = list[key].map((el) => {
                    return dataToNum(el);
                });
                return result;
            }
            if (typeof list[key] === 'object' && list[key] !== null) {
                result[key] = dataToNum(list[key]);
                return result;
            }
            result[key] = parseInt(list[key]);
            return result;
        }, {});
        const newData = {
            ...currentComplex,
            notification: dataToNum(notifyItems)
        };
        dispatch(editComplex(complex_id, removeEmptyFields(newData, false), () => { onChange && onChange(); }));
    };

    const handleChangeInterval = (e, index) => {
        const { value } = e.target;
        const newValue = {
            ...notifyItems.no_violation[index],
            interval: value
        };

        setNotifyItems({
            ...notifyItems,
            no_violation: [
                ...notifyItems.no_violation.slice(0, index),
                newValue,
                ...notifyItems.no_violation.slice(index + 1),
            ]
        });
    };

    return (
        <div className={styles.container}>
            <h2>Настройки уведомлений по комплексу {name}</h2>
            <form className="modal__form">
                <div className="row">
                    <h3 className="row__item">Комплекс не на связи более</h3>
                    <TextField
                        className="row__item"
                        id="outlined"
                        label={titles.MINUTES}
                        variant="outlined"
                        size="small"
                        value={notifyItems.no_data_interval}
                        name="no_data_interval"
                        onChange={handleChange}
                        error={validation.isKey('notification.no_data_interval')}
                        helperText={validation.get('notification.no_data_interval')}
                        type="number"
                        InputProps={{
                            'data-testid': `${test_id_prefix_passport}:no_data_interval/input`,
                        }}
                        inputProps={{ min: 0 }}
                    />
                </div>
                <div className="row">
                    <h3 className="row__item">Нарушений менее 2% от числа проездов</h3>
                    <TextField
                        className="row__item"
                        id="outlined"
                        label={titles.MINUTES}
                        variant="outlined"
                        size="small"
                        value={notifyItems.two_percent_violations_to_passage}
                        name="two_percent_violations_to_passage"
                        onChange={handleChange}
                        error={validation.isKey('notification.two_percent_violations_to_passage')}
                        helperText={validation.get('notification.two_percent_violations_to_passage')}
                        type="number"
                        InputProps={{
                            'data-testid': `${test_id_prefix_passport}:two_percent_violations_to_passage/input`,
                        }}
                        inputProps={{ min: 0 }}
                    />
                </div>
                <div className="row">
                    <h3 className="row__item">Нарушений менее 1% от числа проездов</h3>
                    <TextField
                        className="row__item"
                        id="outlined"
                        label={titles.MINUTES}
                        variant="outlined"
                        size="small"
                        value={notifyItems.one_percent_violations_to_passage}
                        name="one_percent_violations_to_passage"
                        onChange={handleChange}
                        error={validation.isKey('notification.one_percent_violations_to_passage')}
                        helperText={validation.get('notification.one_percent_violations_to_passage')}
                        type="number"
                        InputProps={{
                            'data-testid': `${test_id_prefix_passport}:one_percent_violations_to_passage/input`,
                        }}
                        inputProps={{ min: 0 }}
                    />
                </div>
                <div className="row">
                    <h3 className="row__item">Уведомление о формировании слишком большого кол-ва нарушений (минуты)</h3>
                    <TextField
                        className="row__item"
                        id="outlined"
                        label={titles.MINUTES}
                        variant="outlined"
                        size="small"
                        value={notifyItems.interval}
                        name="interval"
                        onChange={handleChange}
                        error={validation.isKey('notification.interval')}
                        helperText={validation.get('notification.interval')}
                        type="number"
                        InputProps={{
                            'data-testid': `${test_id_prefix_passport}:interval/input`,
                        }}
                        inputProps={{ min: 0 }}
                    />
                </div>
                <div className="row">
                    <h3 className="row__item">Уведомление о формировании слишком большого кол-ва нарушений (%)</h3>
                    <TextField
                        className="row__item"
                        id="outlined"
                        label={titles.PERCENTS}
                        variant="outlined"
                        size="small"
                        value={notifyItems.violation_percentage}
                        name="violation_percentage"
                        onChange={handleChange}
                        error={validation.isKey('notification.violation_percentage')}
                        helperText={validation.get('notification.violation_percentage')}
                        type="number"
                        InputProps={{
                            'data-testid': `${test_id_prefix_passport}:violation_percentage/input`,
                        }}
                        inputProps={{ min: 0 }}
                    />
                </div>
                <div className="row">
                    <h3 className="row__item">Комплекс не на связи N минут</h3>
                    <TextField
                        className="row__item"
                        id="outlined"
                        label={titles.MINUTES}
                        variant="outlined"
                        size="small"
                        value={notifyItems.ping_interval}
                        name="ping_interval"
                        onChange={handleChange}
                        error={validation.isKey('notification.ping_interval')}
                        helperText={validation.get('notification.ping_interval')}
                        type="number"
                        InputProps={{
                            'data-testid': `${test_id_prefix_passport}:ping_interval/input`,
                        }}
                        inputProps={{ min: 0 }}
                    />
                </div>
                <div className="block">
                    <h3>Количество проездов за последний час:</h3>
                </div>
                <div className="row">
                    <TextField
                        className="row__item"
                        id="outlined"
                        label={messages.FIXED}
                        variant="outlined"
                        size="small"
                        value={notifyItems.hourly_passage_created}
                        name="hourly_passage_created"
                        onChange={handleChange}
                        error={validation.isKey('notification.hourly_passage_created')}
                        helperText={validation.get('notification.hourly_passage_created')}
                        type="number"
                        InputProps={{
                            'data-testid': `${test_id_prefix_passport}:hourly_passage_created/input`,
                        }}
                        inputProps={{ min: 0 }}
                    />
                    <TextField
                        className="row__item"
                        id="outlined"
                        label={messages.SAVED}
                        variant="outlined"
                        size="small"
                        value={notifyItems.hourly_passage_saved}
                        name="hourly_passage_saved"
                        onChange={handleChange}
                        error={validation.isKey('notification.hourly_passage_saved')}
                        helperText={validation.get('notification.hourly_passage_saved')}
                        type="number"
                        InputProps={{
                            'data-testid': `${test_id_prefix_passport}:hourly_passage_saved/input`,
                        }}
                        inputProps={{ min: 0 }}
                    />
                </div>
                <div className="block">
                    <h3>Количество нарушений за последний час:</h3>
                </div>
                <div className="row">
                    <TextField
                        className="row__item"
                        id="outlined"
                        label={messages.FIXED}
                        variant="outlined"
                        size="small"
                        value={notifyItems.hourly_violations_created}
                        name="hourly_violations_created"
                        onChange={handleChange}
                        error={validation.isKey('notification.hourly_violations_created')}
                        helperText={validation.get('notification.hourly_violations_created')}
                        type="number"
                        InputProps={{
                            'data-testid': `${test_id_prefix_passport}:hourly_violations_created/input`,
                        }}
                        inputProps={{ min: 0 }}
                    />
                    <TextField
                        className="row__item"
                        id="outlined"
                        label={messages.SAVED}
                        variant="outlined"
                        size="small"
                        value={notifyItems.hourly_violations_saved}
                        name="hourly_violations_saved"
                        onChange={handleChange}
                        error={validation.isKey('notification.hourly_violations_saved')}
                        helperText={validation.get('notification.hourly_violations_saved')}
                        type="number"
                        InputProps={{
                            'data-testid': `${test_id_prefix_passport}:hourly_violations_saved/input`,
                        }}
                        inputProps={{ min: 0 }}
                    />
                </div>
                {notifyItems?.no_violation?.length > 0
                    && <div className="block">
                        <h3 className="row__item">Нет данных о нарушениях с комплекса</h3>
                        {notifyItems.no_violation.map((el, index) => {
                            const v = violations.find(item => item.id == el.violation);
                            return v
                                ? (
                                    <div className="row" key={el.violation}>
                                        <div className="row__item">
                                            <strong>{renderArticleName(v)}</strong>
                                        </div>
                                        <TextField
                                            className="row__item"
                                            id="outlined"
                                            label={titles.MINUTES}
                                            variant="outlined"
                                            size="small"
                                            value={el.interval}
                                            name="no_violation"
                                            onChange={(e) => handleChangeInterval(e, index)}
                                            //todo заменить валидацию
                                            error={!!validation?.no_violation?.[index]?.violation || !!validation?.no_violation?.[index]?.interval}
                                            helperText={validation?.no_violation?.[index] || validation?.no_violation?.[index]?.interval}
                                            type="number"
                                            InputProps={{
                                                'data-testid': `${test_id_prefix_passport}:no_violation/input`,
                                            }}
                                            inputProps={{ min: 0 }}
                                        />
                                    </div>
                                )
                                : null;
                        })
                        }
                    </div>
                }
                {permissions?.is_update
                    && (
                        <FormButtons
                            test_id_prefix_passport={test_id_prefix_passport}
                            buttons={[
                                {
                                    ...buttonsTypes.cancel,
                                    onClick: onCancel,
                                    testName: 'cancel'
                                },
                                {
                                    ...buttonsTypes.save,
                                    onClick: onSave,
                                    testName: 'save'
                                },
                            ]}
                        />
                    )}
            </form>

        </div>
    );

};

export default NotificationSettings;
