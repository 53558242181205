
import React, { useEffect, useRef, useState } from 'react';
import type { SyntheticEvent } from 'react';
import {
    Autocomplete,
    Chip,
    Icon,
    TextField,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ClearIcon from '@mui/icons-material/Clear';

import FormInfoWrapper from 'components/common/FormInfoWrapper';

import type { AutocompleteSelected } from 'types';

const useStyles = makeStyles({
    noOptions: {
        display: 'none'
    },
    wrapper: {
        height: '40px', // нужно для фиксации выпадающего списка
        lineHeight: '1.4375em',
    },
    input: {
        padding: '6px !important',
        paddingRight: '50px !important',
        maxHeight: '40px',
    },
    inputWithoutSearch: {
        padding: '8.5px 14px !important',
        maxHeight: '40px',
        paddingRight: '30px !important',
    },
    focused: {
        maxHeight: 'none',
        backgroundColor: '#fff',
        zIndex: 5,
    },
    adornment: {
        '& > div.MuiAutocomplete-endAdornment': {
            position: 'absolute',
            top: 7,
            '& > button.MuiAutocomplete-popupIndicator': {
                padding: 4
            },
        },
    },
    label: {
        zIndex: 6,
    },
    tag: {
        margin: '0 2px',
    },
    inputTag: {
        minWidth: '1px !important',
    },
    iconStyle: {
        fontSize: 17.5,
        paddingTop: 1,
    },
    iconWithModal: {
        fontSize: 17.5,
        paddingTop: 1,
        transform: 'rotate(-90deg)'
    }
});

export type ItemType<T> = string | T | (string | T | null)[] | null;

export interface RenderGroupParams {
    key: string;
    group: string;
    children?: React.ReactNode;
}

export interface CommonAutocompleteProps<T> {
    multiple?: boolean;
    selected: AutocompleteSelected<T>;
    onChange: (value: ItemType<T>) => void;
    onReset?: () => void; // сброс на начальное значение по крестику
    error?: boolean;
    helperText?: string;
    required?: boolean;
    disabled?:boolean;
    renderLabel?: (option: T | null) => string;
    limitTags?: number;
    inputName?: string;
    label?: string;
    onShowList?: () => void;
    options?:  (T | null)[];
    filterSelectedOptions?: boolean; // скрыть выбранное из выпадающего списка
    isOptionEqualToValue?: (option: T | null, value: T | null) => boolean;  // функция сравнивает option и value по какому-то параметру
    readOnly?: boolean;
    groupBy?: (option: T | null) => string;
    renderGroup?: (params: RenderGroupParams) => React.ReactNode;
    className?: string;
    test_id_prefix?: string;
    openOnFocus?: boolean;
    showAsSelect?: boolean;
    isOpen?: boolean;
    loading?: boolean;
}

function CommonAutocomplete<T>({
    multiple = false,
    selected,
    onChange,
    error = false,
    helperText = '',
    required = false,
    disabled = false,
    onReset,
    renderLabel = () => '',
    limitTags = 2,
    inputName = '',
    label = '',
    onShowList,
    options = [],
    filterSelectedOptions = false,
    isOptionEqualToValue,
    readOnly,
    groupBy,
    renderGroup,
    className = '',
    test_id_prefix = '',
    openOnFocus = false,
    loading = false,
    showAsSelect = false,
    isOpen
}: CommonAutocompleteProps<T>): React.ReactElement {
    const styles = useStyles();

    const [tagWidth, setTagWidth] = useState(0);
    const tagCountRef = useRef<any>(null);

    useEffect(() => {
        if (tagCountRef.current) {
            setTagWidth(tagCountRef.current.offsetWidth);
        }
    }, []);

    const autoCompleteChange = (e: SyntheticEvent, value: ItemType<T> ) => {
        onChange(value);
    };

    const autoCompleteInputChange = (e: SyntheticEvent<Element, Event>) => {
        // для предотвращения ввода в автокомплит с клавиатуры
        // если нужен будет ввод - сделаем отдельный пропс
        e.preventDefault();
        if (e.type === 'click' || !checkSelectedNotEmpty()) {
            onShowList && onShowList();
        }
    };

    function checkSelectedNotEmpty() {
        if (selected && typeof selected === 'object') {
            return Object.keys(selected).length > 0;
        }
        if (selected && Array.isArray(selected)) {
            return selected.length > 0;
        }
        return false;
    }

    return (
        <FormInfoWrapper
            error={error}
            helperText={helperText}
            className={className}
            test_id_prefix={`${test_id_prefix}/autocomplete`}
        >
            <Autocomplete
                clearText="Очистить"
                closeText="Закрыть"
                openText="Открыть"
                disableCloseOnSelect={multiple}
                limitTags={multiple ? limitTags : -1}
                onOpen={autoCompleteInputChange}
                open={isOpen}
                popupIcon={<Icon
                    classes={{ root: showAsSelect ? styles.iconStyle : styles.iconWithModal }}
                    className={multiple ? 'far fa-angle-double-down' : 'far fa-angle-down'}
                    data-testid={`${test_id_prefix}/autocomplete/show/button`}
                />}
                clearIcon={<ClearIcon
                    fontSize="small"
                    data-testid={`${test_id_prefix}/autocomplete/clear/button`}
                />}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                            {...getTagProps({ index })}
                            label={renderLabel(option)}
                            style={{ maxWidth: `calc(80% - ${tagWidth}px)` }}
                            size="small"
                            key={index}
                            data-testid={`${test_id_prefix}/autocomplete/tag`}
                        />
                    ))
                }
                getLimitTagsText={(value) => (
                    <span ref={tagCountRef}>{`+${value}`}</span>
                )}
                multiple={multiple}
                value={(checkSelectedNotEmpty() || multiple) ? selected : null}
                options={options}
                disabled={disabled}
                getOptionLabel={renderLabel}
                openOnFocus={openOnFocus}
                onChange={autoCompleteChange}
                size="small"
                classes={{ tag: styles.tag, input: styles.inputTag, noOptions: styles.noOptions }}
                filterSelectedOptions={filterSelectedOptions}
                isOptionEqualToValue={isOptionEqualToValue}
                readOnly={readOnly}
                renderInput={(params) => (
                    <TextField
                        classes={{ root: styles.wrapper }}
                        {...params}
                        size="small"
                        variant="outlined"
                        required={required}
                        name={inputName}
                        label={label}
                        disabled={disabled}
                        autoFocus={false}
                        error={error}
                        inputProps={{
                            ...params.inputProps,
                            'data-testid': `${test_id_prefix}/autocomplete/input`,
                        }}
                        InputProps={{
                            ...params.InputProps,
                            classes: {
                                root: styles.input,
                                focused: styles.focused,
                                adornedEnd: styles.adornment,
                            },
                        }}
                        InputLabelProps={{
                            classes: { focused: styles.label },
                        }}
                    />
                )}
                groupBy={groupBy}
                renderGroup={renderGroup}
            />
        </FormInfoWrapper>
    );

}

export default CommonAutocomplete;
