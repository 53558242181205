import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { List } from '@mui/material';
import { TabPanel } from '@mui/lab';

import common from 'helpers/constants/buttons/common';
import FormButtons, { buttonsTypes } from 'components/common/FormButtons';
import Loading from 'components/common/Loading';
import NoData from 'components/common/Information/NoData';

import Item from './Item';
import styles from './styles.module.scss';

import type { Action, Setting } from './types';

interface BasicTabProps {
    settings: Setting[]
    editAction: Action
    loading: boolean
    loadingButton: boolean
    reloadList: () => void
}

const BasicTab = ({
    settings,
    editAction,
    loading,
    loadingButton,
    reloadList
}: BasicTabProps) => {
    const dispatch = useDispatch();

    const [settingsState, setSettingsState] = useState<Setting[]>([]);
    const [changed, setChanged] = useState(false);

    const onChange = (id: number, value: any) => {
        setSettingsState(settingsState.map(item => item.id === id ? { ...item, value, changed: true } : item ));
        setChanged(true);
    };

    const onSave = () => {
        const prepareData = settingsState
            .filter(item => item.changed)
            .map(item => ({ id: item.id, value: item.value }));

        dispatch(editAction({ list: prepareData }, reloadList));
        setChanged(false);
    };

    const onReset = () => {
        setSettingsState(settings);
        setChanged(false);
    };

    useEffect(() => {
        if (settings.length > 0) {
            setSettingsState(settings);
        }
    }, [settings]);

    return (
        <TabPanel value="basic">

            {loading && <Loading linear/>}

            {settingsState.length > 0 ? (
                <>
                    <div className={styles.wrapper}>
                        <List className={styles.list}>
                            {settingsState.map(
                                (item, i) =>
                                    i % 2 === 0 && <Item key={item.id} item={item} onChange={onChange} />
                            )}
                        </List>

                        <List className={styles.list}>
                            {settingsState.map(
                                (item, i) =>
                                    i % 2 !== 0 && (
                                        <Item key={item.id} item={item} onChange={onChange} />
                                    )
                            )}
                        </List>
                    </div>

                    <FormButtons
                        positionLeft
                        buttons={[
                            {
                                ...buttonsTypes.resetFilter,
                                name: common.RESET,
                                disabled: !changed,
                                onClick: onReset,
                            },
                            {
                                ...buttonsTypes.save,
                                disabled: !changed,
                                loading: loadingButton,
                                onClick: onSave,
                            },
                        ]}
                    />
                </>
            ) : (
                !loading && <NoData />
            )}
        </TabPanel>
    );
};

export default BasicTab;
