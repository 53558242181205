import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import externalTransportTitles from 'modules/ExternalTransport/utils/helpers/constants';
import SingleKeyboardDateTimePicker from 'components/common/Dates/SingleKeyboardDateTimePicker';
import { loadStationSchedules } from 'modules/ExternalTransport/redux/actions';
import { externalTransportSelectors } from 'modules/ExternalTransport';
import { getHumanDate, getTimeWithoutSeconds } from 'helpers/date.config';
import messages from 'helpers/constants/messages';

import PopupTabs from './Tabs';
import DynamicTable from './DynamicTable';

// TODO: фейк данные, разобрать какие поля должны приходить
const passReportData = [
    { time: '11:00 - 12:00', platformIn: 79, platformOut: 387, total: 466 },
    { time: '12:00 - 13:00', platformIn: 641, platformOut: 524, total: 1165 },
    { time: '13:00 - 14:00', platformIn: 342, platformOut: 7, total: 349 },
    { time: '14:00 - 15:00', platformIn: 428, platformOut: 379, total: 807 },
    { time: '15:00 - 16:00', platformIn: 355, platformOut: 2, total: 357 },
    { time: '16:00 - 17:00', platformIn: 0, platformOut: 0, total: 0 },
];

const passReportHeaders = [
    { title: 'time', value: 'Время', width: '30%' },
    { title: 'platformIn', value: 'На платформу', width: '25%' },
    { title: 'platformOut', value: 'С платформы', width: '25%' },
    { title: 'total', value: 'Всего', width: '20%' },
];
const arrivalScheduleHeaders = [
    { title: 'direction', value: 'Направление', width: '40%' },
    { title: 'number', value: '№ поезда', width: '15%' },
    { title: 'days', value: 'Дни', width: '30%' }, //Дни курсирования
    { title: 'time', value: 'Прибытие', width: '15%' },
];
const departureScheduleHeaders = [
    { title: 'direction', value: 'Направление', width: '40%' },
    { title: 'number', value: '№ поезда', width: '15%' },
    { title: 'days', value: 'Дни', width: '30%' },
    { title: 'time', value: 'Отправление', width: '15%' },
];
const currentDate = new Date();

const RailwayStationTabs = ({ id }) => {
    const dispatch = useDispatch();
    const [schedule, setSchedule] = useState({ arrival: [], departure: [] });
    const [date, setDate] = useState('');
    const loading = useSelector(externalTransportSelectors.loading_station_schedules);

    useEffect(() => {
        const creteObject = (el, time) => {
            return {
                direction: el.schedule?.route?.name || messages.NO_VALUE,
                number: el.schedule?.route?.number || messages.NO_VALUE,
                days: el.schedule?.days || messages.NO_VALUE,
                time: getTimeWithoutSeconds(time),
            };
        };
        dispatch(loadStationSchedules(id, (data) => {
            const newData = data.reduce((res, el, index) => {
                res.arrival = res.arrival || [];
                res.departure = res.departure || [];
                if (el.arrival) {
                    res.arrival.push(creteObject(el, el.arrival));
                }
                if (el.departure) {
                    res.departure.push(creteObject(el, el.departure));
                }

                if (index === 0) setDate(getHumanDate(el.date)); // костыль пока нет выбора даты она нам нудна 1шт
                return res;
            }, { arrival: [], departure: [] });
            setSchedule(newData);
        }));
    }, []);

    const tabList = [
        {
            value: 'arrivalSchedule',
            label: externalTransportTitles.ARRIVAL_SCHEDULE,
            contentComponent: <DynamicTable headers={arrivalScheduleHeaders} data={schedule.arrival} />
        },
        {
            value: 'departureSchedule',
            label: externalTransportTitles.DEPARTURE_SCHEDULE,
            contentComponent: <DynamicTable headers={departureScheduleHeaders} data={schedule.departure} />
        },
        {
            value: 'passReport',
            label: externalTransportTitles.PASS_REPORT,
            contentComponent: <DynamicTable headers={passReportHeaders} data={passReportData} />
        }
    ];

    return <>
        {/* <div style={{ padding: '1rem', width: '50%' }}>
            <SingleKeyboardDateTimePicker value={date} onChange={setDate} label="Данные на дату" />
        </div> */}
        <PopupTabs tabList={tabList} loading={loading} date={date} />
    </>;

};

export default RailwayStationTabs;
