import React from 'react';

import { HeaderPageVisible } from 'components/common/Showcase/HeaderPage';
import BoxContainer from 'components/common/Showcase/BoxContainer';
import HeaderPanel from 'components/common/Showcase/HeaderPanel';
import titles from 'helpers/constants/titles';

import Page from './Page';

const DTP = () => (
    <BoxContainer>

        {/* шапка */}
        <HeaderPageVisible/>

        {/* заголовок */}
        <HeaderPanel title={titles.DTP} />

        {/* содержимое страницы */}
        <Page />

    </BoxContainer>
);

export default DTP;
