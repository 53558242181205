import PrintIcon from '@mui/icons-material/Print';

import buttons from 'helpers/constants/buttons';

// типы кнопок
const buttonsTypes = {
    print: {
        variant: 'contained',
        color: 'infoButton',
        type: 'button',
        name: 'Печать',
        startIcon: <PrintIcon fontSize="inherit" />,
        size: 'small',
        testName: 'print',
    },
    add: {
        variant: 'contained',
        color: 'infoButton',
        type: 'button',
        name: buttons.ADD,
        startIcon: <i className="fal fa-plus" style={{ fontSize: '1rem' }} />,
        size: 'small',
        accessProp: 'is_create',
        testName: 'add',
    },
    reset: {
        color: 'greyButton',
        type: 'button',
        name: buttons.RESET,
        endIcon: <i className="fal fa-times" style={{ fontSize: '1rem', }} />,
        testName: 'reset',
    },
    report: {
        variant: 'contained',
        color: 'infoButton',
        type: 'button',
        name: buttons.REPORT,
        startIcon: <i className="fas fa-chart-bar" style={{ fontSize: '1rem' }} />,
        endIcon: <i className="far fa-angle-down" style={{ fontSize: '1rem' }} />,
        size: 'small',
        testName: 'report',
    },
    upload: { // загрузить
        variant: 'contained',
        color: 'infoButton',
        type: 'button',
        name: buttons.UPLOAD,
        startIcon: <i className="far fa-arrow-from-bottom" style={{ fontSize: '1rem' }} />,
        endIcon: <i className="far fa-angle-down" style={{ fontSize: '1rem' }} />,
        size: 'small',
        testName: 'upload',
    },
    download: { // скачать
        variant: 'contained',
        color: 'infoButton',
        type: 'button',
        name: buttons.DOWNLOAD,
        startIcon: <i className="far fa-arrow-to-bottom" style={{ fontSize: '1rem' }} />,
        endIcon: <i className="far fa-angle-down" style={{ fontSize: '1rem' }} />,
        size: 'small',
        testName: 'download',
    },
    history: {
        variant: 'contained',
        color: 'infoButton',
        type: 'button',
        name: buttons.HISTORY,
        startIcon: <i className="far fa-history" style={{ fontSize: '1rem' }} />,
        size: 'small',
        testName: 'history',
    },
    delete: {
        variant: 'contained',
        color: 'infoButton',
        type: 'button',
        name: buttons.DELETE,
        startIcon: <i className="fal fa-times" style={{ fontSize: '1rem', }} />,
        size: 'small',
        accessProp: 'is_delete',
        testName: 'delete',
    },
    selectOnMap: {
        variant: 'contained',
        color: 'infoButton',
        type: 'button',
        name: buttons.SELECT_ON_MAP,
        testName: 'selectOnMap',
    },
    show: {
        variant: 'contained',
        color: 'primary',
        type: 'button',
        name: buttons.SHOW,
        testName: 'show',
    },
    edit: {
        variant: 'contained',
        color: 'primary',
        type: 'button',
        name: buttons.EDIT,
        accessProp: 'is_update',
        testName: 'edit',
    },
    editIcon: {
        icon: <i className="fas fa-pencil" />,
        name: buttons.EDIT,
        size: 'small',
        accessProp: 'is_update',
        testName: 'edit',
    },
    copyIcon: {
        icon: <i className="fas fa-copy" />,
        name: buttons.COPY,
        size: 'small',
        accessProp: 'is_create',
        testName: 'copy',
    },
    addIcon: {
        icon: <i className="far fa-plus" />,
        name: buttons.ADD,
        size: 'small',
        accessProp: 'is_create',
        testName: 'add',
    },
    deleteIcon: {
        icon: <i className="fas fa-trash" />,
        name: buttons.DELETE,
        size: 'small',
        accessProp: 'is_delete',
        testName: 'delete',
    },
    changeStatusIcon: {
        icon: <i className="fas fa-toggle-on" />,
        name: buttons.CHANGE_STATUS,
        size: 'small',
        accessProp: 'is_update',
        testName: 'change_status',
    },
    printIcon: {
        icon: <PrintIcon />,
        name: buttons.PRINT,
        size: 'small',
        testName: 'print',
    },
    historyIcon: {
        icon: <i className="far fa-history" />,
        name: buttons.HISTORY,
        size: 'small',
        testName: 'history',
    },
    mapIcon: {
        icon: <i className="fas fa-map-marked-alt" />,
        name: buttons.SHOW_ON_MAP,
        size: 'small',
        testName: 'map',
    },
    reportIcon: {
        icon: <i className="far fa-file-download" />,
        name: buttons.GET_REPORT,
        size: 'small',
        testName: 'report',
    },
    removeIcon: {
        icon: <i className="far fa-times-circle" />,
        size: 'small',
        testName: 'remove',
    },
    cancelIcon: {
        icon: <i class="fas fa-ban"/>,
        name: buttons.CANCEL,
        size: 'small',
        testName: 'cancel',
    },
    close: {
        variant: 'contained',
        color: 'inherit',
        type: 'reset',
        name: buttons.CLOSE,
        testName: 'close',
    },
    cancel: {
        variant: 'contained',
        color: 'inherit',
        type: 'reset',
        name: buttons.CANCEL,
        testName: 'cancel',
    },
    apply: {
        variant: 'contained',
        color: 'primary',
        type: 'submit',
        name: buttons.APPLY,
        testName: 'apply',
    },
    send: {
        variant: 'contained',
        color: 'primary',
        type: 'submit',
        name: buttons.SEND,
        testName: 'send',
    },
    ready: {
        variant: 'contained',
        color: 'primary',
        type: 'submit',
        name: buttons.READY,
        testName: 'ready',
    },
    save: {
        variant: 'contained',
        color: 'primary',
        type: 'button',
        name: buttons.SAVE,
        testName: 'save',
        // accessProp: 'is_update', // убираю т.к. в общем случае эти кнопки внутри модалок
        // - если мы модалку уже открыти значит можно сохранять
    },
    change: {
        variant: 'contained',
        color: 'primary',
        type: 'button',
        name: buttons.CHANGE,
        testName: 'change',
    },
    resetFilter: { // TODO удалить после замены везде на reset
        variant: 'contained',
        color: 'secondary',
        type: 'reset',
        name: buttons.RESET_FILTER,
        testName: 'reset',
    },
    allFilters: {
        // variant: 'contained',
        color: 'infoButton',
        type: 'button',
        name: 'Все параметры',
        testName: 'all_filters',
    },
    getReport: {
        variant: 'contained',
        color: 'primary',
        type: 'button',
        name: buttons.GET_REPORT,
        testName: 'get_report',
    },
    find: {
        variant: 'contained',
        color: 'primary',
        type: 'button',
        name: buttons.FIND,
        testName: 'find',
    },
    showOnMap: {
        variant: 'contained',
        color: 'secondary',
        type: 'button',
        name: buttons.SHOW_ON_MAP,
        testName: 'show_on_map',
    },
    search: {
        variant: 'contained',
        color: 'primary',
        type: 'button',
        name: buttons.SEARCH,
        testName: 'search',
    },
    assignVehicle: {
        variant: 'contained',
        color: 'primary',
        type: 'button',
        name: buttons.ASSIGN_VEHICLE
    },
    confirm: {
        variant: 'contained',
        color: 'primary',
        type: 'button',
        name: buttons.CONFIRM
    },
    back: {
        variant: 'contained',
        color: 'primary',
        type: 'button',
        name: buttons.BACK
    },
    create: {
        variant: 'contained',
        color: 'primary',
        type: 'button',
        name: buttons.CREATE,
        // accessProp: 'is_create',// убираю т.к. в общем случае эти кнопки внутри модалок
        // - если мы модалку уже открыти значит можно сохранять
    },
    selectRoute: {
        variant: 'contained',
        color: 'secondary',
        type: 'button',
        name: buttons.ADD_ROUTE
    },
    showDeleted: {
        variant: 'contained',
        color: 'primary',
        type: 'button',
        name: buttons.SHOW_DELETED
    },
    showCurrent: {
        variant: 'contained',
        color: 'primary',
        type: 'button',
        name: buttons.SHOW_CURRENT
    },
    addNewKeyword: {
        variant: 'contained',
        color: 'primary',
        type: 'button',
        name: buttons.ADD_NEW_KEYWORD
    },
    select: {
        variant: 'contained',
        color: 'primary',
        type: 'button',
        name: buttons.SELECT
    },
    selectAll: {
        variant: 'contained',
        color: 'primary',
        type: 'button',
        name: buttons.SELECT_ALL
    },
    addStation: {
        color: 'primary',
        type: 'button',
        name: buttons.ADD_STATION
    },
    addDeleteStation: {
        color: 'primary',
        type: 'button',
        name: buttons.ADD_OR_DELETE_STATION
    },
    preview: {
        color: 'secondary',
        type: 'button',
        variant: 'contained',
        name: buttons.PREVIEW
    },
    showRoutes: {
        variant: 'contained',
        color: 'primary',
        type: 'button',
        name: buttons.SHOW_ROUTES
    },
    deleteAll: {
        variant: 'contained',
        color: 'primary',
        type: 'delete',
        name: buttons.DELETE_ALL
    },
    deleteEvents: {
        variant: 'contained',
        color: 'inherit',
        type: 'deleteEvents',
        name: buttons.DELETE_EVENTS
    },
    addOrDeleteLinks: {
        variant: 'contained',
        color: 'primary',
        type: 'button',
        name: buttons.ADD_OR_DELETE_LINKS
    },
    defaultPrimary: {
        variant: 'contained',
        color: 'primary',
        type: 'button',
    },
    defaultSecondary: {
        variant: 'contained',
        color: 'secondary',
        type: 'button',
    },
    defaultInfo: {
        variant: 'contained',
        color: 'infoButton',
        type: 'button',
    },
    defaultPrimaryWithoutVariant: {
        color: 'primary',
        type: 'button',
    },
    defaultOutlined: {
        variant: 'outlined',
        color: 'primary',
        type: 'button',
    },
    defaultTextInfo: {
        variant: 'text',
        color: 'infoButton',
        type: 'text'
    }
};

export default buttonsTypes;
