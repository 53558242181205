import L from 'leaflet';

import removeEmptyFields from 'helpers/removeEmptyFields';
import iconMap from 'modules/ExternalTransport/utils/icons/iconMap';
import { getMarker } from 'components/MapComponents/marker/Marker';

type Types = {
    [key: string | number]: string;
}
export const iconByType: Types = {
    1: 'fas fa-train', //'Электричка'
    2: 'fas fa-plane', //'Самолёт',
    3: 'fas fa-train',  //'ЖД станций',
};
export const createIconMarker = (color, type) => L.divIcon({
    className: 'marker',
    iconSize: [28, 36],
    iconAnchor: [15, 36],
    popupAnchor: [0, -36],
    // html: iconMap({ fill: color }, isAirport),
    html: `
                ${getMarker(color)}
                <i class="${iconByType[type]}" style="color: #FFFFFF; position: absolute; left: 7px; top: 7px; font-size: 16px;"/>
            `
});

export const getFilter = (filter = {}) => {
    const filters = {
        ...filter,
        ...(filter.hasOwnProperty('transport_types') && filter.transport_types
            ? { transport_types: filter.transport_types.map(el => el.id || Number(el)) }
            : {})
    };

    return removeEmptyFields(filters, false);
};

