import L from 'leaflet';

import removeEmptyFields from 'helpers/removeEmptyFields';
import { fullDateTimeWithTimeZone } from 'helpers/date.config';

import iconSvg from './icons/iconSvg';

export const createIconTS = (typeColor, statusColor) => {
    return iconSvg({ inner: typeColor, outer: statusColor });
};

export const createIcon = (typeColor, statusColor) => {
    return iconSvg({ inner: typeColor, outer: statusColor });
};

// получить иконку маркера
export const createIconMarker = (icon) => {
    return L.divIcon({
        className: 'marker',
        iconSize: [30, 30],
        iconAnchor: [15, 30],
        popupAnchor: [0, -30],
        html: `${icon}`,
    });
};

export const getFilter = (filter) => {
    const filters = {
        ...filter,
        start_date: filter.start_date ? fullDateTimeWithTimeZone(filter.start_date) : null,
        end_date: filter.end_date ? fullDateTimeWithTimeZone(filter.end_date) : null,
        status: filter?.status || null,
        type: filter?.type || null
    };

    return removeEmptyFields(filters, false);
};

export const getItemToSave = (item) => {
    const ret = { ...item };
    if (
        !ret.hasOwnProperty('dtp_ids')
        || ret?.dtp_ids?.length === 0
    ) ret.dtp_ids = ret.dtp_list.map(i => i.id) ?? [];

    // задать имя из id дтп
    if (ret.name?.length === 0 && ret?.dtp_ids?.length > 0) {
        const name = [`${ret?.dtp_ids?.[0]}`];
        if (ret?.dtp_ids.length > 1) name.push(`${ret?.dtp_ids?.[ret?.dtp_ids.length - 1]}`);
        ret.name = name.join(' - ');
    }

    return ret;
};
