import React from 'react';
import { useDispatch } from 'react-redux';
import { Tooltip } from '@mui/material';

import { setSelectedLayer } from 'redux/Menu/actions';
import layersLazyLoad from 'components/MapComponents/helpers/layersLazyLoad';
import FormButtonsComponent from 'components/common/FormButtons';
import titles from 'helpers/constants/titles';

import './style.scss';

const LayersSideBar = ({ selectedLayer = '' }) => {
    const dispatch = useDispatch();
    // подключенные слои
    const layers = layersLazyLoad();
    // забираем из слоя sidebar
    const SelectedLayerComponent = layers[selectedLayer]?.SideBar;

    // назад к меню
    const handleClickToBack = () => {
        dispatch(setSelectedLayer());
    };

    const renderHeader = () => (
        <div className="layers-sidebar__header">
            <FormButtonsComponent
                className="go-to-back"
                noPadding
                positionLeft
                noMarginLeft
                buttons={[
                    {
                        onClick: handleClickToBack,
                        name: <Tooltip title={titles.BACK_TO_MENU}><span><i className="fas fa-arrow-left"></i></span></Tooltip>
                    }
                ]}
            />
        </div>
    );

    const renderComponentLayer = () => !!selectedLayer ? <SelectedLayerComponent /> : null;

    return (
        <div className="layers-sidebar layers-sidebar__flex-column">
            {/* {renderHeader()} */}
            {SelectedLayerComponent
                ? renderComponentLayer()
                : (
                    <div className="layers-sidebar__layer-not-list">
                        У выбранного слоя нет списка
                    </div>
                )}
        </div>
    );
};

export default LayersSideBar;
