import { config } from 'config';

const apiCarsWanted = () => config.get('urls').apiCarsWanted;

const apiUrls = {
    getTrackingList: () => `${apiCarsWanted()}/tracking`,

    getTrackingMoreInfo: (id) => `${apiCarsWanted()}/tracking/report/${id}`,

    getTrackingDeployedInfo: (id, grz) => `${apiCarsWanted()}/tracking/report/grz/${id}/${grz}`,

    getTrackingStatuses: () => `${apiCarsWanted()}/tracking/statuses`,

    loadWantedList: () => `${apiCarsWanted()}/wanted`,

    createWanted: () => `${apiCarsWanted()}/wanted`,

    editWanted: (id) => `${apiCarsWanted()}/wanted/${id}`,

    deleteWanted: (id) => `${apiCarsWanted()}/wanted/${id}`,

    loadTypesBase: () => `${apiCarsWanted()}/cars_wanted_types`,

    createTracking: () => `${apiCarsWanted()}/tracking/`,

    editTracking: (id) => `${apiCarsWanted()}/tracking/${id}`,

    deleteTracking: (id) => `${apiCarsWanted()}/tracking/${id}`,

    stopWanted: (id) => `${apiCarsWanted()}/wanted/${id}/stop`,

    loadWantedEvents: () => `${apiCarsWanted()}/event-wanted`,

    getVehicleInfo: () => `${apiCarsWanted()}/vehicle-info`,

    eventWantedTrack: () => `${apiCarsWanted()}/video-event/search`,

    videoEventSources: () => `${apiCarsWanted()}/video-event/sources`,
};

export default apiUrls;
