
const Item = ({ name, address_text }) => {

    return (
        <>
            <div className="description">
                Название: {name}
            </div>
            <div className="description">
                Адрес: {address_text}
            </div>
        </>
    );
};

export default Item;
