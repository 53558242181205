import * as types from './types';

const initialState = {
    colectionServicesList: {
        data: [],
        meta: { last_page: 0 },
    },
    loadingColectionServices: false,
    types: [],
    templates: []
};

export default function reducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case types.LOADED_COLLECTION_SERVICES: {
            return {
                ...state,
                colectionServicesList: payload
            };
        }

        case types.LOADING_COLLECTION_SERVICES: {
            return {
                ...state,
                loadingColectionServices: payload
            };
        }

        case types.LOADED_COLLECTION_SERVICES_TYPES: {
            return {
                ...state,
                types: payload
            };
        }

        case types.LOADED_COLLECTION_SERVICES_TEMPLATES: {
            return {
                ...state,
                templates: payload
            };
        }

        default:
            return state;
    }
}
