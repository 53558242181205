import L from 'leaflet';
import { renderToString } from 'react-dom/server';
import { v4 as uuid_v4 } from 'uuid';

//
import handleClick from './handleClick';
// popup
import handleClickPopUp from './handleClickPopUp';
// contect menu
import handleCloseContextMenu from './contextMenu/handleCloseContextMenu';
import handleShowContextMenu from './contextMenu/handleShowContextMenu';

// функция обработки 1 элемента
const onEachFeature = (
    layerProps,
    layerRef,
    currentIdsRef,
    contextMenuRef,
    decoratorRef
) => (feature, layer) => {
    const {
        map,
        // parent,
        popUpOptions,
        popUpTemplate,
        contextMenuTemplate,
        // centerByClick,
        // centerByClickBefore,
        // centerByClickAfter,
        onClosePopup,
        toolTipOptions,
        toolTipTemplate,
        style,
        // onClick,
        idPrefix,
        cluster,
        decoratorOptions
    } = layerProps;

    if ((feature?.style || style) && layer.setStyle) {
        layer.setStyle(feature.style || style);
    }

    // tooltip
    if (toolTipTemplate) {
        const tooltipContent = toolTipTemplate(feature?.properties?.data || {});
        const renderString = renderToString(tooltipContent);

        if (renderString) {
            const toolTip = L.tooltip(toolTipOptions);
            toolTip.setContent(renderString);
            layer.bindTooltip(toolTip);
        }
    }

    if (feature?.properties && feature?.properties?.data) {
        const newUuId = uuid_v4();

        // popup
        if (popUpTemplate) {
            const newId = newUuId + '_popup';
            // popUpRef.current = newId;
            const popUp = L
                .popup({
                    minWidth: 600,
                    closeOnClick: true,
                    className: 'custom-popup-wrapper',
                    ...(popUpOptions || {})
                })
                .setContent(`<div class="popup-route" id="${newId}"></div>`);
            layer.bindPopup(popUp);

            const id = feature?.properties?.data?.id;

            // вызов функции handleClick не срабатывает
            layer.on('click', handleClickPopUp(feature?.properties?.data || {}, newId, layer, layerProps));
            onClosePopup && layer.on('popupclose', () => onClosePopup());

            if (id) {
                map.on(`showBy${idPrefix}${id}`, () => {
                    handleClick({ cluster, layer, id });
                });

                // addId(id);
                currentIdsRef?.current?.push(id);
            }
        }

        // contextMenu
        if (contextMenuTemplate) {
            const newId = newUuId + '_contextmenu';
            map.on('context_menu_close', handleCloseContextMenu(newId, contextMenuRef));
            layer.on('contextmenu', handleShowContextMenu(
                layerProps, layer, feature.properties.data, newId, layerRef, contextMenuRef
            ));
        }
    }

    if(decoratorOptions){
        const polylineArrow = new L.polylineDecorator(layer, decoratorOptions(style.color));
        polylineArrow.addTo(map);

        decoratorRef?.current?.push(polylineArrow);
    }
};

export default onEachFeature;
